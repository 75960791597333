import React, { useState, useEffect, createRef } from "react";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { Activity, ChevronDown, Eye, EyeOff, TrendingUp, ChevronUp, CornerDownRight, RefreshCw, ExternalLink } from "react-feather";
import { Config as config } from "../../lib/config";
import styled from "styled-components";
import Logo from "../../assets/C_Logo_Blue.webp";
import { Edit3 } from "react-feather";
import Modal from "../Modal";
import SnackBar from "../SnackBar";
import { FaRecycle, FaUserShield } from "react-icons/fa";
import { update, userData, cancel, reactivate, regenerate, userSubscriptions } from "../../lib/api/user";
import { AxiosResponse } from "axios";
import { MdError, MdConstruction } from "react-icons/md";
import Spinner from "../Spinner";
import { Link, useLocation } from "react-router-dom";
import ConfirmationModal from "./confirmation";
import ConfirmModal from "./confirm";
import ConfirmRegenModal from "./confirmRegen";
import { convertUTCToLocal } from "../../lib/utils";

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 42px 0px;
  width: 100%;
`

const AccountContainer = styled.div`
  display: flex;
  flex-direction column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const ProfileImg = styled.div`
  border: 1px solid #303030;
  border-radius: 6px;
  padding: 18px;
  height: 160px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SelectImageTextWrapper = styled.p`
  color: lightgrey;
  font-size: 12px;
  opacity: 1;
  position: absolute;
  font-weight: 600;
  pointer-events: none;
`;

export const ImageWrapper = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    filter: blur(4px);
  }
`;

export const ProfileImgP = styled.div`
  border: 1px solid #303030;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageWrapperP = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100%;
`;

export const NoticeWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  text-align: left;
  font-size: 14px;
  width: 80%;
  font-weight: 100;
  color: grey;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  gap: 10px;
`;

export const OuterDiv = styled.div`
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease;
  border-radius: 100%;
`;

export const ImageIconWrapper = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  top: 25px;
  right: 25px;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const CreateProposalIconWrapper = styled.div`
  padding: 6px 6px 4px 6px;
  border-radius: 50%;
  border: 1px solid #909090;
  cursor: pointer;
  &:hover {
    border: 1px solid lightgrey;
  }
`

export const NoticeContent = styled.div`
  color: lightgrey;
  pointer-events: all;
  font-size: 1rem;
  font-weight: 100;
  line-height: 18px;
`

export const NoticeContentStrong = styled.span`
  font-weight: 400;
  cursor: pointer;
  color: ${({ color }) => color};
  a {
    color: ${({ color }) => color};
    text-decoration: none;
  }
`

export const InputCustom = styled.input`
  text-align: left;
  background-color: transparent;
  border: 1px solid #303030;
  border-radius: 6px;
  color: white;
  width: ${({ width }) => width};
  white-space: nowrap;
  height: ${({ height }) => height};
  padding: 4px 12px;
  &:focus {
    background-color: lightgrey;
    color: black;
  }
`

export const InputCustomAlt = styled.input`
  text-align: left;
  background-color: transparent !important;
  color: white;
  width: ${({ width }) => width};
  white-space: nowrap !important;
  height: ${({ height }) => height};
  border: transparent !important;
  padding: 4px 12px;
  &:focus {
    border: transparent !important;
    outline: none !important;
  }
`

export const InputCustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 1px solid #303030;
  border-radius: 6px;
  width: 420px;
`

export const CopyButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 6px;
  padding: 4px 6px;
  border: 1px solid #303030;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: #202020;
    filter: brightness(150%);
  }
`

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
  width: 90%;
  @media (max-width: 1400px) {
    flex-direction: column;
    padding: 42px 0px 0px 0px;
  }
`

export const Box1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 420px;
  align-items: center;
  justify-content: center;
  padding: 22px 42px;
  border-radius: 6px;
  border: 1px solid #303030;
  height: 200px;
  gap: 42px;
  @media (max-width: 1400px) {
    width: 100%;
    padding: 22px 0px;
    gap: 22px;
  }
  @media (max-width: 620px) {
    width: 100%;
    padding: 22px 0px;
    flex-direction: column;
    height: fit-content;
    gap: 12px;
  }
`

export const Box2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 620px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #303030;
  gap: 22px;
  height: 620px;
  @media (max-width: 1400px) {
    width: 100%;
  }
`

export interface User {
  email: string;
  first_name: string;
  last_name: string;
  user_id: string;
  available_requests: string;
  user_tier: string;
}

export interface Subscription {
  subscription_id: string;
  status: string;
  current_period_start: string;
  current_period_end: string;
  cancel_at_period_end: boolean;
}

const Account = () => {
  const [id, setId] = useState<string | null | undefined>(sessionStorage.getItem("id"));
  const [auth, setAuth] = useState<string | undefined | null>(sessionStorage.getItem("auth"));
  const [user, setUser] = useState<User | undefined>(undefined);
  const [fname, setFirstName] = useState<string | undefined>(undefined);//(user !== undefined ? user.last_name : undefined);
  const [lname, setLastName] = useState<string | undefined>(undefined);//(user !== undefined ? user.last_name : undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);//(user !== undefined ? user.email : undefined);
  const [botImg, setBotImg] = useState(Logo);
  const [uploadImg, setUploadImg] = useState(false);
  const [botUploadFile, setBotUploadFile] = useState(null);
  const [showSelectImg, setShowSelectImg] = useState(false);
  const fileInputRef = createRef<HTMLInputElement>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profileContent, setProfileContent] = useState<number>(2);
  const [snackBarTitle, setSnackBarTitle] = useState<string | undefined>(undefined);
  const [snackBarType, setSnackBarType] = useState<string | undefined>(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  const [showPassowrd, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showActive, setShowActive] = useState<boolean>(false);
  const [showRequestDetails, setShowRequestDetails] = useState<boolean>(true);
  const [showSubscriptionDetails, setShowSubscriptionDetails] = useState<boolean>(true);
  const [loadingSubs, setLoadingSubs] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [showActiveAuthCancel, setShowActiveAuthCancel] = useState<boolean>(false);
  const [showActiveAuthReactivate, setShowActiveAuthReactivate] = useState<boolean>(false);
  const [actionReactivate, setActionReactivate] = useState<boolean>(false);
  const [showActiveAuthRegen, setShowActiveAuthRegen] = useState<boolean>(false);

  const {theme} = useGetTheme();
  const location = useLocation();

  const ButtonA = styled.div`
    padding: 10px;
    width: 220px;
    border: 1px solid #303030;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    height: fit-content;
    transition: all 0.3s ease-in-out;
    filter: brightness(100%);
    &:hover {
      filter: brightness(150%);
      border: 1px solid ${theme === 'dark' ? "#909090" : "#101010"};
    }
    span {
      color: lightgrey;
      font-weight: 600;
      font-size: 14px;
    }
  `

  async function checkUserLogin() {
    setLoading(true)
    if (id !== undefined && id !== null) {
      const response = await userData(JSON.parse(id));
      if (response.status !== 200) {
        setAuth(JSON.stringify(false));
        setId(undefined);
        sessionStorage.setItem("auth", JSON.stringify(false))
        sessionStorage.removeItem("id");
        window.location.href = "/"
      } else {
        console.log("USER", response.data)
        setUser(response.data)
        if (password === undefined) {
          setPassword(response.data.password);
        }
        if (fname === undefined) {
          setFirstName(response.data.first_name);
        }
        if (lname === undefined) {
          setLastName(response.data.last_name);
        }
        if (email === undefined ) {
          setEmail(response.data.email);
        }
      }
    }
    setLoading(false)
  }

  const hideModal = () => setShowModal(false);

  const onFileChange = (event: any) => {
    console.log(event.target.files[0]);
    if (event.target.files[0]) {
      let img = event.target.files[0];
      setBotUploadFile(img);
      setBotImg(img === undefined ? botImg : URL.createObjectURL(img));
      setUploadImg(true);
    }
  };

  const handleChange = (e: any, t: string) => {
    var tar = e.target.value;
    if (t === 'fname') {
      setFirstName(tar)
    } else if (t === 'lname') {
      setLastName(tar)
    } else if (t === 'email') {
      setEmail(tar)
    } else if (t === 'password') {
      setPassword(tar)
    }
  };

  function addDefaultSrc(e: React.ChangeEvent<HTMLImageElement>) {
    e.currentTarget.src = "default.webp";
  }

  const saveInfo = async () => {
    let response: AxiosResponse | undefined = undefined;
    if (id !== undefined && id !== null) {
      // update
      response = await update(JSON.parse(id), {
        first_name: fname,
        last_name: lname,
        email: email,
        password: password
      });
      console.log(response)
      if (response !== undefined) {
        if (response.status !== 200) {
          try {
            if (response.data.errors.length > 0) {
              setErrors(response.data.errors);
            }
          } catch (e) {
            setErrors(["Unknown Error"])
          }
        } else {
          setErrors([])
          setSnackBarTitle("Updated")
          setSnackBarType("success")
          setTimeout(() => {
            setSnackBarType(undefined);
            setSnackBarTitle(undefined);
            setAuth(JSON.stringify(true));
            setId(JSON.stringify(response?.data.user_id));
            sessionStorage.setItem("auth", JSON.stringify(true));
            sessionStorage.setItem("id", JSON.stringify(response?.data.user_id));
            checkUserLogin()
            setShowModal(false);
          }, config.snackTimeout);
        }
      }
    }
  }

  const ModalBody = (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%",}}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center",  justifyContent: "center", width: "82%", gap: "12px",}}>
        <div style={{display: "flex", flexDirection: "row",  alignItems: "center", justifyContent: "space-between",width: "100%"}}>
          {/*<div style={{display: "flex", flexDirection: "column", alignItems: "center",}}>
            <RowWrapper>
              <input
                type="file"
                onChange={(event) => onFileChange(event)}
                ref={fileInputRef}
                hidden
              />
              <ProfileImg>
                <ImageWrapper
                  onMouseOver={() => {
                    setShowSelectImg(true);
                  }}
                  onMouseOut={() => {
                    setShowSelectImg(false);
                  }}
                  onClick={() => fileInputRef.current?.click()}
                  src={botImg}
                  alt="bot-profile-img"
                  onError={addDefaultSrc}
                />
                {showSelectImg && (
                  <SelectImageTextWrapper>
                    {"Upload Image"}
                  </SelectImageTextWrapper>
                )}
                {botImg !== Logo ? (
                  <p
                    onClick={() => {
                      setBotImg(Logo);
                    }}
                    style={{
                      color: "lightgrey",
                      fontSize: "12px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </p>
                ) : (
                  ""
                )}
              </ProfileImg>

              <OuterDiv>
                <ImageIconWrapper>
                  <Edit
                    onClick={() => fileInputRef.current?.click()}
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "#1f1f1f",
                      cursor: "pointer",
                      // marginLeft: "4px",
                    }}
                  />
                </ImageIconWrapper>
              </OuterDiv>
            </RowWrapper>
          </div>*/}
          <div style={{display: "flex", flexDirection: "column",  alignItems: "center", justifyContent: "center", gap: "6px",width: "100%"}}>
            <InputCustom
              type="text"
              name="fname"
              value={fname}
              onChange={event => handleChange(event, "fname")}
              placeholder="First Name"
              width={"100%"}
              height={"36px"}
              />
            <InputCustom
              type="text"
              name="lname"
              value={lname}
              onChange={event => handleChange(event, "lname")}
              placeholder="Last Name"
              width={"100%"}
              height={"36px"}
              />
            <InputCustom
              type="email"
              name="email"
              value={email}
              onChange={event => handleChange(event, "email")}
              placeholder="Email"
              width={"100%"}
              height={"36px"}
              />
            <InputCustomContainer>
              <InputCustomAlt
                type={showPassowrd ? "text" : "password"}
                name="password"
                value={password}
                onChange={event => handleChange(event, "password")}
                placeholder="Password"
                width={"100%"}
                height={"36px"}
                />
              {showPassowrd
              ? <Eye size={18} cursor={"pointer"} color={getThemeData(theme).colorAccent} onClick={() => {setShowPassword(false)}}/>
              : <EyeOff size={18} cursor={"pointer"} color={getThemeData(theme).colorAccent} onClick={() => {setShowPassword(true)}}/>
              }
              <div style={{width: "12px"}}/>
            </InputCustomContainer>
            {/*<ButtonA content={<span> {"Save"} </span>} onclick={() => {saveInfo()}}/>*/}
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row",  alignItems: "center", justifyContent: "center",width: "100%"}}>
          <ButtonA onClick={() => {saveInfo()}}>{<span> {"Save"} </span>}</ButtonA>
        </div>
        <div style={{width: "100%", height: "22px"}}/>
        {errors.map((value, _index) => {
          return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "4px"}}>
              <MdError size={18} color={"red"}/>
              <span style={{fontSize: "0.8rem", fontWeight: 600, color: "red"}}>
                {value}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  );

  const ProfileMenuOption = styled.div<{active: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 12px;
    width: 100%;
    border-right: 2px solid ${({ active }) => (active ? getThemeData(theme).textColor : "grey")};
    cursor: pointer;
    span {
      padding-right: 12px;
      color: ${({ active }) => (active ? getThemeData(theme).textColor : "grey")};
      font-size: 1rem;
      font-weight: 600;
    }
    &:hover {
      filter: brightness(150%);
    }
    @media (max-width: 1400px) {
      justify-content: center;
      padding: 10px;
      width: 220px;
      border: 1px solid #303030;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;
      height: fit-content;
      transition: all 0.3s ease-in-out;
      filter: brightness(100%);
      &:hover {
        filter: brightness(150%);
        border: 1px solid ${theme === 'dark' ? "#909090" : "#101010"};
      }
      span {
        color: ${({ active }) => (active ? getThemeData(theme).textColor : "grey")};
        font-weight: 600;
        font-size: 14px;
      }
    }
  `

  async function getUserSubscription() {
    setLoadingSubs(true);
    if (id !== null && id !== undefined) {
      const resp: any = await userSubscriptions(JSON.parse(id));
      console.log("SUBS", resp)
      if (resp && resp.data && resp.data.subscriptions && resp.data.subscriptions.data) {
        let temp = resp.data.subscriptions.data;
        temp = temp.filter((obj:any) => !obj.status.includes("incomplete"));
        temp = temp.filter((obj:any) => !obj.status.includes("canceled"));
        temp = temp.filter(
          (obj:any) =>
            !(
              obj.cancel_at_period_end &&
              obj.cancel_at &&
              Math.floor(Date.now() / 1000) > obj.cancel_at
            )
        );
        // currently just one possible subscription
        if (temp.length > 0) {
          if (temp[0].cancel_at_period_end === true) {
            setActionReactivate(true);
          } else {
            setActionReactivate(false);
          }
        } else {
          setActionReactivate(false);
        }
        let subs: Subscription[] = [];
        var offset = new Date().getTimezoneOffset();
        for (let i = 0; i < temp.length; i++) {
          //var cps = new Date(temp[i].current_period_start*1000);
          //var cpe = new Date(temp[i].current_period_end*1000);
          var cps = convertUTCToLocal(temp[i].current_period_start*1000).toLocaleString();
          var cpe = convertUTCToLocal(temp[i].current_period_end*1000).toLocaleString();
          var stt = temp[i].status[0].toUpperCase()+temp[i].status.substring(1);
          subs.push({
            subscription_id: temp[i].id,
            status: stt,
            current_period_start: cps,
            current_period_end: cpe,
            cancel_at_period_end: temp[i].cancel_at_period_end,
          })
        }
        setSubscriptions([...subs]);
      }
    }
    setLoadingSubs(false)
  }

  async function cancelSubscription(p: string) {
    if (id !== null && id !== undefined) {
      console.log("SUBSCRIPTIONS", subscriptions)
      // currently just one possible subscription
      if (subscriptions.length > 0) {
        const resp: any = await cancel(
          subscriptions[0].subscription_id,
          p,
          JSON.parse(id),
        );
        console.log("CANCEL", resp)
        if (resp && resp.status === 200) {
          setSnackBarTitle("Canceled")
          setSnackBarType("success")
          setTimeout(() => {
            setSnackBarTitle(undefined);
            setSnackBarType(undefined);
            checkUserLogin()
            getUserSubscription()
            setShowActiveAuthCancel(false);
          }, config.snackTimeout);
        } else {
          setSnackBarTitle("Wrong Password")
          setSnackBarType("error")
          setTimeout(() => {
            setSnackBarTitle(undefined);
            setSnackBarType(undefined);
          }, config.snackTimeout);
        }
      }
    }
  }

  async function reactivateSubscription(p: string) {
    if (id !== null && id !== undefined) {
      console.log("SUBSCRIPTIONS", subscriptions)
      // currently just one possible subscription
      if (subscriptions.length > 0) {
        const resp: any = await reactivate(
          subscriptions[0].subscription_id,
          p,
          JSON.parse(id),
        );
        console.log("REACTIVATE", resp)
        if (resp && resp.status === 200) {
          setSnackBarTitle("Reactivated")
          setSnackBarType("success")
          setTimeout(() => {
            setSnackBarTitle(undefined);
            setSnackBarType(undefined);
            checkUserLogin()
            getUserSubscription()
            setShowActiveAuthReactivate(false);
          }, config.snackTimeout);
        } else {
          setSnackBarTitle("Wrong Password")
          setSnackBarType("error")
          setTimeout(() => {
            setSnackBarTitle(undefined);
            setSnackBarType(undefined);
          }, config.snackTimeout);
        }
      }
    }
  }

  async function regenUserAPIKey() {
    if (id !== null && id !== undefined) {
      if (subscriptions.length > 0) {
        const resp: any = await regenerate(JSON.parse(id));
        console.log("REGEN", resp)
        if (resp && resp.status === 200) {
          setSnackBarTitle("Regenerated API Key")
          setSnackBarType("success")
          setTimeout(() => {
            setSnackBarTitle(undefined);
            setSnackBarType(undefined);
            checkUserLogin()
            setShowActiveAuthRegen(false);
          }, config.snackTimeout);
        } else {
          setSnackBarTitle("Unknown Error")
          setSnackBarType("error")
          setTimeout(() => {
            setSnackBarTitle(undefined);
            setSnackBarType(undefined);
          }, config.snackTimeout);
        }
      }
    }
  }

  const fullContW = "80%";
  const leftHandSideW = "40%";
  const rightHandSideW = "60%";

  const upgradePlan = () => {window.location.href = `${config.appUrl}#plans`}

  useEffect(() => {
    checkUserLogin()
  },[])

  useEffect(() => {
    getUserSubscription()
  },[])

  useEffect(() => {
    const state = location.search;
    var a = window.location.href;
    var b = a.substring(a.indexOf("?") + 1);
    if (state && b === "success=true") {
      setTimeout(() => {
        setShowActive(true);
      }, 1500);
    } else {
      setShowActive(false);
    }
  }, []);

  const firstDateOfMonth = (date = new Date()) => {
    if (date.getMonth() == 11) {
      return new Date(date.getFullYear() + 1, 0, 1);
    } else {
      return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
  }

  return (
    <AccountWrapper>
      <ConfirmModal
        setShowActive={setShowActiveAuthCancel}
        showActive={showActiveAuthCancel}
        actionName={"Cancel Subscription"}
        handleAction={cancelSubscription}
      />
      <ConfirmModal
        setShowActive={setShowActiveAuthReactivate}
        showActive={showActiveAuthReactivate}
        actionName={"Reactivate Subscription"}
        handleAction={reactivateSubscription}
      />
      <ConfirmRegenModal
        setShowActive={setShowActiveAuthRegen}
        showActive={showActiveAuthRegen}
        actionName={"Regenerate API Key"}
        handleAction={regenUserAPIKey}
      />
      <ConfirmationModal
        setShowActive={setShowActive}
        showActive={showActive}
        title={"Congratulations on your new Subscription! 🎉"}
        description={
          <>
            <p>
              {"Follow us on"}
              <Link to={config.twitterUrl} target="_self" style={{textDecoration: "none"}}>
                <div style={{display: "inline-flex", alignItems: "center", justifyContent: "center", padding:"0px 4px",gap: "4px", cursor: "pointer"}}>
                  <span style={{color: getThemeData(theme).colorAccent, fontWeight: 600, lineHeight: "22px"}}>
                    Twitter
                  </span>
                  <ExternalLink size={16} color={getThemeData(theme).iconColor}/>
                </div>
              </Link>
              {"or"}
              <Link to={config.discordUrl} target="_self" style={{textDecoration: "none"}}>
                <div style={{display: "inline-flex", alignItems: "center", justifyContent: "center", padding:"0px 4px",gap: "4px", cursor: "pointer"}}>
                  <span style={{color: getThemeData(theme).colorAccent, fontWeight: 600, lineHeight: "22px"}}>
                    Discord
                  </span>
                  <ExternalLink size={16} color={getThemeData(theme).iconColor}/>
                </div>
              </Link>
              {"to stay updated on exclusive features available to our subscribers."}
            </p>
            <p>{"Get started by checking out our documentation."}</p>
          </>
        }
      />
      <AccountContainer>
        {loading
        ? <Spinner text={"Loading"} color={"white"} />
        : <BoxWrapper>
            <Box1>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",width: "50%"}}>
                {/*<ProfileImgP>
                  <ImageWrapperP src={Logo}/>
                </ProfileImgP>*/}
                <FaUserShield size={92} color={getThemeData(theme).colorAccent}/>
                <div style={{height: "12px"}}/>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", gap: "6px", textAlign: "center"}}>
                  <span style={{color: getThemeData(theme).textColor, fontSize: "16px", fontWeight: "600"}}>
                    {user !== undefined && `${user.first_name} ${user.last_name}`}
                  </span>
                </div>
                <div style={{height: "6px"}}/>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", gap: "8px"}}>
                  {/*<FaUserShield size={18} color={getThemeData(theme).colorAccent}/>*/}
                  <span style={{color: getThemeData(theme).textColor4, fontSize: "0.9rem", fontWeight: "600"}}>
                    {user !== undefined && `${user.email}`}
                  </span>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",width: "50%"}}>
                <ProfileMenuOption active={(profileContent === 2)} onClick={() => {setProfileContent(2)}}>
                  <Edit3 size={22} color={profileContent === 2 ? getThemeData(theme).iconColor : "grey"}/>
                  <span>
                    {"Manage"}
                  </span>
                </ProfileMenuOption>
                <div style={{height: "22px"}}/>
                <ProfileMenuOption active={(profileContent === 1)} onClick={() => {setProfileContent(1)}}>
                  <Activity size={22} color={profileContent === 1 ? getThemeData(theme).iconColor : "grey"}/>
                  <span>
                    {"Activity"}
                  </span>
                </ProfileMenuOption>
                <div style={{height: "22px"}}/>
                {auth !== undefined && auth !== null && JSON.parse(auth) === true
                  && <>
                    <ButtonA onClick={() => {setShowModal(true)}}>
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "12px", width: "100%"}}>
                        <span style={{paddingRight: "12px", fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                          {"Edit Profile"}
                        </span>
                      </div>
                    </ButtonA>
                  </>
                  }
              </div>
            </Box1>
            {(profileContent === 1) &&
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Box2>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: "100%", borderBottom: "1px solid #303030",}}>
                    <span style={{fontSize: "16px", color: getThemeData(theme).textColor, padding: "12px", fontWeight: 600}}>
                      {"View Account API Activity"}
                    </span>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"center", width: fullContW, gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "6px", width: leftHandSideW+rightHandSideW}}>
                    <MdConstruction size={22} color={getThemeData(theme).textColor}/>
                      <span style={{fontSize: "22px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"Coming Soon"}
                      </span>
                      <MdConstruction size={22} color={getThemeData(theme).textColor}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: leftHandSideW+rightHandSideW}}>
                      <span style={{fontSize: "16px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"Explore API requests and Logs for your integration"}
                      </span>
                    </div>
                  </div>
                </Box2>
              </div>
            }
            {(profileContent === 2) &&
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                  <Box2>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: "100%", borderBottom: "1px solid #303030",}}>
                    <span style={{fontSize: "16px", color: getThemeData(theme).textColor, padding: "12px", fontWeight: 600}}>
                      {"Manage API Key and Subscription"}
                    </span>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: leftHandSideW}}>
                      <span style={{fontSize: "16px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"Tier"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: rightHandSideW}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {user !== undefined
                        ? <>
                          {parseInt(user.user_tier) === 1 && "Free" /*"Starter"*/}
                          {parseInt(user.user_tier) === 2 && "Starter" /*"Professional"*/}
                          {/*parseInt(user.user_tier) === 3 && "Premium"*/}
                          {parseInt(user.user_tier) === 4 && "Premium" /*"Elite"*/}
                        </>
                        : "-"
                        }
                      </span>
                    </div>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,}}>
                      <span style={{fontSize: "16px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"Rate limit"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: rightHandSideW,}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {"500 requests per minute"}
                      </span>
                    </div>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: leftHandSideW, gap: "4px"}}>
                      <span style={{fontSize: "16px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"Requests"}
                      </span>
                      {!showRequestDetails && <ChevronDown cursor={"pointer"} size={18} color={getThemeData(theme).textColor} onClick={() => setShowRequestDetails(true)}/>}
                      {showRequestDetails && <ChevronUp cursor={"pointer"} size={18} color={getThemeData(theme).textColor} onClick={() => setShowRequestDetails(false)}/>}
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", width: rightHandSideW}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {user !== undefined
                        ? <>
                          {parseInt(user.user_tier) === 1 && parseInt("1000").toLocaleString()}
                          {parseInt(user.user_tier) === 2 && parseInt("50000").toLocaleString()}
                          {parseInt(user.user_tier) === 3 && parseInt("500000").toLocaleString()}
                          {parseInt(user.user_tier) === 4 && parseInt("5000000").toLocaleString()}
                        </>
                        : "-"
                        }
                        {" per month"}
                      </span>
                    </div>
                  </div>
                  {showRequestDetails && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                      <CornerDownRight size={18} color={getThemeData(theme).colorAccent} />
                      <span style={{fontSize: "16px", color: getThemeData(theme).colorAccent, fontWeight: 600}}>
                        {"Available"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,gap: "8px"}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {user !== undefined
                        ? <> {parseInt(user.available_requests).toLocaleString()} </>
                        : "-"
                        }
                        {" this month"}
                      </span>
                      <RefreshCw size={16} color={getThemeData(theme).textColor} onClick={() => checkUserLogin()} cursor={"pointer"}/>
                    </div>
                  </div>}
                  {showRequestDetails && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                      <CornerDownRight size={18} color={getThemeData(theme).colorAccent} />
                      <span style={{fontSize: "16px", color: getThemeData(theme).colorAccent, fontWeight: 600}}>
                        {"Reset"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {firstDateOfMonth().toLocaleString()}
                      </span>
                    </div>
                  </div>}
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                      <span style={{fontSize: "16px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"API Key"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,}}>
                      <ButtonA onClick={() => {setShowActiveAuthRegen(true)}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "12px", width: "100%" }}>
                          <FaRecycle size={18} color={getThemeData(theme).colorAccent}/>
                          <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                            {"Regenerate"}
                          </span>
                        </div>
                      </ButtonA>
                    </div>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                      <span style={{fontSize: "16px", color: getThemeData(theme).textColor, fontWeight: 600}}>
                        {"Subscription"}
                      </span>
                      {!showSubscriptionDetails && <ChevronDown cursor={"pointer"} size={18} color={getThemeData(theme).textColor} onClick={() => setShowSubscriptionDetails(true)}/>}
                      {showSubscriptionDetails && <ChevronUp cursor={"pointer"} size={18} color={getThemeData(theme).textColor} onClick={() => setShowSubscriptionDetails(false)}/>}
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,}}>
                      <ButtonA onClick={() => {
                        if (user !== undefined) {
                          if (parseInt(user.user_tier) === 1 && !actionReactivate) {
                            upgradePlan()
                          } else {
                            if (actionReactivate) {
                              setShowActiveAuthReactivate(true);
                            } else {
                              setShowActiveAuthCancel(true);
                            }
                          }
                        }
                      }}>
                        {user !== undefined
                        ? parseInt(user.user_tier) === 1 && !actionReactivate
                          ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "12px", width: "100%"}}>
                            <TrendingUp size={18} color={getThemeData(theme).colorAccent}/>
                            <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                              {"Upgrade to Paid Plan"}
                            </span>
                          </div>
                          : <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "12px", width: "100%"}}>
                          <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                            {actionReactivate ? "Reactivate" : "Cancel"}
                          </span>
                        </div>
                        : null}
                      </ButtonA>
                    </div>
                  </div>
                  {showSubscriptionDetails && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                    <CornerDownRight size={18} color={getThemeData(theme).colorAccent} />
                    <span style={{fontSize: "16px", color: getThemeData(theme).colorAccent, fontWeight: 600}}>
                      {"Status"}
                    </span>
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,}}>
                    <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                      {subscriptions && subscriptions.length > 0 && subscriptions[0].status
                      ? <> {subscriptions[0].cancel_at_period_end
                        ? `${subscriptions[0].status} - Canceled at period end`
                        : `${subscriptions[0].status} - Renewed at period end`
                        } </>
                      : "None"}
                    </span>
                  </div>
                  </div>}
                  {showSubscriptionDetails && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                      <CornerDownRight size={18} color={getThemeData(theme).colorAccent} />
                      <span style={{fontSize: "16px", color: getThemeData(theme).colorAccent, fontWeight: 600}}>
                        {"Current Period Start"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {subscriptions && subscriptions.length > 0 && subscriptions[0].current_period_start
                        ? subscriptions[0].current_period_start
                        : "None"}
                      </span>
                    </div>
                  </div>}
                  {showSubscriptionDetails && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: fullContW,gap: "12px"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: leftHandSideW,gap: "6px"}}>
                      <CornerDownRight size={18} color={getThemeData(theme).colorAccent} />
                      <span style={{fontSize: "16px", color: getThemeData(theme).colorAccent, fontWeight: 600}}>
                        {"Current Period End"}
                      </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left",width: rightHandSideW,}}>
                      <span style={{fontSize: "16px", fontWeight: 600, color:getThemeData(theme).textColor}}>
                        {subscriptions && subscriptions.length > 0 && subscriptions[0].current_period_end
                        ? subscriptions[0].current_period_end
                        : "None"}
                      </span>
                    </div>
                  </div>}
                </Box2>
              </div>
            }
          </BoxWrapper>
        }
      </AccountContainer>
      {showModal &&
        <Modal
          title="Edit Profile"
          active={showModal}
          hideModal={hideModal}
          body={ModalBody}
          action={""}
        />
      }
      {snackBarTitle !== undefined && <SnackBar title={snackBarTitle} type={snackBarType} />}
    </AccountWrapper>
  );
}

export default Account;