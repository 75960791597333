import { useEffect, useState } from "react";
import { Code, Users, Briefcase, Link2 } from "react-feather"
import styled from 'styled-components'
import { getThemeData } from "../../theme"
import { useGetTheme } from "../../lib/contexts";
import { Config as config } from "../../lib/config";
import { Link, useNavigate } from "react-router-dom";
import Stats from '../Stats';

interface ButtonClaimRewardsProps {
  color: string;
  backgroundColor: string;
  backgroundColorHover: string;
}

interface ButtonStakeProps {
  backgroundColor: string;
  backgroundColorHover: string;
}

export const ButtonClaimRewards = styled.div<ButtonClaimRewardsProps>`
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  padding: 12px;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: black;
  }
`

export const ButtonStake = styled.div<ButtonStakeProps>`
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  color: lightgrey;
  background-color: ${(props) => props.backgroundColor};
  padding: 12px;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: black;
  }
`

export const ButtonBuy = styled.div<ButtonStakeProps>`
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  color: lightgrey;
  background-color: ${(props) => props.backgroundColor};
  padding: 12px;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: black;
  }
`

export const RewardsBody = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 27px;
  color: ${({color}) => color};
`

export const RewardsBodyWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`

export const LearnMoreWrap = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 28px;
  color: ${(props) => props.color};
  cursor: pointer;
`

export const BodyBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 260px;
  width: 464px;
  padding: 22px;
  margin: 12px 12px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: ${({color}) => color};
  &:hover {
    transform: scale(1.05);
    @media (max-width: 600px) {
      transform: none;
    }
  }
  @media (max-width: 600px) {
    width: 92%;
    padding: 22px 2%;
    margin: 12px 0;
    height: fit-content;
    margin-left: 2%;
  }
`

export const BodyCellWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 100%;
  justify-content: space-between;
`

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${({color}) => color};
`

const HeadingText = styled.span`
  font-size: 2rem;
  font-weight: 800;
  background: ${(props) => props.color};
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const SubHeadingText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 27px;
  background: ${(props) => props.color};
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const Features = () => {
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <div style={{ display: "flex", paddingTop: "100px", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "0px 0px", gap: "22px" }}>
    <div style={{ display: "flex", paddingTop: "100px", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
    <Stats/>
    </div>
    <div style={{ display: "flex", paddingTop: "100px", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingBottom: "20px", }}>
      <div style={{ textAlign: "center" }}>
        <HeadingText color={getThemeData(theme).textColor}>
          {"Disrupting Traditional Betting Markets Globally"}
        </HeadingText>
      </div>
      <div style={{ display: "flex", flexDirection: "row", maxWidth: (windowDimension.winWidth > 600 ? "50%" : "100%"), padding: "22px 12px" }}>
        <SubHeadingText color={getThemeData(theme).textColor}>
          {"Unlock new possibilities with reliable, accurate and comprehensive statistics made possible by combining odds and game data."}
        </SubHeadingText>
      </div>
      <RewardsBodyWrap>
        <BodyBoxWrap color={getThemeData(theme).bgBox1}>
          <BodyCellWrap>
            <TitleWrap>
              <div>
                <Title color={getThemeData(theme).textColor}>
                  {"For Developers"}
                </Title>
              </div>
              <div>
                <Code size={32} color={"lightgrey"} />
              </div>
            </TitleWrap>
            <div>
              <RewardsBody color={getThemeData(theme).textColor}>
                {"Seamlessly integrate consistent, real-time odds data from various bookies into your applications. "+
                "Access comprehensive and reliable statistics transformed to train predictive algorithms, including reinforcement and deep learning models. "+
                "Innovate by creating novel applications and tools for sports betting analysis and decision-making."
                }
              </RewardsBody>
            </div>
            <div style={{height: "12px"}}/>
            <div onClick={() => {navigate(config.docs)}}>
              <LearnMoreWrap color={getThemeData(theme).textColor}>
                {"Request Data"}
              </LearnMoreWrap>
            </div>
          </BodyCellWrap>
        </BodyBoxWrap>
        <BodyBoxWrap color={getThemeData(theme).bgBox1}>
          <BodyCellWrap>
            <TitleWrap>
              <div>
                <Title color={getThemeData(theme).textColor}>
                  {"For Bettors"}
                </Title>
              </div>
              <div>
                <Users size={32} color={"lightgrey"} />
              </div>
            </TitleWrap>
            <div>
              <RewardsBody color={getThemeData(theme).textColor}>
                {"Make more informed, data-driven betting decisions by accessing comprehensive statistics and indicators. "+
                 "By finding the most volatile odds, comparing bets, visualizing trends, determining optimal hedges and more, advanced bettors can enhance their betting experience, manage risk, and discover their competitive edge."
                }
              </RewardsBody>
            </div>
            <div style={{height: "12px"}}/>
            <div onClick={() => {navigate("/")}}>
              <LearnMoreWrap color={getThemeData(theme).textColor}>
                {"Try The App"}
              </LearnMoreWrap>
            </div>
          </BodyCellWrap>
        </BodyBoxWrap>
      </RewardsBodyWrap>
      <RewardsBodyWrap>
        <BodyBoxWrap color={getThemeData(theme).bgBox1}>
          <BodyCellWrap>
            <TitleWrap>
              <div>
                <Title color={getThemeData(theme).textColor}>
                  {"For Institutions"}
                </Title>
              </div>
              <div>
                <Briefcase size={32} color={"lightgrey"} />
              </div>
            </TitleWrap>
            <div>
              <RewardsBody color={getThemeData(theme).textColor}>
                {"Access consistent and compliant data, ensuring adherence to regulatory requirements while promoting a fairer prediction market and increased transparency in the industry. "+
                 "Conduct in-depth odds market analyses using our platform's comprehensive statistics and indicators to inform strategic decisions and improve efficiency."
                }
              </RewardsBody>
            </div>
            <div style={{height: "12px"}}/>
            <div onClick={(e) => {
              window.location.href = `mailto:${config.email}`;
              e.preventDefault();
            }}>
              <LearnMoreWrap color={getThemeData(theme).textColor}>
                {"Get In Touch"}
              </LearnMoreWrap>
            </div>
          </BodyCellWrap>
        </BodyBoxWrap>
        <BodyBoxWrap color={getThemeData(theme).bgBox1}>
          <BodyCellWrap>
            <TitleWrap>
              <div>
                <Title color={getThemeData(theme).textColor}>
                  {"For Web3"}
                </Title>
              </div>
              <div>
                <Link2 size={32} color={"lightgrey"} />
              </div>
            </TitleWrap>
            <div>
              <RewardsBody color={getThemeData(theme).textColor}>
                {"We offer odds market data from on-chain protocols and unparalleled access to infrastructure for serving odds and game data to smart contracts on the blockchain. "+
                "We provide decentralized data access through oracle networks such as Chainlink, allowing users to access and verify odds and betting market data for reduced information symmetry."}
              </RewardsBody>
            </div>
            <div style={{height: "12px"}}/>
            <div onClick={(e) => {
              window.location.href = `mailto:${config.email}`;
              e.preventDefault();
            }}>
              <LearnMoreWrap color={getThemeData(theme).textColor}>
                {"Get In Touch"}
              </LearnMoreWrap>
            </div>
          </BodyCellWrap>
        </BodyBoxWrap>
      </RewardsBodyWrap>
    </div>
    </div>
  )
}

export default Features;