import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { TypeAnimation } from "react-type-animation";
import Marquee from 'react-marquee-master';
import LogoLoop from "../../assets/C_Logo_Blue.webp";
import LogoLoop2 from "../../assets/C_Logo_LightBlue.webp";
import LogoLoop3 from "../../assets/C_Logo_LightBlue.webp";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRight } from "react-feather";
import { Config as config } from "../../lib/config";

export const TextButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  position: relative;
  //top: -22.5px;
  //left: 0;
  //top: 0;
  //left: 280px;
  //margin-top: 45px;
  //margin-left: 120px;
  left: 10%;
  padding: 0px 50px;

  /*@media (max-width: 1000px) {
    //margin-top: 120px;
    align-items: center;
    left: 0;
  }*/

  /*@media (max-width: 800px) {
    //background: white;
    padding: 0 0 0 50px;
  }*/

  @media (max-width: 1000px) {
    padding: 0 0 0 20px;
  }
`;

interface HeroTextProps {
  wid: any;
}

export const HeroText = styled(motion.div)<HeroTextProps>`
  font-size: 2.2rem;
  text-align: left;
  line-height: 40px;
  width: ${(props) => props.wid};
  font-weight: 600;
  color: ${({color}) => color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  p {
    font-weight: bold;
  }

  @media (max-width: 1000px) {
    font-size: 1.6rem;
    line-height: 30px;
    //color: red;
    //background: blue;
    width: 100%:
  }

  @media (max-width: 800px) {
    font-size: 1.2rem;
    line-height: 30px;
    //color: red;
    //background: blue;
    width: 300px;
  }

  @media (max-width: 450px) {
    font-size: 1rem;
    line-height: 20px;
    //color: red;
    //background: blue;
    width: 200px;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  border-radius: 4px;
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 480px;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  //margin-top: -125px;
  margin-top: -20px;
  aspect-ratio: 16 / 9;
  //background: linear-gradient(180deg, #313755 0%, #1e2131 100%);
  position: relative;
  @media (max-width: 1000px) {
    margin-top: 0px;
  }

  @media (min-width: 500px) and (max-width: 1000px) {
    aspect-ratio: 16 / 11;
  }

  @media (max-width: 500px) {
    margin-top: 0px;
    aspect-ratio: 14/12;
  }

  .height-divider {
    @media (min-width: 1000px) and (max-width: 1295px) {
      height: 60px;
    }
  }

  .hero-image {
    max-width: 1200px;
    padding-top: 100px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    object-fit: contain;
    touch-action: pan-x;
    overflow-y: hidden;
    overflow-x: hidden;
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
  }
  .numberIndicatorWrapper {
    .wrapper {
      width: 50px;
      @media (max-width: 500px) {
        width: 30px;
      }
    }
    .button {
      background-color: white !important;
      animation: fill 8s ease-in-out;
      width: 50px;
      @media (max-width: 500px) {
        width: 30px;
      }
    }
    @keyframes fill {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
`;

const InfiniteLoopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  //background: red;
`

const InfiniteLooperWrap = styled.div`
display: flex;
flex-direction: column;
top: 0;
width: 120px;
position: relative;
height: 600px;
//background: red;
background: transparent;

.test {
  height: 100%;
  //background: blue;
  //background: transparent;
}

.testing {
  height: 100%;
  //background: red;
}
`

const LooperWrap = styled.div`
  .looper{
    font-size: 1.9rem;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-weight: 400;
    height: 50px;
    color: ${({color}) => color};
    @media (max-width: 1000px) {
      font-size: 1.6rem;
      line-height: 30px;
    }
    @media (max-width: 800px) {
      font-size: 1.2rem;
      line-height: 30px;
    }
    @media (max-width: 450px) {
      font-size: 0.95rem;
      line-height: 20px;
    }
  }
`

const LooperComponent = (col: any) => {
  const loopText = [
    'for sports betting',
    1000,
    'for backtesting strategies',
    500,
    'for risk management',
    500,
    'for machine learning',
    500,
    'for informed betting',
    500,
    'for developers',
    500,
    //'for reducing information asymmetry',
    //500,
    'for fairer prediction markets',
    500,
    'for market efficiency',
    500,
  ];

  return (
    <div style={{display: "flex", width: "100%"}}>
      <LooperWrap color={col}>
      <TypeAnimation
        sequence={loopText}
        wrapper="span"
        cursor={true}
        repeat={Infinity}
        className="looper"
      />
      </LooperWrap>
    </div>
  );
};

const TextLooper = React.memo(() => {
  const {theme} = useGetTheme();
  return (
    <>
      <LooperComponent col={getThemeData(theme).colorAccent}/>
    </>
  );
});

const Hero = () => {
  const images = [
    {
      //url: HeroGeneral,
      url: "https://ik.imagekit.io/p4fmkwyaaf/hero/tr:w-1500/general.webp",
      title: "General",
      id: 0,
    },
    /*{
      url: HeroSatellite,
      // url: "https://ik.imagekit.io/p4fmkwyaaf/hero/tr:w-1500/satellite.webp",
      title: "Satellites",
      id: 4,
    },
    {
      url: HeroAlpha,
      // url: "https://ik.imagekit.io/p4fmkwyaaf/hero/tr:w-1500/alpha.webp",
      title: "Alpha",
      id: 5,
    },
    {
      url: HeroSocial,
      // url: "https://ik.imagekit.io/p4fmkwyaaf/hero/tr:w-1500/social.webp",
      title: "Social",
      id: 2,
    },
    {
      url: HeroManager,
      // url: "https://ik.imagekit.io/p4fmkwyaaf/hero/tr:w-1500/manager.webp",
      title: "Manager",
      id: 1,
    },
    */

    // {
    //   url: HeroMusic,
    //   // url: "https://ik.imagekit.io/p4fmkwyaaf/hero/tr:w-1500/music.webp",
    //   title: "Audio",
    //   id: 3,
    // },
  ];
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  const isDark = theme === 'dark';
  const [ init, setInit ] = useState(false);

  /*const particlesInit = initParticlesEngine(async (engine: Engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadFull(engine);
      //await loadBasic(engine);
    }).then(() => {
        setInit(true);
    });

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container);
  }, []);
  */

  const logoLoopW1 = "100px"
  const logoLoopW2 = "80px"

  useEffect(() => {
    images.forEach((image) => {
      new Image().src = image.url;
    });
  }, []);

  const [windowDimenion, detectHW] = React.useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
      detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
      });
  };

  useEffect(() => {
      window.addEventListener("resize", detectSize);
      return () => {
          window.removeEventListener("resize", detectSize);
      };
  }, [windowDimenion]);

  return (
    <>
      {windowDimenion.winWidth > 1000
      ? <> {
        /*
        init && <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={{
          style: {
            position: "absolute",
            //height: "75%",
            height: "10%",
            width: "100%",
            top: "0px",//"calc(0% - 80px)",
          },
          fullScreen: {
            enable: true,
            zIndex: 1,
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              //resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: isDark
                ? [getThemeData(theme).gradientEnd, getThemeData(theme).gradientStart]
                : ["#ffffff", "#ffffff"],
            },
            collisions: {
              enable: true,
            },
            move: {
              //directions: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 0.1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                //area: 800,
              },
              value: 50,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: isDark ? { min: 0.5, max: 1.25 } : { min: 0.5, max: 1.25 },
            },
          },
          detectRetina: true,
        }}
      /> */} </>
      : null}
      <HeroWrapper>
        {currentIndex === 0 && <div className="height-divider"></div>}
        {/*<motion.img
          className="hero-image"
          alt="hero-image"
          key={images[currentIndex].id}
          src={images[currentIndex].url}
          initial={{ y: -320, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          style={{
            paddingTop: currentIndex === 0 ? "50px" : "100px",
          }}
          transition={{
            type: "spring",
            damping: 18,
            stiffness: 150,
          }}
        />*/}

        {/*<Chains dir={"ltr"}/>*/}

        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          //position: "relative",
          //background: "green",
          //zIndex: 1,
        }}>
          <TextButtonWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            id="hero"
            style={{
              zIndex: 1,
              position: "relative",
              //top: "-250px",
            }}
          >
          <HeroText
            wid={"100%"}
            color={getThemeData(theme).textColor}
          >
            <span>{"The most comprehensive"}</span>
            <span style={{color: getThemeData(theme).colorAccent}}>{"intelligent odds platform"}</span>
            {/*<span>{"for sports fans, bettors and developers"}</span>*/}
            <TextLooper />
            <div style={{
              display: "flex",
              flexDirection: (windowDimenion.winWidth > 800 ? "row" : "column"),
              //alignItems: (windowDimenion.winWidth > 600 ? "center" : "left"),
              //justifyContent: (windowDimenion.winWidth > 600 ? "left" : "center"),
              gap: "12px",
              width: "100%"
            }}>
                <div
                  style={{
                    cursor: "pointer",
                    padding: (windowDimenion.winWidth > 800 ? "2px 18px" : "8px 0px"),
                    width: (windowDimenion.winWidth > 800 ? "fit-content" : "100%"),
                    background: getThemeData(theme).bgButton1,
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    navigate("/")
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer", gap: "4px" }}>
                    <span style={{ fontSize: "1rem", fontWeight: "600", color: "white" }}>
                      {"Start For Free"}
                    </span>
                    <ArrowRight size={"16px"} color={"white"}/>
                  </div>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    padding: (windowDimenion.winWidth > 800 ? "1px 17px" : "8px 0px"),
                    width: (windowDimenion.winWidth > 800 ? "fit-content" : "calc(100% - 2px)"),
                    background: getThemeData(theme).bgGlobal,
                    borderRadius: "10px",
                    textAlign: "center",
                    border: "1px solid #303030",
                    //transition: "all 0.3s ease-in-out",
                  }}
                  onClick={() => {
                    navigate(config.accountRegister)
                  }}
                >
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    gap: "4px"
                  }}>
                    <span style={{ fontSize: "1rem", fontWeight: "600", color: getThemeData(theme).textColor }}>
                      {"Get Your API Key"}
                    </span>
                    <ArrowRight size={"16px"} color={getThemeData(theme).textColor}/>
                  </div>
                </div>
              {/*<Link to={"/products/api/docs/overview"} style={{textDecoration: "none"}}>
                <ButtonLearnMore>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                    <span style={{ fontSize: "1rem", fontWeight: "600", color: getThemeData(theme).textColor }}>
                      Explore Documentation
                    </span>
                  </div>
                </ButtonLearnMore>
              </Link>*/}
            </div>
          </HeroText>
        </TextButtonWrapper>
        <InfiniteLoopContainer>
          {windowDimenion.winWidth > 1000
          ?
          <InfiniteLooperWrap>
            <Marquee
              marqueeClassName="Test"
              marqueeContainerClassName="Testing"
              direction="down"
              height={800}
              marqueeItems={[
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop2} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop3} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop2} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop3} alt="" width={logoLoopW1}/>
                </ImgWrap>,
              ]}
            />
          </InfiniteLooperWrap>
          :null}
          {windowDimenion.winWidth > 600
          ?
          <InfiniteLooperWrap>
            <Marquee
              marqueeClassName="test"
              marqueeContainerClassName="testing"
              direction="up"
              height={800}
              marqueeItems={[
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop2} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop3} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop2} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop} alt="" width={logoLoopW1}/>
                </ImgWrap>,
                <ImgWrap>
                  <img src={LogoLoop3} alt="" width={logoLoopW1}/>
                </ImgWrap>,

              ]}
            />
          </InfiniteLooperWrap>
          : null}
        <InfiniteLooperWrap>
          <Marquee
            marqueeClassName="Test"
            marqueeContainerClassName="Testing"
            direction="down"
            height={800}
            marqueeItems={[
              <ImgWrap>
                <img src={LogoLoop} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop2} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop3} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop2} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop3} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
              <ImgWrap>
                <img src={LogoLoop} alt="" width={windowDimenion.winWidth > 800 ? logoLoopW1 : logoLoopW2}/>
              </ImgWrap>,
            ]}
          />
        </InfiniteLooperWrap>
        </InfiniteLoopContainer>
        </div>

      </HeroWrapper>
    </>
  );
};

export default Hero;