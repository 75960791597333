import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, ChevronRight, CornerDownRight, ExternalLink } from "react-feather";
import { useGetTheme } from "../../lib/contexts";
import { SubMenuItem } from "../../lib/interface";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { Config as config } from "../../lib/config";
import { useEffect } from "react";

interface SideMenuItemWrapperProps {
  isChild: boolean
};

interface BoxContentWrapProps {
  isMobile: boolean
}

export const SideMenuWrapper = styled.div`
  display: flex;
  flex-direction: row; //column;
  //width: 260px;
  width: 100%;
  //height: 100%;
  align-items: center;
  justify-content: center; //top;
  background: transparent;
  //overflow: auto;
  transform: all 0.5s ease-in-out;
  ::-webkit-scrollbar {
    display: none;
  }
  //@media (max-width: 1040px) {
  //  width: 100%;
  //}
`

export const SideMenuItemWrapper = styled.div<SideMenuItemWrapperProps>`
  width: 180px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //justify-content: ${(props) => props.isChild ? "left" : "space-between"};
  //gap: ${(props) => props.isChild ? "12px" : "0px"};
  cursor: pointer;
  border-radius: 6px;
  span {
    font-weight: 600;
  }
  &:hover {
    filter: brightness(125%);
  }
  //@media (max-width: 1040px) {
  //  justify-content: center;
  //}
`

export const BasicLink = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
  }
`;

export const BoxWrap = styled.div`
  //width: fit-content;
  //height: 100vh;
  //padding: 20px 0px;
  //width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  //overflow: auto;
  transform: all 0.5s ease-in-out;
  //position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1040px) {
    height: fit-content;
  }
`;

export const BoxContentWrap = styled.div<BoxContentWrapProps>`
  display: flex;
  flex-wrap: wrap;
  width: ${({isMobile}) => isMobile ? "100%" : "100%"};
  //flex-direction: row; //column;
  justify-content: ${({isMobile}) => isMobile ? "center" : "left"};
  padding: ${({isMobile}) => isMobile ? "0px 0px 0px 0px" : "0px 0px 0px 22px"};
  //align-items: center;
  //position: relative;
  //width: 100%;
  //background: red;
`;

export const SwitchSubTitle = styled.div<SwitchSubTitleProps>`
  //width: 80%;
  //min-width: 200px;
  //width: 120px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  border-radius: 10px;
  background: ${(props) => (
    props.isExternal
    ? (props.isDark
      ? "linear-gradient(to right, #25adea 0%, #a151ff 100%)"
      : "black")
    : (props.active
      ? (props.isDark
        ? "#303030"
        : "lightgrey")
      : "transparent")
  )};
  //margin-bottom: 10px;
`;

export const ButtonText = styled.span<ButtonTextProps>`
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  opacity: ${(props) => (props.activeText ? 1 : 0.6)};
  :hover {
    opacity: 1;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TopButtonWrap = styled.div`
  width: 100%;
  padding: 0px 0px 22px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface SwitchSubTitleProps {
  active: boolean,
  isDark: boolean,
  isExternal: boolean
}

interface ButtonTextProps {
  activeText: boolean
}

interface SideMenuProps {
  isMobile: boolean,
  docsPath: string
}

interface SubMenuItemProps {
  config: SubMenuItem,
  index: number,
  isChild: boolean,
  isExternal: boolean,
  isActive: boolean
}

const SubMenuItemComponent = ({config, index, isChild, isExternal, isActive}: SubMenuItemProps) => {
  const {theme} = useGetTheme();
  return (
    <SideMenuItemWrapper isChild={isChild}>
      {isChild && <CornerDownRight size={22} color={"grey"} />}
      <span style={{ color: (
          isExternal
          ? (theme === "dark"
            ? "black"
            : "lightgrey")
          : (isActive
            ? (theme === "dark"
              ? "lightgrey"
              : "black")
            : (theme === "dark"
              ? "lightgrey"
              : "black")
            )
          )
        }}>
        {config.name}
      </span>
      {config.external && <ExternalLink size={22} color={(theme === "dark" ? "black" : "lightgrey")} />}
      {config.activeUnder.length > 0
      && <> {isActive
        ? <ChevronDown size={22} color={theme === "dark" ? "lightgrey" : "black"} />
        : <ChevronRight size={22} color={"grey"} />
      } </> }
    </SideMenuItemWrapper>
  );
};

const SideMenu = ({ isMobile, docsPath }: SideMenuProps) => {
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  const dirs = [
    {
      path: "/api-docs",
      name: "Overview",
      activeUnder: [],
      external: false,
    },
    {
      path: "/api-docs/endpoints",
      name: "Schema",
      activeUnder: [],
      external: false,
    },
    {
      path: "/api-docs/endpoints/helper",
      name: "Helper Endpoints",
      activeUnder: [],
      external: false,
    },
    {
      path: "/api-docs/endpoints/odds",
      name: "Data Endpoints",
      activeUnder: [],
      external: false,
    },
    /*{
      path: "/api-docs/endpoints",
      name: "API Schema",
      activeUnder: [
        {
          path: "/api-docs/endpoints/helper",
          name: "Helper Endpoints",
          activeUnder: [],
          external: false,
        },
        {
          path: "/api-docs/endpoints/odds",
          name: "Data Endpoints",
          activeUnder: [],
          external: false,
        },
      ],
      external: false,
    },*/
    /*{
      path: "",
      name: "Code Examples",
      activeUnder: [],
      external: true,
    },*/
  ]

  //const [isUrlChanged, setIsUrlChanged] = useState(false);
  //const [dirActive, setDirActive] = useState(-1);

  // trigger when clicked outside the box
  //const wrapperRef = useRef(null);

  //useEffect(() => {
  //  setIsUrlChanged(!isUrlChanged);
  //}, [location]);

  console.log(docsPath);

  return (
    <SideMenuWrapper>
      {/*<BoxWrap ref={wrapperRef}>*/}
        <BoxContentWrap isMobile={isMobile}>
          {/*<TopButtonWrap>
            <span style={{ color: getThemeData(theme).colorAccent, fontWeight: 600 }}>
              {"API Documentation"}
            </span>
          </TopButtonWrap>*/}
          {/*<div style={{width: "22px"}}/>*/}
          {dirs.map((item, index) => {
            console.log(item.path)
            return (
              <div style={{display: "inline-flex", flexDirection: "row",}}>
                <SwitchSubTitle
                  //active={window.location.pathname.toString() === item.path}
                  active={docsPath === item.path}
                  isDark={theme === 'dark'}
                  isExternal={item.external}
                >
                  {item.external
                    ? <ButtonWrap>
                        <Link to={config.githubExamplesUrl} style={{textDecoration: 'none'}}>
                        <SubMenuItemComponent
                          config={item}
                          index={index}
                          isChild={false}
                          isExternal={item.external}
                          isActive={docsPath === item.path}
                        />
                      </Link>
                    </ButtonWrap>
                    : <ButtonWrap onClick={() => { navigate(item.path) }}>
                      <SubMenuItemComponent
                        config={item}
                        index={index}
                        isChild={false}
                        isExternal={item.external}
                        isActive={docsPath === item.path}
                      />
                    </ButtonWrap>
                    }
                </SwitchSubTitle>
                {/*
                dirActive === index && item.activeUnder.map((child, child_index) => {
                  return (
                    <SwitchSubTitle active={window.location.pathname === child.path} isDark={theme === 'dark'} isExternal={item.external}>
                      <ButtonWrap>
                        <BasicLink to={child.path}>
                          {getSubMenuItem(child, index, true, item.external)}
                        </BasicLink>
                      </ButtonWrap>
                    </SwitchSubTitle>
                  )
                })
                */}
              </div>
            );
          })}
        </BoxContentWrap>
      {/*</BoxWrap>*/}
    </SideMenuWrapper>
  );
}

export default SideMenu;