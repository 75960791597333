export const convertOdds = (
  decimal_odds: number,
  odds_type: number = 1
) => {
  if (odds_type === 2) {
    // american
    if (decimal_odds < 2) {
      return parseInt((-100 / (decimal_odds - 1)).toFixed(0));
    } else {
      return  parseInt(((decimal_odds - 1) * 100).toFixed(0));
    }
  } else if (odds_type === 3) {
    // implied
    return parseFloat(((1 / decimal_odds) * 100).toFixed(2));
  } else {
    // decimal
    return decimal_odds;
  }
}

export const convertOddsAmericanToDecimal = (odds: number | undefined) => {
  if (odds === undefined) { return 0; }
  if (odds < 0) {
    return (1 + (odds / 100));
  } else {
    return (1 - (100 / odds));
  }
}

export const convertOddsImpliedToDecimal = (odds: number | undefined) => {
  if (odds === undefined) { return 0; }
  return ((1 / odds) * 100);
}