import { reqMarkdown } from "../../lib/interface";

const getCodeExampleBlock = (codeType: string, code: string) => {
  return `
<div className="inlinecode-wrapper">
<div className="tip1">
<i class="fa fa-check-circle" className="icon"></i>
<span className="content2"> ${codeType} </span>
</div>
<code class="inlinecode-${codeType.toLowerCase()}">${code}</code>
</div>
`
};

const getMarkdownReq = ({
  type,
  name,
  name3,
  title,
  desc,
  params,
  successResponse,
  successResponseDesc,
  errorResponses,
}: reqMarkdown) => {
  if (type === 'get') {
    return `
<div className="getrequest">
<div className="row1">
<span className="gettag">
${type.toUpperCase()}
</span>
<div className="gettitle">
${name3}
</div>
</div>
<div className="divider1"></div>
<div className="reqcontent">
${title}
</div>
<p className="subcontent">
${desc}
</p>
<div className="divider2"></div>
<div className="gettitle">
Parameters
</div>
<div className="divider2"></div>
<div className="gettitle2">
Body
</div>
<div className="divider1"></div>
<div className="getparams">
${params.length == 0 ?
`<div className="getparamrow">
<span className="getparamcontent">
None
</span>
</div>`
: params.map((value, _index) => {
return (
`<div className="getparamrow">
<span className="getparamcontent">
${value.name}
</span>
<span className="getparamcontent">
${value.type}
</span>
<span className="getparamcontent">
${value.desc}
</span>
</div>`
)
})}
</div>
<div className="divider1"></div>
<div className="divider2"></div>
<div className="gettitle">
Responses
</div>
<div className="divider2"></div>
<div className="getresponses">
<div className="getresponserow">
<div>
<span className="getresponsecontent1">
●
</span>
<span className="getresponsecontent3">
200
</span>
</div>
<div>
<span className="getresponsecontent3">
${successResponseDesc}
</span>
</div>
</div>
<div className="inlinecode-wrapper">
${successResponse}
</div>
${errorResponses}
</div>
</div>
`;
  } else {
    return ``;
  }
}

export const getPageContent= (req: reqMarkdown) => {
  if (req.hasAdv) {
    return `
  <div className="mdrow" id=${req.id}>`
  +getMarkdownReq(req)
  +`
  <div className="mdcol">
    <div className="tip3">
      <i class="fa fa-info-circle" className="icon"></i>
      <span className="content2"> Public </span>
      <span className="endpoint2"> https://crunchtimeodds.com${req.name} </span>
    </div>
    <div className="tip3">
      <i class="fa fa-info-circle" className="icon"></i>
      <span className="content2"> Privileged </span>
      <span className="endpoint2"> https://crunchtimeodds.com${req.name2} </span>
    </div>
`+req.codeBlocks.map((cb,cbi) => {return cb})+
`
  </div>
  </div>
  `;
  } else {
  return `
  <div className="mdrow" id=${req.id}>`
  +getMarkdownReq(req)
  +`
  <div className="mdcol">
    <div className="tip3">
      <i class="fa fa-info-circle" className="icon"></i>
      <span className="content2"> Public </span>
      <span className="endpoint2"> https://crunchtimeodds.com${req.name} </span>
    </div>
`+req.codeBlocks.map((cb,cbi) => {return cb})+
`
  </div>
  </div>
  `;
  }
}

const error401 = `
<div className="getresponserow">
<div>
<span className="getresponsecontent2">
●
</span>
<span className="getresponsecontent3">
401
</span>
</div>
<div>
<span className="getresponsecontent3">
Unauthorized
</span>
</div>
</div>
<div className="divider2"></div>
`

const error400 = `
<div className="getresponserow">
<div>
<span className="getresponsecontent2">
●
</span>
<span className="getresponsecontent3">
400
</span>
</div>
<div>
<span className="getresponsecontent3">
Bad Request
</span>
</div>
</div>
<div className="divider2"></div>
`

const error429 = `
<div className="getresponserow">
<div>
<span className="getresponsecontent2">
●
</span>
<span className="getresponsecontent3">
429
</span>
</div>
<div>
<span className="getresponsecontent3">
Too Many Requests
</span>
</div>
</div>
<div className="divider2"></div>
`

export const reqs: reqMarkdown[] = [
  {
    id: "health",
    type: "get",
    name: "/api/health",
    name2: "/api/health",
    name3: "/api/health",
    hasAdv: false,
    title: "Get API Health",
    desc: "Gets the health of the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">{
  "status": "ok",
  "version": "0.2.6"
}</code>
</p>
`,
    params: [],
    successResponseDesc: "Successful Response",
    errorResponses: error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" imports """
import os
import requests
from dotenv import load_dotenv
""" load env """
load_dotenv()
""" request auth """
API_KEY = os.getenv('API_KEY')
HEADERS = {
  'x-api-key': API_KEY,
  'Content-Type': 'application/json',
}
API_DOMAIN = 'https://www.crunchtimeodds.com'
HEALTH_ENDPOINT = f'{API_DOMAIN}/api/health'
""" health check """
resp = requests.get(HEALTH_ENDPOINT, headers=HEADERS)
print(resp.json())
`
      ),
    ]
  },
  {
    id: "sites",
    type: "get",
    name: "/api/v1/sites",
    name2: "/api/v1/sites",
    name3: "/api/v1/sites",
    hasAdv: false,
    title: "Get Sites",
    desc: "Gets sites available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">[
  {
    "site_id": "Unibet"
  },
  {
    "site_id": "BetOnline"
  },
]</code>
</p>
`,
    params: [],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" imports """
import os
import requests
from dotenv import load_dotenv
""" load env """
load_dotenv()
""" request auth """
API_KEY = os.getenv('API_KEY')
HEADERS = {
  'x-api-key': API_KEY,
  'Content-Type': 'application/json',
}
API_DOMAIN = 'https://www.crunchtimeodds.com'
SITES_ENDPOINT = f'{API_DOMAIN}/api/v1/sites'
""" all sites """
resp = requests.get(SITES_ENDPOINT, headers=HEADERS)
sites = resp.json()
print(f"{len(sites)} sites found")
`
      ),
    ]
  },
  {
    id: "teams",
    type: "get",
    name: "/api/v1/teams",
    name2: "/api/v1/teams",
    name3: "/api/v1/teams",
    hasAdv: false,
    title: "Get Teams",
    desc: "Gets teams available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">[
  {
    "team_id": "nba-Milwaukee",
    "nid": 101
  },
  {
    "team_id": "nba-Portland",
    "nid": 102
  },
]</code>
</p>
`,
    params: [],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" imports """
import os
import requests
from dotenv import load_dotenv
""" load env """
load_dotenv()
""" request auth """
API_KEY = os.getenv('API_KEY')
HEADERS = {
  'x-api-key': API_KEY,
  'Content-Type': 'application/json',
}
API_DOMAIN = 'https://www.crunchtimeodds.com'
TEAMS_ENDPOINT = f'{API_DOMAIN}/api/v1/teams'
""" all teams """
resp = requests.get(TEAMS_ENDPOINT, headers=HEADERS)
teams = resp.json()
print(f"{len(teams)} teams found")
`
      ),
    ]
  },
  {
    id: "odds",
    type: "get",
    name: "/api/v1/odds",
    name2: "/api/v1/adv/odds",
    name3: "/api/v1{/adv}/odds",
    hasAdv: true,
    title: "Get Odds",
    desc: "Gets odds and game data for pending games available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">{
  "game_id": "nba-Miami-Boston-1714003200",
  "site_id": "DraftKings",
  "t1_id": "nba-Miami",
  "t2_id": "nba-Boston",
  "game_time": 1713999600,
  "ml_t1_odds": 10.0,
  "ml_t2_odds": 1.0625,
  "ps_t1_line": 15.0,
  "ps_t2_line": -15.0,
  "ps_t1_odds": 1.9090909090909092,
  "ps_t2_odds": 1.9090909090909092,
  "over_odds": 1.9090909090909092,
  "under_odds": 1.9090909090909092,
  "line": 207.0,
  "ml_updated_at": 1713727230,
  "ps_updated_at": 1713727230,
  "ou_updated_at": 1713727230
}</code>
</p>
`,
    params: [
      {
        name: "game_id",
        type: "string (optional)",
        desc: "Game ID"
      },
      {
        name: "page",
        type: "int (optional)",
        desc: "Page"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error400+error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" example available at the CrunchtimeOdds Github """
`
      ),
    ]
  },
  {
    id: "odds_index",
    type: "get",
    name: "/api/v1/odds/index/ml",
    name2: "/api/v1/adv/odds/index/ml",
    name3: "/api/v1{/adv}/odds/index/ml",
    hasAdv: true,
    title: "Get Odds Moneyline Index",
    desc: "Gets indexed odds moneyline data derived from odds and game data available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">{
  'league': 'nba',
  'site_id': 'Fanduel',
  'team_id': 'nba-Milwaukee',
  'opp_id': 'nba-LA-Clippers',
  'is_underdog_odds': 0,
  'is_underdog_team': 1,
  'game_time': 1709600400,
  'odds_update_time': 1709595603,
  'odds': 1.9615384615384615,
  'odds_no_vig': 2.0376931775348663,
  'opp_odds': 1.8928571428571428,
  'opp_odds_no_vig': 1.9636759898292773,
  'vig': 0.03810580836108032,
  'is_winner': 1,
  'unit_profit': 0.9615384615384615
}</code>
</p>
`,
    params: [
      {
        name: "game_id",
        type: "string (optional)",
        desc: "Game ID"
      },
      {
        name: "site_id",
        type: "string (optional)",
        desc: "Site ID"
      },
      {
        name: "team_id",
        type: "string (optional)",
        desc: "Team ID"
      },
      {
        name: "page",
        type: "int (optional)",
        desc: "Page"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" example available at the CrunchtimeOdds Github """
`
      ),
    ]
  },
  {
    id: "game_ids",
    type: "get",
    name: "/api/v1/games/ids",
    name2: "/api/v1/adv/games/ids",
    name3: "/api/v1{/adv}/games/ids",
    hasAdv: true,
    title: "Get Game IDs",
    desc: "Gets game ids available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">[
  {
    "game_id": "nba-Milwaukee-NewOrleans-1711670400"
  },
  {
    "game_id": "nba-Atlanta-Boston-1711670400"
  },
]</code>
</p>
`,
    params: [
      {
        name: "team_id",
        type: "string (optional)",
        desc: "Team ID"
      },
      {
        name: "page",
        type: "int (optional)",
        desc: "Page"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" imports """
import os
import requests
from dotenv import load_dotenv
""" load env """
load_dotenv()
""" request auth """
API_KEY = os.getenv('API_KEY')
HEADERS = {
  'x-api-key': API_KEY,
  'Content-Type': 'application/json',
}
API_DOMAIN = 'https://www.crunchtimeodds.com'
"""
depending on your subscription
can be 'paid' or 'starter'
"""
MY_PLAN = 'paid'
API_ENDPOINT = f'{API_DOMAIN}/api/v1'
if MY_PLAN == 'paid':
  """
  paid plans have access to
  privileged endpoints
  """
  API_ENDPOINT += '/adv'
GAMEIDS_ENDPOINT = f'{API_ENDPOINT}/games/ids'
""" game ids """
game_ids = []
n_pages = 10
for page in range(0,n_pages):
  print(f"page = {page}")
  pg = page * 10
  resp = requests.get(
    f'{GAMEIDS_ENDPOINT}?page={pg}',
    headers=HEADERS
  )
  game_ids.extend(resp.json())
print(f"{len(game_ids)} game ids found in {n_pages} pages")
`
      ),
    ]
  },
  {
    id: "ml",
    type: "get",
    name: "/api/v1/ml",
    name2: "/api/v1/adv/ml",
    name3: "/api/v1{/adv}/ml",
    hasAdv: true,
    title: "Get Moneyline",
    desc: "Gets moneylines available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">[
  {
    "game_id": "nhl-Pittsburgh-NewJersey-1712102400",
    "site_id": "Betrivers",
    "league": "nhl",
    "t1_id": "nhl-Pittsburgh",
    "t2_id": "nhl-New-Jersey",
    "t1_odds": 2.16,
    "t2_odds": 1.6993006993006994,
    "starts_at": 1712102400,
    "updated_at": 1711970416,
    "draw_odds": 0.0
},
]</code>
</p>
`,
    params: [
      {
        name: "game_id",
        type: "string (optional)",
        desc: "Game ID"
      },
      {
        name: "site_id",
        type: "string (optional)",
        desc: "Site ID"
      },
      {
        name: "team_id",
        type: "string (optional)",
        desc: "Team ID"
      },
      {
        name: "page",
        type: "int (optional)",
        desc: "Page"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" example available at the CrunchtimeOdds Github """
`
      ),
    ]
  },
  {
    id: "ou",
    type: "get",
    name: "/api/v1/ou",
    name2: "/api/v1/adv/ou",
    name3: "/api/v1{/adv}/ou",
    hasAdv: true,
    title: "Get Over Under",
    desc: "Gets over unders available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">[
  {
    "game_id": "mlb-SanDiegoPadres-StLouisCardinals-1712108400",
    "site_id": "OvertimeMarkets",
    "league": "mlb",
    "t1_id": "over",
    "t2_id": "under",
    "line": 7.5,
    "t1_odds": 1.8695652173913044,
    "t2_odds": 1.9523809523809523,
    "starts_at": 1712108400,
    "updated_at": 1712016015
  },
]</code>
</p>
`,
    params: [
      {
        name: "game_id",
        type: "string (optional)",
        desc: "Game ID"
      },
      {
        name: "site_id",
        type: "string (optional)",
        desc: "Site ID"
      },
      {
        name: "team_id",
        type: "string (optional)",
        desc: "Team ID"
      },
      {
        name: "page",
        type: "int (optional)",
        desc: "Page"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" example available at the CrunchtimeOdds Github """
`
      ),
    ]
  },
  {
    id: "ps",
    type: "get",
    name: "/api/v1/ps",
    name2: "/api/v1/adv/ps",
    name3: "/api/v1{/adv}/ps",
    hasAdv: true,
    title: "Get Point Spread",
    desc: "Gets point spreads available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">[
  {
    "game_id": "nhl-Boston-Nashville-1712102400",
    "site_id": "DraftKings",
    "league": "nhl",
    "t1_id": "nhl-Boston",
    "t2_id": "nhl-Nashville",
    "t1_ps_line": -1.5,
    "t2_ps_line": 1.5,
    "t1_odds": 3.0,
    "t2_odds": 1.4081632653061225,
    "starts_at": 1712102400,
    "updated_at": 1711987218
  },
]</code>
</p>
`,
    params: [
      {
        name: "game_id",
        type: "string (optional)",
        desc: "Game ID"
      },
      {
        name: "site_id",
        type: "string (optional)",
        desc: "Site ID"
      },
      {
        name: "team_id",
        type: "string (optional)",
        desc: "Team ID"
      },
      {
        name: "page",
        type: "int (optional)",
        desc: "Page"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" example available at the CrunchtimeOdds Github """
`
      ),
    ]
  },
  /*{
    id: "stats",
    type: "get",
    name: "/api/v1/stats/ml",
    name2: "/api/v1/adv/stats/ml",
    name3: "/api/v1{/adv}/stats/ml",
    hasAdv: true,
    title: "Get Moneyline Index Stats",
    desc: "Gets moneyline indexed stats available with the API.",
    successResponse: `
<p className="getresponsecontent3">
<code class="inlinecode-bash">{
  "unit_profit_7d": "0.822789445935036",
  "unit_profit_30d": "0.822789445935036",
  "unit_profit_90d": "0.822789445935036",
  "unit_profit_1y": "0.822789445935036",
  "unit_profit_all_time": "0.822789445935036",
  "unit_profit_7d_underdog": "2.30153846153846",
  "unit_profit_30d_underdog": "2.30153846153846",
  "unit_profit_90d_underdog": "2.30153846153846",
  "unit_profit_1y_underdog": "2.30153846153846",
  "unit_profit_all_time_underdog": "2.30153846153846",
  "unit_profit_7d_favored": "-1.47874901560343",
  "unit_profit_30d_favored": "-1.47874901560343",
  "unit_profit_90d_favored": "-1.47874901560343",
  "unit_profit_1y_favored": "-1.47874901560343",
  "unit_profit_all_time_favored": "-1.47874901560343"
}</code>
</p>
`,
    params: [
      {
        name: "site_id",
        type: "string (required)",
        desc: "Site ID"
      },
      {
        name: "team_id",
        type: "string (required)",
        desc: "Team ID"
      },
    ],
    successResponseDesc: "Successful Response",
    errorResponses: error401+error429,
    codeBlocks: [
      getCodeExampleBlock(
        "Python",
`""" example available at the CrunchtimeOdds Github """
`
      ),
    ]
  },*/
]