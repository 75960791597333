import React, { useEffect } from "react"
import { FaDiscord, FaGithub } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { BsTwitterX } from "react-icons/bs";
import {
  FooterWrapper,
  FooterLeftContentWrap,
  FooterLink,
  FooterSubMenuContent,
} from "../../styles"
import { Config as config } from '../../lib/config';
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import styled from "styled-components"
import LogoWhite from "../../assets/C_Logo_White.webp";
import LogoBlack from "../../assets/C_Logo_Black.webp";
import { useNavigate } from "react-router-dom";

const FooterContentTitle = styled.div`
  color: ${({color}) => color};
  font-weight: 800;
  font-size: 1.1rem;
`

interface HoverContainerProps {
  color: string;
  color2: string;
}

const HoverContainer = styled.div<HoverContainerProps>`
  .item {
    color: ${({color}) => color};
    &:hover {
      color: ${({color2}) => color2};
    }
  }
`
const Footer = () => {

  const {theme} = useGetTheme();
  const navigate = useNavigate();

  const [windowDimenion, detectHW] = React.useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return (
    <FooterWrapper>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "top",
        alignItems: "top",
        gap: "22px",
        height: (windowDimenion.winWidth <= 1040 ? "fit-content" : "220px"),
        padding: (windowDimenion.winWidth <= 1040 ? "12px 0px 22px 0px" : "0px 0px 0px 0px"),
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "12px",
          cursor: "pointer",
        }}>
          <img src={theme === 'dark' ? LogoWhite : LogoBlack} width={38} height={38} alt={'logo'}/>
          <span style={{
            fontSize: "1.4rem",
            color: (theme === 'dark'
              ? "white"
              : "black"),
            letterSpacing: "0.025rem",
            fontWeight: 600
          }}>
            {config.appName.substring(0,config.appName.length).toUpperCase()}
          </span>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: (windowDimenion.winWidth <= 1040 ? "center" : "left"),
          alignItems: "center",
          gap: "12px",
          cursor: "pointer",
          //width: "100%",
        }}>
          <span style={{
            fontWeight: 600,
            fontSize: "0.9rem",
            color: getThemeData(theme).textColor4
          }}>
            {"Copyright @ 2024 CrunchtimeOdds"}
          </span>
        </div>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "top",
        alignItems: "top",
        gap: "12px",
      }}>
        <FooterLeftContentWrap>
          <FooterContentTitle color={getThemeData(theme).textColor}>
            {"Resources"}
          </FooterContentTitle>
          <FooterLink>
            <FooterSubMenuContent color={getThemeData(theme).textColor4} onClick={() => {navigate(config.docs)}}>
              {"How to Use"}
            </FooterSubMenuContent>
          </FooterLink>
          <FooterLink>
            <FooterSubMenuContent color={getThemeData(theme).textColor4} onClick={() => {navigate(config.terms)}}>
              {"Terms of Service"}
            </FooterSubMenuContent>
          </FooterLink>
          <FooterLink>
            <FooterSubMenuContent color={getThemeData(theme).textColor4} onClick={() => {navigate(config.privacy)}}>
              {"Privacy Policy"}
            </FooterSubMenuContent>
          </FooterLink>
          <FooterLink>
            <FooterSubMenuContent color={getThemeData(theme).textColor4} onClick={() => {navigate(config.docs)}}>
              {"Documentation"}
            </FooterSubMenuContent>
          </FooterLink>
        </FooterLeftContentWrap>
        <FooterLeftContentWrap>
          <FooterContentTitle color={getThemeData(theme).textColor}>
            {"Socials"}
          </FooterContentTitle>
          <FooterLink>
            <HoverContainer
              color={getThemeData(theme).textColor4}
              color2={getThemeData(theme).iconColor}
            >
              <div className="item" onClick={(e) => {
                window.location.href = `mailto:${config.email}`;
                e.preventDefault();
              }}>
                <div style={{cursor: "pointer", display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
                  <MdEmail size={"18px"} />
                  <FooterSubMenuContent>
                    {"Email"}
                  </FooterSubMenuContent>
                </div>
              </div>
            </HoverContainer>
          </FooterLink>
          <FooterLink>
            <HoverContainer
              color={getThemeData(theme).textColor4}
              color2={getThemeData(theme).iconColor}
            >
              <a className="item" href={`${config.discordUrl}`} target="_blank">
                <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
                  <FaDiscord size={"18px"}/>
                  <FooterSubMenuContent>
                    {"Discord"}
                  </FooterSubMenuContent>
                </div>
              </a>
            </HoverContainer>
          </FooterLink>
          <FooterLink>
            <HoverContainer
              color={getThemeData(theme).textColor4}
              color2={getThemeData(theme).iconColor}
            >
              <a className="item" href={`${config.twitterUrl}`} target="_blank">
                <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
                  <BsTwitterX size={"18px"}/>
                  <FooterSubMenuContent>
                    {"Twitter"}
                  </FooterSubMenuContent>
                </div>
              </a>
            </HoverContainer>
          </FooterLink>
          <FooterLink>
            <HoverContainer
              color={getThemeData(theme).textColor4}
              color2={getThemeData(theme).iconColor}
            >
              <a className="item" href={`${config.githubUrl}`} target="_blank">
                <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
                  <FaGithub size={"18px"}/>
                  <FooterSubMenuContent>
                    {"GitHub"}
                  </FooterSubMenuContent>
                </div>
              </a>
            </HoverContainer>
          </FooterLink>
          {/*
          <FooterLink>
            <HoverContainer
              color={getThemeData(theme).textColor4}
              color2={getThemeData(theme).iconColor}
            >
              <a className="item" href={`${config.mediumUrl}`} target="_self">
                <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
                  <FaMedium size={"18px"}/>
                  <FooterSubMenuContent>
                    {"Medium"}
                  </FooterSubMenuContent>
                </div>
              </a>
            </HoverContainer>
          </FooterLink>
          */}
          {/*
          <FooterLink>
            <HoverContainer
              color={getThemeData(theme).textColor4}
              color2={getThemeData(theme).iconColor}
            >
              <a className="item" href={`${config.telegramUrl}`} target="_self">
                <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
                  <FaTelegram size={"18px"}/>
                  <FooterSubMenuContent>
                    {"Telegram"}
                  </FooterSubMenuContent>
                </div>
              </a>
            </HoverContainer>
          </FooterLink>
          */}
        </FooterLeftContentWrap>
      </div>
    </FooterWrapper>
  );
}

export default Footer;