import React from "react";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "react-feather";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { Link } from "react-router-dom";
import { Config as config } from "../../lib/config";

export const ScreenSizes = {
  mobile: "0px",
  tablet: "768px",
  desktop: "1024px",
  desktopL: "1440px",
};

export const QuoteFontSize = {
  mobile: "18px",
  tablet: "20px",
  desktop: "22px",
  desktopL: "22px",
};

export const NavBarFontSize = {
  mobile: "17px",
  tablet: "18px",
  desktop: "19px",
  desktopL: "20px",
};

export const HeadingFontSize = {
  mobile: "24px",
  desktop: "28px",
};

export const NormalTextFontSize = {
  mobile: "12px",
  desktop: "16px",
};

interface FaqComponentProps {
  width: string;
  bgColor: string;
}

interface FaqWrapperProps {
  maxWidth: string;
}

export const FaqWrapper = styled.div<FaqWrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 125px 0px;
`;

export const FaqComponent = styled.div<FaqComponentProps>`
  width: 620px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
  background: ${({bgColor}) => bgColor};
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 90vw;
  }
  @media (max-width: 1250px) {
    width: 80vw;
  }
`;

export const FaqRow1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface TextProps {
  isActive: boolean | undefined;
  color: string;
}

export const QuestionText = styled.p<TextProps>`
  color: ${({color}) => color};
  font-weight: 600;
  font-size: 1.1rem;
`;

export const AnswerText = styled.p<TextProps>`
  color: ${({color}) => color};
  font-weight: 400;
  margin-top: 20px;
  display: ${(props) => (props.isActive ? "block" : "none")};
  font-size: 1.1rem;
  line-height: 27px;
`;

interface FaqIconsProps {
  rotate: boolean;
}

export const FaqIcons = styled.div<FaqIconsProps>`
  cursor: pointer;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  ${({ rotate }) => rotate && `transform: rotate(360deg)`};
`;

export const FaqToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 50px;
  cursor: pointer;
`;

interface ToggleTextProps {
  isDark: boolean;
  isActive: boolean;
}

export const ToggleText = styled.p<ToggleTextProps>`
  color: ${(props) =>
    props.isDark === true
      ? props.isActive
        ? "black"
        : "#2021248f"
      : props.isActive
      ? "white"
      : "grey"};
  font-weight: bold;
`;

interface ToggleButtonProps {
  isActive: boolean;
}

export const ToggleButton = styled.div<ToggleButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.isActive ? "end" : "flex-start")};
  height: 15px;
  width: 50px;
  border-radius: 50px;
  background-color: grey;
`;

export const CircleToggle = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const DummyHeight = styled.div`
  height: 50px;
`;

const HeadingText = styled.h1`
  font-size: 2rem;
  font-weight: 800;
  background: ${({ color }) => color};
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const Faq = () => {
  const {theme} = useGetTheme();
  const faqList = [
    {
      question: "How can I access the API?",
      answer: <>
        <span>
          <Link to={config.accountRegister} target="_self" style={{textDecoration: "none", fontWeight: 600, color: getThemeData(theme).iconColor}}>
            {"Create an account"}
          </Link>
          {" to get started and receive your API key via email for free."}
        </span>
        <div style={{height: "22px"}}/>
        <span>
          <Link to={config.accountLogin} target="_self" style={{textDecoration: "none", fontWeight: 600, color: getThemeData(theme).iconColor}}>
            {"Login"}
          </Link>
          {" to your account dashboard to view and manage access to our products."}
        </span>
      </>
    },
    {
      question: "How do subscriptions work?",
      answer: <>
        <span>
          {"After purchasing a paid plan, your subscription is renewed automatically each month, on the same day of the month that the subscription started."}
        </span>
        <div style={{height: "22px"}}/>
        <span>
          {"You can cancel your access to the paid CrunchtimeOdds API services at any time, and your plan will remain active until the end of the current billing cycle."}
        </span>
      </>
    },
    {
      question: "Which leagues do you support?",
      answer:
        "We support NBA, Euroleague, WNBA, NFL, MLB, NHL, Premier League, La Liga, MLS, Champions League, Serie A, Ligue 1, Europa League, German Bundesliga and more.",
    },
    {
      question: "What odds markets do you support?",
      answer:
        "We support Money Line, Over Under, Point Spread and Parlays.",
    },
  ];

  const [currentIndex, setCurrentIndex] = React.useState([1, 0, 0, 0]);
  const [rotate, setRotate] = React.useState([1, 0, 0, 0]);

  const exitDetailHandler = (e: any) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      setCurrentIndex([0,0,0,0]);
    }
  };

  return (
    <FaqWrapper
      maxWidth={"200"}
      onClick={exitDetailHandler}
      className="shadow"
    >
      <HeadingText color={getThemeData(theme).textColor}>
        {"FAQs"}
      </HeadingText>
      <DummyHeight />

      {faqList.map((faq, index) => {
        return (
          <FaqComponent
            bgColor={getThemeData(theme).bgBox1}
            width={"1008"}
            onClick={() => {
              if (currentIndex[index] === 1) {
                let inds = [...currentIndex]
                inds[index] = 0
                setCurrentIndex(inds);
                setRotate(inds);
              } else {
                let inds = [...currentIndex]
                inds[index] = 1
                setCurrentIndex(inds);
                setRotate(inds);
              }
            }}
            key={index}
          >
            <FaqRow1>
              <QuestionText isActive={undefined} color={getThemeData(theme).textColor}>{faq.question}</QuestionText>
                <FaqIcons rotate={rotate[index] === 1}>
                  {currentIndex[index] === 1 ? (
                    <ChevronUp
                      size={28}
                      onClick={() => {
                        let inds = [...currentIndex]
                        inds[index] = 0
                        setCurrentIndex(inds);
                        setRotate(inds);
                      }}
                      color={getThemeData(theme).textColor}
                    />
                  ) : (
                    <ChevronDown
                      size={28}
                      onClick={() => {
                        let inds = [...currentIndex]
                        inds[index] = 1
                        setCurrentIndex(inds);
                      }}
                      color={getThemeData(theme).textColor}
                    />
                  )}
                </FaqIcons>
            </FaqRow1>

              <AnswerText
                isActive={currentIndex[index] === 1}
                color={getThemeData(theme).textColor}
              >
                {faq.answer}
              </AnswerText>
          </FaqComponent>
        );
      })}
    </FaqWrapper>
  );
};

export default Faq;