import { UserX } from "react-feather";
import StatsComponent from "./component";
import { StatsComponentWrapper, StatsMainWrapper, StatsDivider } from "./style";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { useEffect, useState } from "react";
import { getApiStats } from "../../lib/api/data";

const Stats = () => {
  const [apiStats, setApiStats] = useState({
    num_teams: 0,
    num_mlodds: 0,
    num_ouodds: 0,
    num_psodds: 0,
  });
  const {theme} = useGetTheme();

  async function loadApiStats() {
    const resp = await getApiStats();
    if (resp && resp.status === 200) {
      setApiStats({...resp.data});
      console.error({...resp.data})
    }
  }

  useEffect(() => {
    loadApiStats()
  },[])

  return (
    <StatsMainWrapper color={"transparent"}>
      <StatsComponentWrapper>
        <StatsDivider color={theme === 'dark' ? "#303030" : "lightgrey"}/>
        <StatsComponent
          count={apiStats.num_teams}
          title={"Teams"}
          IconName={UserX}
          theme={theme}
          addPlus={false}
        />
        <StatsDivider color={theme === 'dark' ? "#303030" : "lightgrey"}/>
        <StatsComponent
          count={apiStats.num_mlodds}
          title={"Moneylines"}
          IconName={UserX}
          theme={theme}
          addPlus={false}
        />
        <StatsDivider color={theme === 'dark' ? "#303030" : "lightgrey"}/>
        <StatsComponent
          count={apiStats.num_ouodds}
          title={"Over Unders"}
          IconName={UserX}
          theme={theme}
          addPlus={false}
        />
        <StatsDivider color={theme === 'dark' ? "#303030" : "lightgrey"}/>
        <StatsComponent
          count={apiStats.num_psodds}
          title={"Point Spreads"}
          IconName={UserX}
          theme={theme}
          addPlus={false}
        />
        <StatsDivider color={theme === 'dark' ? "#303030" : "lightgrey"}/>
      </StatsComponentWrapper>
    </StatsMainWrapper>
  );
};

export default Stats;