import { useState, useRef, useEffect } from "react";
import { PendingBet, getHedgeAmounts } from "./compute";
import { getThemeData } from "../../theme";
import { useGetOdds, useGetTheme } from "../../lib/contexts";
import { Plus, Minus, DollarSign, CheckCircle, XCircle } from "react-feather";
import { getSlider } from "./slider";
import { FaInfoCircle as Info} from "react-icons/fa";
import { convertOdds, convertOddsAmericanToDecimal, convertOddsImpliedToDecimal } from "../../odds";
import { Tooltip } from 'react-tooltip';
import {
  Form,
  Row,
  Input,
  Button,
  NumberWithCircle,
  TextContent,
  TextContent2,
  TextContent3,
  HedgeContainer,
  HedgeResultsContainer,
  PendingBetsContainer,
  TooltipContainer,
  ItemContentMobile2,
  OddsButton,
} from './hedgerStyle';
import styled from "styled-components";

interface HedgerProps {
  showSubMenu: boolean;
  lgSubMenuW: string;
  smSubMenuW: string;
  isMobile: boolean;
};

interface HedgeBet {
  bet_index: number;
  bet_odds: number;
  hedge_amount: number;
};

interface HedgeAmount {
  current_total: number;
  hedge_bets: HedgeBet[];
};

interface HedgeWrapperProps {
  color: string;
  brColor: string;
  height: string;
  width: string;
};

const HedgeWrapper = styled.div<HedgeWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height};
  width: ${({width}) => width};
  //margin-top: 10vh;
  background: ${({color}) => color};
  border-radius: 10px;
  border: 1px solid ${({brColor}) => brColor};
`;

const HedgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 1100px) {
    margin-top: 82px;
  }
`

const HedgeOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 22px 0px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

const Hedger = ({
  showSubMenu,
  lgSubMenuW,
  smSubMenuW,
  isMobile
}: HedgerProps) => {
  const {theme} = useGetTheme();
  const {odds, setOdds} = useGetOdds();
  const [showHedge, setShowHedge] = useState<boolean>(false);
  const [hedge, setHedge] = useState<HedgeAmount | undefined>(undefined);
  const [currentTotal, setCurrentTotal] = useState<number | undefined>(10);//undefined);
  const [guarantee, setGuarantee] = useState<number | undefined>(5);//undefined);
  const [pendingBets, setPendingBets] = useState<PendingBet[]>([
    {
      bet_amount: 4,
      bet_odds: 2.1,
      cur_opp_odds: 1.9,
      assume_win: false,
    },
    {
      bet_amount: 6,
      bet_odds: 1.1,
      cur_opp_odds: 3.6,
      assume_win: false,
    },
    {
      bet_amount: 5,
      bet_odds: 1.4,
      cur_opp_odds: 3.1,
      assume_win: false,
    },
    {
      bet_amount: 2,
      bet_odds: 6.4,
      cur_opp_odds: 1.1,
      assume_win: false,
    },
    {
      bet_amount: 10,
      bet_odds: 1.9,
      cur_opp_odds: 1.9,
      assume_win: false,
    },
    {
      bet_amount: 1,
      bet_odds: 2.1,
      cur_opp_odds: 1.9,
      assume_win: false,
    },
    {
      bet_amount: 2,
      bet_odds: 2.2,
      cur_opp_odds: 1.9,
      assume_win: false,
    },
  ]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    input_type: number,
    index: number
  ) => {
    let validNumber = new RegExp(/^[-+]?[0-9]\d*(\.\d+)?$/);
    //new RegExp(/^\d*\.?\d*$/);
    //new RegExp(/^\d+(\.\d+)?$/);
    if (validNumber.test(e.target.value.toString())) {
      let newNumber = undefined;
      if (e.target.value.toString() !== "") {
        newNumber = parseFloat(e.target.value);
      }
      if (input_type === 1) {
        setCurrentTotal(newNumber);
        setHedge(undefined);
      } else if (input_type === 2) {
        setGuarantee(newNumber);
        setHedge(undefined);
      } else if (input_type === 3) {
        var newPbs = [...pendingBets];
        newPbs[index].bet_amount = newNumber !== undefined ? newNumber : 0;
        setPendingBets(newPbs);
        setHedge(undefined);
      } else if (input_type === 4) {
        var newPbs = [...pendingBets];
        if (odds === 2) {
          newNumber = convertOddsAmericanToDecimal(newNumber)
        } else if (odds === 3) {
          newNumber = convertOddsImpliedToDecimal(newNumber);
        }
        newPbs[index].bet_odds = newNumber !== undefined ? newNumber : 0;
        setPendingBets(newPbs);
        setHedge(undefined);
      } else if (input_type === 5) {
        var newPbs = [...pendingBets];
        if (odds === 2) {
          newNumber = convertOddsAmericanToDecimal(newNumber)
        } else if (odds === 3) {
          newNumber = convertOddsImpliedToDecimal(newNumber);
        }
        newPbs[index].cur_opp_odds = newNumber !== undefined ? newNumber : 0;
        setPendingBets(newPbs);
        setHedge(undefined);
      }
    }
  }

  const computeHedge = () => {
    if (
      currentTotal !== undefined &&
      guarantee !== undefined
    ) {
      const hedge: HedgeAmount = getHedgeAmounts(
        currentTotal,
        guarantee,
        pendingBets
      );
      setHedge(hedge);
      setShowHedge(true);
    }
  }

  const handleRemovePb = (index: number) => {
    var newPbs = [...pendingBets];
    delete newPbs[index];
    setPendingBets(newPbs.filter(item => item));
    setHedge(undefined);
  }

  const handleAddPb = () => {
    const defaultPb: PendingBet = {
      bet_amount: 1,
      bet_odds: 1.5,
      cur_opp_odds: 2.5,
      assume_win: false,
    }
    setPendingBets([defaultPb, ...pendingBets]);
    setHedge(undefined);
  }

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "calc(100% - 420px)" },
  };

  const hedgeResultsRef = useRef<HTMLInputElement>(null);

  const sliderW = "160px";

  useEffect(() => {
    if (!showHedge) {
      setTimeout(() => {
        computeHedge()
      },200);
    }
  },[])

  return (
    <HedgerContainer>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
        gap: "12px",
        width: "100%",
        padding: "42px 0px 0px 0px",
      }}>
        <div style={{
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: (isMobile ? "center" : "left"),
          alignItems: "center",
          gap: "6px",
          width: "100%",
        }}>
          <OddsButton
            color={odds === 1 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setOdds(1)}}
          >
            <ItemContentMobile2 color={getThemeData(theme).textColor}>
              {"Decimal Odds"}
            </ItemContentMobile2>
          </OddsButton>
          <OddsButton
            color={odds === 2 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setOdds(2)}}
          >
            <ItemContentMobile2 color={getThemeData(theme).textColor}>
              {"American Odds"}
            </ItemContentMobile2>
          </OddsButton>
          <OddsButton
            color={odds === 3 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setOdds(3)}}
          >
            <ItemContentMobile2 color={getThemeData(theme).textColor}>
              {"Implied Probability"}
            </ItemContentMobile2>
          </OddsButton>
        </div>
      </div>
      <HedgeOuterWrapper>
        <HedgeWrapper
          color={isMobile ? "transparent" : (theme === 'dark'
            ? getThemeData(theme).themeBlue
            : getThemeData(theme).bgGlobal
          )}
          brColor={isMobile ? "transparent" : getThemeData(theme).borderColor}
          width={isMobile ? "100%" : "420px"}
          height={"620px"}
        >
          <HedgeContainer>
            <Form>
              <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 0px 12px 0px",
              }}>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  width: isMobile ? "fit-content" : "100px",
                }}>
                  <TextContent isMobile={isMobile} color={getThemeData(theme).textColor}>
                    {"Starting"}
                  </TextContent>
                  <div style={{width: "6px"}}/>
                  <Info size={16} color={"grey"} cursor={"pointer"} data-tooltip-id="tip1"/>
                  <TooltipContainer color={getThemeData(theme).bgBox1}>
                    <Tooltip id="tip1" place="bottom" className='custom-tooltip' opacity={1.0}>
                      <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                        {"This is your current profit or balance"}
                      </TextContent3>
                    </Tooltip>
                  </TooltipContainer>
                </div>
                {!isMobile ? <div style={{width: "6px"}}/> : <div style={{width: "12px"}}/>}
                {getSlider(currentTotal !== undefined ? currentTotal : 0, setCurrentTotal, sliderW, setHedge)}
                <div style={{width: "6px"}}/>
                <DollarSign size={16} color={getThemeData(theme).textColor}/>
                <Input
                  type="text"
                  name="currentTotal"
                  value={currentTotal}
                  onChange={(event) => {
                    event.preventDefault();
                    handleInputChange(event, 1, -1);
                    //setCurrentTotal(parseFloat(event.target.value));
                  }}
                  placeholder='Current Total'
                  width={"60px"}
                  color={getThemeData(theme).textColor}
                />
              </div>
              <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 0px 12px 0px",
              }}>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  width: (isMobile ? "fit-content" : "100px"),
                }}>
                  <TextContent isMobile={isMobile} color={getThemeData(theme).textColor}>
                    {"Lock In"}
                  </TextContent>
                  <div style={{width: "6px"}}/>
                  <Info size={16} color={"grey"} cursor={"pointer"} data-tooltip-id="tip2"/>
                  <TooltipContainer color={getThemeData(theme).bgBox1}>
                    <Tooltip id="tip2" place="bottom" className='custom-tooltip' opacity={1.0}>
                      <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                        {"This is the amount you want to guarantee by hedging pending bets"}
                      </TextContent3>
                    </Tooltip>
                  </TooltipContainer>
                </div>
                {!isMobile ? <div style={{width: "6px"}}/> : <div style={{width: "12px"}}/>}
                {getSlider(guarantee !== undefined ? guarantee : 0, setGuarantee, sliderW, setHedge)}
                <div style={{width: "6px"}}/>
                <DollarSign size={16} color={getThemeData(theme).textColor}/>
                <Input
                  type="text"
                  name="guarantee"
                  value={guarantee}
                  onChange={(event) => {
                    event.preventDefault();
                    handleInputChange(event, 2, -1);
                    //setGuarantee(parseFloat(event.target.value));
                  }}
                  placeholder='Guarantee'
                  width={"60px"}
                  color={getThemeData(theme).textColor}
                />
              </div>
              <div style={{height: "12px"}}/>
              <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                padding: "12px 0px 12px 0px"
              }}>
                <Row>
                  <div style={{width: "24px"}}/>
                  <div style={{display: "inline-flex", width: "84px",}}>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"Bet Size"}
                    </TextContent2>
                  </div>
                  <div style={{display: "inline-flex", width: "84px",}}>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"Bet Odds"}
                    </TextContent2>
                  </div>
                  <div style={{display: "inline-flex", width: "84px",}}>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"Counter Bet Odds"}
                    </TextContent2>
                  </div>
                  <div style={{display: "inline-flex", width: isMobile ? "32px" : "44px",}}>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"Simulate Win/Loss"}
                    </TextContent2>
                  </div>
                </Row>
              </div>
              <PendingBetsContainer
                scrollbarColor={getThemeData(theme).colorAccent}
              >
                {pendingBets.filter(item => item).map((pb, index) => {
                  return (
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: isMobile ? "center" : "space-between",
                      width:"100%"
                    }}>
                      <Row>
                        <NumberWithCircle
                          bgcolor={getThemeData(theme).borderColor}
                        >
                          <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                            {index+1}
                          </TextContent3>
                        </NumberWithCircle>
                        <Input
                          type="text"
                          name={`betAmount${index}`}
                          value={pb.bet_amount}
                          onChange={(event) => {
                            event.preventDefault();
                            handleInputChange(event, 3, index);
                            //var newPbs = [...pendingBets];
                            //newPbs[index].bet_amount = parseFloat(event.target.value);
                            //setPendingBets(newPbs);
                          }}
                          placeholder='Bet Amount'
                          width={"60px"}
                          color={getThemeData(theme).textColor}
                        />
                        <Input
                          type="text"
                          name={`betOdds${index}`}
                          value={convertOdds(pb.bet_odds, odds)}
                          onChange={(event) => {
                            event.preventDefault();
                            handleInputChange(event, 4, index);
                            //var newPbs = [...pendingBets];
                            //newPbs[index].bet_odds = parseFloat(event.target.value);
                            //setPendingBets(newPbs);
                          }}
                          placeholder='Bet Odds'
                          width={"60px"}
                          color={getThemeData(theme).textColor}
                        />
                        <Input
                          type="text"
                          name={`curOppOdds${index}`}
                          value={convertOdds(pb.cur_opp_odds, odds)}
                          onChange={(event) => {
                            event.preventDefault();
                            handleInputChange(event, 5, index);
                            //var newPbs = [...pendingBets];
                            //newPbs[index].cur_opp_odds = parseFloat(event.target.value);
                            //setPendingBets(newPbs);
                          }}
                          placeholder='Opp Odds'
                          width={"60px"}
                          color={getThemeData(theme).textColor}
                        />
                        <div style={{
                          display: "inline-flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          border: `1px solid ${getThemeData(theme).borderColor}`,
                          padding: isMobile ? "6px 6px" : "6px 12px",
                          width: "20px",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }} onClick={() => {
                          var newPbs = [...pendingBets];
                          newPbs[index].assume_win = !newPbs[index].assume_win;
                          setPendingBets(newPbs);
                          setHedge(undefined);
                        }}>
                          {pb.assume_win
                          ? <TextContent isMobile={isMobile} color={getThemeData(theme).textColor}>{"W"}</TextContent>
                          : <TextContent isMobile={isMobile} color={getThemeData(theme).textColor}>{"L"}</TextContent>
                          }
                        </div>
                      </Row>
                      {pendingBets.length === 1
                      ? <Row>
                        <Plus
                          size={22}
                          cursor={"pointer"}
                          color={getThemeData(theme).iconColor}
                          onClick={() => {
                            handleAddPb();
                          }}
                        />
                      </Row>
                      : <> {pendingBets.length > 1 && index === pendingBets.length - 1
                        ?
                        <Row>
                          <Plus
                            size={22}
                            cursor={"pointer"}
                            color={getThemeData(theme).iconColor}
                            onClick={() => {
                              handleAddPb();
                            }}
                          />
                        </Row>
                        : <Row>
                          <Minus
                            size={22}
                            cursor={"pointer"}
                            color={getThemeData(theme).iconColor}
                            onClick={() => {
                              handleRemovePb(index);
                            }}
                          />
                        </Row>
                        } </>
                      }
                    </div>
                  );
                })}
              </PendingBetsContainer>
              <div style={{height: "12px"}}/>
              <Button
              color={getThemeData(theme).gradient1}
              onClick={() => {
                computeHedge();
              }}>
                <TextContent isMobile={isMobile} color={"white"}>
                  {"Compute Hedge"}
                </TextContent>
              </Button>
            </Form>
          </HedgeContainer>
        </HedgeWrapper>
        {hedge === undefined && <div style={{width: "420px", height: "620px"}}/>}
        <HedgeResultsContainer
          ref={hedgeResultsRef}
          color={getThemeData(theme).textColor}
          //background={getThemeData(theme).mobileNavBackground}
          background={getThemeData(theme).bgGlobal}
          brColor={isMobile ? "transparent" : getThemeData(theme).borderColor}
          animate={hedge !== undefined ? "open" : "closed"}
          variants={variants}
          hidden={hedge === undefined ? "none" : "flex"}
          scrollbarColor={getThemeData(theme).colorAccent}
          //width={showSubMenu ? `calc(100% - ${lgSubMenuW})` : `calc(100% - ${smSubMenuW})`}
          width={"420px"}
          height={"620px"}
        >
          <div style={{
            display: showHedge ? "flex" : "none",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "32px 60px 12px 60px",
          }}>
            {hedge !== undefined && <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "22px",
              width: "100%",
            }}>
              <div style={{
                display: "inline-flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "left",
                alignItems: "center",
                gap: "4px",
                width: "100%",
              }}>
                <TextContent isMobile={isMobile} color={getThemeData(theme).textColor}>
                  {`Hedge options to lock in`}
                </TextContent>
                <DollarSign size={16} color={getThemeData(theme).textColor}/>
                <TextContent isMobile={isMobile} color={getThemeData(theme).textColor}>
                  {`${guarantee}`}
                </TextContent>
              </div>
              {hedge.hedge_bets.length === 0
              && <div style={{
                display: "inline-flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "left",
                alignItems: "center",
                gap: "4px",
                width: "100%",
              }}>
                <XCircle size={20} color={getThemeData(theme).themeRed}/>
                <div style={{width: "4px"}}/>
                <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                  {"None: change parameters"}
                </TextContent2>
              </div>
              }
              {hedge.hedge_bets.map((hb, hbi) => {
                return (
                  <div style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "0px",
                    width: "100%",
                  }}>
                    <CheckCircle size={20} color={getThemeData(theme).themeGreen}/>
                    <div style={{width: "6px"}}/>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {`Bet`}
                    </TextContent2>
                    <div style={{width: "2px"}}/>
                    <DollarSign size={16} color={getThemeData(theme).textColor}/>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {`${hb.hedge_amount.toFixed(2)}`}
                    </TextContent2>
                    <div style={{width: "4px"}}/>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {`on`}
                    </TextContent2>
                    <div style={{width: "4px"}}/>
                    <NumberWithCircle
                      bgcolor={getThemeData(theme).borderColor}
                    >
                      <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                        {hb.bet_index}
                      </TextContent3>
                    </NumberWithCircle>
                    <div style={{width: "4px"}}/>
                    <TextContent2 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {`with ${
                        odds === 2
                        ? (hb.bet_odds >= 2
                          ? "+"+convertOdds(hb.bet_odds, odds)
                          : convertOdds(hb.bet_odds, odds)
                        )
                        : convertOdds(hb.bet_odds, odds)
                      } odds`}
                    </TextContent2>
                  </div>
                );
              })}
            </div>
            }
          </div>
        </HedgeResultsContainer>
      </HedgeOuterWrapper>
    </HedgerContainer>
  );
}

export default Hedger;