import React, { useState, useEffect } from 'react';
import { SnackBarProps } from '../../lib/interface';
import { AlertTriangle, CheckCircle } from "react-feather";
import {
  SnackBarWrapper,
  SnackBarTileWrapper
} from "../../styles";
import Spinner from "../Spinner";

const SnackBar = ({ type, title }: SnackBarProps) => {
  return (
    <SnackBarWrapper color = {
      type === "success"
        ? "green"
        : type === "customSuccess"
        ? "green"
        : type === "error"
        ? "red"
        : type === "customWarning"
        ? "orange"
        : type === "comingSoon"
        ? "lightgreen"
        : type === "warning"
        ? "orange"
        : type === "customWait"
        ? "orange"
        : type === "loading"
        ? "#6832e3"
        : "orange"
    }>
      {(() => {
        switch (type) {
          case "success":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  Success
                </p>
                <CheckCircle size={18} color="white" />
              </SnackBarTileWrapper>
            );
          case "error":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "black",
                    fontWeight: 600,
                  }}
                >
                  Error: {title}
                </p>
                <AlertTriangle size={18} color="black" />
              </SnackBarTileWrapper>
            );
          case "warning":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "black",
                    fontWeight: 600,
                  }}
                >
                  Warning
                </p>
                <AlertTriangle color="black" />
              </SnackBarTileWrapper>
            );
          case "loading":
            return <Spinner text={"Loading"} color={"white"} />;
          case "deleting":
            return <Spinner text={"Deleting"} color={"white"} />;
          case "creating":
            return <Spinner text={"Creating"} color={"white"} />;
          case "customWarning":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "black",
                    fontWeight: 600,
                  }}
                >
                  {title}
                </p>
                <AlertTriangle size={22} color="black" />
              </SnackBarTileWrapper>
            );
          case "customSuccess":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "white",
                    fontWeight: 600,
                  }}
                >
                  {title}
                </p>
                <CheckCircle size={18} color="white" />
              </SnackBarTileWrapper>
            );
          case "comingSoon":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "black",
                    fontWeight: 600,
                  }}
                >
                  {title}
                </p>
              </SnackBarTileWrapper>
            );
          case "customWait":
            return (
              <SnackBarTileWrapper>
                <p
                  style={{
                    color: "black",
                    fontWeight: 600,
                  }}
                >
                  {title}
                </p>
                <AlertTriangle color="black" />
              </SnackBarTileWrapper>
            );
          default:
            return null;
        }
      })()}
    </SnackBarWrapper>
  )
}

export default SnackBar;