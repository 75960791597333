import { useNavigate } from "react-router-dom";
import { useGetTheme } from "../../lib/contexts";
import { getThemeData } from "../../theme";
import { Config as config } from "../../lib/config";

interface CookieAlertProps {
  showSubMenu: boolean;
  lgSubMenuW: string;
  smSubMenuW: string;
  isMobile: boolean;
  setHasAcceptedCookies: any;
}

const CookieAlert = ({
  showSubMenu,
  lgSubMenuW,
  smSubMenuW,
  isMobile,
  setHasAcceptedCookies,
}: CookieAlertProps) => {
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  return (
    <div style={{
      width: (isMobile
        ? '100%'
        : (
          showSubMenu
          ? `calc(100% - ${lgSubMenuW})`
          : `calc(100% - ${smSubMenuW})`
        )
      ),
      position: "fixed",
      background: getThemeData(theme).cookieAlertBgColor,
      bottom: 0,
      right: 0,
      //left: 0,
    }}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "22px 22px",
      }}>
        <span style={{
          color: getThemeData(theme).textColor,
          fontSize: isMobile ? "0.85rem" : "1rem",
          fontWeight: 500,
        }}>
          {"CrunchtimeOdds uses cookies for the best experience. "}
          <span style={{
            color: getThemeData(theme).colorAccent,
            fontWeight: 500,
            cursor: "pointer",
          }} onClick={() => {
            window.open(config.privacy, "_blank"); //navigate(config.privacy)
          }}>
            {"Learn more."}
          </span>
        </span>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: "8px 12px",
          borderRadius: "10px",
          background: getThemeData(theme).colorAccent,
          cursor: "pointer",
        }} onClick={() => {
          setHasAcceptedCookies('yes')
        }}>
          <span style={{
            color: theme === 'dark' ? getThemeData('light').textColor : getThemeData('dark').textColor,
            fontSize: isMobile ? "0.85rem" : "1rem",
            fontWeight: 500,
          }}>
            {"Allow"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CookieAlert;