import styled from "styled-components";
import { getThemeData } from "../../theme";
import { useGetAcceptedCookies, useGetHeaderDisplay, useGetTheme } from "../../lib/contexts";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getOddsPendingGames,
  //getMlTopBetStats,
  //getPsTopBetStats,
  //getOuTopBetStats,
  getMlBetStats,
  getOuBetStats,
  getPsBetStats,
  //getTeams,
  //getSites
} from "../../lib/api/data";
import { OD, RowObj, QuestionData } from "../../lib/interface";
import { Config as config } from "../../lib/config";
import SnackBar from "../SnackBar";
import { convertUTCToLocal } from "../../lib/utils";
//import { convertOdds, useGetOdds } from "../../odds";
//import LoadingData from "../LoadingData";
import HeaderDash from "./header";
import Hedger from "./hedger";
import Home from "./home";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Docs from "../Docs";
import Help from "./help";
import Query from "./query";
import CookieAlert from "./cookieAlert";
import { generalSearchState } from "../Search/stores";
import Account from "../Account";
import { convertOdds } from "../../odds";
import BetsTop from "./betsTop";
import BetsTrending from "./betsTrending";
import BetsQ from "./betsQ";

interface BodyContainerProps {
  width: string;
}

interface BodyWrapperProps {
  isMobile: boolean;
}

const BodyWrapper = styled.div<BodyWrapperProps>`
  display: flex;
  flex-direction: ${({isMobile}) => isMobile ? "column" : "row"};
  justify-content: center;
  align-items: top;
  height: 100%;
  width: 100%;
`

const BodyContainer = styled.div<BodyContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${({width}) => width};
`

interface DashProps {
  content: string;
}

const Dash = ({content}: DashProps) => {
  const {theme} = useGetTheme();
  const {hasAcceptedCookies, setHasAcceptedCookies} = useGetAcceptedCookies();
  const [sortAllTime, setSortAllTime] = useState(false);
  const [sort1Y, setSort1Y] = useState(false);
  const [sort3M, setSort3M] = useState(false);
  const [sort1M, setSort1M] = useState(false);
  const [sort7D, setSort7D] = useState(true);
  const [sortAllTime2, setSortAllTime2] = useState(false);
  const [sort1Y2, setSort1Y2] = useState(false);
  const [sort3M2, setSort3M2] = useState(false);
  const [sort1M2, setSort1M2] = useState(false);
  const [sort7D2, setSort7D2] = useState(true);
  const [sortVig, setSortVig] = useState(false);
  //const [topRows, setTopRows] = useState<RowObj2[]>([]);
  const [betRows, setRows] = useState<RowObj[]>([]);
  const [betRowsStore, setBetRowsStore] = useState<RowObj[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page2, setPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);
  const [lastColSorted, setLastColSorted] = useState(1);
  const [lastColSorted2, setLastColSorted2] = useState(1);
  const [lastDirectionSorted, setLastDirectionSorted] = useState(1);
  const [lastDirectionSorted2, setLastDirectionSorted2] = useState(1);
  const [firstLoad, setFirstLoad] = useState(false);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showCustomizeModal2, setShowCustomizeModal2] = useState(false);
  const [betTypes, setBetTypes] = useState(['ml','ou','ps']);
  const [betTypes2, setBetTypes2] = useState(['ml','ou','ps']);
  const [snackBarTitle, setSnackBarTitle] = useState<string | undefined>(undefined);
  const [snackBarType, setSnackBarType] = useState<string | undefined>(undefined);
  const [oddsDataStore, setOddsDataStore] = useState<OD[]>([]);
  const [oddsData, setOddsData] = useState<OD[]>([]);
  //const {odds} = useGetOdds();
  //const [rowsTopBets, setRowsTopBets] = useState(5);
  //const [rowsBottomBets, setRowBottomBets] = useState(5);
  const [loadingOddsData, setLoadingOddsData] = useState(true);
  const [loadingBetRows, setLoadingBetRows] = useState(true);
  //const [showModal, setShowModal] = useState<boolean>(false);
  const lgSubMenuW = "240px";
  const smSubMenuW = "80px";
  const {headerDisplay, setHeaderDisplay} = useGetHeaderDisplay();
  const setShowSubMenu = (show: boolean) => {
    if (show) {
      setHeaderDisplay('show')
    } else {
      setHeaderDisplay('hide')
    }
  }
  const [showAvg, setShowAvg] = useState(false);
  const [minAllTimeInstancesTopBets, setMinAllTimeInstancesTopBets] = useState(2);
  const [loadingNLUPrediction, setLoadingNLUPrediction] = useState(false);
  const [isQuery, setIsQuery] = useState<boolean>(false);
  const [showSR, setShowSR] = useState<boolean>(false);
  const [questionParams, setQuestionParams] = useState<QuestionData>({
    site_ids: [],
    team_ids: [],
    bet_types: [],
    bet_lines: [],
    leagues: [],
    intent: "",
  } as QuestionData);
  //const [qRows, setQRows] = useState<RowObj[]>([]);
  const restrictRowsAfterN = 15;
  //const [showOddsChart, setShowOddsChart] = useState<boolean[]>(Array(oddsData.length).fill(false));
  const setText = generalSearchState((state) => state.setText);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  //const navigate = useNavigate();
  const isHome = (content === 'home');
  //const isBets = (content === 'bets');
  const isBetsTrending = (location.pathname.toString() === "/bets/trending");
  const isBetsTop = (location.pathname.toString() === "/bets/top");
  const isHedger = (content === 'hedger');
  const isApiSpec = (content === 'api-spec');
  const isApiDocs = (content === 'api-docs');
  const isHelp = (content === 'help');
  const isAccount = (content === 'account');
  //const isQ = (location.pathname.toString().includes("/q/"));
  const isQ = (content === 'q');
  //const question = (isQ && location.pathname.toString().split("q").length > 1 ? location.pathname.toString().split("q")[1] : "");

  const SwaggerUIContainer = styled.div`
    width: 100%;
    height: 100%;
    span {
      color: ${getThemeData(theme).textColor4} !important;
    }
    h1 {
      color: ${getThemeData(theme).textColor4} !important;
    }
    h2 {
      color: ${getThemeData(theme).textColor4} !important;
    }
    h3 {
      color: ${getThemeData(theme).textColor4} !important;
    }
    h4 {
      color: ${getThemeData(theme).textColor4} !important;
    }
    th {
      color: ${getThemeData(theme).textColor4} !important;
    }
    td {
      color: ${getThemeData(theme).textColor4} !important;
    }
    tr {
      color: ${getThemeData(theme).textColor4} !important;
    }
    tbody {
      color: ${getThemeData(theme).textColor4} !important;
    }
    thead {
      color: ${getThemeData(theme).textColor4} !important;
    }
    .parameter__name {
      color: ${getThemeData(theme).textColor4} !important;
    }
    .opblock-summary-description {
      color: ${getThemeData(theme).textColor4} !important;
    }
    .opblock-control-arrow {
      background: ${theme === 'dark' ? getThemeData(theme).colorAccent : 'inherit'} !important;
      border-radius: 4px !important;
    }
    .swagger-ui {
      background: ${getThemeData(theme).bgGlobal};
      font-family: Avenir Next, Arial, sans-serif;
      //font-family: 'Montserrat', sans-serif;
    }
    .swagger-main {
      background: ${getThemeData(theme).bgGlobal};
      font-family: Avenir Next, Arial, sans-serif;
      //font-family: 'Montserrat', sans-serif;
    }
    .scheme-container {
      background: ${getThemeData(theme).bgGlobal};
      font-family: Avenir Next, Arial, sans-serif;
      //font-family: 'Montserrat', sans-serif;
    }
    .opblock-section-header {
      background: ${getThemeData(theme).bgGlobal} !important;
      font-family: Avenir Next, Arial, sans-serif;
      //font-family: 'Montserrat', sans-serif;
      button {
        color: ${getThemeData(theme).textColor4} !important;
      }
    }
    .opblock-tag-section {
      .expand-operation {
        background: ${theme === 'dark' ? getThemeData(theme).colorAccent : 'inherit'} !important;
        border-radius: 4px !important;
      }
    }
  `

  const parseGameInfoFromGameId = (gid: string, tid:string) => {
    let gidnt = gid.replace('-'+gid.split('-')[gid.split('-').length - 1],'');
    let tidnl = tid.replace(gidnt.split('-')[0]+"-",'').replaceAll('-','');
    let oppdis = gidnt.replace(gidnt.split('-')[0]+"-",'').replace(tidnl,'').replaceAll('-','');
    let l = gidnt.split('-')[0].toUpperCase();
    //console.log('PARSE FROM GAMEID', oppdis)
    /*
    let regex = new RegExp(/[A-Z][a-z]+/g);
    let olm_index = -1;
    let tlm_index = -1;
    let match;
    while ((match = regex.exec(oppdis)) != null) {
      olm_index = match.index;
    }
    while ((match = regex.exec(tidnl)) != null) {
      tlm_index = match.index;
    }
    if (olm_index !== -1) {
      oppdis = oppdis.substring(0, olm_index) + " " + oppdis.substring(olm_index);
    }
    if (tlm_index !== -1) {
      tidnl = tidnl.substring(0, tlm_index) + " " + tidnl.substring(tlm_index);
    }
    */
    let regex = new RegExp(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g);
    if (tidnl.match(regex) != null) {
      tidnl = tidnl.match(regex)!.join(" ");
    }
    if (oppdis.match(regex) != null) {
      oppdis = oppdis.match(regex)!.join(" ");
    }
    //console.log('PARSE FROM GAMEID', oppdis, tidnl)
    let t1 = tidnl;
    let t2 = oppdis;
    return {
      league: l,
      team1: t1,
      team2: t2,
    }
  }

  const getGameDesc = (gid: string, tid: string, t: number) => {
    const gameInfo = parseGameInfoFromGameId(gid, tid);
    console.log(`${gid} ${gameInfo.team1} vs ${gameInfo.team2} ${t}`)
    const gameTime = convertUTCToLocal(t*1000).toLocaleString(undefined, {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      //hour12: false,
      minute: '2-digit',
      //second:'2-digit'
    });
    //const gameDesc = `${gameInfo.league} ${gameInfo.team1} vs ${gameInfo.team2} ${gameTime}`;
    //const gameDesc = `${gameInfo.team1} vs ${gameInfo.team2} ${gameTime}`;
    return {
      league: `${gameInfo.league}`,
      team1: `${gameInfo.team1}`,
      team2: `${gameInfo.team2}`,
      gameTime: `${gameTime}`,
    }
  }

  function getStandardDeviation (array: number[]) {
    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
  }

  const formatTeamId = (team_id: string) => {
    return team_id.replace(team_id.split('-')[0]+"-","").replaceAll("-"," ")
  }

  const formatBetType = (
    bet_type: string,
    team_id_display: string,
    opp_id_display: string,
    val: number,
  ) => {
    let bt = "";
    if (bet_type === 'Moneyline') {
      bt = `${team_id_display} Moneyline vs ${opp_id_display}`;
    } else if (bet_type === 'Spread') {
      if (val > 0) {
        bt = `${team_id_display} Spread +${val}`;
      } else {
        bt = `${team_id_display} Spread ${val}`;
      }
    } else if (bet_type === 'Over' || bet_type === 'Under') {
      bt = `${bet_type} ${val}`;
    }
    return bt;
  }

  const getOddsTrendData = (
    //odds_data: any[],
    odds_attr: string,
    //odds_attr_org: string,
    game_id: string,
    site_id: string,
    team_id: string,
    opp_attr: string,
    //game_time: number,
    bet_type: string,
    objs_unsorted: any[],
    uat_col: string,
  ) => {
    if (objs_unsorted === undefined || objs_unsorted.length === 0) {
      return undefined;
    }
    var data = undefined;
    try {
      //var objs = [...objs_unsorted].sort((a, b) => parseFloat(a.updated_at) - parseFloat(b.updated_at));
      var objs = [...objs_unsorted];
      console.log(objs[0])
      var game_time = parseInt(objs[objs.length-1].game_time);
      var current = parseFloat(objs[objs.length-1][`${odds_attr}`]);
      var first = parseFloat(objs[0][`${odds_attr}`]);
      const objs_attr = [...objs].map(item => parseFloat(item[`${odds_attr}`]));
      var std = getStandardDeviation(objs_attr)
      var lowest = Math.min(...objs_attr); //Number.POSITIVE_INFINITY;
      var highest = Math.max(...objs_attr); //Number.NEGATIVE_INFINITY;
      //var tmp;
      //for (var x=objs.length-1; x>=0; x--) {
      //    tmp = parseFloat(objs[x][`${odds_attr}`]);
      //    if (tmp < lowest) lowest = tmp;
      //    if (tmp > highest) highest = tmp;
      //}
      var team_id_display = formatTeamId(team_id);
      var opp_id_display = "";
      var bt = "";
      let btln = "";
      if (bet_type === 'Moneyline') {
        opp_id_display = formatTeamId(objs[0][opp_attr]); //Array.from(new Set(objs.map(ost1 => ost1[opp_id])))[0];
        bt = formatBetType(bet_type, team_id_display, opp_id_display, 0);
      } else if (bet_type === 'Spread') {
        if (odds_attr === 'ps_t1_odds') {
          if (objs[objs.length-1].ps_t1_line === null) {
            return undefined;
          }
          bt = formatBetType(bet_type, team_id_display, opp_id_display, objs[objs.length-1].ps_t1_line);
          btln = objs[objs.length-1].ps_t1_line.toString();
        } else {
          if (objs[objs.length-1].ps_t2_line === null) {
            return undefined;
          }
          bt = formatBetType(bet_type, team_id_display, opp_id_display, objs[objs.length-1].ps_t2_line);
          btln = objs[objs.length-1].ps_t2_line.toString();
        }
      } else if (bet_type === 'Over' || bet_type === 'Under') {
        if (objs[objs.length-1].line === null) {
          return undefined;
        }
        bt = formatBetType(bet_type, team_id_display, opp_id_display, objs[objs.length-1].line);
        btln = objs[objs.length-1].line.toString();
      }
      var draw_odds = 0;
      var opp_odds = 0;
      if (bet_type === 'Over' || bet_type === 'Under') {
        if (bet_type === 'Over') {
          opp_odds = objs[objs.length-1][`under_odds`];
        } else {
          opp_odds = objs[objs.length-1][`over_odds`];
        }
      } else {
        if (odds_attr.includes('t1')) {
          opp_odds = objs[objs.length-1][`${odds_attr.replace('t1','t2')}`];
        } else {
          opp_odds = objs[objs.length-1][`${odds_attr.replace('t2','t1')}`];
        }
      }
      if (objs[objs.length-1]['draw_odds'] !== undefined && objs[objs.length-1]['draw_odds'] !== null) {
        draw_odds = objs[objs.length-1]['draw_odds'];
      }
      console.log("ODDS", bet_type, convertOdds(opp_odds,2), convertOdds(current,2), convertOdds(draw_odds,2))
      var vig = (convertOdds(opp_odds,3) + convertOdds(current,3) - 100);
      if (bet_type === 'Moneyline' && draw_odds > 0) {
        vig += convertOdds(draw_odds,3)
      }
      var bet_nav_url = "/"
      if (bet_type === "Moneyline") {
        bet_nav_url = `/q?team_id=${team_id}&bet_type=moneyline&intent=GetSiteTeamMl`
      } else if (bet_type === "Spread") {
        bet_nav_url = `/q?team_id=${team_id}&bet_type=spread&intent=GetSiteTeamPs`
      } else if (bet_type === "Over") {
        //bet_nav_url = `/q?team_id=${team_id}&bet_type=over&intent=GetSiteTeamOver`
        bet_nav_url = `/q?bet_line=${btln}&bet_type=over&intent=GetSiteOverLine`
      } else if (bet_type === "Under") {
        //bet_nav_url = `/q?team_id=${team_id}&bet_type=under&intent=GetSiteTeamUnder`
        bet_nav_url = `/q?bet_line=${btln}&bet_type=under&intent=GetSiteUnderLine`
      }
      data = {
        league: game_id.split('-')[0].toUpperCase(),
        game_desc: getGameDesc(game_id, team_id, game_time),
        game_id: game_id,
        site_id: site_id,
        team_id: team_id_display,
        current: current,
        first: first,
        lowest: lowest,
        highest: highest,
        standard_deviation: std,
        change: ((current - first) / (first) * 100),
        change_highest: ((current - highest) / (highest) * 100),
        change_lowest: ((current - lowest) / (lowest) * 100),
        data: [...objs_attr], //[...objs],
        update_times: [...objs].map(item => {
          //const dateLabel = new Date(item[`${uat_col}`]*1000).toISOString().split('T')[0]
          const d = new Date(item[`${uat_col}`]*1000)
          /*const dateLabel = [
            d.getFullYear(),
            d.getMonth(),
            d.getDate()].join('-')+' '+
          [d.getHours(),
            d.getMinutes(),
            d.getSeconds()].join(':');
          */
          const dateLabel = d.toISOString().split('.')[0];
          return dateLabel;
        }),
        bet_type: bt,
        org_bet_type: bet_type,
        vig: vig,
        bet_nav_url: bet_nav_url,
      };
    } catch {
      console.log('error')
    }
    return data;
  }

  async function getOddsPendingGamesData() {
    const odds_resp = await getOddsPendingGames();
    console.log("ODDS", odds_resp);
    //const buffer = 60 * 60 * 48;
    const odds_data = [...odds_resp.data]//.filter(item => {
    //  if (parseInt(item.game_time.toString()) <= ((Math.floor(Date.now() / 1000)) + buffer)) {
    //    return item;
    //  }
    //});
    let game_ids: string[] = odds_data.map(item => item.game_id.toString());
    let site_ids: string[] = odds_data.map(item => item.site_id.toString());
    let team_ids1: string[] = odds_data.map(item => item.t1_id.toString());
    let team_ids2: string[] = odds_data.map(item => item.t2_id.toString());
    //let team_ids = [...team_ids1, ...team_ids2];
    //let game_times: number[] = odds_data.map(item => parseInt(item.game_time.toString()));
    game_ids = Array.from(new Set([...game_ids])); //[...game_ids].filter((x, i, a) => a.indexOf(x) == i);
    site_ids = Array.from(new Set([...site_ids])); //[...site_ids].filter((x, i, a) => a.indexOf(x) == i);
    let team_ids = Array.from(new Set([...team_ids1, ...team_ids2])); //[...team_ids].filter((x, i, a) => a.indexOf(x) == i);
    const odds_objs = [];
    //const buffer = 60 * 60 * 1;
    //const cutoff = (Math.floor(Date.now() / 1000)) + buffer;
    const odds_data_sorted = [...odds_data].sort((a, b) => parseFloat(a.updated_at) - parseFloat(b.updated_at));
    console.log("PENDING GAMES LENGTH", odds_data_sorted.length)
    console.log("PENDING GAMES GAMEID LENGTH", game_ids.length)
    console.log("PENDING GAMES SITEID LENGTH", site_ids.length)
    console.log("PENDING GAMES TEAMID LENGTH", team_ids.length)
    for (let i = 0; i < game_ids.length; i++) {
      const objs_sorted_game_id = odds_data_sorted.filter(function(o) {
        return (o.game_id.toString() === game_ids[i])
      })
      for (let j = 0; j < site_ids.length; j++) {
        const objs_sorted_site_id = objs_sorted_game_id.filter(function(o) {
          return (o.site_id.toString() === site_ids[j])
        })
        for (let k = 0; k < team_ids.length; k++) {
          const objs_sorted_t1 = objs_sorted_site_id.filter(function(o) {
            return (
              o[`t1_id`].toString() === team_ids[k]
            );
          });
          const objs_sorted_t2 = objs_sorted_site_id.filter(function(o) {
            return (
              o[`t2_id`].toString() === team_ids[k]
            );
          });
          //var objs_sorted_t1 = objs_unsorted_t1.sort((a, b) => parseFloat(a.updated_at) - parseFloat(b.updated_at));
          //var objs_sorted_t2 = objs_unsorted_t2.sort((a, b) => parseFloat(a.updated_at) - parseFloat(b.updated_at));
          //console.log("PENDING GAMES", game_ids[i], site_ids[j], team_ids[k], objs_sorted_t1, objs_sorted_t2);
          const objd_ml_t1 = getOddsTrendData('ml_t1_odds', game_ids[i], site_ids[j], team_ids[k], 't2_id', 'Moneyline', objs_sorted_t1, 'ml_updated_at');
          const objd_ml_t2 = getOddsTrendData('ml_t2_odds', game_ids[i], site_ids[j], team_ids[k], 't1_id', 'Moneyline', objs_sorted_t2, 'ml_updated_at');
          const objd_ps_t1 = getOddsTrendData('ps_t1_odds', game_ids[i], site_ids[j], team_ids[k], 't2_id', 'Spread', objs_sorted_t1,'ps_updated_at');
          const objd_ps_t2 = getOddsTrendData('ps_t2_odds', game_ids[i], site_ids[j], team_ids[k], 't1_id', 'Spread', objs_sorted_t2,'ps_updated_at');
          const objd_ou_t1 = getOddsTrendData('over_odds', game_ids[i], site_ids[j], team_ids[k], '', 'Over', objs_sorted_t1, 'ou_updated_at');
          const objd_ou_t2 = getOddsTrendData('under_odds', game_ids[i], site_ids[j], team_ids[k], '', 'Under', objs_sorted_t2, 'ou_updated_at');
          if (objd_ml_t1 !== undefined) odds_objs.push({...objd_ml_t1});
          if (objd_ml_t2 !== undefined) odds_objs.push({...objd_ml_t2});
          if (objd_ps_t1 !== undefined) odds_objs.push({...objd_ps_t1});
          if (objd_ps_t2 !== undefined) odds_objs.push({...objd_ps_t2});
          if (objd_ou_t1 !== undefined) odds_objs.push({...objd_ou_t1});
          if (objd_ou_t2 !== undefined) odds_objs.push({...objd_ou_t2});
        }
      }
    }
    const odds_objs_sorted2 = [...odds_objs].sort((a, b) => b.standard_deviation - a.standard_deviation);
    console.log("PENDING GAMES LENGTH2", odds_objs_sorted2.length)
    setOddsData(odds_objs_sorted2);
    setOddsDataStore(odds_objs_sorted2);
    if (loadingOddsData) {
      setLoadingOddsData(false);
    }
  }

  /*
  async function getTopBetsTableData() {
    const ml_resp = await getMlTopBetStats();
    const ps_resp = await getPsTopBetStats();
    const ou_resp = await getOuTopBetStats();
    let ml_data: RowObj2[] = [];
    let ps_data: RowObj2[] = [];
    let ou_data: RowObj2[] = [];
    if (ml_resp && ml_resp.status === 200 && ml_resp.data) {
      const ml_resp_data = [...ml_resp.data];
      ml_data = ml_resp_data.map(val => ({
        c1: val.game_id.split('-')[0].toUpperCase(),
        c2: getGameDesc(val.game_id.toString(), parseInt(val.game_time)),
        c3: val.team_id.replace(val.game_id.split('-')[0]+"-","").replace("-"," ").toString(),
        c4: val.site_id.toString(),
        c5: `+${(parseFloat(val.max_ml_odds)*100).toFixed(0).toString()}%`,
      }));
    }
    if (ps_resp && ps_resp.status === 200 && ps_resp.data) {
      const ps_resp_data = [...ps_resp.data];
      ps_data = ps_resp_data.map(val => ({
        c1: val.game_id.split('-')[0].toUpperCase(),
        c2: getGameDesc(val.game_id.toString(), parseInt(val.game_time)),
        c3: val.team_id.replace(val.game_id.split('-')[0]+"-","").replace("-"," ").toString()+" "+val.ps_line.toString(),
        c4: val.site_id.toString(),
        c5: `+${(parseFloat(val.max_ps_odds)*100).toFixed(0).toString()}%`,
      }));
    }
    if (ou_resp && ou_resp.status === 200 && ou_resp.data) {
      const ou_resp_data = [...ou_resp.data];
      ou_data = ou_resp_data.map(val => ({
        c1: val.game_id.split('-')[0].toUpperCase(),
        c2: getGameDesc(val.game_id.toString(), parseInt(val.game_time)),
        c3: val.side.toString()[0]+val.side.toString().slice(1)+" "+val.line.toString(),
        c4: val.site_id.toString(),
        c5: `+${(parseFloat(val.max_ou_odds)*100).toFixed(0).toString()}%`,
      }));
    }
    let new_rows: RowObj2[] = [...ml_data, ...ps_data, ...ou_data];
    new_rows = new_rows.sort((a, b) => parseFloat(a.c3) - parseFloat(b.c3));
    setTopRows([...new_rows]);
    setLoadingBetRows(false);
  }
  */

  async function getMainTableData() {
    console.log("Polling for data");
    const ml_resp = await getMlBetStats();
    const ps_resp = await getPsBetStats();
    const ou_resp = await getOuBetStats();
    //console.log("ML STATS INDEX", ml_resp.data)
    //console.log("PS STATS INDEX", ps_resp.data)
    //console.log("OU STATS INDEX", ou_resp.data)
    let ml_data: RowObj[] = [];
    let ps_data: RowObj[] = [];
    let ou_data: RowObj[] = [];
    if (ml_resp && ml_resp.status === 200 && ml_resp.data) {
      ml_data = [...ml_resp.data].filter(v => parseInt(v.instances_all_time) >= minAllTimeInstancesTopBets).map((val, _) => {
        var c6_agg = parseFloat(val.unit_profit_7d).toFixed(2).toString();
        var c7_agg = parseFloat(val.unit_profit_30d).toFixed(2).toString();
        var c8_agg = parseFloat(val.unit_profit_90d).toFixed(2).toString();
        var c9_agg = parseFloat(val.unit_profit_1y).toFixed(2).toString();
        var c10_agg = parseFloat(val.unit_profit_all_time).toFixed(2).toString();
        var c6_avg = parseInt(val.instances_7d) > 0
          ? (parseFloat(val.unit_profit_7d) / parseInt(val.instances_7d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c7_avg = parseInt(val.instances_30d) > 0
          ? (parseFloat(val.unit_profit_30d) / parseInt(val.instances_30d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c8_avg = parseInt(val.instances_90d) > 0
          ? (parseFloat(val.unit_profit_90d) / parseInt(val.instances_90d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c9_avg = parseInt(val.instances_1y) > 0
          ? (parseFloat(val.unit_profit_1y) / parseInt(val.instances_1y)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c10_avg = parseInt(val.instances_all_time) > 0
          ? (parseFloat(val.unit_profit_all_time) / parseInt(val.instances_all_time)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        return {
          c1: formatTeamId(val.team_id),
          c2: val.team_id.split('-')[0].toUpperCase(),
          c3: "Moneyline",
          c4: val.site_id,
          c5: val.underdog_team === 1 ? "Underdog" : "Favored",
          c6: c6_agg, //showAvg ? c6_avg : c6_agg,
          c7: c7_agg, //showAvg ? c7_avg : c7_agg,
          c8: c8_agg, //showAvg ? c8_avg : c8_agg,
          c9: c9_agg, //showAvg ? c9_avg : c9_agg,
          c10: c10_agg, //showAvg ? c10_avg : c10_agg,
          c6_agg: c6_agg,
          c7_agg: c7_agg,
          c8_agg: c8_agg,
          c9_agg: c9_agg,
          c10_agg: c10_agg,
          c6_avg: c6_avg,
          c7_avg: c7_avg,
          c8_avg: c8_avg,
          c9_avg: c9_avg,
          c10_avg: c10_avg,
          c11: formatTeamId(val.opp_id),
        }
      });
    }
    if (ps_resp && ps_resp.status === 200 && ps_resp.data) {
      ps_data = [...ps_resp.data].filter(v => parseInt(v.instances_all_time) >= minAllTimeInstancesTopBets).map((val, _) => {
        var c6_agg = parseFloat(val.unit_profit_7d).toFixed(2).toString();
        var c7_agg = parseFloat(val.unit_profit_30d).toFixed(2).toString();
        var c8_agg = parseFloat(val.unit_profit_90d).toFixed(2).toString();
        var c9_agg = parseFloat(val.unit_profit_1y).toFixed(2).toString();
        var c10_agg = parseFloat(val.unit_profit_all_time).toFixed(2).toString();
        var c6_avg = parseInt(val.instances_7d) > 0
          ? (parseFloat(val.unit_profit_7d) / parseInt(val.instances_7d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c7_avg = parseInt(val.instances_30d) > 0
          ? (parseFloat(val.unit_profit_30d) / parseInt(val.instances_30d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c8_avg = parseInt(val.instances_90d) > 0
          ? (parseFloat(val.unit_profit_90d) / parseInt(val.instances_90d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c9_avg = parseInt(val.instances_1y) > 0
          ? (parseFloat(val.unit_profit_1y) / parseInt(val.instances_1y)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c10_avg = parseInt(val.instances_all_time) > 0
          ? (parseFloat(val.unit_profit_all_time) / parseInt(val.instances_all_time)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        return {
          c1: formatTeamId(val.team_id),
          c2: val.team_id.split('-')[0].toUpperCase(),
          c3: "Spread",
          c4: val.site_id,
          c5: val.underdog_team === 1 ? "Underdog" : "Favored",
          c6: c6_agg, //showAvg ? c6_avg : c6_agg,
          c7: c7_agg, //showAvg ? c7_avg : c7_agg,
          c8: c8_agg, //showAvg ? c8_avg : c8_agg,
          c9: c9_agg, //showAvg ? c9_avg : c9_agg,
          c10: c10_agg, //showAvg ? c10_avg : c10_agg,
          c6_agg: c6_agg,
          c7_agg: c7_agg,
          c8_agg: c8_agg,
          c9_agg: c9_agg,
          c10_agg: c10_agg,
          c6_avg: c6_avg,
          c7_avg: c7_avg,
          c8_avg: c8_avg,
          c9_avg: c9_avg,
          c10_avg: c10_avg,
          c11: parseFloat(val.team_ps_line) > 0 ? "+"+val.team_ps_line : val.team_ps_line,
        }
      });
    }
    if (ou_resp && ou_resp.status === 200 && ou_resp.data) {
      ou_data = [...ou_resp.data].filter(v => parseInt(v.instances_all_time) >= minAllTimeInstancesTopBets).map((val, _) => {
        var c6_agg = parseFloat(val.unit_profit_7d).toFixed(2).toString();
        var c7_agg = parseFloat(val.unit_profit_30d).toFixed(2).toString();
        var c8_agg = parseFloat(val.unit_profit_90d).toFixed(2).toString();
        var c9_agg = parseFloat(val.unit_profit_1y).toFixed(2).toString();
        var c10_agg = parseFloat(val.unit_profit_all_time).toFixed(2).toString();
        var c6_avg = parseInt(val.instances_7d) > 0
          ? (parseFloat(val.unit_profit_7d) / parseInt(val.instances_7d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c7_avg = parseInt(val.instances_30d) > 0
          ? (parseFloat(val.unit_profit_30d) / parseInt(val.instances_30d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c8_avg = parseInt(val.instances_90d) > 0
          ? (parseFloat(val.unit_profit_90d) / parseInt(val.instances_90d)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c9_avg = parseInt(val.instances_1y) > 0
          ? (parseFloat(val.unit_profit_1y) / parseInt(val.instances_1y)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        var c10_avg = parseInt(val.instances_all_time) > 0
          ? (parseFloat(val.unit_profit_all_time) / parseInt(val.instances_all_time)).toFixed(2).toString()
          : parseFloat("0").toFixed(2).toString();
        return {
          c1: formatTeamId(val.team_id),
          c2: val.team_id.split('-')[0].toUpperCase(),
          c3: val.side[0].toUpperCase()+val.side.slice(1),
          c4: val.site_id,
          c5: val.underdog_side === 1 ? "Underdog" : "Favored",
          c6: c6_agg, //showAvg ? c6_avg : c6_agg,
          c7: c7_agg, //showAvg ? c7_avg : c7_agg,
          c8: c8_agg, //showAvg ? c8_avg : c8_agg,
          c9: c9_agg, //showAvg ? c9_avg : c9_agg,
          c10: c10_agg, //showAvg ? c10_avg : c10_agg,
          c6_agg: c6_agg,
          c7_agg: c7_agg,
          c8_agg: c8_agg,
          c9_agg: c9_agg,
          c10_agg: c10_agg,
          c6_avg: c6_avg,
          c7_avg: c7_avg,
          c8_avg: c8_avg,
          c9_avg: c9_avg,
          c10_avg: c10_avg,
          c11: val.line,
        }
      });
    }
    setRows([...ml_data, ...ps_data, ...ou_data]);
    setBetRowsStore([...ml_data, ...ps_data, ...ou_data]);
    setFirstLoad(true);
    setLoadingBetRows(false);
  }

  function handleSort2(col_num: number, direction: number, sort_store: boolean = true){
    if (col_num === 1) {
      setLastColSorted2(1)
      if (direction === 1) {
        setLastDirectionSorted2(1);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => b.standard_deviation - a.standard_deviation);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => b.standard_deviation - a.standard_deviation);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(true)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      } else if (direction === 0) {
        setLastDirectionSorted2(0);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => a.standard_deviation - b.standard_deviation);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => a.standard_deviation - b.standard_deviation);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      }
    } else if (col_num === 2) {
      setLastColSorted2(2)
      if (direction === 1) {
        setLastDirectionSorted2(1);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => b.current - a.current);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => b.current - a.current);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(true)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      } else if (direction === 0) {
        setLastDirectionSorted2(0);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => a.current - b.current);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => a.current - b.current);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      }
    } else if (col_num === 3) {
      setLastColSorted2(3)
      if (direction === 1) {
        setLastDirectionSorted2(1);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => b.change - a.change);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) =>  b.change - a.change);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(true)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      } else if (direction === 0) {
        setLastDirectionSorted2(0);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) =>  a.change - b.change);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => a.change - b.change);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      }
    } else if (col_num === 4) {
      setLastColSorted2(4)
      if (direction === 1) {
        setLastDirectionSorted2(1);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => b.change_highest - a.change_highest);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => b.change_highest - a.change_highest);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(true)
        setSortAllTime2(false)
        setSortVig(false)
      } else if (direction === 0) {
        setLastDirectionSorted2(0);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => a.change_highest - b.change_highest);
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => a.change_highest - b.change_highest);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      }
    } else if (col_num === 5) {
      setLastColSorted2(5)
      if (direction === 1) {
        setLastDirectionSorted2(1);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => b.change_lowest - a.change_lowest);
        console.log("ROWS", new_rows)
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => b.change_lowest - a.change_lowest);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(true)
        setSortVig(false)
      } else if (direction === 0) {
        setLastDirectionSorted2(0);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => a.change_lowest - b.change_lowest);
        console.log("ROWS", new_rows)
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => a.change_lowest - b.change_lowest);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      }
    } else if (col_num === 6) {
      setLastColSorted2(6)
      if (direction === 1) {
        setLastDirectionSorted2(1);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => b.vig - a.vig);
        console.log("ROWS", new_rows)
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => b.vig - a.vig);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(true)
      } else if (direction === 0) {
        setLastDirectionSorted2(0);
        let new_rows: OD[] = [...oddsData];
        new_rows = new_rows.sort((a, b) => a.vig - b.vig);
        console.log("ROWS", new_rows)
        setOddsData([...new_rows]);
        let new_rows_store: OD[] = [...oddsDataStore];
        new_rows_store = new_rows_store.sort((a, b) => a.vig - b.vig);
        setOddsDataStore([...new_rows_store]);
        setSort7D2(false)
        setSort1M2(false)
        setSort3M2(false)
        setSort1Y2(false)
        setSortAllTime2(false)
        setSortVig(false)
      }
    }
  }

  function handleSort(col_num: number, direction: number, sort_store: boolean = true){
    if (col_num === 1) {
      setLastColSorted(1)
      if (direction === 1) {
        setLastDirectionSorted(1);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(b.c6) - parseFloat(a.c6));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(b.c6) - parseFloat(a.c6));
        setBetRowsStore([...new_rows_store]);
        setSort7D(true)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      } else if (direction === 0) {
        setLastDirectionSorted(0);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(a.c6) - parseFloat(b.c6));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(a.c6) - parseFloat(b.c6));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      }
    } else if (col_num === 2) {
      setLastColSorted(2)
      if (direction === 1) {
        setLastDirectionSorted(1);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(b.c7) - parseFloat(a.c7));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(b.c7) - parseFloat(a.c7));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(true)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      } else if (direction === 0) {
        setLastDirectionSorted(0);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(a.c7) - parseFloat(b.c7));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(a.c7) - parseFloat(b.c7));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      }
    } else if (col_num === 3) {
      setLastColSorted(3)
      if (direction === 1) {
        setLastDirectionSorted(1);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(b.c8) - parseFloat(a.c8));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(b.c8) - parseFloat(a.c8));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(true)
        setSort1Y(false)
        setSortAllTime(false)
      } else if (direction === 0) {
        setLastDirectionSorted(0);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(a.c8) - parseFloat(b.c8));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(a.c8) - parseFloat(b.c8));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      }
    } else if (col_num === 4) {
      setLastColSorted(4)
      if (direction === 1) {
        setLastDirectionSorted(1);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(b.c9) - parseFloat(a.c9));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(b.c9) - parseFloat(a.c9));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(true)
        setSortAllTime(false)
      } else if (direction === 0) {
        setLastDirectionSorted(0);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(a.c9) - parseFloat(b.c9));
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(a.c9) - parseFloat(b.c9));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      }
    } else if (col_num === 5) {
      setLastColSorted(5)
      if (direction === 1) {
        setLastDirectionSorted(1);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(b.c10) - parseFloat(a.c10));
        console.log("ROWS", new_rows)
        setRows([...new_rows]);
        let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(b.c10) - parseFloat(a.c10));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(true)
      } else if (direction === 0) {
        setLastDirectionSorted(0);
        let new_rows: RowObj[] = [...betRows];
        new_rows = new_rows.sort((a, b) => parseFloat(a.c10) - parseFloat(b.c10));
        console.log("ROWS", new_rows)
        setRows([...new_rows]);let new_rows_store: RowObj[] = [...betRowsStore];
        new_rows_store = new_rows_store.sort((a, b) => parseFloat(a.c10) - parseFloat(b.c10));
        setBetRowsStore([...new_rows_store]);
        setSort7D(false)
        setSort1M(false)
        setSort3M(false)
        setSort1Y(false)
        setSortAllTime(false)
      }
    }
  }

  const addBetType = (betType: string) => {
    setBetTypes([...betTypes, betType]);
  }

  const removeBetType = (betType: string) => {
    var bts = [...betTypes];
    var index = bts.indexOf(betType);
    if (index !== -1) {
      bts.splice(index, 1);
      if (bts.length === 0) {
        setSnackBarTitle("Must have at least one active bet type")
        setSnackBarType("customWarning")
        setTimeout(() => {
          setSnackBarType(undefined);
          setSnackBarTitle(undefined);
        }, config.snackTimeout);
      } else {
        setBetTypes([...bts]);
      }
    }
  }

  const addBetType2 = (betType: string) => {
    setBetTypes2([...betTypes2, betType]);
  }

  const removeBetType2 = (betType: string) => {
    var bts = [...betTypes2];
    var index = bts.indexOf(betType);
    if (index !== -1) {
      bts.splice(index, 1);
      if (bts.length === 0) {
        setSnackBarTitle("Must have at least one active bet type")
        setSnackBarType("customWarning")
        setTimeout(() => {
          setSnackBarType(undefined);
          setSnackBarTitle(undefined);
        }, config.snackTimeout);
      } else {
        setBetTypes2([...bts]);
      }
    }
  }

  useEffect(() => {
    console.log("GETTING TOP BETS DATA")
    getMainTableData();
    adjustMainTableData();
  },[])

  const adjustMainTableData = () => {
    if (showAvg) {
      console.log("SETTING AVG")
      const newBetRows = [...betRows].map((val, _) => {
        const new_c6 = val.c6_avg;
        const new_c7 = val.c7_avg;
        const new_c8 = val.c8_avg;
        const new_c9 = val.c9_avg;
        const new_c10 = val.c10_avg;
        return {
          ...val,
          c6: new_c6,
          c7: new_c7,
          c8: new_c8,
          c9: new_c9,
          c10: new_c10
        }
      });
      const newBetRowsStore = [...betRowsStore].map((val, _) => {
        const new_c6 = val.c6_avg;
        const new_c7 = val.c7_avg;
        const new_c8 = val.c8_avg;
        const new_c9 = val.c9_avg;
        const new_c10 = val.c10_avg;
        return {
          ...val,
          c6: new_c6,
          c7: new_c7,
          c8: new_c8,
          c9: new_c9,
          c10: new_c10
        }
      });
      setRows([...newBetRows]);
      setBetRowsStore([...newBetRowsStore]);
    } else {
      console.log("SETTING AGG")
      const newBetRows = [...betRows].map((val, _) => {
        const new_c6 = val.c6_agg;
        const new_c7 = val.c7_agg;
        const new_c8 = val.c8_agg;
        const new_c9 = val.c9_agg;
        const new_c10 = val.c10_agg;
        return {
          ...val,
          c6: new_c6,
          c7: new_c7,
          c8: new_c8,
          c9: new_c9,
          c10: new_c10
        }
      });
      const newBetRowsStore = [...betRowsStore].map((val, _) => {
        const new_c6 = val.c6_agg;
        const new_c7 = val.c7_agg;
        const new_c8 = val.c8_agg;
        const new_c9 = val.c9_agg;
        const new_c10 = val.c10_agg;
        return {
          ...val,
          c6: new_c6,
          c7: new_c7,
          c8: new_c8,
          c9: new_c9,
          c10: new_c10
        }
      });
      setRows([...newBetRows]);
      setBetRowsStore([...newBetRowsStore]);
    }
  }

  useEffect(() => {
    adjustMainTableData();
  },[showAvg])

  useEffect(() => {
    console.log("GETTING PENDING BETS DATA")
    getOddsPendingGamesData();
  },[])

  //useEffect(() => {
  //  getTopBetsTableData();
  //},[])

  //useEffect(() => {
  //  if (isBetsTop) {
  //    getMainTableData();
  //  } else if (isBetsTrending) {
  //    getOddsPendingGamesData();
  //  }
  //},[isBetsTop, isBetsTrending]);

  /*
  useEffect(() => {
    if (firstLoad) {
      handleSort(1, 1);
      setFirstLoad(false);
      const showOddsChartNew = [...showOddsChart];
      if (showOddsChartNew.length > 0) {
        showOddsChartNew[0] = true;
        setShowOddsChart([...showOddsChartNew])
      }
    }
  },[firstLoad])*/

  function isSelectedBetType(row: RowObj) {
    if (
      (row.c3 === 'Moneyline' && betTypes.includes('ml')) ||
      (row.c3 === 'Spread' && betTypes.includes('ps')) ||
      ((row.c3 === 'Over' || row.c3 === 'Under') && betTypes.includes('ou'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isSelectedBetType2(row: OD) {
    if (
      (row.org_bet_type === 'Moneyline' && betTypes2.includes('ml')) ||
      (row.org_bet_type === 'Spread' && betTypes2.includes('ps')) ||
      ((row.org_bet_type === 'Over' || row.org_bet_type === 'Under') && betTypes2.includes('ou'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isPartOfBetQ(row: RowObj) {
    if (questionParams !== undefined && questionParams !== null) {
      const bet_type_valid = (questionParams.bet_types.length === 0 || (
        (row.c3 === 'Moneyline' && questionParams.bet_types.includes('moneyline')) ||
        (row.c3 === 'Spread' && questionParams.bet_types.includes('spread')) ||
        (row.c3 === 'Over' && questionParams.bet_types.includes('over')) ||
        (row.c3 === 'Under' && questionParams.bet_types.includes('under')) ||
        ((row.c3 === 'Over' || row.c3 === 'Under') && questionParams.bet_types.includes('overunder'))
      ));
      const site_id_valid = (
        (questionParams.site_ids.length === 0 || questionParams.site_ids.map(val => val?.toLowerCase()).includes(row.c4.toLowerCase()))
      );
      const team_id_valid = (
        (questionParams.team_ids.length === 0 || questionParams.team_ids.map(val => val?.toLowerCase().split("-").slice(1).join(" ")).includes(row.c1.toLowerCase()))
      )
      const bet_line_valid = (
        (questionParams.bet_lines.length === 0 || questionParams.bet_lines.includes(row.c11.toString().replace('+','')))
      )
      const leagues_valid = (
        (questionParams.leagues.length === 0 || questionParams.leagues.map(val => val?.toLowerCase()).includes(row.c2.toLowerCase()))
      )
      return (bet_type_valid && site_id_valid && team_id_valid && bet_line_valid && leagues_valid);
    } else {
      return true;
    }
  }

  //function filterQ() {
  //  var filteredRows  = [...betRowsStore];
  //  filteredRows = filteredRows.filter(isPartOfBetQ);
  //  setQRows([...filteredRows]);
  //}

  //useEffect(() => {
  //  filterQ();
  //},[questionParams])

  function parseQ() {
    if (isQ) {
      var siteids = [];
      var teamids = [];
      var bettypes = [];
      var betlines = [];
      var lgs = [];
      var intent: string|null = "";
      if (searchParams.has("site_id")) {
        siteids.push(searchParams.get("site_id"));
      }
      if (searchParams.has("team_id")) {
        teamids.push(searchParams.get("team_id"));
      }
      if (searchParams.has("bet_type")) {
        bettypes.push(searchParams.get("bet_type"));
      }
      if (searchParams.has("bet_line")) {
        betlines.push(searchParams.get("bet_line"));
      }
      if (searchParams.has("league")) {
        lgs.push(searchParams.get("league"));
      }
      if (searchParams.has("intent")) {
        intent = searchParams.get("intent");
      }
      // start NEW
      if (teamids.length > 0 && lgs.length === 0) {
        for (let itx = 0; itx < teamids.length; itx++) {
          lgs.push(teamids[itx]!.split("-")[0]);
        }
      }
      // end
      const questionData: QuestionData = {
        site_ids: [...siteids],
        team_ids: [...teamids],
        bet_types: [...bettypes],
        bet_lines: [...betlines],
        leagues: [...lgs],
        intent: intent,
      }
      console.log("QUESTION PARAMS", questionData);
      setQuestionParams({...questionData});
    }
  }

  useEffect(() => {
    parseQ();
  },[isQ, searchParams])

  function filterBetTypes() {
    var filteredRows  = [...betRowsStore];
    filteredRows = filteredRows.filter(isSelectedBetType);
    setRows([...filteredRows]);
  }

  function filterBetTypes2() {
    var filteredRows  = [...oddsDataStore];
    filteredRows = filteredRows.filter(isSelectedBetType2);
    setOddsData([...filteredRows]);
  }

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  const keyDownHandler = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      console.log("OPEN SEARCH")
      if (!isQuery) {
        setIsQuery(true);
      }
    }
    if (event.key === "Escape") {
      console.log("CLOSE SEARCH")
      if (isQuery) {
        setIsQuery(false);
        setText({
          text: "",
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [showSR]);

  const keyDownHandlerSR = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      console.log("OPEN SEARCH")
      if (!showSR) {
        setShowSR(true);
      }
    }
    if (event.key === "Escape") {
      console.log("CLOSE SEARCH")
      if (showSR) {
        setShowSR(false);
        setText({
          text: "",
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [isQuery]);

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandlerSR);
    return () => {
      window.removeEventListener("keydown", keyDownHandlerSR);
    };
  }, [showSR]);

  useEffect(() => {
    filterBetTypes();
  },[betTypes])

  useEffect(() => {
    filterBetTypes2();
  },[betTypes2])

  //useEffect(() => {
  //  console.log("LOCATION PATHNAME", location);
  //},[location])

  //console.log("isHome", isHome);
  //console.log("isBets", isBets);

  const isMobile = (windowDimension.winWidth <= 1000);

  const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.platform);
  const isIOS = /(iPhone|iPod|iPad)/i.test(window.navigator.platform);

  return (
    <BodyWrapper isMobile={isMobile}>
      <HeaderDash
        //showModal={showModal}
        //setShowModal={setShowModal}
        showSubMenu={headerDisplay === 'show'}
        setShowSubMenu={setShowSubMenu}
        lgSubMenuW={lgSubMenuW}
        smSubMenuW={smSubMenuW}
        isMobile={isMobile}
        setIsQuery={setIsQuery}
        isMacLike={isMacLike}
        isIOS={isIOS}
      />
      <BodyContainer width={
        isMobile
        ? '98%' //'100%'
        : (
          headerDisplay === 'show'
          ? `calc(100% - ${lgSubMenuW})`
          : `calc(100% - ${smSubMenuW})`
        )
      }>
        {isQuery &&
          <Query
            isMobile={isMobile}
            isQuery={isQuery}
            setIsQuery={setIsQuery}
            isMacLike={isMacLike}
            isIOS={isIOS}
            showSR={showSR}
            setShowSR={setShowSR}
            setLoadingNLUPrediction={setLoadingNLUPrediction}
          />
        }
        {isQ &&
          <BetsQ
            isMobile={isMobile}
            showSubMenu={headerDisplay === 'show'}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            //betRows={betRows}
            betRows={[...betRows.filter(isPartOfBetQ)]}
            betRowsStore={betRowsStore}
            loadingBetRows={loadingBetRows}
            handleSort={handleSort}
            sort7D={sort7D}
            sort1M={sort1M}
            sort3M={sort3M}
            sort1Y={sort1Y}
            sortAllTime={sortAllTime}
            lastColSorted={lastColSorted}
            showCustomizeModal={showCustomizeModal}
            setShowCustomizeModal={setShowCustomizeModal}
            addBetType={addBetType}
            removeBetType={removeBetType}
            betTypes={betTypes}
            restrictRowsAfterN={restrictRowsAfterN}
            questionParams={questionParams}
            setSnackBarType={setSnackBarType}
            setSnackBarTitle={setSnackBarTitle}
            loadingNLUPrediction={loadingNLUPrediction}
            setIsQuery={setIsQuery}
            showAvg={showAvg}
            setShowAvg={setShowAvg}
            setBetTypes={setBetTypes}
          />
        }
        {isBetsTop &&
          <BetsTop
            isMobile={isMobile}
            showSubMenu={headerDisplay === 'show'}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            betRows={betRows}
            betRowsStore={betRowsStore}
            loadingBetRows={loadingBetRows}
            handleSort={handleSort}
            sort7D={sort7D}
            sort1M={sort1M}
            sort3M={sort3M}
            sort1Y={sort1Y}
            sortAllTime={sortAllTime}
            lastColSorted={lastColSorted}
            showCustomizeModal={showCustomizeModal}
            setShowCustomizeModal={setShowCustomizeModal}
            addBetType={addBetType}
            removeBetType={removeBetType}
            betTypes={betTypes}
            restrictRowsAfterN={restrictRowsAfterN}
            setSnackBarType={setSnackBarType}
            setSnackBarTitle={setSnackBarTitle}
            setIsQuery={setIsQuery}
            showAvg={showAvg}
            setShowAvg={setShowAvg}
          />
        }
        {isBetsTrending &&
          <BetsTrending
            isMobile={isMobile}
            showSubMenu={headerDisplay === 'show'}
            page2={page2}
            setPage2={setPage2}
            rowsPerPage2={rowsPerPage2}
            setRowsPerPage2={setRowsPerPage2}
            oddsData={oddsData}
            oddsDataStore={oddsDataStore}
            betRowsStore={betRowsStore}
            loadingOddsData={loadingOddsData}
            handleSort2={handleSort2}
            sort7D2={sort7D2}
            sort1M2={sort1M2}
            sort3M2={sort3M2}
            sort1Y2={sort1Y2}
            sortVig={sortVig}
            sortAllTime2={sortAllTime2}
            showCustomizeModal={showCustomizeModal2}
            setShowCustomizeModal={setShowCustomizeModal2}
            addBetType={addBetType2}
            removeBetType={removeBetType2}
            betTypes={betTypes2}
            restrictRowsAfterN={restrictRowsAfterN}
            setSnackBarType={setSnackBarType}
            setSnackBarTitle={setSnackBarTitle}
            setIsQuery={setIsQuery}
          />
        }
        {isHome &&
          <Home
            showSubMenu={headerDisplay === 'show'}
            isMobile={isMobile}
            isMacLike={isMacLike}
            isIOS={isIOS}
            setShowSR={setShowSR}
            showSR={showSR}
            setLoadingNLUPrediction={setLoadingNLUPrediction}
          />
        }
        {isAccount &&
          <Account/>
        }
        {isHedger &&
          <Hedger
            showSubMenu={headerDisplay === 'show'}
            lgSubMenuW={lgSubMenuW}
            smSubMenuW={smSubMenuW}
            isMobile={isMobile}
          />
        }
        {isApiSpec &&
          <SwaggerUIContainer>
            <SwaggerUI
              url={config.swagger}
              docExpansion={'list'}
            />
          </SwaggerUIContainer>
        }
        {isApiDocs &&
          <Docs
            isMobile={isMobile}
          />
        }
        {isHelp &&
          <Help
            isMobile={isMobile}
          />
        }
        {snackBarTitle !== undefined &&
          <SnackBar
            title={snackBarTitle}
            type={snackBarType}
          />
        }
        {hasAcceptedCookies !== 'yes' &&
          <CookieAlert
            showSubMenu={headerDisplay === 'show'}
            lgSubMenuW={lgSubMenuW}
            smSubMenuW={smSubMenuW}
            isMobile={isMobile}
            setHasAcceptedCookies={setHasAcceptedCookies}
          />
        }
      </BodyContainer>
    </BodyWrapper>
  );
};

export default Dash;