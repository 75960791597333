import React, { Fragment } from "react"
//import { X } from "react-feather"
//import { ModalProps } from "../../lib/interface"
import {
  ModalBlock,
  ModalOverlay,
  //ModalContainer,
  //ModalHeader,
  //ModalTitle,
  //NoticeContent,
  //NoticeContentStrong,
  //NoticeWrap,
} from "../../styles";
//import { Config as config } from '../../lib/config';
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import styled, { keyframes } from "styled-components";
import LogoWhite from "../../assets/C_Logo_White.webp";
import LogoBlack from "../../assets/C_Logo_Black.webp";
//import Spinner from "../Spinner";

interface LoadingProps {
  active: boolean;
}

const scale = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  animation: ${scale} 2s linear infinite;
  z-index: 1;
  width: 220px;
  height: 220px;
  overflow-y: auto;
  @media screen and (max-width: 280px) {
    width: 96%;
  }
`;

const LoadingData = ({ active, }: LoadingProps) => {
  const {theme} = useGetTheme();
  return (
  <Fragment>
    {active && (
    <ModalBlock>
      <ModalOverlay/>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "fit-content", zIndex: 1}}>
        <LoadingContainer>
            <img
              src={theme === 'dark'
                ? LogoWhite
                : LogoBlack
              }
              width={"100%"}
              height={"100%"}
              alt={'loadinglogo'}
            />
        </LoadingContainer>
        {/*<Spinner text={"Loading"} color={getThemeData(theme).textColor}/>*/}
        <div style={{height: "42px"}}/>
        <span style={{color: getThemeData(theme).textColor, fontSize: "1.2rem", fontWeight: 600}}>
          {"Loading..."}
        </span>
      </div>
    </ModalBlock>
    )}
  </Fragment>
  );
};

export default LoadingData;