import { getThemeData } from "../../theme";
import { useNavigate } from "react-router-dom";
import { logoUrl } from "./constants";
import { useEffect, useState } from "react";
import { useGetTheme } from "../../lib/contexts";

interface ExploreProps {
  site_ids: string[];
  inc_trending: boolean;
  inc_top: boolean;
};

const Explore = ({
  site_ids,
  inc_trending,
  inc_top,
}: ExploreProps) => {
  const {theme} = useGetTheme();
  const navigate = useNavigate();

  var exploreItems = [
    {
      val: `${site_ids[0]} Moneyline`,
      val_type: "search",
      val_link: `/q?site_id=${site_ids[0]}&bet_type=moneyline&intent=GetSiteMl`,
    },
    {
      val: `${site_ids[1]} Spread`,
      val_type: "search",
      val_link: `/q?site_id=${site_ids[1]}&bet_type=spread&intent=GetSitePs`,
    },
    {
      val: `${site_ids[2]} Over Under`,
      val_type: "search",
      val_link: `/q?site_id=${site_ids[2]}&bet_type=overunder&intent=GetSiteOu`,
    },
  ];

  if (inc_trending) {
    exploreItems = [...exploreItems, {
      val: "Trending Bets",
      val_type: "trending",
      val_link: "/bets/trending",
    }]
  }

  if (inc_top) {
    exploreItems = [...exploreItems, {
      val: "Top Bets by Unit Profit",
      val_type: "top",
      val_link: "/bets/top",
    }]
  }

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <div style={{
      display: "flex",
      flexDirection: windowDimension.winWidth < 929 ? "column" : "row",
      width: "calc(100% - 96px)",
      padding: "24px 48px",
      justifyContent: windowDimension.winWidth < 929 ? "center" : "space-between",
      alignItems: "center",
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        padding: "22px 22px",
        width: windowDimension.winWidth < 929 ? "fit-content" : "calc(50% - 44px)",
        height: windowDimension.winWidth < 929 ? "fit-content" : "188px",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
          alignItems: "center",
          gap: "12px",
        }}>
          <img src={
            theme === 'dark'
            ? logoUrl
            : logoUrl
            }
            width={"26px"}
            height={"26px"}
            alt={'logo'}
          />
          <span style={{
            fontSize: "1.8rem",
            fontWeight: 600,
            color: getThemeData(theme).textColor,
          }}>
            {windowDimension.winWidth < 929 ? "Explore" : "Explore Betting Data"}
          </span>
        </div>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        padding: "22px 22px",
        width: windowDimension.winWidth < 929 ? "fit-content" : "calc(50% - 44px)",
        height: windowDimension.winWidth < 929 ? "fit-content" : "188px",
        gap: "22px",
      }}>
        {exploreItems.map((item, _ind) => {
          return (
            <div style={{
              width: "100%",
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              cursor: "pointer",
            }} onClick={() => {
              navigate(item.val_link);
            }}>
              {item.val_type === 'search'
                && <span style={{
                  fontSize: windowDimension.winWidth < 475 ? "1rem" : "1.4rem",
                }}>
                  {"🔍"}
                </span>
              }
              {item.val_type === 'trending'
                && <span style={{
                  fontSize: windowDimension.winWidth < 475 ? "1rem" : "1.4rem",
                }}>
                  {"🔥"}
                </span>
              }
              {item.val_type === 'top'
                && <span style={{
                  fontSize: windowDimension.winWidth < 475 ? "1rem" : "1.4rem",
                }}>
                  {"💰"}
                </span>
              }
              {item.val_type == 'search'
              ? <div style={{width: "12px"}}/>
              : <div style={{width: "8px"}}/>
              }
              <span style={{
                color: getThemeData(theme).colorAccent,
                fontWeight: 600,
                fontSize: windowDimension.winWidth < 475 ? "1rem" : "1.4rem",
                letterSpacing: "0.025rem",
              }}>
                {item.val}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Explore;