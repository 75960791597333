export const searches = [
  {
    result: "Trending Bets",
    subtitle: "",
    path: `/bets/trending`,
    external: false,
  },
  {
    result: "Best Bets of All Time",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "Best Bets in the last 7 days",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "Best Bets in the last 1 month",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "Best Bets in the last 3 months",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "Best Bets in the last 1 year",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "API Overview",
    subtitle: "API reference",
    path: `/api-docs#about`,
    external: false,
  },
  {
    result: "Crunchtime Plans",
    subtitle: "API paid plans",
    path: `/company#plans`,
    external: false,
  },
  {
    result: "Get API Key",
    subtitle: "Get started",
    path: `/api-docs#getapikey`,
    external: false,
  },
  {
    result: "API Health",
    subtitle: "Teams available through the API",
    path: `/api-docs/endpoints/helper#health`,
    external: false,
  },
  {
    result: "API Teams",
    subtitle: "Teams available through the API",
    path: `/api-docs/endpoints/helper#teams`,
    external: false,
  },
  {
    result: "API Sites",
    subtitle: "bookies available through the API",
    path: `/api-docs/endpoints/helper#sites`,
    external: false,
  },
  {
    result: "API Odds",
    subtitle: "Odds data available through the API",
    path: `/api-docs/endpoints/odds#odds_index`,
    external: false,
  },
  {
    result: "API Moneylines",
    subtitle: "Moneyline data available through the API",
    path: `/api-docs/endpoints/odds#ml`,
    external: false,
  },
  {
    result: "API Over Unders",
    subtitle: "Over Under data available through the API",
    path: `/api-docs/endpoints/odds#ou`,
    external: false,
  },
  {
    result: "API Point Spreads",
    subtitle: "Point Spread data available through the API",
    path: `/api-docs/endpoints/odds#ps`,
    external: false,
  },
  {
    result: "API Statistics",
    subtitle: "Stats available through the API",
    path: `/api-docs/endpoints/odds#stats`,
    external: false,
  },
  {
    result: "API Games",
    subtitle: "Games available through the API",
    path: `/api-docs/endpoints/helper#game_ids`,
    external: false,
  },
  {
    result: "API Authorization",
    subtitle: "API authorization",
    path: `/api-docs/endpoints#authorization`,
    external: false,
  },
  {
    result: "API Pagination",
    subtitle: "API pagination",
    path: `/api-docs/endpoints#pagination`,
    external: false,
  },
  {
    result: "API Rate Limits",
    subtitle: "API rate limits",
    path: `/api-docs/endpoints#rate_limits`,
    external: false,
  },
  {
    result: "API Request Limits",
    subtitle: "API request limits",
    path: `/api-docs/endpoints#request_limits`,
    external: false,
  },
  {
    result: "API Endpoints",
    subtitle: "API endpoints",
    path: `/api-docs/endpoints#available_endpoints`,
    external: false,
  },
]