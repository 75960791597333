import { OD } from "../../lib/interface";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import {
  //Chart as ChartJS,
  //CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getThemeData } from "../../theme";
import { convertOdds } from "../../odds";
import { useGetOdds, useGetTheme } from "../../lib/contexts";
import { sortArrays } from "./helpers";

Chart.register(
  //CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface OddsChartProps {
  oddsData: OD[],
  isMobile: boolean;
  chartWidth: string;
  chartHeight: string;
  canViewAdv: boolean;
}

/*
function hexToRgb(hex: string) {
  var bigint = parseInt(hex.replace("#",""), 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return "rgb(" + r + "," + g + "," + b + ")";
}
*/

/*
function random_rgba(x: number) {
  const colors = [
    'rgb(0,255,0)',
    'rgb(0,0,255)',
    'rgb(255,255,0)',
    'rgb(0,255,255)',
    'rgb(255,0,255)',
  ]
  if (x >= colors.length) {
    return colors[(x % colors.length)];
  } else {
    return colors[x];
  }
  //var o = Math.round, r = Math.random, s = 255;
  //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
}
*/

/*
const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart: any, args: any, options: any) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};
*/

const OddsChart = ({
  oddsData,
  isMobile,
  chartWidth,
  chartHeight,
  canViewAdv,
}: OddsChartProps) => {
  const {theme} = useGetTheme();
  const {odds} = useGetOdds();
  const labs_unsorted = [...oddsData].map(item => item.update_times).flat(1);
  const shifts_unsorted = [...oddsData].map(item => item.data).flat(1);
  const arrs = sortArrays([labs_unsorted, shifts_unsorted]);
  const labs = Array.from([...arrs[0]]);
  const shifts = Array.from([...arrs[1]]);
  //const labs = Array.from(new Set(allLabs)).flat(1);
  //console.log(labs, labs.length, oddsData.length)
  var data = {
      //labels: [...labs.map(l => new Date(l))],
      //labels: labs,
      labels: [...labs.map(l => new Date(l).toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
        //second:'2-digit'
      }))],
      datasets: [...oddsData].map((item, ind) => {
          //const randColor = hexToRgb(getThemeData(theme).themeBlue);
          //const randColor = random_rgba(ind)
          //const updateTimes = item.update_times;
          //const randColor = getThemeData(theme).colorAccent;
          //console.log(randColor);
          return {
            label: `${item.site_id}`,
            borderColor: getThemeData(theme).colorAccent,
            backgroundColor: getThemeData(theme).bgBox2, //randColor,
            data: shifts.map((xVal, xInd) => {
              return {
                x: new Date(labs[xInd]), //updateTimes[xInd],
                y: convertOdds(xVal, odds)
              }
            })
            //data: item.data.map(d => convertOdds(d,odds)),
          }
      }).flat(1),
  };
  var options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: false, //true,
          color: getThemeData(theme).textColor4,
          type: 'time',
          maxRotation: 90,
          minRotation: 90
        },
        title: {
          display: false, //true,
          text: 'Time',
        },
        grid: {
          color: "transparent", //getThemeData(theme).borderColor,
        }
      },
      y: {
        ticks: {
          display: true,
          color: getThemeData(theme).textColor4,
        },
        grid: {
          color: getThemeData(theme).borderColor,
        }
      }
    },
    plugins: {
      /*
      customCanvasBackgroundColor: {
        color: getThemeData(theme).bgBox2,
      },*/
      legend: {
        display: false,
        position: 'top' as const,
        labels: {
          color: getThemeData(theme).textColor4,
          font: {
            size: 12,
            weight: 400,
          }
        }
      },
      title: {
        display: true,
        //text: `${oddsData[0].bet_type} Odds Over Time`,
        //text: `${oddsData[0].site_id}: ${oddsData[0].bet_type} Odds Over Time`,
        text: `${oddsData[0].org_bet_type} Odds Over Time`,
        color: getThemeData(theme).textColor,
        font: {
          size: 12,
          weight: 500,
        }
      },
    },
  };

  return (
      <div style={{
        width: chartWidth,
        height: chartHeight,
      }}>
        <Line
          data={data}
          options={options}
          //plugins={[plugin]}
        />
      </div>
  )
};

export default OddsChart;