import styled, { keyframes } from "styled-components";

export const StatsComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  gap: 42px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
`;

export const StatsComponentStyle = styled.div`
  //height: 200px;
  width: 200px;
  //width: fit-content;
  //background: red;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s ease-in-out;
`;

const GradientAnimation = keyframes`
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(405deg);
  }
`;

export const StatsIconContainer = styled.div`
  height: 80px;
  width: 80px;
  transform: rotate(45deg);
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .gradientCirle {
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    align-items: center;
    height: 100%;
    width: 100%;
    background: ${({color}) => color};
    animation: ${GradientAnimation} 3s ease infinite;
  }
  .statsIcon {
    height: 30px;
    width: 30px;
  }
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    .statsIcon {
      height: 20px;
      width: 20px;
    }
  }
`;

export const StatsCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StatsMainWrapper = styled.div`
  width: 100%;
  padding: 50px 0px;
  background: ${({color}) => color};
`;

export const StatsDivider = styled.div`
  height: 80px;
  width: 1px;
  background-color: ${({color}) => color};
  @media (max-width: 1000px) {
    display: none;
  }
`;