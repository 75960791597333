import { useState } from "react";
import { User } from "../Account";

export const getUserScope = () => {
  const [id, setId] = useState<string | null | undefined>(sessionStorage.getItem("id"));
  const [auth, setAuth] = useState<string | undefined | null>(sessionStorage.getItem("auth"));
  const [user, setUser] = useState<User | undefined>(undefined);
  if (auth !== undefined && auth !== null && JSON.parse(auth) === true) {
    if (user !== undefined) {
      if (parseInt(user.user_tier) === 1) {
        //"Starter"
        return 1;
      } else if (parseInt(user.user_tier) === 2) {
        //"Professional"
        return 2;
      } else if (parseInt(user.user_tier) === 3) {
        //"Premium"
        return 3;
      } else if (parseInt(user.user_tier) === 4) {
        //"Elite"
        return 4;
      }
    }
  } else {
    return 0;
  }
}