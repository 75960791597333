import { ChevronDown, ChevronUp, Filter } from "react-feather";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import styled from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const CustomizeButton = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  width: 112px;
  border-radius: 10px;
  background: ${({color}) => color};
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: brightness(150%);
  }
`;

const RowsPerPageButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({color}) => color};
  padding: 6px;
  cursor: pointer;
`;

interface TableFilterProps {
  showCustomizeModal: boolean,
  setShowCustomizeModal: any,
  betTypes: string[],
  addBetType: any,
  removeBetType: any,
  showAvg: boolean | null,
  setShowAvg: any | null,
  rowsPerPage: number,
  setRowsPerPage: any,
  displayBetTypeFilter: boolean,
};

const TableFilter = ({
  showCustomizeModal,
  setShowCustomizeModal,
  betTypes,
  addBetType,
  removeBetType,
  showAvg,
  setShowAvg,
  rowsPerPage,
  setRowsPerPage,
  displayBetTypeFilter,
}: TableFilterProps) => {
  const {theme} = useGetTheme();
  return (
    <div style={{display: "inline-flex", flexDirection: "column", position: "relative" }}>
      <CustomizeButton
        color={getThemeData(theme).bgButton1}
        onClick={() => {
          setShowCustomizeModal(!showCustomizeModal);
        }}
      >
        <Filter size={18} color={"white"}/>
        <span style={{
          color: "white",
          fontSize: "1rem",
          fontWeight: 600,
        }}>
          {"Filter"}
        </span>
        {!showCustomizeModal
          ? <ChevronDown size={18} color={"white"}/>
          : <ChevronUp size={18} color={"white"}/>
        }
      </CustomizeButton>
      {showCustomizeModal && <div style={{
        //display: "flex",
        //flexDirection: "column",
        display: "block",
        position: "absolute",
        top: "38px",
        right: 0,
        transition: "all 0.3s ease-in-out",
        padding: "12px",
        width: "160px",
        borderRadius: "10px",
        background: getThemeData(theme).bgBox1,
        border: `1px solid ${getThemeData(theme).borderColor}`,
        zIndex: 999,
      }}>
        {displayBetTypeFilter && ( <>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "left",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor,
              fontSize: "0.85rem",
              fontWeight: 600,
            }}>
              {"Bets"}
            </span>
          </div>
          <div style={{height: "12px"}}/>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor,
              fontSize: "1rem",
              fontWeight: 600,
            }}>
              {"Moneyline"}
            </span>
            <Toggle
              checked={betTypes.includes('ml')}
              onChange={(event) => {
                if (event.target.checked) {
                  addBetType('ml')
                } else {
                  removeBetType('ml')
                }
              }}
            />
          </div>
          <div style={{height: "12px"}}/>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor,
              fontSize: "1rem",
              fontWeight: 600,
            }}>
              {"Point Spread"}
            </span>
            <Toggle
              checked={betTypes.includes('ps')}
              onChange={(event) => {
                if (event.target.checked) {
                  addBetType('ps')
                } else {
                  removeBetType('ps')
                }
              }}
            />
          </div>
          <div style={{height: "12px"}}/>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor,
              fontSize: "1rem",
              fontWeight: 600,
            }}>
              {"Over Under"}
            </span>
            <Toggle
              checked={betTypes.includes('ou')}
              onChange={(event) => {
                if (event.target.checked) {
                  addBetType('ou')
                } else {
                  removeBetType('ou')
                }
              }}
            />
          </div>
        </> )}
        {displayBetTypeFilter && showAvg !== null && <div style={{height: "12px"}}/>}
        {showAvg !== null && setShowAvg !== null && ( <>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "left",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor,
              fontSize: "0.85rem",
              fontWeight: 600,
            }}>
              {"Unit Profit Data"}
            </span>
          </div>
          <div style={{height: "12px"}}/>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor,
              fontSize: "1rem",
              fontWeight: 600,
            }}>
              {"Average"}
            </span>
            <Toggle
              checked={showAvg}
              onChange={(event) => {
                if (event.target.checked) {
                  setShowAvg(true);
                } else {
                  setShowAvg(false);
                }
              }}
            />
          </div>
          <div style={{height: "4px"}}/>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "left",
            width: "100%",
          }}>
            <span style={{
              color: getThemeData(theme).textColor4,
              fontSize: "0.75rem",
              fontWeight: 600,
              fontStyle: "italic",
            }}>
              {"Per Game Average or Sum"}
            </span>
          </div>
        </> )}
        <div style={{height: "12px"}}/>
        <div style={{
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "left",
          width: "100%",
        }}>
          <span style={{
            color: getThemeData(theme).textColor,
            fontSize: "0.85rem",
            fontWeight: 600,
          }}>
            {"Rows"}
          </span>
        </div>
        <div style={{height: "12px"}}/>
        <div style={{
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "left",
          alignItems: "center",
          width: "100%",
        }}>
          <RowsPerPageButton
            color={rowsPerPage === 5 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setRowsPerPage(5)}}
          >
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
              {"5"}
            </span>
          </RowsPerPageButton>
          <RowsPerPageButton
            color={rowsPerPage === 20 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setRowsPerPage(20)}}
          >
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
              {"20"}
            </span>
          </RowsPerPageButton>
          <RowsPerPageButton
            color={rowsPerPage === 50 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setRowsPerPage(50)}}
          >
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
              {"50"}
            </span>
          </RowsPerPageButton>
          <RowsPerPageButton
            color={rowsPerPage === 100 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
            onClick={() => {setRowsPerPage(100)}}
          >
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
              {"100"}
            </span>
          </RowsPerPageButton>
        </div>
      </div>
      }
    </div>
  );
};

export default TableFilter;