import React, { Fragment } from "react"
import { X } from "react-feather"
import { ModalProps } from "../../lib/interface"
import {
  ModalBlock,
  ModalContainer,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  NoticeContent,
  NoticeContentStrong,
  NoticeWrap,
} from "../../styles";
import { Config as config } from '../../lib/config';
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { useNavigate } from "react-router-dom";

const Modal = ({ title, active, hideModal, body, action }: ModalProps) => {
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  return (
  <Fragment>
    {active && (
    <ModalBlock>
      <ModalOverlay onClick={() => hideModal()}/>
      <ModalContainer
        bgColor={getThemeData(theme).modalBgColor}
        brColor={getThemeData(theme).modalBrColor}
      >
        <div style={{display: "flex", width: "100%", flexDirection: "row", justifyContent: "right"}}>
          <X color='grey' onClick={() => hideModal()}  cursor={"pointer"}/>
        </div>
        <ModalHeader>
          <ModalTitle color={getThemeData(theme).textColor}>
            {title}
          </ModalTitle>
        </ModalHeader>
        {body}
        {action !== "" && <NoticeWrap>
          <NoticeContent color={getThemeData(theme).textColor4}>
            {`By ${action}, you agree to our `}
            <NoticeContentStrong color={getThemeData(theme).colorAccent} onClick={() => {navigate(config.terms)}}>
              {"Terms of Service"}
            </NoticeContentStrong>
            {" and acknowledge you have read and understand the protocol risks"}
          </NoticeContent>
      </NoticeWrap>}
      </ModalContainer>
    </ModalBlock>
    )}
  </Fragment>
  );
};

export default Modal;