import React, { useState, useContext, useMemo, createContext } from 'react';
import { login as userLogin, logout as userLogout, register as userRegister } from './api/user';

const AuthContext = createContext({
  user: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null);

  const login = async (body) => {
    const user = await userLogin(body);
    setUser(user);
    return user;
  };

  const register = async (body) => {
    const user = await userRegister(body);
    setUser(user);
    return user;
  }

  const logout = async () => {
    const user = await userLogout();
    setUser(null);
    return user;
  };

  const auth = useMemo(() => ({ user, login, logout, register }), [user]);

  return <AuthContext.Provider value={auth} {...props} />;
};

export const useAuth = () => useContext(AuthContext);