import styled from "styled-components";
import { motion } from "framer-motion";

interface HedgeResultsProps {
  color: string;
  background: string;
  hidden: string;
  scrollbarColor: string;
  width: string;
  height: string;
  brColor: string;
}

interface PendingBetsProps {
  scrollbarColor: string;
}

interface NumberWithCircleProps {
  bgcolor: string;
}

export const TooltipContainer = styled.div`
  .custom-tooltip {
    background: ${({color}) => color};
    border-radius: 10px;
    max-width: 120px;
    padding: 22px;
    z-index: 9999;
    cursor: pointer;
  }
`

export const HedgeContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 6px;
  padding: 0 0 0 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
`

export const Input = styled.input`
  text-align: left;
  padding: 6px 12px;
  max-width: 100%;
  width: ${({width}) => width};
  background-color: transparent !important;
  white-space: nowrap !important;
  outline: none !important;
  //border: 1px solid #303030;
  //border-radius: 10px;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: 1px solid #303030;
  transition: all 0.3s ease-out;
  color: ${({color}) => color};
  &:focus {
    border: 1px solid #303030;
    outline: none !important;
  }
  /* Chrome, Safari, Edge, Opera */
  //&::-webkit-outer-spin-button,
  //&::-webkit-inner-spin-button {
  //  -webkit-appearance: none;
  //  margin: 0;
  //}
  /* Firefox */
  //input[type=number] {
  //  -moz-appearance: textfield;
  //}
`;

export const PendingBetsContainer = styled.div<PendingBetsProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 288px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollbarColor};
  }
`

export const HedgeResultsContainer = styled(motion.div)<HedgeResultsProps>`
  color: ${(props) => props.color};
  display: ${(props) => props.hidden};
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-color: ${(props) => props.background};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border: 1px solid ${(props) => props.brColor};
  border-radius: 10px;
  overflow-y: scroll;
  //z-index: 2;
  //max-height: 380px;
  //position: fixed;
  //bottom: 0;
  //right: 0;
  //left: 0;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollbarColor};
  }
`;

export const Button = styled.div`
  padding: 6px 12px;
  width: fit-content;
  //border: 1px solid #303030;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  background: ${({color}) => color};
  &:hover {
    filter: brightness(120%);
  }
`

interface TextContentProps {
  color: string;
  isMobile: boolean;
}

export const TextContent = styled.span<TextContentProps>`
  color: ${({color}) => color};
  font-weight: 600;
  font-size: ${({isMobile}) => isMobile ? "14px" : "16px"};
`

export const TextContent2 = styled.span<TextContentProps>`
  color: ${({color}) => color};
  font-weight: 600;
  font-size: ${({isMobile}) => isMobile ? "14px" : "16px"};
`

export const TextContent3 = styled.span<TextContentProps>`
  color: ${({color}) => color};
  font-weight: 600;
  font-size: ${({isMobile}) => isMobile ? "12px" : "14px"};
`

export const NumberWithCircle = styled.div<NumberWithCircleProps>`
  border-radius: 50%;
  width: 24px;
  padding: 4px 0px;
  background: ${({bgcolor}) => bgcolor};
  text-align: center;
`

interface HeaderContainerProps {
  color: string;
};

interface MobileNavbarProps {
  color: string;
  background: string;
  hidden: string;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  position: sticky;
  border-radius: 10px;
  background: linear-gradient(to bottom, ${({color}) => color} 70%, transparent);
  z-index: 999;
  // border-bottom: 1px solid #303030;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  justify-content: center;
  align-items: center;
  padding: 22px 0px;
`

export const HeaderSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`

export const ItemContentMobile = styled.span`
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s ease-in-out all;
  color: ${(props) => props.color};
  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
`;

export const ItemContentMobile2 = styled.span`
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s ease-in-out all;
  color: ${(props) => props.color};
  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
`;

export const MobileNavBar = styled(motion.div)<MobileNavbarProps>`
  z-index: 2;
  color: ${(props) => props.color};
  display: ${(props) => props.hidden};
  flex-direction: column;
  justify-content: top;
  background-color: ${(props) => props.background};
  height: fit-content;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ItemButtonsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: flex-start;
  padding: 22px 60px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({color}) => color};
  padding: 6px;
  cursor: pointer;
`

export const OddsButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({color}) => color};
  padding: 6px;
  cursor: pointer;
`