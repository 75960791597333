import { StyledSpinner } from "../../styles";
import { SpinnerProps } from "../../lib/interface";

const Spinner= ({ text, color }: SpinnerProps) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </StyledSpinner>
    <p
      style={{
        color: color,
        fontSize: "1.1rem",
        fontWeight: 600,
      }}
    >
      {text}...
    </p>
  </div>
);

export default Spinner;