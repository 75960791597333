import { ChevronLeft, ChevronRight } from "react-feather";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { MainTableFooter, RowsPerPageButton } from "./tableStyle";

interface FooterProps {
  isMobile: boolean;
  pageN: number;
  rowsPerPageN: number;
  dataN: number;
  setPageN: any;
  setRowsPerPageN: any;
  showIncNotice: boolean;
};

const TableFooter = ({
  isMobile,
  pageN,
  rowsPerPageN,
  dataN,
  setPageN,
  setRowsPerPageN,
  showIncNotice,
}: FooterProps) => {
  const {theme} = useGetTheme();
  return (
    <MainTableFooter>
      {!isMobile &&
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          minWidth: "800px",
        }}>
          <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", alignItems: "center",}}>
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px",}}>
              {`Showing ${pageN === 1 ? 1 : ((pageN-1)*rowsPerPageN)} to ${pageN*rowsPerPageN} of ${dataN.toLocaleString()} results`}
            </span>
          </div>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px"
          }}>
            <ChevronLeft
              size={16}
              cursor={pageN > 1 ? "pointer" : "default"}
              color={pageN === 1 ? "grey" : getThemeData(theme).gradientStart}
              onClick={() => {
                if (pageN > 1) {
                  setPageN(pageN - 1);
                }
            }}/>
            <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 12px",
              background: (pageN === 1 ? getThemeData(theme).bgBox1 : "transparent"),
              borderRadius: "10px",
              cursor: "pointer",
            }} onClick={() => {setPageN(1)}}>
              <span style={{
                fontWeight: 600,
                color: (pageN === 1
                  ? getThemeData(theme).gradientStart
                  : getThemeData(theme).textColor),
                fontSize: "14px",
              }}>
                {"1"}
              </span>
            </div>
            {(Math.ceil(dataN / rowsPerPageN)) > 3 &&
            pageN > 2 ? <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <span style={{
                fontWeight: 600,
                color: getThemeData(theme).textColor,
                fontSize: "14px",
              }}>
                {"..."}
              </span>
            </div>
            : <> {(Math.ceil(dataN / rowsPerPageN)) > 1 && <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 12px",
              background: (pageN === 2 ? getThemeData(theme).bgBox1 : "transparent"),
              borderRadius: "10px",
              cursor: "pointer",
            }} onClick={() => {setPageN(2)}}>
              <span style={{
                fontWeight: 600,
                color: (pageN === 2
                  ? getThemeData(theme).gradientStart
                  : getThemeData(theme).textColor),
                fontSize: "14px",
              }}>
                {"2"}
              </span>
            </div>
            } </> }
            {pageN > 2 && pageN < ((Math.ceil(dataN / rowsPerPageN)) - 1) && <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 12px",
              background: getThemeData(theme).bgBox1,
              borderRadius: "10px",
              cursor: "pointer",
            }} onClick={() => {setPageN(pageN)}}>
              <span style={{
                fontWeight: 600,
                color: getThemeData(theme).gradientStart,
                fontSize: "14px",
              }}>
                {pageN}
              </span>
            </div>
            }
            {(Math.ceil(dataN / rowsPerPageN)) > 3 &&
            pageN < ((Math.ceil(dataN / rowsPerPageN)) - 1) ? <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <span style={{
                fontWeight: 600,
                color: getThemeData(theme).textColor,
                fontSize: "14px",
              }}>
                {"..."}
              </span>
            </div>
            : <> { (Math.ceil(dataN / rowsPerPageN)) > 3 && <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 12px",
              background: (pageN === ((Math.ceil(dataN / rowsPerPageN)) - 1) ? getThemeData(theme).bgBox1 : "transparent"),
              borderRadius: "10px",
              cursor: "pointer",
            }} onClick={() => {setPageN((Math.ceil(dataN / rowsPerPageN)) - 1)}}>
              <span style={{
                fontWeight: 600,
                color: (pageN === ((Math.ceil(dataN / rowsPerPageN)) - 1)
                  ? getThemeData(theme).gradientStart
                  : getThemeData(theme).textColor),
                fontSize: "14px",
              }}>
                {((Math.ceil(dataN / rowsPerPageN)) - 1).toString()}
              </span>
            </div>
            } </> }
            {(Math.ceil(dataN / rowsPerPageN)) > 2 && <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 12px",
              background: (pageN === (Math.ceil(dataN / rowsPerPageN)) ? getThemeData(theme).bgBox1 : "transparent"),
              borderRadius: "10px",
              cursor: "pointer",
            }} onClick={() => {setPageN(Math.ceil(dataN / rowsPerPageN))}}>
              <span style={{
                fontWeight: 600,
                color: (pageN === (Math.ceil(dataN / rowsPerPageN))
                  ? getThemeData(theme).gradientStart
                  : getThemeData(theme).textColor),
                fontSize: "14px",
              }}>
                {(Math.ceil(dataN / rowsPerPageN)).toString()}
              </span>
            </div>}
            <ChevronRight
              size={16}
              cursor={pageN < (Math.ceil(dataN / rowsPerPageN)) ? "pointer" : "default"}
              color={pageN < (Math.ceil(dataN / rowsPerPageN)) ? getThemeData(theme).gradientStart : "grey"}
              onClick={() => {
                if (pageN < (Math.ceil(dataN / rowsPerPageN))) {
                  setPageN(pageN + 1);
                }
            }}/>
          </div>
          <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "right", alignItems: "center",gap: "6px"}}>
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
              Rows
            </span>
            <RowsPerPageButton
              color={rowsPerPageN === 5 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
              onClick={() => {setRowsPerPageN(5)}}
            >
              <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px",minWidth: "18px", textAlign: "center"}}>
                {"5"}
              </span>
            </RowsPerPageButton>
            <RowsPerPageButton
              color={rowsPerPageN === 20 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
              onClick={() => {setRowsPerPageN(20)}}
            >
              <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                {"20"}
              </span>
            </RowsPerPageButton>
            <RowsPerPageButton
              color={rowsPerPageN === 50 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
              onClick={() => {setRowsPerPageN(50)}}
            >
              <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                {"50"}
              </span>
            </RowsPerPageButton>
            <RowsPerPageButton
              color={rowsPerPageN === 100 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
              onClick={() => {setRowsPerPageN(100)}}
            >
              <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                {"100"}
              </span>
            </RowsPerPageButton>
          </div>
        </div>
      }
      {isMobile && <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>
        <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center",gap: "12px"}}>
          <ChevronLeft
            size={16}
            cursor={pageN > 1 ? "pointer" : "default"}
            color={pageN === 1 ? "grey" : getThemeData(theme).gradientStart}
            onClick={() => {
              if (pageN > 1) {
                setPageN(pageN - 1);
              }
          }}/>
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 12px",
            background: (pageN === 1 ? getThemeData(theme).bgBox1 : "transparent"),
            borderRadius: "10px",
            cursor: "pointer",
          }} onClick={() => {setPageN(1)}}>
            <span style={{
              fontWeight: 600,
              color: (pageN === 1
                ? getThemeData(theme).gradientStart
                : getThemeData(theme).textColor),
              fontSize: "14px",
            }}>
              {"1"}
            </span>
          </div>
          {(Math.ceil(dataN / rowsPerPageN)) > 3 &&
          pageN > 2 ? <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <span style={{
              fontWeight: 600,
              color: getThemeData(theme).textColor,
              fontSize: "14px",
            }}>
              {"..."}
            </span>
          </div>
          : <> {(Math.ceil(dataN / rowsPerPageN)) > 1 && <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 12px",
            background: (pageN === 2 ? getThemeData(theme).bgBox1 : "transparent"),
            borderRadius: "10px",
            cursor: "pointer",
          }} onClick={() => {setPageN(2)}}>
            <span style={{
              fontWeight: 600,
              color: (pageN === 2
                ? getThemeData(theme).gradientStart
                : getThemeData(theme).textColor),
              fontSize: "14px",
            }}>
              {"2"}
            </span>
          </div>
          } </> }
          {pageN > 2 && pageN < ((Math.ceil(dataN / rowsPerPageN)) - 1) && <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 12px",
            background: getThemeData(theme).bgBox1,
            borderRadius: "10px",
            cursor: "pointer",
          }} onClick={() => {setPageN(pageN)}}>
            <span style={{
              fontWeight: 600,
              color: getThemeData(theme).gradientStart,
              fontSize: "14px",
            }}>
              {pageN}
            </span>
          </div>
          }
          {(Math.ceil(dataN / rowsPerPageN)) > 3 &&
          pageN < ((Math.ceil(dataN / rowsPerPageN)) - 1) ? <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <span style={{
              fontWeight: 600,
              color: getThemeData(theme).textColor,
              fontSize: "14px",
            }}>
              {"..."}
            </span>
          </div>
          : <> { (Math.ceil(dataN / rowsPerPageN)) > 3 && <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 12px",
            background: (pageN === ((Math.ceil(dataN / rowsPerPageN)) - 1) ? getThemeData(theme).bgBox1 : "transparent"),
            borderRadius: "10px",
            cursor: "pointer",
          }} onClick={() => {setPageN((Math.ceil(dataN / rowsPerPageN)) - 1)}}>
            <span style={{
              fontWeight: 600,
              color: (pageN === ((Math.ceil(dataN / rowsPerPageN)) - 1)
                ? getThemeData(theme).gradientStart
                : getThemeData(theme).textColor),
              fontSize: "14px",
            }}>
              {((Math.ceil(dataN / rowsPerPageN)) - 1).toString()}
            </span>
          </div>
          } </> }
          {(Math.ceil(dataN / rowsPerPageN)) > 2 && <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 12px",
            background: (pageN === (Math.ceil(dataN / rowsPerPageN)) ? getThemeData(theme).bgBox1 : "transparent"),
            borderRadius: "10px",
            cursor: "pointer",
          }} onClick={() => {setPageN(Math.ceil(dataN / rowsPerPageN))}}>
            <span style={{
              fontWeight: 600,
              color: (pageN === (Math.ceil(dataN / rowsPerPageN))
                ? getThemeData(theme).gradientStart
                : getThemeData(theme).textColor),
              fontSize: "14px",
            }}>
              {(Math.ceil(dataN / rowsPerPageN)).toString()}
            </span>
          </div>}
          <ChevronRight
            size={16}
            cursor={pageN < (Math.ceil(dataN / rowsPerPageN)) ? "pointer" : "default"}
            color={pageN < (Math.ceil(dataN / rowsPerPageN)) ? getThemeData(theme).gradientStart : "grey"}
            onClick={() => {
              if (pageN < (Math.ceil(dataN / rowsPerPageN))) {
                setPageN(pageN + 1);
              }
          }}/>
        </div>
      </div>
      }
      {showIncNotice && <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: isMobile ? "center" : "left",
        alignItems: "center",
        width: "100%",
        padding: "4px 0px 0px 0px",
      }}>
        <span style={{
          fontSize: "0.75rem",
          color: getThemeData(theme).textColor4,
          fontStyle: "italic",
          fontWeight: 400,
          letterSpacing: "0.01rem",
        }}>
          {'Odds data dates back to 8/6/2022 and may be incomplete.'}
        </span>
      </div>}
    </MainTableFooter>
  )
}

export default TableFooter;