import { useGetTheme } from "../../lib/contexts";

export const logoUrl = 'https://crunchtimeodds.s3.amazonaws.com/C_Logo_Blue.png';

export const twitterHandle = "@CrunchtimeOdds";

const leagueEmojiMap = {
  "nba": "🏀",
  "nhl": "🏒",
  "nfl": "🏈",
  "epl": "⚽",
  "laliga": "⚽",
  "mls": "⚽",
  "cl": "⚽",
  "sa": "⚽",
  "eurl": "⚽",
  "bun": "⚽",
  "lig": "⚽",
  "mlb": "⚾",
  "eurol": "🏀",
  "wnba": "🏀",
};

export const getLeagueEmoji = (league: string) => {
  return Object.values(leagueEmojiMap)[Object.keys(leagueEmojiMap).indexOf(league.toLowerCase())]
}
export const getSiteURLs = (site_id: string) => {
  /*
  if (site_id === "Fanduel") {
    return "https://www.fanduel.com";
  } else if (site_id === "DraftKings") {
    return "https://www.draftkings.com";
  }
  */
  return "";
}

const percentColorsDark = [
  { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
  { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } },
];

const percentColorsLight = [
  { pct: 0.0, color: { r: 0x00, g: 0xaf, b: 0 } },
  { pct: 0.5, color: { r: 0xaf, g: 0xaf, b: 0 } },
  { pct: 1.0, color: { r: 0xaf, g: 0x00, b: 0 } },
];

const getPercentColorsByTheme = () => {
  const {theme} = useGetTheme();
  if (theme === 'dark') {
    return percentColorsDark;
  } else {
    return percentColorsLight;
  }
}

export const getColorForPercentage = (pct: number) => {
  let percentColors = getPercentColorsByTheme();
  for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
          break;
      }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
};

export const SUPPORTED_FILES = [
  "nfl/Arizona.png",
  "nfl/Baltimore.png",
  "nfl/Houston.png",
  "nfl/New-England.png",
  "nfl/Seattle.png",
  "nfl/LA-Rams.png",
  "nfl/Washington.png",
  "nfl/Detroit.png",
  "nfl/NY-Giants.png",
  "nfl/Chicago.png",
  "nfl/_NFL.png",
  "nfl/Las-Vegas.png",
  "nfl/New-Orleans.png",
  "nfl/San-Francisco.png",
  "nfl/Atlanta.png",
  "nfl/Cleveland.png",
  "nfl/Carolina.png",
  "nfl/Green-Bay.png",
  "nfl/Pittsburgh.png",
  "nfl/Kansas-City.png",
  "nfl/Indianapolis.png",
  "nfl/LA-Chargers.png",
  "nfl/Buffalo.png",
  "nfl/Jacksonville.png",
  "nfl/Philadelphia.png",
  "nfl/Minnesota.png",
  "nfl/Tampa-Bay.png",
  "nfl/NY-Jets.png",
  "nfl/Denver.png",
  "nfl/Miami.png",
  "nfl/Tennessee.png",
  "nfl/Dallas.png",
  "nfl/Cincinnati.png",
  "nba/Houston.png",
  "nba/Brooklyn.png",
  "nba/Milwaukee.png",
  "nba/Portland.png",
  "nba/Washington.png",
  "nba/_NBA.png",
  "nba/Detroit.png",
  "nba/Chicago.png",
  "nba/Oklahoma.png",
  "nba/Golden-State.png",
  "nba/LA-Clippers.png",
  "nba/Memphis.png",
  "nba/Utah.png",
  "nba/Orlando-Magic.png",
  "nba/New-Orleans.png",
  "nba/Atlanta.png",
  "nba/Boston.png",
  "nba/Indiana.png",
  "nba/Cleveland.png",
  "nba/San-Antonio.png",
  "nba/New-York.png",
  "nba/Toronto.png",
  "nba/Philadelphia.png",
  "nba/Minnesota.png",
  "nba/Sacramento.png",
  "nba/Charlotte.png",
  "nba/Denver.png",
  "nba/Miami.png",
  "nba/LA-Lakers.png",
  "nba/Dallas.png",
  "nba/Phoenix.png",
  "mlb/Los-Angeles-Dodgers.png",
  "mlb/St-Louis-Cardinals.png",
  "mlb/Cincinnati-Reds.png",
  "mlb/Tampa-Bay-Rays.png",
  "mlb/Washington-Nationals.png",
  "mlb/Toronto-Blue-Jays.png",
  "mlb/Texas-Rangers.png",
  "mlb/Philadelphia-Phillies.png",
  "mlb/San-Francisco-Giants.png",
  "mlb/Minnesota-Twins.png",
  "mlb/Arizona-Diamondbacks.png",
  "mlb/Detroit-Tigers.png",
  "mlb/Boston-Red-Sox.png",
  "mlb/Colorado-Rockies.png",
  "mlb/Kansas-City-Royals.png",
  "mlb/Cleveland-Guardians.png",
  "mlb/Houston-Astros.png",
  "mlb/Atlanta-Braves.png",
  "mlb/Miami-Marlins.png",
  "mlb/Baltimore-Orioles.png",
  "mlb/Los-Angeles-Angels.png",
  "mlb/Chicago-White-Sox.png",
  "mlb/Pittsburgh-Pirates.png",
  "mlb/_MLB.png",
  "mlb/Oakland-Athletics.png",
  "mlb/Chicago-Cubs.png",
  "mlb/San-Diego-Padres.png",
  "mlb/New-York-Yankees.png",
  "mlb/New-York-Mets.png",
  "mlb/Milwaukee-Brewers.png",
  "mlb/Seattle-Mariners.png",
  "epl/_EPL.png",
  "epl/Everton.png",
  "epl/Arsenal.png",
  "epl/West-Ham.png",
  "epl/Brighton.png",
  "epl/Tottenham.png",
  "epl/Leicester.png",
  "epl/Southampton.png",
  "epl/Fulham.png",
  "epl/Liverpool.png",
  "epl/Manchester-Utd.png",
  "epl/Chelsea.png",
  "epl/Aston-Villa.png",
  "epl/Bournemouth.png",
  "epl/Manchester-City.png",
  "epl/Wolves.png",
  "epl/Newcastle.png",
  "epl/Crystal-Palace.png",
  "epl/Burnley.png",
  "mls/New-England.png",
  "mls/Toronto-FC.png",
  "mls/NY-Red-Bulls.png",
  "mls/Seattle.png",
  "mls/Columbus-Crew.png",
  "mls/FC-Cincinnati.png",
  "mls/LA-Angeles-FC.png",
  "mls/NYCFC.png",
  "mls/FC-Dallas.png",
  "mls/Real-Salt-Lake.png",
  "mls/Colorado-Rapids.png",
  "mls/_MLS.png",
  "mls/Inter-Miami.png",
  "mls/LA-Galaxy.png",
  "mls/Minnesota-Utd.png",
  "mls/St-Louis.png",
  "mls/Vancouver.png",
  "mls/San-Jose.png",
  "mls/Philadelphia.png",
  "mls/Portland-Timbers.png",
  "mls/DC-United.png",
  "mls/Nashville.png",
  "mls/Sporting-KC.png",
  "mls/Charlotte.png",
  "mls/CF-Montreal.png",
  "mls/Austin.png",
  "mls/Chicago-Fire.png",
  "mls/Orlando-City.png",
  "mls/Houston-Dynamo.png",
  "mls/Atlanta-Utd.png",
  "nhl/Montreal.png",
  "nhl/Winnipeg.png",
  "nhl/Seattle.png",
  "nhl/Washington.png",
  "nhl/New-Jersey.png",
  "nhl/Columbus.png",
  "nhl/Detroit.png",
  "nhl/Colorado.png",
  "nhl/Chicago.png",
  "nhl/NY-Rangers.png",
  "nhl/Edmonton.png",
  "nhl/Calgary.png",
  "nhl/Boston.png",
  "nhl/_NHL.png",
  "nhl/Carolina.png",
  "nhl/Pittsburgh.png",
  "nhl/Ottawa.png",
  "nhl/Vegas.png",
  "nhl/Los-Angeles.png",
  "nhl/St-Louis.png",
  "nhl/Vancouver.png",
  "nhl/Buffalo.png",
  "nhl/Anaheim.png",
  "nhl/San-Jose.png",
  "nhl/Toronto.png",
  "nhl/Philadelphia.png",
  "nhl/Minnesota.png",
  "nhl/Tampa-Bay.png",
  "nhl/Nashville.png",
  "nhl/NY-Islanders.png",
  "nhl/Dallas.png",
  "nhl/Florida.png",
]