export const contentNotFound = ``;
export const contentOverview = `

<p id="about" className="title">
  API Reference
</p>

<p className="main">
  The Crunchtime API serves integrated insights from odds and game data, empowering users to make data-driven decisions.
  It provides comprehensive and reliable statistics and indicators leveraging team statistics, game outcomes, and odds.
  The API empowers users to design, backtest, and deploy limitless strategies with ease.
  With Crunchtime, users can now capitalize on market inefficiencies and identify their competitive edge like never before.
</p>

<p className="main">
  The API aggregates consistent and accurate odds data from dozens of bookies and sports leagues to provide real-time odds and powerful statistics
  in a reliable and accessible manner, all in one convenient place.
  It features robust request handlers with consistent responses and powerful request routing.
  It can handle a large number of requests efficiently without sacrificing performance or stability,
  with fast response times to provide users with a seamless experience.
</p>

<p className="title2">
  An unparalleled blend of statistics and real-time odds, providing immediate and impactful resources for users.
</p>

<p className="main">
  Our API serves statistics that are indexed and optimized for immediate use in backtesting strategies or training models.
  These statistics are meticulously curated to provide valuable insights into odds markets, enabling users to make informed decisions with confidence.
  Whether you're refining existing models or training new ones,
  our API provides the essential resources needed to stay ahead in the dynamic world of strategic sports betting.
</p>

<p className="main">
  To learn more about all endpoints that are available, check out the <a className="link" href="/api-docs/endpoints">API schema</a>.
  To test endpoints using an API key check out the <a className="link" href="/api-spec">Swagger UI</a>.
</p>

<p id="getapikey" className="title">
  Get an API Key
</p>

<p className="main">
  The Crunchtime API uses API keys to authenticate requests.
  We authenticate your private API key using HTTP authorization request headers.
  Any request to authorized endpoints that doesn't include an API key will return an error.
  To learn more about how authorization headers work, check out
  <a className="link" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization#basic_authentication">
  related documentation from Mozilla foundation
  </a>.
</p>

<p className="main">
  <a className="link" href="/account?register=true">Create an account</a> to get started and receive your API key via email.
  <a className="link" href="/account?login=true">Login</a> to your account dashboard to view and manage access to our products.
</p>
`;

export const contentSchemaLanding = `
<div className="landing">
<p id="schema" className="title">
  API Schema
</p>

<span className="main">
  Get up and running quickly by trying the <span className="endpoint"> /api/health </span> endpoint.
</span>

<span className="main">
  Just curious? Try the endpoint through the <a className="link" href="/api-spec">API Spec</a>, no API Key required.
</span>

<span className="main">
  Once you've <a className="link" href="/account?register=true">created an account</a> and received your API key via email,
  you can call any of our protected endpoints detailed in the <a className="link" href="/api-docs/endpoints/helper">helper</a>
  and <a className="link" href="/api-docs/endpoints/odds">data</a> endpoint sections.
</span>

<p id="authorization" className="subtitle2">Authorization</p>

<span className="main">
  When requesting the API, ensure you've added
  <span className="emph">
    x-api-key
  </span>
  to the header with your private API key.
</span>

<span className="main">
  You can then try out all the available endpoints on <a className="link" href="/api-spec">API Spec</a> by entering your API key into the x-api-key section under Parameters.
  This will allow you to see the responses for various endpoints based on parameters you specify.
</span>

<p id="pagination" className="subtitle2">Pagination</p>

<span className="main">
  Pagination with an optional parameter <span className="emph">page</span> allows users to navigate through large sets of data in manageable chunks of <span className="emph">10</span> items per page.
</span>

<span className="main">
  This is accomplished by supplying <span className="emph">?page=</span> (or <span className="emph">&page=</span> if additional parameters precede it) followed by the page number to a relevant endpoint.
</span>

<p id="rate_limits" className="subtitle2">Rate Limits</p>

<span className="main">
  All private API keys have a rate limit of <span className="emph">500</span> requests per minute (RPM) on all endpoints.
</span>

<p id="request_limits" className="subtitle2">Request Limits</p>

<span className="main">
  <span className="emph"> Premium </span> users can send up to <span className="emph">5,000,000 requests</span> per month.
</span>

<span className="main">
  <span className="emph"> Starter </span> users can send up to <span className="emph">50,000 requests</span> per month.
</span>

<span className="main">
  <span className="emph"> Free </span> users can send up to <span className="emph">1,000 requests</span> per month.
</span>

<p id="available_endpoints" className="subtitle2">Public and Privileged Endpoints</p>

<span className="main">
  <span className="emph"> All paid plans </span> can access <span className="emph"> historical data </span> along with privileged <span className="emph2">/api/v1/adv</span> endpoints.
</span>

<span className="main">
  <span className="emph"> Free </span> users can only access basic <span className="emph2">/api/v1</span> endpoints.
</span>
</div>
`;

/*
<span className="main">
  <span className="emph"> Elite </span> users can send up to <span className="emph">5,000,000 requests</span> per month.
</span>

<span className="main">
  <span className="emph"> Premium </span> users can send up to <span className="emph">500,000 requests</span> per month.
</span>

<span className="main">
  <span className="emph"> Professional </span> users can send up to <span className="emph">100,000 requests</span> per month.
</span>

<span className="main">
  <span className="emph"> Free </span> users can send up to <span className="emph">1,000 requests</span> per month.
</span>
*/