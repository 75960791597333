import { useGetTheme } from "../../lib/contexts";
import { RowObj } from "../../lib/interface";
import { getThemeData } from "../../theme";
import { tmap } from "./teamDisplayMap";
import { getTeamLogo } from "./helpers";

interface BannerTextProps {
  betRows: RowObj[],
  isMobile: boolean;
};

const BannerText = ({
  betRows,
  isMobile,
}: BannerTextProps) => {
  const {theme} = useGetTheme();
  const sortedRows = [...betRows].sort((a, b) => parseFloat(b.c10) - parseFloat(a.c10));
  if (sortedRows.length === 0) {
    return <></>
  }
  const row = sortedRows[0];
  const row2 = sortedRows[sortedRows.length - 1];
  if (row === undefined || row2 === undefined){
    return <></>
  }
  var c1_name = row.c1;
  var c2_name = row.c11;
  var d1_name = row2.c1;
  var d2_name = row2.c11;
  for (let [key, valued] of Object.entries(tmap)) {
    if (key.toLowerCase() === row.c2.toLowerCase()) {
      console.log(valued)
      for (let [v1, vk] of Object.entries(valued)) {
        let tn1 = v1; //key+"-"+v1.split(" ").join("-");
        let tn2 = vk; //key+"-"+vk.split(" ").join("-");

        if (c1_name.length < tn1.length && row.c1 === tn2) {
          c1_name = tn1;
        } else if (c2_name.length < tn1.length && row.c3 === "Moneyline" && row.c11 === tn2) {
          c2_name = tn1;
        }

        if (d1_name.length < tn1.length && row2.c1 === tn2) {
          d1_name = tn1;
        } else if (d2_name.length < tn1.length && row2.c3 === "Moneyline" && row2.c11 === tn2) {
          d2_name = tn1;
        }
      }
    }
  }
  const team1Logo = getTeamLogo(
    row.c2,
    row.c1.split(" ").map(tc => tc).join("-"),
    isMobile ? "20px" : "22px",
    isMobile ? "20px" : "22px"
  );
  const team2Logo = getTeamLogo(
    row2.c2,
    row2.c1.split(" ").map(tc => tc).join("-"),
    isMobile ? "20px" : "22px",
    isMobile ? "20px" : "22px"
  );
  return (
    <div style={{
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    }}>
      <div style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        maxWidth: "100%",
      }}>
        <div style={{
          display: "inline-block",
          width: isMobile ? "calc(100% - 24px)" : "calc(49% - 24px)",
          padding: "12px 12px",
          textAlign: isMobile ? "center" : "left",
          borderRadius: "10px",
          background: "rgb(15, 255, 80, 0.2)",
          //background: "linear-gradient(90deg, rgb(15, 255, 80, 0.2) 0%, transparent 50%, rgb(15, 255, 80, 0.2) 100%)",
        }}>
          <span style={{fontWeight: 600, fontSize: isMobile ? "20px" : "22px", color: getThemeData(theme).textColor,}}>
            {"The best bet of all time is the"}
            {team1Logo !== null && (" ")}
            {team1Logo}
            <span style={{fontWeight: 700}}>
              {` ${c1_name} `}
            </span>
            <span style={{fontWeight: 700}}>
              {row.c3 === "Moneyline" && `${row.c3}`}
              {row.c3 === "Spread" && `${row.c3} `}
              {(row.c3 === "Over" || row.c3 === "Under") && `${row.c3} `}
            </span>
            {(row.c3=== "Spread" || row.c3 === "Over" || row.c3 === "Under") && <span style={{fontWeight: 700}}>
              {` ${c2_name}`}
            </span>}
            {" when "}
            <span style={{color: (row.c5 === 'Underdog' ? getThemeData(theme).underdogPosition : getThemeData(theme).favoredPosition),}}>
              {`${row.c5}`}
            </span>
            {row.c3 === "Moneyline" && <span> {" vs "} </span>}
            {row.c3 === "Moneyline" && <span style={{fontWeight: 700}}>
              {c2_name}
            </span>}
            {" with "}
            <span style={{ color: (parseFloat(row.c10) >= 0 ? getThemeData(theme).themeGreen : getThemeData(theme).themeRed) }}>
              {parseFloat(row.c10) >= 0 ? "+" : "-"}
            </span>
            <span style={{fontWeight: 600, color: (parseFloat(row.c10) >= 0 ? getThemeData(theme).themeGreen : getThemeData(theme).themeRed) }}>
              {"$"+row.c10.replace("-","")}
            </span>
            {" unit profit on "}
            <span style={{fontWeight: 700}}>
              {row.c4}
            </span>
          </span>
        </div>
        {isMobile && <div style={{height: "22px"}}/>}
        <div style={{
          display: "inline-block",
          width: isMobile ? "calc(100% - 24px)" : "calc(49% - 24px)",
          padding: "12px 12px",
          textAlign: isMobile ? "center" : "left",
          borderRadius: "10px",
          background: "rgb(255, 87, 51, 0.2)",
          //background: "linear-gradient(90deg, rgb(255, 87, 51, 0.2) 0%, transparent 50%, rgb(255, 87, 51, 0.2) 100%)",
        }}>
          <span style={{fontWeight: 600, fontSize: isMobile ? "20px" : "22px", color: getThemeData(theme).textColor,}}>
            {"The worst bet of all time is the"}
            {team2Logo !== null && (" ")}
            {team2Logo}
            <span style={{fontWeight: 700}}>
              {` ${d1_name} `}
            </span>
            <span style={{fontWeight: 700}}>
              {row2.c3 === "Moneyline" && `${row2.c3}`}
              {row2.c3 === "Spread" && `${row2.c3} `}
              {(row2.c3 === "Over" || row2.c3 === "Under") && `${row2.c3} `}
            </span>
            {(row2.c3=== "Spread" || row2.c3 === "Over" || row2.c3 === "Under") && <span style={{fontWeight: 700}}>
              {` ${d2_name}`}
            </span>}
            {" when "}
            <span style={{color: (row2.c5 === 'Underdog' ? getThemeData(theme).underdogPosition : getThemeData(theme).favoredPosition),}}>
              {`${row2.c5}`}
            </span>
            {row2.c3 === "Moneyline" && <span> {" vs "} </span>}
            {row2.c3 === "Moneyline" && <span style={{fontWeight: 700}}>
              {d2_name}
            </span>}
            {" with "}
            <span style={{ color: (parseFloat(row2.c10) >= 0 ? getThemeData(theme).themeGreen : getThemeData(theme).themeRed) }}>
              {parseFloat(row2.c10) >= 0 ? "+" : "-"}
            </span>
            <span style={{fontWeight: 600, color: (parseFloat(row2.c10) >= 0 ? getThemeData(theme).themeGreen : getThemeData(theme).themeRed) }}>
              {"$"+row2.c10.replace("-","")}
            </span>
            {" unit profit on "}
            <span style={{fontWeight: 700}}>
              {row2.c4}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BannerText;