import { ThemeData } from '../lib/interface';

export const getThemeData = (theme: string) => {;
  const darkMode = (true ? theme === 'dark' : false);
  const themeData: ThemeData = {
    bgGlobal: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#070816"
      //? "#1E2131"
      : "#fdfbfb",
      //: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
    textColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "white"
      : "black",
    textColor2: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "grey"
      : "grey",
    textColor3: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "lightblue"
      : "black",
    textColor4: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "lightgrey"
      : "black",
    iconColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#25adea"
      : "black",
    colorAccent: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#25adea"
      : "#3C84E4",
    bgButton1: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "linear-gradient(to right, #25adea 0%, #a151ff 100%)"
      : "#3C84E4",//"black",
    bgBox1: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#3b436b"
      : "#DCDCDC",
    bgBox2: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#3b436b"
      : "#EEEEEE",
    borderColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#353535"
      : "lightgrey",
    navBarBgColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "linear-gradient(to right, #25adea 0%, #8A53CD 100%)"
      : "#1E2131",
    mobileNavBackground: (darkMode !== null && darkMode !== undefined && darkMode)
        //? "#1E2131"
        ? "#070816"
        : "#ebedee",
    themeGreen: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "lightgreen"
      : "green",
    bgBlurGlobal: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#383944"
      : "#bcbdbe",
    gradient1: "linear-gradient(to right, #25adea 0%, #a151ff 100%)",
    gradientStart: "#25adea",
    gradientEnd: "#8A53CD",
    themeRed: "red",
    themeBlue: "#1E2131",
    underdogPosition: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#25adea"
      : "#3C84E4", //"#12c2e9",
    favoredPosition: "#c471ed",
    modalBgColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#070816" //"#101010"
      : "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
    modalBrColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "#353535" //"#303030"
      : "lightgrey",
    cookieAlertBgColor: (darkMode !== null && darkMode !== undefined && darkMode)
      ? "black"
      : "#ebedee",
  };
  return themeData;
}