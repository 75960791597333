import { Helmet } from 'react-helmet-async';

interface PageTagsProps {
  pageUrl: string,
  pageTitle: string,
  pageDescription: string,
  pageImageUrl: string,
  pageTwitter: string | null
};

const PageTags = ({
  pageUrl,
  pageTitle,
  pageDescription,
  pageImageUrl,
  pageTwitter,
}: PageTagsProps) => {
  return (
    <Helmet>
      {/* HTML Meta Tags */}
      <title>{pageTitle}</title>
      <link rel="canonical" href={`${pageUrl}`}/>
      <meta name="description" content={`${pageDescription}`}/>
      {/* Facebook Meta Tags */}
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={`${pageUrl}`}/>
      <meta property="og:title" content={`${pageTitle}`}/>
      <meta property="og:description" content={`${pageDescription}`}/>
      <meta property="og:image" content={`${pageImageUrl}`}/>
      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={`${pageTitle}`}/>
      <meta name="twitter:description" content={`${pageDescription}`}/>
      <meta name="twitter:image" content={`${pageImageUrl}`}/>
      {pageTwitter !== null && <meta name="twitter:site" content={`${pageTwitter}`}/>}
    </Helmet>
  );
};

export default PageTags;