import styled from "styled-components";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { useEffect, useState } from "react";

const TermsContainer = styled.div`
  width: ${({isMobile}) => isMobile ? "90%" : "75%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 22px;
  padding: 22px 0 82px 0;
`

const Header = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 24px;
`

const SubHeader = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 20px;
`

const Content = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 18px;
`

const Terms = () => {
  const {theme} = useGetTheme();

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <TermsContainer isMobile={windowDimension.winWidth < 1040}>
      <Header color={getThemeData(theme).textColor}>
        CRUNCHTIMEODDS TERMS OF SERVICE
      </Header>
      <SubHeader color={getThemeData(theme).textColor}>
        Last Updated: June 12, 2024
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        By accessing and using CrunchtimeOdds services, you agree to comply with and be bound by the following terms of service.
        Please read these terms of service carefully before using the services.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        1. Definitions
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        "CrunchtimeOdds", "We", "Us", or "Our" refers to the service provided by CrunchtimeOdds.
        "User", "You", or "Your" refers to any individual or entity that accesses or uses the CrunchtimeOdds service.
        "Service" refers to the website, application, product, software, tool, API services and their associated data provided by CrunchtimeOdds.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        2. Liability
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        While CrunchtimeOdds endeavors to maintain the accuracy and timeliness of its data, errors may occasionally occur.
        We shall not be held liable for any losses or damages resulting from the use of our service data, whether the data is accurate, erroneous, or outdated.
        It is your responsibility to independently verify the accuracy of the data before relying on it for any purpose.
        We encourage you to report any errors or inaccuracies to us promptly.
        We shall not be liable for any losses or damages incurred as a result of actions taken based on the information provided by our service.
        CrunchtimeOdds assumes no responsibility for any products or services created by users of the service.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        3. Restrictions
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        You agree not to resell or redistribute CrunchtimeOdds service data as a standalone data product, including but not limited to data dumps and API access.
        Any unauthorized resale or distribution of our data may result in the immediate termination of your service access.
        The service is intended solely for persons who are (i) 18 or older, or (ii) 13 and older if either (a) an emancipated minor, or (b) he/she possess legal parental or guardian consent.
        All users must be willing and able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.
        In any case, you affirm that you are over the age of 13, as the service is not intended for children under 13.
        If you are under 13 years of age, then please do not use the service.
        By registering for a user account, or by clicking to accept these terms, you are deemed to have executed these terms electronically, effective on the date you register your account, pursuant to the U.S. Electronic Signatures in Global and National Commerce Act (the E-Sign Act) (15 U.S.C. § 7001, et seq.).
        Your creating of a user account constitutes an acknowledgement that you are able to electronically receive, download, and print these terms, and any amendments.
        If you have questions about permissible use, please contact us for clarification.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        4. API Key Responsibility
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        Upon registration for the CrunchtimeOdds API service, you will receive a unique API key via email.
        You are solely responsible for maintaining the confidentiality of your API key.
        Failure to safeguard your API key may result in unauthorized access and depletion of your usage quota.
        If you suspect a breach of your API key's privacy, please notify us immediately to request a new API key.
      </Content>
      <Content color={getThemeData(theme).textColor}>
        Users have the option to regenerate their API key securely through their account dashboard on the CrunchtimeOdds platform.
        The regenerated API key will be sent exclusively to the user's registered email address via secure channels.
        Users acknowledge and agree that the regenerated API key shall be used in accordance with these terms of service.
        CrunchtimeOdds reserves the right to limit the frequency of API key regeneration and to verify the identity of the user before processing any requests for regeneration.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        5. Cancellations & Refunds
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        You may cancel your access to the paid CrunchtimeOdds services at any time.
        However, any unused portion of your payment is non-refundable, and your plan will remain active until the end of the current billing cycle.
        Refund requests will be considered on a case-by-case basis, with decisions made at our discretion.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        6. Appropriate Usage and Abuse
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        CrunchtimeOdds reserves the right to terminate service access, block IP addresses, or take other appropriate measures without prior notice if we suspect misuse, abuse, or malicious intent by any user of our service.
      </Content>
    </TermsContainer>
  );
};

export default Terms;