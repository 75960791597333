import { useEffect } from "react";
import {
  BodyWrapper,
  BodyContainer,
  BodyContent,
} from "../../styles";
import Hero from "../Hero";
import Highlights from "../Highlights";
import JoinUs from "../JoinUs";
import Features from "../Features";
import Faq from "../Faq";
import Plans from '../Plans';

const LandingAPI = () => {

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    console.log(href)
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth',});
    }
  }, [window.location.href]);

  return (
    <BodyWrapper>
      <BodyContainer>
        <BodyContent>
          <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
            <Hero/>
            <Highlights/>
            <Features/>
            <div id="plans" style={{padding: "42px 0px"}}/>
            <Plans/>
            <Faq/>
            <JoinUs/>
          </div>
        </BodyContent>
      </BodyContainer>
    </BodyWrapper>
  );
}

export default LandingAPI;