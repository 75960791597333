import { searches } from "./items";
import { questions } from "./questions";
import { tmap, leaguestmap } from "../Dash/teamDisplayMap";

const searchObjs1 = [...searches].map((v,i) => {
  return v;
});

// CURRENTLY NOT SUPPORTING CUSTOM TIME RANGE IN SEARCH QUERY
const searchObjs2 = [...questions].filter(
  v => ((!(v.includes('today'))) && (!(v.includes("last ")) && (!(v.includes("since ")))))
).map((v,i) => {
  return {
    result: v.split(' ').map(w => {
      if (leaguestmap.includes(w.toLowerCase())) {
        return w.toUpperCase();
      } else {
        return w[0].toUpperCase() + w.slice(1);
      }
    }).join(' '),
    subtitle: "",
    path: undefined,
    external: false,
  }
});

export const MAX_SEARCH_HISTORY_STORE = 5;

export const searchItems = [
  ...searchObjs1,
  ...searchObjs2,
];

export const minTextLengthForSearchResults = 0;
export const maxResults = 10

export const defaultQuestionsSection1 = [
  {
    result: "Trending Bets",
    subtitle: "",
    path: `/bets/trending`,
    external: false,
  },
]
export const defaultQuestionsSection2 = [
  {
    result: "Best Bets of All Time",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "Best Bets in the last 7 days",
    subtitle: "",
    path: `/bets/top`,
    external: false,
  },
  {
    result: "BetOnline Moneyline",
    subtitle: "",
    path: `/q?site_id=BetOnline&bet_type=moneyline&intent=GetSiteMl`,
    external: false,
  },
  {
    result: "Fanduel Spread",
    subtitle: "",
    path: `/q?site_id=Fanduel&bet_type=spread&intent=GetSitePs`,
    external: false,
  },
  {
    result: "Betrivers Over Under",
    subtitle: "",
    path: `/q?site_id=Betrivers&bet_type=overunder&intent=GetSiteOu`,
    external: false,
  },
  {
    result: "MLB Over 8.5",
    subtitle: "",
    path: `/q?league=mlb&bet_line=8.5&bet_type=over&intent=GetSiteOverLine`,
    external: false,
  },
  {
    result: "Pittsburgh Pirates Spread -1.5",
    subtitle: "",
    path: `/q?team_id=mlb-pittsburgh-pirates&bet_type=spread&intent=GetSiteTeamPs`,
    external: false,
  },
  {
    result: "Moneyline Betrivers NBA Milwaukee Bucks",
    subtitle: "",
    path: `/q?site_id=Betrivers&league=nba&team_id=nba-Milwaukee&bet_type=moneyline&intent=GetSiteTeamMl`,
    external: false,
  },
  {
    result:  "Best Site to bet on the LA Clippers Moneyline",
    subtitle: "",
    path: `/q?team_id=nba-LA-Clippers&league=nba&bet_type=moneyline&intent=GetTeamMl`,
    external: false,
  },
]
export const defaultSearchItems = [
  ...defaultQuestionsSection1,
  ...defaultQuestionsSection2,
]
