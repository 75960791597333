import axios, { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { API_ENDPOINT_URL, API_PROXY_ENDPOINT_URL } from '../config';

axios.defaults.withCredentials = true

/*
const config: AxiosRequestConfig = {
  headers: {
    'Accept': 'application/json',
  } as RawAxiosRequestHeaders,
};
*/
const config = { withCredentials: true };

const config_internal: AxiosRequestConfig = {
  headers: {
    'Accept': 'application/json',
    'x-api-key': `${process.env.REACT_APP_EDGEBETS_API_KEY}`,
  } as RawAxiosRequestHeaders,
};

export const login = async (data: Object) => {
  try {
    const response: AxiosResponse = await axios.post(
      `${API_ENDPOINT_URL}/p/auth/login`,
      data,
      config,
    );
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
};

export const logout = async () => {
  try {
    const response = await axios.get(
      `${API_ENDPOINT_URL}/p/auth/logout`,
      config
    );
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
};

export const checkout = async (data: Object) => {
  try {
    const response: AxiosResponse = await axios.post(
      `${API_PROXY_ENDPOINT_URL}/proxy/payments/create-checkout-session`,
      data,
      {...config_internal, withCredentials: false},
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const register = async (data: Object) => {
  try {
    const response: AxiosResponse = await axios.post(
      `${API_PROXY_ENDPOINT_URL}/proxy/payments/create-customer`,
      data,
      {...config_internal, withCredentials: false},
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const update = async (id: string, data: Object) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${API_ENDPOINT_URL}/p/user/${id}`,
      data,
      config
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const regenerate = async (id: string) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${API_ENDPOINT_URL}/p/regen`,
      {user_id: id},
      config
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const userData = async (id: string) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/p/user/${id}`,
      config
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const userSubscriptions = async (id: string) => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_PROXY_ENDPOINT_URL}/proxy/payments/subscriptions/${id}`,
      {...config_internal, withCredentials: false},
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (e) {
    console.log(e);
  }
};

export const cancel = async (subscription_id: String, p: String, user_id: String) => {
  try {
    const response = await axios.post(
      `${API_PROXY_ENDPOINT_URL}/proxy/payments/cancel-subscription`,
      {
        subscription_id: subscription_id,
        password: p,
        user_id: user_id
      },
      {...config_internal, withCredentials: false},
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (e) {
    console.log(e);
  }
};

export const reactivate = async (subscription_id: String, p: String, user_id: String,) => {
  try {
    const response = await axios.post(
      `${API_PROXY_ENDPOINT_URL}/proxy/payments/reactivate-subscription`,
      {
        subscription_id: subscription_id,
        password: p,
        user_id: user_id
      },
      {...config_internal, withCredentials: false},
    );
    console.log(response)
    return response as AxiosResponse;
  } catch (e) {
    console.log(e);
  }
};
