import styled from "styled-components";
import { motion } from "framer-motion";

export const GradientWrapper = styled.div`
  height: 100%;
  width: fit-content;
  border-radius: 10px;
  transition: 0.3s ease-in-out all;
  &:hover {
    filter: brightness(120%);
  }
`;

export const PlanBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  flex-direction: column;
`;

export const HeadingText = styled.h1`
  font-size: 35px;
  font-weight: normal;
  background: ${({color}) => color};
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

export const HeadingTextBottom = styled.h1`
  font-size: 35px;
  font-weight: normal;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  @media (max-width: 420px) {
    font-size: 24px;
  }
`;

export const HeadingTextMainPlan = styled.h1`
  font-size: 35px;
  font-weight: normal;
  background: ${({color}) => color};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 40px;
`;

export const HeadingTextAlt = styled.h1`
  font-size: 2rem;
  font-weight: 800;
  background: ${({ color }) => color};
  width: fit-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

export const PlanWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 22px;
  flex-wrap: wrap;
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 700px;
  width: 288px;
  border-radius: 10px;
  transition: 0.3s ease-in-out all;
`;

export const PlanButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 10px;
  background: ${({bgColor}) => bgColor};
  color: ${({color}) => color};
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  padding: 0 10px;
  margin-top: 10px;
`;

export const BenefitContainer = styled.div`
  width: 80%;
  height: fit-content;
  margin-top: 20px;
`;

export const BenefitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center; //flex-start;
`;

export const BenefitText = styled.div`
  color: ${({color}) => color};
  font-size: 15px;
  font-weight: 400;
  //letter-spacing: 0.01rem;
  cursor: default;
`;

export const RightIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlanHeadText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${({color}) => color};
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

export const Divider = styled.div`
  width: 120%;
  height: 1px;
  background-color: #606060;
`;

export const MonthFontNumber = styled.p`
  font-size: 29px;
  font-weight: normal;
  color: ${({color}) => color};
`;
export const MonthFontText = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: ${({color}) => color};
  width: auto;
  line-height: 0.9;
  margin-left: 5px;
`;

export const PlanTag = styled.div`
  padding: 6px 15px;
  background-color: #15ca87;
  /* background-color: transparent; */
  border: #15ca87 solid 1px;
  /* color: #15ca87; */
  color: white;
  font-weight: bold;
  border-radius: 50px;
  font-size: 12px;
  margin-top: 10px;
`;

export const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

export const IncludeText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: white;
`;

export const PriceDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({justifyContent}) => justifyContent};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 145px;
  margin-top: 50px;
`;

export const MostPopTag = styled.div`
  width: 288px;
  height: 50px;
  background-color: ${(props) => props.backgroundColor};
  position: absolute;
  top: -20px;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  font-weight: 800;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;