import { useEffect, useState, createRef } from 'react';
import { useAuth } from '../../lib/auth';
import styled from "styled-components";
import { MdError } from 'react-icons/md';
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { Config as config } from '../../lib/config';
import { Eye, EyeOff } from 'react-feather';
import Spinner from "../Spinner";
//import { loadReCaptcha, removeReCaptcha, getToken } from '../../lib/recaptcha';
import ReCAPTCHA from "react-google-recaptcha";
//import { loadReCaptcha, removeReCaptcha } from '../../lib/recaptcha';

const Form = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 414px;
  padding: 0.8rem 1.2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Input = styled.input`
  text-align: left;
  max-width: 100%;
  padding: 11px 13px;
  background-color: transparent !important;
  white-space: nowrap !important;
  border: 1px solid #303030;
  border-radius: 6px;
  color: ${({color}) => color};
  margin-bottom: 0.9rem;
  //white-space: nowrap;
  transition: all 0.3s ease-out;
  &:focus {
    border: 1px solid #303030;
    outline: none !important;
  }
`;

const InputAlt = styled.input`
  text-align: left;
  background-color: transparent !important;
  white-space: nowrap !important;
  border: transparent !important;
  transition: all 0.3s ease-out;
  padding: 11px 13px;
  width: 100%;
  color: ${({color}) => color};
  &:focus {
    border: transparent !important;
    outline: none !important;
  }
`;

export const InputCustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 1px solid #303030;
  border-radius: 6px;
  margin-bottom: 0.9rem;
  max-width: 100%;
`

const ButtonLogin = styled.div`
  padding: 10px 0;
  width: 100%;
  border: 1px solid #303030;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  filter: brightness(120%);
  &:hover {
    filter: brightness(150%);
    border: 1px solid #909090;
  }
  span {
    font-weight: 600;
    font-size: 14px;
  }
`

const Login = ({setSnackBarTitle, setSnackBarType, setShowModal, setAuth, setId, registerMode, setRegisterMode}) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [errors, setErrors] = useState([]);
  const [showPassowrd, setShowPassword] = useState(false);
  const [loadingReCaptcha, setLoadingReCaptcha] = useState(false);

  const {theme} = useGetTheme();
  const { login, register } = useAuth();

  const recaptchaRef = createRef();

  /*
  useEffect(() => {
    if (registerMode) {
      loadReCaptcha(config.recaptchaSiteKey);
    } else {
      removeReCaptcha();
    }
  },[registerMode])
  */

  useEffect(() => {
    if (registerMode) {
      setLoadingReCaptcha(true);
      console.log("LOADING RE CAPTCHA")
    } else {
      setLoadingReCaptcha(false);
      console.log("NOT LOADING RE CAPTCHA")
      try {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        //window.grecaptcha.reset();
      } catch {
        console.log("recaptcha reset error")
      }
    }
  },[registerMode])

  const attemptLoginOrRegister = async () => {
    let response = undefined;
    let regError = false;
    if (!registerMode) {
      // login
      response = await login({
        email: email,
        password: password
      });
    } else {
      // register
      //let token = await getToken(config.recaptchaSiteKey, "login");
      const token = recaptchaRef.current.getValue();
      let tempErrors = [];
      if (password !== undefined && password.toString().length < 6) {
        tempErrors.push('Password must be at least 6 characters.')
      }
      if (firstName !== undefined && firstName.toString().length < 3) {
        tempErrors.push('First name must be at least 3 characters.')
      }
      if (lastName !== undefined && lastName.toString().length < 3) {
        tempErrors.push('Last name must be at least 3 characters.')
      }
      if (tempErrors.length > 0) {
        regError = true;
        setErrors([...tempErrors]);
      } else {
        setErrors([]);
      }
      response = await register({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        recaptcha_response_token: token
      });
    }
    console.log(response)
    if (response !== undefined && regError === false) {
      if (response.status !== 200) {
        try {
          if (response.data.errors.length > 0) {
            setErrors(response.data.errors);
          }
        } catch (e) {
          setErrors(["Error"])
        }
      } else {
        setErrors([])
        if (registerMode) {
          setSnackBarTitle("Registered: Login to get started")
          setSnackBarType("customSuccess")
          setTimeout(() => {
            setSnackBarType(undefined);
            setSnackBarTitle(undefined);
            window.location.href = '/account?login=true'
            //setShowModal(false);
          }, config.snackTimeout);
        } else {
          setSnackBarTitle("Logged In")
          setSnackBarType("success")
          setTimeout(() => {
            setSnackBarType(undefined);
            setSnackBarTitle(undefined);
            setAuth(JSON.stringify(true));
            setId(JSON.stringify(response.data.user_id));
            sessionStorage.setItem("auth", JSON.stringify(true));
            sessionStorage.setItem("id", JSON.stringify(response.data.user_id));
            setShowModal(false);
          }, config.snackTimeout);
        }
      }
    }
  };

  const handleRegister = (mode) => {
    setRegisterMode(mode)
    resetStates();
  }

  const resetStates = () => {
    setEmail(undefined);
    setPassword(undefined);
    setFirstName(undefined);
    setLastName(undefined);
    setErrors([]);
  }

  return (
    <Form>
      {registerMode &&
      <Input
        type="text"
        name="fname"
        value={firstName}
        onChange={(event) => {
          event.preventDefault();
          setFirstName(event.target.value);
        }}
        color={getThemeData(theme).textColor}
        placeholder='First Name'
      />
      }
      {registerMode &&
      <Input
        type="text"
        name="lname"
        value={lastName}
        onChange={(event) => {
          event.preventDefault();
          setLastName(event.target.value);
        }}
        color={getThemeData(theme).textColor}
        placeholder='Last Name'
      />
      }
      <Input
        type="email"
        name="email"
        value={email}
        onChange={(event) => {
          event.preventDefault();
          setEmail(event.target.value);
        }}
        color={getThemeData(theme).textColor}
        placeholder='Email'
      />
      <InputCustomContainer>
        <InputAlt
          type={showPassowrd ? "text" : "password"}
          name="password"
          value={password}
          onChange={(event) => {
            event.preventDefault();
            setPassword(event.target.value)
          }}
          color={getThemeData(theme).textColor}
          placeholder='Password'
        />
        {showPassowrd
        ? <Eye size={18} cursor={"pointer"} color={getThemeData(theme).colorAccent} onClick={() => {setShowPassword(false)}}/>
        : <EyeOff size={18} cursor={"pointer"} color={getThemeData(theme).colorAccent} onClick={() => {setShowPassword(true)}}/>
        }
        <div style={{width: "12px"}}/>
      </InputCustomContainer>
      <ButtonLogin onClick={() => {attemptLoginOrRegister()}}>
        <span style={{color: getThemeData(theme).textColor}}>
          {registerMode === true ? "REGISTER" : "SIGN IN"}
        </span>
      </ButtonLogin>
      <div style={{width: "100%", height: "22px"}}/>
      {registerMode === false
      ? <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "4px"}}>
        <span style={{fontSize: "0.9rem", fontWeight: 400, color: getThemeData(theme).textColor}}>
          No account?
        </span>
        <span style={{cursor: "pointer", fontSize: "0.9rem", fontWeight: 600, color: getThemeData(theme).colorAccent}}
          onClick={() => { handleRegister(true) }}>
          Register to get started
        </span>
      </div>
      : <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "4px"}}>
        <span style={{fontSize: "0.9rem", fontWeight: 400, color: getThemeData(theme).textColor}}>
          Have an account?
        </span>
        <span style={{cursor: "pointer", fontSize: "0.9rem", fontWeight: 600, color: getThemeData(theme).colorAccent}}
          onClick={() => { handleRegister(false) }}>
          Sign in
        </span>
      </div>
      }
      {registerMode && (
        <>
          <div style={{width: "100%", height: "22px"}}/>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
            {loadingReCaptcha
            ? <Spinner text={"Loading"} color={"white"} />
            : (
            <ReCAPTCHA
              size="normal"
              render="explicit"
              ref={recaptchaRef}
              sitekey={config.recaptchaSiteKey}
              theme={theme === 'dark' ? 'dark' : 'light'}
              //onloadCallback={() => {
              asyncScriptOnLoad={() => {
                setLoadingReCaptcha(false)
                console.log("LOADED RE CAPTCHA")
              //  setTimeout(() => {
              //    setLoadingReCaptcha(false)
              //    console.log("LOADED RE CAPTCHA")
              //  },500)
              }}
            />)}
          </div>
        </>
      )}
      <div style={{width: "100%", height: "12px"}}/>
      {errors.map((value, _index) => {
        return (
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "4px"}}>
            <MdError size={18} color={"red"}/>
            <span style={{fontSize: "0.8rem", fontWeight: 600, color: "red"}}>
              {value}
            </span>
          </div>
        )
      })}
    </Form>
  );
}

export default Login;