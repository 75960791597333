import React from "react";
import styled from "styled-components";
import Confetti from "react-confetti";

const ActionButton = styled.button`
  width: fit-content;
  background: linear-gradient(to right, #25adea 0%, #8a53cd 100%);
  color: white;
  padding: 8px 16px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  &:hover {
    filter: brightness(0.8);
  }
`;

export const MainWrapper = styled.div`
  height: calc(100%);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.s ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3dbc;
  z-index: 200;
`;

export const AlterModalWrap = styled.div`
  height: 300px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AlterModalTop = styled.div`
  height: 230px;
  width: 100%;
  background-color: #18191d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const AlterModalBottom = styled.div`
  height: 60px;
  width: 100%;
  background-color: #18191d;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #d8d8d8;
`;

export const CancelIcon = styled.div`
  position: relative;
  /* top: 10px; */
  top: 40px;
  right: -225px;
  display: none;
`;

export const CancelButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  padding: 8px 20px;
  border: 1px solid #404040;
  margin-right: 30px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;

const ConfirmationModal = ({
  title,
  description,
  showActive,
  setShowActive,
}) => {
  // set to display none when clicked out of the modal
  const exitDetailHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      setShowActive();
    }
  };

  return (
    <MainWrapper
      onClick={(e) => {
        exitDetailHandler(e);
      }}
      className="shadow"
      s={showActive}
    >
      <Confetti tweenDuration={5000} />
      {/* <CancelIcon>
        <AiOutlineClose size={24} color={"white"} />
      </CancelIcon> */}
      <AlterModalWrap>
        <AlterModalTop>
          <TextContainer>
            <div style={{padding: "22px"}}>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <ActionButton
                style={{
                  background: "linear-gradient(to right, #25adea 0%, #a151ff 100%)",
                  color: "black",
                  fontWeight: 600,
                }}
                onClick={() => {
                  window.location.href = '/products/api/docs/overview'
                }}
              >
                Documentation
              </ActionButton>
            </div>
          </TextContainer>
        </AlterModalTop>
      </AlterModalWrap>
    </MainWrapper>
  );
};

export default ConfirmationModal;