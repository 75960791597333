import styled from "styled-components";
import { getThemeData } from "../../theme";
import { FaFileDownload } from "react-icons/fa";
import { Config as config } from "../../lib/config";
import { handleDataDownload } from "./helpers";
import { useGetTheme } from "../../lib/contexts";

interface DownloadButtonProps {
  isSmallWidth: boolean;
  dataRows: any;
  dataName: string;
  canDownloadCSV: boolean;
  setSnackBarTitle: any;
  setSnackBarType: any;
}

interface DownloadButtonContainerProps {
  bgColor1: string;
  bgColor2: string;
}

const DownloadButtonContainer = styled.div<DownloadButtonContainerProps>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
  width: fit-content;
  border-radius: 10px;
  border: 1px solid ${({bgColor1}) => bgColor1};
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    //filter: brightness(150%);
    border: 1px solid ${({bgColor2}) => bgColor2};
  }
`

export const DownloadButton = ({
  isSmallWidth,
  dataRows,
  dataName,
  canDownloadCSV,
  setSnackBarTitle,
  setSnackBarType,
}: DownloadButtonProps) => {
  const {theme} = useGetTheme();
  return (
    <DownloadButtonContainer
      bgColor1={getThemeData(theme).borderColor}
      bgColor2={getThemeData(theme).colorAccent}
      onClick={() => {
        if (canDownloadCSV) {
          handleDataDownload(dataRows, dataName);
        } else {
          setSnackBarTitle("Upgrade your subscription")
          setSnackBarType("customWarning")
          setTimeout(() => {
            setSnackBarType(undefined);
            setSnackBarTitle(undefined);
          }, config.snackTimeout);
        }
      }}
    >
      <FaFileDownload size={26} color={getThemeData(theme).textColor}/>
      <div style={{display: "inline-flex", flexDirection: "column", }}>
        <span style={{
          color: getThemeData(theme).textColor,
          fontSize: "0.9rem",
          fontWeight: 600,
        }}>
          {"Download CSV"}
        </span>
        {isSmallWidth
        ? null
        : <span style={{
          color: getThemeData(theme).textColor4,
          fontSize: "0.7rem",
          fontWeight: 500,
        }}>
          {"Available with Premium"}
        </span>
        }
      </div>
    </DownloadButtonContainer>
  );
};