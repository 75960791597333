import { useEffect, useState } from "react";
import {
  BodyWrapper,
  BodyContainer,
  BodyContent,
  BodySubContent,
} from "../../styles";
import { useLocation } from "react-router-dom";
import SideMenu from "../SideMenu";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import Markdown from "react-markdown"
import styled from "styled-components"
import remarkGfm from 'remark-gfm'
//import remarkMdx from 'remark-mdx'
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark as darkStyle, oneLight as lightStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Check, Copy } from "react-feather";
import { reqs, getPageContent, } from "./reqs";
import {
  contentOverview as schemaOverview,
  contentSchemaLanding as schemaLanding,
  contentNotFound as schemaNotFound,
} from "./content";

const getMarkdown = (location: string, theme: string) => {
  const contentOverview = schemaOverview;
  const contentSchemaLanding = schemaLanding;
  const contentSchemaHealth = getPageContent(reqs[0]);
  const contentSchemaSites = getPageContent(reqs[1]);
  const contentSchemaTeams = getPageContent(reqs[2]);
  const contentSchemaOddsIndex = getPageContent(reqs[3]);
  const contentSchemaOdds = getPageContent(reqs[4]);
  const contentSchemaGameIds = getPageContent(reqs[5]);
  const contentML = getPageContent(reqs[6]);
  const contentOU = getPageContent(reqs[7]);
  const contentPS = getPageContent(reqs[8]);
  //const contentStats = getPageContent(reqs[9]);
  const contentNotFound = schemaNotFound;

  if (location === '/api-docs' || location === '/api-docs') {
    return contentOverview
  } else if (location === '/api-docs/endpoints/helper') {
    return contentSchemaHealth+contentSchemaSites+contentSchemaTeams+contentSchemaGameIds;
  } else if (location === '/api-docs/endpoints/odds') {
    return contentSchemaOddsIndex+contentSchemaOdds+contentML+contentOU+contentPS;//+contentStats;
  } else if (location === '/api-docs/endpoints') {
    return contentSchemaLanding;
  } else {
    return contentNotFound;
  }
}

interface DocsProps {
  isMobile: boolean;
};

const Docs = ({
  isMobile
}: DocsProps) => {
  const {theme} = useGetTheme();
  const location = useLocation();

  const MarkdownContainer = styled.div`
    width: 96%;
    //overflow: auto;
    overflow-x: hidden;
    font-family: Avenir Next, Arial, sans-serif !important;
    padding: 0px 0px 42px 0px;
    .landing {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      gap: 2px;
    }
    .endpoint {
      color: ${getThemeData(theme).colorAccent};
      text-decoration: none;
      font-weight: 600;
    }
    .endpoint2 {
      color: ${getThemeData(theme).colorAccent};
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
    }
    .mdrow {
      display: flex;
      flex-direction: row;
      align-items: top;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 6px;
      details > summary {
          list-style-type: '▶️';
      }

      details[open] > summary {
          list-style-type: '🔽';
      }

      details {
          border: 1px solid gray;
          border-radius: 0.2rem;
          padding: 0.5rem;
      }

      details[open] > summary {
          margin-bottom: 0.5rem;
      }
      @media (max-width: 875px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 6px;
      }
    }
    .mdcol {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: top;
      //gap: 22px;
      width: 45%;
      @media (max-width: 875px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
    .title {
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 26px;
      font-weight: 600;
    }
    .title2 {
      color: ${getThemeData(theme).colorAccent};
      text-decoration: none;
      font-size: 26px;
      font-weight: 600;
    }
    .emph {
      font-family: Avenir Next, Arial, sans-serif !important;
      font-weight: 600;
    }
    .emph2 {
      color: ${getThemeData(theme).colorAccent};
      font-family: Avenir Next, Arial, sans-serif !important;
      font-weight: 600;
    }
    .inlinecode-wrapper {
      width: 100%;
      overflow: auto;
    }
    .toptag {
      display; flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 51%;
      @media (max-width: 875px) {
        align-items: center;
        justify-content: center;
        width: 94%;
      }
    }
    .subtitle {
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 20px;
      font-weight: 600;
    }
    .subtitle2 {
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 20px;
      font-weight: 600;
    }
    .subcontent {
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 16px;
    }
    .reqcontent {
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
    }
    .divider1 {
      display: flex;
      flex-direction: row;
      height: 22px;
    }
    .divider2 {
      display: flex;
      flex-direction: row;
      height: 12px;
    }
    .getrequest {
      display; flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid ${theme === 'dark' ? "#303030" : "lightgrey"};
      border-radius: 10px;
      padding: 42px 2%;
      width: 50%;
      @media (max-width: 875px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 22px 2%;
        width: 94%;
      }
    }
    .row1 {
      display: inline-flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      //width: 100%;
      gap: 12px;
      margin-left: 6px;
    }
    .gettag {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding: 4px 8px;
      border-radius: 10px;
      background: ${getThemeData(theme).bgBox1};
      color: ${getThemeData(theme).textColor};
      font-weight: 600;
    }
    .gettitle {
      color: ${getThemeData(theme).textColor};
      font-weight: 600;
      font-size: 18px;
    }
    .gettitle2 {
      color: ${getThemeData(theme).textColor};
      font-weight: 600;
      font-size: 16px;
    }
    .getparams {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      gap: 4px;
    }
    .getresponses {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      gap: 0px;
    }
    .getparamrow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${theme === 'dark' ? "#202020" : "lightgrey"};
      width: 100%;
    }
    .getresponserow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .getparamcontent {
      color: ${getThemeData(theme).textColor};
      font-size: 16px;
    }
    .getresponsecontent1 {
      color: ${theme === 'dark' ? 'lightgreen' : 'green'};
    }
    .getresponsecontent2 {
      color: red;
    }
    .getresponsecontent3 {
      color: ${getThemeData(theme).textColor};
      font-size: 16px;
    }
    .main {
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 20px;
      font-weight: 400;
    }
    .link {
      font-weight: 600;
      text-decoration: none;
      color: ${getThemeData(theme).colorAccent};
    }
    .tip1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      gap: 12px;
      background: ${getThemeData(theme).bgBox1};
      padding: 12px;
      border-radius: 10px;
      border-left: 5px solid ${theme === 'dark' ? 'lightgreen' : 'green'};
      margin: 4px 0;
      width: calc(100% - 29px);
    }
    .tip2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      gap: 12px;
      background: ${getThemeData(theme).bgBox1};
      padding: 12px;
      border-radius: 10px;
      border-left: 5px solid red;
      margin: 4px 0;
      width: 100%;
    }
    .tip3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      gap: 12px;
      background: ${getThemeData(theme).bgBox1};
      padding: 12px;
      border-radius: 10px;
      border-left: 5px solid ${theme === 'dark' ? 'lightblue' : 'blue'};
      margin: 4px 0;
      width: calc(100% - 29px);
      overflow-x: scroll;
    }
    .content {
      background: transparent;
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
    }
    .content2 {
      background: transparent;
      color: ${getThemeData(theme).textColor};
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
    }
    .tip1 .icon {
      color: ${theme === 'dark' ? 'lightgreen' : 'green'};
      font-size: 22px;
    }
    .tip2 .icon {
      color: ${theme === 'dark' ? 'red' : 'red'};
      font-size: 22px;
    }
    .tip3 .icon {
      color: ${theme === 'dark' ? 'lightblue' : 'blue'};
      font-size: 22px;
    }
  `;
  const markdown = getMarkdown(location.pathname.toString(), theme);

  const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .code_hover {
      background: ${getThemeData(theme).bgBox2};
      border-radius: 10px;
      width: 100%;
    }
    .code_hover .icon {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.3s, opacity 0.3s linear;
    }
    .code_hover:hover .icon {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s linear;
    }
  `

  const content = (
    <MarkdownContainer>
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        children={markdown}
        components={{
          code(props) {
            const {children, className, node, ...rest} = props
            const match = /language-(\w+)/.exec(className || '')
            const [showCopySuccess, setShowCopySuccess] = useState(false);
            console.log(match, className)
            return match || className?.includes('inlinecode') ? (
              <Container>
              <div className="code_hover">
                <div className="icon" style={{
                  float: "right",
                  padding: "6px 6px 4px 6px",
                  border: "1px solid #303030",
                  borderRadius: "10px",
                  marginTop: "4px",
                  marginRight: "4px",
                }}>
                  {/*<span style={{color: getThemeData(theme).textColor}}>
                    {match[1]}
                  </span>*/}
                  {!showCopySuccess
                  ? <Copy size={22} color={getThemeData(theme).textColor} cursor={"pointer"} onClick={() => {
                    navigator.clipboard.writeText(String(children).replace(/\n$/, ''));
                    setShowCopySuccess(true)
                    setTimeout(() => {
                      setShowCopySuccess(false)
                    },1000)
                  }}/>
                  : <Check size={22} color={theme === 'dark' ? 'lightgreen' : 'green'} cursor={"pointer"}/>
                  }

                </div>
                <SyntaxHighlighter
                  {...rest}
                  children={String(children).replace(/\n$/, '')}
                  language={match ? match[1] : (className ? className.split('-')[1] : '')}
                  //style={theme === 'dark' ? docco : docco}
                  //style={theme === 'dark' ? darkStyle : lightStyle}
                  //style={theme === 'dark' ? oneDark : oneLight}
                  //style={theme === 'dark' ? solarizedDarkAtom : solarizedlight}
                  style={theme === 'dark' ? darkStyle : lightStyle}
                  customStyle={{
                    background: getThemeData(theme).bgBox2,
                    fontFamily: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New",
                    fontSize: "12px",
                  }}
                  codeTagProps={{style: {
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    outline: "none !important",
                    outlineWidth: "0 !important",
                  }}}
                  wrapLines={true}
                  wrapLongLines={true}
                  showLineNumbers={false}
                  showInlineLineNumbers={false}
                  lineNumberStyle={{color: getThemeData(theme).textColor}}
                />
              </div>
              </Container>
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            )
          }
        }}
      />
    </MarkdownContainer>
  );

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    console.log(href)
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth',});
    }
  }, [window.location.href]);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column", //"row",
      justifyContent: "center", //"left",
      alignItems: "center",
      width: "100%",
      padding: "42px 0px",
    }}>
      {isMobile && <div style={{height: "60px"}}/>}
      <SideMenu isMobile={isMobile} docsPath={location.pathname.toString()}/>
      <div style={{height: "22px"}}/>
      <BodySubContent>
        {content}
      </BodySubContent>
    </div>
  );
}

export default Docs;