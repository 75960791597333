import styled from 'styled-components';
import ReactSlider from 'react-slider';

interface StyledTrackProps {
  index: number;
  value: number | readonly number[];
}

interface StyledThumbProps {
  index: number;
  value: number | readonly number[];
  valueNow: number;
}

interface StyledContainerProps {
  width: string;
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 16px;
`;

const StyledThumb = styled.div<StyledThumbProps>`
  //top: 0;
  //bottom: 0;
  height: 16px;
  width: 16px;
  //text-align: center;
  background-color: #000;
  color: #000;
  //color: #fff;
  border-radius: 50%;
  cursor: grab;
  //font-size: 14px;
  font-size: 0px;
`;

const StyledTrack = styled.div<StyledTrackProps>`
  top: 0;
  bottom: 0;
  border-radius: 999px;
  background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#ddd' : '#0f0')};
`;

const StyledContainer = styled.div<StyledContainerProps>`
  //resize: horizontal;
  //overflow: auto;
  width: ${({width}) => width};
`;

const Thumb = (props:any, state:any) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

const Track = (props:any, state:any) => <StyledTrack {...props} index={state.index} />;

export const getSlider = (val:number, setVal:any, width:string, setHedge:any) => (
    <StyledContainer width={width}>
        <StyledSlider
          value={val}
          step={0.01}
          onBeforeChange={(value, index) =>
            console.log(`onBeforeChange: ${JSON.stringify({ value, index })}`)
          }
          onChange={(value, index) => {
            setVal(value)
            setHedge(undefined)
            //console.log(`onChange: ${JSON.stringify({ value, index })}`)
          }}
          onAfterChange={(value, index) =>
            console.log(`onAfterChange: ${JSON.stringify({ value, index })}`)
          }
          renderTrack={Track}
          renderThumb={Thumb}
        />
    </StyledContainer>
);