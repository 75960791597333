import { useState } from "react";
import styled from "styled-components";
import { Eye, EyeOff } from "react-feather";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";

const ActionButton = styled.button`
  width: fit-content;
  background: linear-gradient(to right, #25adea 0%, #8a53cd 100%);
  color: white;
  padding: 8px 16px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  &:hover {
    filter: brightness(0.8);
  }
`;
export const MainWrapper = styled.div`
  height: calc(100%);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.s ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3dbc;
  z-index: 200;
`;

export const AlterModalWrap = styled.div`
  height: 300px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AlterModalTop = styled.div`
  height: 230px;
  width: 100%;
  background-color: #18191d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const AlterModalBottom = styled.div`
  height: 60px;
  width: 100%;
  background-color: #18191d;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 12px 0px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: #d8d8d8;
  padding: 12px 0px;
`;

const InputAlt = styled.input`
  text-align: left;
  background-color: transparent !important;
  white-space: nowrap !important;
  border: transparent !important;
  transition: all 0.3s ease-out;
  padding: 11px 13px;
  width: 100%;
  color: white;
  &:focus {
    border: transparent !important;
    outline: none !important;
  }
`;

export const InputCustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 1px solid #303030;
  border-radius: 6px;
  margin-bottom: 0.9rem;
  max-width: 100%;
`

const ConfirmModal = ({
  showActive,
  setShowActive,
  actionName,
  handleAction,
}) => {
  const [password, setPassword] = useState();
  const [showPassowrd, setShowPassword] = useState(false);
  const {theme} = useGetTheme();

  const exitDetailHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      setShowActive();
    }
  };

  return (
    <MainWrapper
      onClick={(e) => {
        exitDetailHandler(e);
      }}
      className="shadow"
      s={showActive}
    >
      <AlterModalWrap>
        <AlterModalTop>
          <TextContainer>
            <div style={{padding: "22px"}}>
              <Title>{"Confirm your password"}</Title>
              <Description>{"For your security, please confirm your password."}</Description>
              <InputCustomContainer>
                <InputAlt
                  type={showPassowrd ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(event) => {
                    event.preventDefault();
                    setPassword(event.target.value)
                  }}
                  placeholder='Password'
                />
                {showPassowrd
                ? <Eye size={18} cursor={"pointer"} color={getThemeData(theme).colorAccent} onClick={() => {setShowPassword(false)}}/>
                : <EyeOff size={18} cursor={"pointer"} color={getThemeData(theme).colorAccent} onClick={() => {setShowPassword(true)}}/>
                }
                <div style={{width: "12px"}}/>
              </InputCustomContainer>
              <ActionButton
                style={{
                  background: "linear-gradient(to right, #25adea 0%, #a151ff 100%)",
                  color: "black",
                  fontWeight: 600,
                }}
                onClick={() => {
                  handleAction(password)
                }}
              >
                {actionName}
              </ActionButton>
            </div>
          </TextContainer>
        </AlterModalTop>
      </AlterModalWrap>
    </MainWrapper>
  );
};

export default ConfirmModal;