import { Fragment } from "react";
import { ModalBlock, ModalOverlay } from "../../styles";
import Search from "../Search";

interface QueryProps {
  isMobile: boolean;
  isQuery: boolean;
  setIsQuery: any;
  isMacLike: boolean;
  isIOS: boolean;
  showSR: boolean;
  setShowSR: any;
  setLoadingNLUPrediction: any;
};

const Query = ({
  isMobile,
  isQuery,
  setIsQuery,
  isMacLike,
  isIOS,
  showSR,
  setShowSR,
  setLoadingNLUPrediction,
}: QueryProps) => {
  return (
    <Fragment>
      {isQuery && (
      <ModalBlock>
        <ModalOverlay onClick={() => setIsQuery(false)}/>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "fit-content", zIndex: 1}}>
          <Search
            isMacLike={isMacLike}
            isIOS={isIOS}
            setIsQuery={setIsQuery}
            isMobile={isMobile}
            showSR={showSR}
            setShowSR={setShowSR}
            isQuery={isQuery}
            setLoadingNLUPrediction={setLoadingNLUPrediction}
          />
        </div>
      </ModalBlock>
      )}
    </Fragment>
  )
}

export default Query;