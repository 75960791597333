export const teamToLeagueAndIdMap = {
  "Washington-Football-Team": ['nfl', 'Washington'],
  "Brighton-and-Hove-Albion": ['epl', 'Brighton'],
  "Borussia-Monchengladbach": ['bun', "M'Gladbach"],
  "EA7-Emporio-Armani-Milan": ['eurol', 'EA7-Emporio-Armani-Milan'],
  "Virtus-Segafredo-Bologna": ['eurol', 'Virtus-Segafredo-Bologna'],
  "Wolverhampton-Wanderers": ['epl', 'Wolves'],
  "Minnesota-Timberwolves": ['nba', 'Minnesota'],
  "Portland-Trail-Blazers": ['nba', 'Portland'],
  "Brighton-&-Hove-Albion": ['epl', 'Brighton'],
  "New-England-Revolution": ['mls', 'New-England'],
  "Vancouver-Whitecaps-FC": ['mls', 'Vancouver'],
  "Columbus-Blue-Jackets": ['nhl', 'Columbus'],
  "Washington-Commanders": ['nfl', 'Washington'],
  "Oklahoma-City-Thunder": ['nba', 'Oklahoma'],
  "Portland-Trailblazers": ['nba', 'Portland'],
  "Golden-State-Warriors": ['nba', 'Golden-State'],
  "Nashville-Soccer-Club": ['mls', 'Nashville'],
  "Philadelphia-Phillies": ['mlb', 'Philadelphia-Phillies'],
  "Vegas-Golden-Knights": ['nhl', 'Vegas'],
  "Jacksonville-Jaguars": ['nfl', 'Jacksonville'],
  "Los-Angeles-Chargers": ['nfl', 'LA-Chargers'],
  "New-England-Patriots": ['nfl', 'New-England'],
  "Tampa-Bay-Buccaneers": ['nfl', 'Tampa-Bay'],
  "New-Orleans-Pelicans": ['nba', 'New-Orleans'],
  "Los-Angeles-Clippers": ['nba', 'LA-Clippers'],
  "San-Jose-Earthquakes": ['mls', 'San-Jose'],
  "Sporting-Kansas-City": ['mls', 'Sporting-KC'],
  "KF-Ballkani-Therandë": ['cl', 'KF-Ballkani-Therande'],
  "KF-Ballkani-Therande": ['cl', 'KF-Ballkani-Therande'],
  "Arizona-Diamondbacks": ['mlb', 'Arizona-Diamondbacks'],
  "San-Francisco-Giants": ['mlb', 'San-Francisco-Giants'],
  "Washington-Nationals": ['mlb', 'Washington-Nationals'],
  "Partizan-Mozzart-Bet": ['eurol', 'Partizan-Mozzart-Bet'],
  "Carolina-Hurricanes": ['nhl', 'Carolina'],
  "Nashville-Predators": ['nhl', 'Nashville'],
  "Philadelphia-Flyers": ['nhl', 'Philadelphia'],
  "Pittsburgh-Penguins": ['nhl', 'Pittsburgh'],
  "Tampa-Bay-Lightning": ['nhl', 'Tampa-Bay'],
  "Toronto-Maple-Leafs": ['nhl', 'Toronto'],
  "Washington-Capitals": ['nhl', 'Washington'],
  "Philadelphia-Eagles": ['nfl', 'Philadelphia'],
  "San-Francisco-49ers": ['nfl', 'San-Francisco'],
  "Pittsburgh-Steelers": ['nfl', 'Pittsburgh'],
  "Washington-Redskins": ['nfl', 'Washington'],
  "Cleveland-Cavaliers": ['nba', 'Cleveland'],
  "Vancouver-Whitecaps": ['mls', 'Vancouver'],
  "Minnesota-United-FC": ['mls', 'Minnesota-Utd'],
  "Seattle-Sounders-FC": ['mls', 'Seattle'],
  "Saint-Louis-City-SC": ['mls', 'St-Louis'],
  "Paris-Saint-Germain": ['cl', 'Paris'],
  "Paris-Saint-Germain": ['cl', 'Paris'],
  "Eintracht-Frankfurt": ['bun', 'Eint-Frankfurt'],
  "Olympique-Marseille": ['lig', 'Marseille'],
  "Cleveland-Guardians": ['mlb', 'Cleveland-Guardians'],
  "Los-Angeles-Dodgers": ['mlb', 'Los-Angeles-Dodgers'],
  "St.-Louis-Cardinals": ['mlb', 'St-Louis-Cardinals'],
  "Chicago-Blackhawks": ['nhl', 'Chicago'],
  "Colorado-Avalanche": ['nhl', 'Colorado'],
  "Montreal-Canadiens": ['nhl', 'Montreal'],
  "New-York-Islanders": ['nhl', 'NY-Islanders'],
  "VGS-Golden-Knights": ['nhl', 'Vegas'],
  "Cincinnati-Bengals": ['nfl', 'Cincinnati'],
  "Indianapolis-Colts": ['nfl', 'Indianapolis'],
  "Kansas-City-Chiefs": ['nfl', 'Kansas-City'],
  "New-Orleans-Saints": ['nfl', 'New-Orleans'],
  "Washington-Wizards": ['nba', 'Washington'],
  "Philadelphia-76ers": ['nba', 'Philadelphia'],
  "Los-Angeles-Lakers": ['nba', 'LA-Lakers'],
  "Philadelphia-Union": ['mls', 'Philadelphia'],
  "New-York-Red-Bulls": ['mls', 'NY-Red-Bulls'],
  "Los-Angeles-Galaxy": ['mls', 'LA-Galaxy'],
  "Olympique-Lyonnais": ['lig', 'Lyon'],
  "Kansas-City-Royals": ['mlb', 'Kansas-City-Royals'],
  "Los-Angeles-Angels": ['mlb', 'Los-Angeles-Angels'],
  "Pittsburgh-Pirates": ['mlb', 'Pittsburgh-Pirates'],
  "Los-Angeles-Sparks": ['wnba', 'Los-Angeles-Sparks'],
  "Washington-Mystics": ['wnba', 'Washington-Mystics'],
  "Detroit-Red-Wings": ['nhl', 'Detroit'],
  "Los-Angeles-Kings": ['nhl', 'Los-Angeles'],
  "New-Jersey-Devils": ['nhl', 'New-Jersey'],
  "Vancouver-Canucks": ['nhl', 'Vancouver'],
  "Arizona-Cardinals": ['nfl', 'Arizona'],
  "Carolina-Panthers": ['nfl', 'Carolina'],
  "Green-Bay-Packers": ['nfl', 'Green-Bay'],
  "Minnesota-Vikings": ['nfl', 'Minnesota'],
  "Las-Vegas-Raiders": ['nfl', 'Las-Vegas'],
  "Charlotte-Hornets": ['nba', 'Charlotte'],
  "Memphis-Grizzlies": ['nba', 'Memphis'],
  "San-Antonio-Spurs": ['nba', 'San-Antonio'],
  "POR-Trail-Blazers": ['nba', 'Portland'],
  "Tottenham-Hotspur": ['epl', 'Tottenham'],
  "Nottingham-Forest": ['epl', 'Nottingham-Forest'],
  "Manchester-United": ['epl', 'Manchester-Utd'],
  "St.-Louis-City-SC": ['mls', 'St-Louis'],
  "St.-Louis-CITY-SC": ['mls', 'St-Louis'],
  "Atlanta-United-FC": ['mls', 'Atlanta-Utd'],
  "Borussia-Dortmund": ['cl', 'Dortmund'],
  "Slovan-Bratislava": ['cl', 'Slovan-Bratislava'],
  "Stade-Brestois-29": ['lig', 'Brest'],
  "Baltimore-Orioles": ['mlb', 'Baltimore-Orioles'],
  "Chicago-White-Sox": ['mlb', 'Chicago-White-Sox'],
  "Oakland-Athletics": ['mlb', 'Oakland-Athletics'],
  "Toronto-Blue-Jays": ['mlb', 'Toronto-Blue-Jays'],
  "Milwaukee-Brewers": ['mlb', 'Milwaukee-Brewers'],
  "Crvena-zvezda-mts": ['eurol', 'Crvena-zvezda-mts'],
  "Florida-Panthers": ['nhl', 'Florida'],
  "New-York-Rangers": ['nhl', 'NY-Rangers'],
  "CBJ-Blue-Jackets": ['nhl', 'Columbus'],
  "Baltimore-Ravens": ['nfl', 'Baltimore'],
  "Cleveland-Browns": ['nfl', 'Cleveland'],
  "Los-Angeles-Rams": ['nfl', 'LA-Rams'],
  "Seattle-Seahawks": ['nfl', 'Seattle'],
  "Tennessee-Titans": ['nfl', 'Tennessee'],
  "Sacramento-Kings": ['nba', 'Sacramento'],
  "Dallas-Mavericks": ['nba', 'Dallas'],
  "MIN-Timberwolves": ['nba', 'Minnesota'],
  "Newcastle-United": ['epl', 'Newcastle'],
  "Deportivo-Alaves": ['laliga', 'Alaves'],
  "Portland-Timbers": ['mls', 'Portland-Timbers'],
  "Minnesota-United": ['mls', 'Minnesota-Utd'],
  "Seattle-Sounders": ['mls', 'Seattle'],
  "Columbus-Crew-SC": ['mls', 'Columbus-Crew'],
  "New-York-City-FC": ['mls', 'NYCFC'],
  "VAN-Whitecaps-FC": ['mls', 'Vancouver'],
  "Sheriff-Tiraspol": ['cl', 'Sheriff-Tiraspol'],
  "Inter-d'Escaldes": ['cl', "Inter-d'Escaldes"],
  "Hellas-Verona-FC": ['sa', 'Hellas-Verona'],
  "Frosinone-Calcio": ['sa', 'Fiorentina'],
  "Bayer-Leverkusen": ['eurl', 'Leverkusen'],
  "Colorado-Rockies": ['mlb', 'Colorado-Rockies'],
  "Milwaukee-Braves": ['mlb', 'Milwaukee-Brewers'],
  "New-York-Yankees": ['mlb', 'New-York-Yankees'],
  "San-Diego-Padres": ['mlb', 'San-Diego-Padres'],
  "Seattle-Mariners": ['mlb', 'Seattle-Mariners'],
  "ARI-Diamondbacks": ['mlb', 'Arizona-Diamondbacks'],
  "Maccabi-Tel-Aviv": ['eurol', 'Maccabi-Tel-Aviv'],
  "Fenerbahce-Ulker": ['eurol', 'Fenerbahce-Beko'],
  "New-York-Liberty": ['wnba', 'New-York-Liberty'],
  "Arizona-Coyotes": ['nhl', 'Arizona'],
  "Edmonton-Oilers": ['nhl', 'Edmonton'],
  "Ottawa-Senators": ['nhl', 'Ottawa'],
  "San-Jose-Sharks": ['nhl', 'San-Jose'],
  "St.-Louis-Blues": ['nhl', 'St-Louis'],
  "TOR-Maple-Leafs": ['nhl', 'Toronto'],
  "Atlanta-Falcons": ['nfl', 'Atlanta'],
  "Oakland-Raiders": ['nfl', 'Las-Vegas'],
  "New-York-Giants": ['nfl', 'NY-Giants'],
  "Detroit-Pistons": ['nba', 'Detroit'],
  "Toronto-Raptors": ['nba', 'Toronto'],
  "New-York-Knicks": ['nba', 'New-York'],
  "Houston-Rockets": ['nba', 'Houston'],
  "Milwaukee-Bucks": ['nba', 'Milwaukee'],
  "Manchester-City": ['epl', 'Manchester-City'],
  "West-Ham-United": ['epl', 'West-Ham'],
  "Nott'ham-Forest": ['epl', 'Nottingham-Forest'],
  "AFC-Bournemouth": ['epl', 'Bournemouth'],
  "Athletic-Bilbao": ['laliga', 'Athletic-Club'],
  "Atlético-Madrid": ['laliga', 'Atletico-Madrid'],
  "Atletico-Madrid": ['laliga', 'Atletico-Madrid'],
  "Colorado-Rapids": ['mls', 'Colorado-Rapids'],
  "Orlando-City-SC": ['mls', 'Orlando-City'],
  "Sporting-Lisboa": ['cl', 'Sporting-CP'],
  "Sutjeska-Nikšić": ['cl', 'Sutjeska-Niksic'],
  "Zrinjski-Mostar": ['cl', 'Zrinjski-Mostar'],
  "Levadia-Tallinn": ['cl', 'Levadia-Tallinn'],
  "Sutjeska-Niksic": ['cl', 'Sutjeska-Niksic'],
  "Cagliari-Calcio": ['sa', 'Cagliari'],
  "SV-Darmstadt-98": ['bun', 'Darmstadt'],
  "Montpellier-HSC": ['lig', 'Montpellier'],
  "Arizona-D'Backs": ['mlb', 'Arizona-Diamondbacks'],
  "Cincinnati-Reds": ['mlb', 'Cincinnati-Reds'],
  "Minnesota-Twins": ['mlb', 'Minnesota-Twins'],
  "National-League": ['mlb', 'National-League'],
  "American-League": ['mlb', 'American-League'],
  "Fenerbahçe-Beko": ['eurol', 'Fenerbahce-Beko'],
  "Valencia-Basket": ['eurol', 'Valencia-Basket'],
  "Fenerbahce-Beko": ['eurol', 'Fenerbahce-Beko'],
  "Connecticut-Sun": ['wnba', 'Connecticut-Sun'],
  "Phoenix-Mercury": ['wnba', 'Phoenix-Mercury'],
  "Buffalo-Sabres": ['nhl', 'Buffalo'],
  "Calgary-Flames": ['nhl', 'Calgary'],
  "Minnesota-Wild": ['nhl', 'Minnesota'],
  "Seattle-Kraken": ['nhl', 'Seattle'],
  "CAR-Hurricanes": ['nhl', 'Carolina'],
  "CHI-Blackhawks": ['nhl', 'Chicago'],
  "Golden-Knights": ['nhl', 'Vegas'],
  "N.Y.-Islanders": ['nhl', 'NY-Islanders'],
  "Dallas-Cowboys": ['nfl', 'Dallas'],
  "Denver-Broncos": ['nfl', 'Denver'],
  "Houston-Texans": ['nfl', 'Houston'],
  "Miami-Dolphins": ['nfl', 'Miami'],
  "Indiana-Pacers": ['nba', 'Indiana'],
  "Boston-Celtics": ['nba', 'Boston'],
  "Denver-Nuggets": ['nba', 'Denver'],
  "Crystal-Palace": ['epl', 'Crystal-Palace'],
  "Manchester-Utd": ['epl', 'Manchester-Utd'],
  "Leicester-City": ['epl', 'Leicester'],
  "Rayo-Vallecano": ['laliga', 'Rayo-Vallecano'],
  "Houston-Dynamo": ['mls', 'Houston-Dynamo'],
  "Real-Salt-Lake": ['mls', 'Real-Salt-Lake'],
  "Los-Angeles-FC": ['mls', 'Los-Angeles-FC'],
  "Atlanta-United": ['mls', 'Atlanta-Utd'],
  "St.-Louis-City": ['mls', 'St-Louis'],
  "Inter-Miami-CF": ['mls', 'Inter-Miami'],
  "Eint-Frankfurt": ['cl', 'Eint-Frankfurt'],
  "Viktoria-Plzeň": ['cl', 'Viktoria-Plzen'],
  "The-New-Saints": ['cl', 'The-New-Saints'],
  "Tobol-Qostanaı": ['cl', 'Tobol-Qostanai'],
  "Bayern-Munchen": ['cl', 'Bayern-Munchen'],
  "Viktoria-Plzen": ['cl', 'Viktoria-Plzen'],
  "Tobol-Qostanai": ['cl', 'Tobol-Qostanai'],
  "Stade-Brestois": ['lig', 'Brest'],
  "Atlanta-Braves": ['mlb', 'Atlanta-Braves'],
  "Boston-Red-Sox": ['mlb', 'Boston-Red-Sox'],
  "Detroit-Tigers": ['mlb', 'Detroit-Tigers'],
  "Houston-Astros": ['mlb', 'Houston-Astros'],
  "Tampa-Bay-Rays": ['mlb', 'Tampa-Bay-Rays'],
  "Chi.-White-Sox": ['mlb', 'Chicago-White-Sox'],
  "Cazoo-Baskonia": ['eurol', 'Cazoo-Baskonia'],
  "Bayern-München": ['eurol', 'Bayern-Munchen'],
  "Saski-Baskonia": ['eurol', 'Cazoo-Baskonia'],
  "Olimpia-Milano": ['eurol', 'EA7-Emporio-Armani-Milan'],
  "Virtus-Bologna": ['eurol', 'Virtus-Segafredo-Bologna'],
  "Minnesota-Lynx": ['wnba', 'Minnesota-Lynx'],
  "Las-Vegas-Aces": ['wnba', 'Las-Vegas-Aces'],
  "Anaheim-Ducks": ['nhl', 'Anaheim'],
  "Boston-Bruins": ['nhl', 'Boston'],
  "Winnipeg-Jets": ['nhl', 'Winnipeg'],
  "NSH-Predators": ['nhl', 'Nashville'],
  "DET-Red-Wings": ['nhl', 'Detroit'],
  "MTL-Canadiens": ['nhl', 'Montreal'],
  "COL-Avalanche": ['nhl', 'Colorado'],
  "Buffalo-Bills": ['nfl', 'Buffalo'],
  "Chicago-Bears": ['nfl', 'Chicago'],
  "Detroit-Lions": ['nfl', 'Detroit'],
  "New-York-Jets": ['nfl', 'NY-Jets'],
  "L.A.-Chargers": ['nfl', 'LA-Chargers'],
  "San-Francisco": ['nfl', 'San-Francisco'],
  "Oklahoma-City": ['nba', 'Oklahoma'],
  "Chicago-Bulls": ['nba', 'Chicago'],
  "MEM-Grizzlies": ['nba', 'Memphis'],
  "Orlando-Magic": ['nba', 'Orlando'],
  "DAL-Mavericks": ['nba', 'Dallas'],
  "Atlanta-Hawks": ['nba', 'Atlanta'],
  "Brooklyn-Nets": ['nba', 'Brooklyn'],
  "CLE-Cavaliers": ['nba', 'Cleveland'],
  "L.A.-Clippers": ['nba', 'LA-Clippers'],
  "Wolverhampton": ['epl', 'Wolves'],
  "Newcastle-Utd": ['epl', 'Newcastle'],
  "Villarreal-CF": ['laliga', 'Villarreal'],
  "Athletic-Club": ['laliga', 'Athletic-Club'],
  "Real-Sociedad": ['laliga', 'Real-Sociedad'],
  "Real-Mallorca": ['laliga', 'Mallorca'],
  "Celta-de-Vigo": ['laliga', 'Celta-Vigo'],
  "UD-Las-Palmas": ['laliga', 'Las-Palmas'],
  "FC-Cincinnati": ['mls', 'FC-Cincinnati'],
  "Minnesota-Utd": ['mls', 'Minnesota-Utd'],
  "Columbus-Crew": ['mls', 'Columbus-Crew'],
  "New-York-City": ['mls', 'NYCFC'],
  "NE-Revolution": ['mls', 'New-England'],
  "Dinamo-Zagreb": ['cl', 'Dinamo-Zagreb'],
  "FC-Copenhagen": ['cl', 'FC-Copenhagen'],
  "Maccabi-Haifa": ['cl', 'Maccabi-Haifa'],
  "Bayern-Munich": ['cl', 'Bayern-Munich'],
  "Qarabağ-Ağdam": ['cl', 'Qarabag-Agdam'],
  "Shkupi-Skopje": ['cl', 'Shkupi-Skopje'],
  "F91-Dudelange": ['cl', 'F91-Dudelange'],
  "Dinamo-Batumi": ['cl', 'Dinamo-Batumi'],
  "PSV-Eindhoven": ['cl', 'PSV-Eindhoven'],
  "Qarabag-Agdam": ['cl', 'Qarabag-Agdam'],
  "Hellas-Verona": ['sa', 'Hellas-Verona'],
  "Sparta-Prague": ['eurl', 'Sparta-Prague'],
  "Hertha-Berlin": ['bun', 'Hertha-BSC'],
  "Werder-Bremen": ['bun', 'Werder-Bremen'],
  "VfB-Stuttgart": ['bun', 'Stuttgart'],
  "FC-Heidenheim": ['bun', 'Heidenheim'],
  "Clermont-Foot": ['lig', 'Clermont-Foot'],
  "RC-Strasbourg": ['lig', 'Strasbourg'],
  "Stade-Rennais": ['lig', 'Rennes'],
  "Miami-Marlins": ['mlb', 'Miami-Marlins'],
  "New-York-Mets": ['mlb', 'New-York-Mets'],
  "Texas-Rangers": ['mlb', 'Texas-Rangers'],
  "TOR-Blue-Jays": ['mlb', 'Toronto-Blue-Jays'],
  "CHI-White-Sox": ['mlb', 'Chicago-White-Sox'],
  "WAS-Nationals": ['mlb', 'Washington-Nationals'],
  "CLE-Guardians": ['mlb', 'Cleveland-Guardians'],
  "STL-Cardinals": ['mlb', 'St-Louis-Cardinals'],
  "OAK-Athletics": ['mlb', 'Oakland-Athletics'],
  "Panathinaikos": ['eurol', 'Panathinaikos'],
  "Crvena-zvezda": ['eurol', 'Crvena-zvezda-mts'],
  "Atlanta-Dream": ['wnba', 'Atlanta-Dream'],
  "Indiana-Fever": ['wnba', 'Indiana-Fever'],
  "Seattle-Storm": ['wnba', 'Seattle-Storm'],
  "Dallas-Stars": ['nhl', 'Dallas'],
  "FLA-Panthers": ['nhl', 'Florida'],
  "TB-Lightning": ['nhl', 'Tampa-Bay'],
  "NY-Islanders": ['nhl', 'NY-Islanders'],
  "PIT-Penguins": ['nhl', 'Pittsburgh'],
  "OTT-Senators": ['nhl', 'Ottawa'],
  "Blue-Jackets": ['nhl', 'Columbus'],
  "N.Y.-Rangers": ['nhl', 'NY-Rangers'],
  "Philadelphia": ['nhl', 'Philadelphia'],
  "Indianapolis": ['nfl', 'Indianapolis'],
  "Jacksonville": ['nfl', 'Jacksonville'],
  "Timberwolves": ['nba', 'Minnesota'],
  "Phoenix-Suns": ['nba', 'Phoenix'],
  "Golden-State": ['nba', 'Golden-State'],
  "Leeds-United": ['epl', 'Leeds'],
  "Liverpool-FC": ['epl', 'Liverpool'],
  "FC-Barcelona": ['laliga', 'Barcelona'],
  "Orlando-City": ['mls', 'Orlando-City'],
  "NY-Red-Bulls": ['mls', 'NY-Red-Bulls'],
  "Chicago-Fire": ['mls', 'Chicago-Fire'],
  "Nashville-SC": ['mls', 'Nashville'],
  "Charlotte-FC": ['mls', 'Charlotte'],
  "Shamrock-Rov": ['cl', 'Shamrock-Rov'],
  "St.-Gilloise": ['cl', 'Union-SG'],
  "Union-Berlin": ['eurl', 'Union-Berlin'],
  "Stade-Rennes": ['eurl', 'Rennes'],
  "Darmstadt-98": ['bun', 'Darmstadt'],
  "Chicago-Cubs": ['mlb', 'Chicago-Cubs'],
  "PHI-Phillies": ['mlb', 'Philadelphia-Phillies'],
  "Diamondbacks": ['mlb', 'Arizona-Diamondbacks'],
  "SEA-Mariners": ['mlb', 'Seattle-Mariners'],
  "Anadolu-Efes": ['eurol', 'Anadolu-Efes'],
  "Dallas-Wings": ['wnba', 'Dallas-Wings'],
  "ARI-Coyotes": ['nhl', 'Arizona'],
  "VAN-Canucks": ['nhl', 'Vancouver'],
  "Maple-Leafs": ['nhl', 'Toronto'],
  "Los-Angeles": ['nhl', 'Los-Angeles'],
  "Kansas-City": ['nfl', 'Kansas-City'],
  "New-Orleans": ['nfl', 'New-Orleans'],
  "New-England": ['nfl', 'New-England'],
  "N.Y.-Giants": ['nfl', 'NY-Giants'],
  "LA-Clippers": ['nba', 'LA-Clippers'],
  "WAS-Wizards": ['nba', 'Washington'],
  "BOS-Celtics": ['nba', 'Boston'],
  "DEN-Nuggets": ['nba', 'Denver'],
  "GS-Warriors": ['nba', 'Golden-State'],
  "DET-Pistons": ['nba', 'Detroit'],
  "HOU-Rockets": ['nba', 'Houston'],
  "CHA-Hornets": ['nba', 'Charlotte'],
  "NO-Pelicans": ['nba', 'New-Orleans'],
  "TOR-Raptors": ['nba', 'Toronto'],
  "OKC-Thunder": ['nba', 'Oklahoma'],
  "L.A.-Lakers": ['nba', 'LA-Lakers'],
  "San-Antonio": ['nba', 'San-Antonio'],
  "Aston-Villa": ['epl', 'Aston-Villa'],
  "Southampton": ['epl', 'Southampton'],
  "Bournemouth": ['epl', 'Bournemouth'],
  "Real-Madrid": ['laliga', 'Real-Madrid'],
  "Atlanta-Utd": ['mls', 'Atlanta-Utd'],
  "D.C.-United": ['mls', 'DC-United'],
  "Inter-Miami": ['mls', 'Inter-Miami'],
  "CF-Montréal": ['mls', 'CF-Montreal'],
  "Sporting-KC": ['mls', 'Sporting-KC'],
  "New-York-RB": ['mls', 'NY-Red-Bulls'],
  "ORL-City-SC": ['mls', 'Orlando-City'],
  "CF-Montreal": ['mls', 'CF-Montreal'],
  "RB-Salzburg": ['cl', 'RB-Salzburg'],
  "Sporting-CP": ['cl', 'Sporting-CP'],
  "Club-Brugge": ['cl', 'Club-Brugge'],
  "Lech-Poznań": ['cl', 'Lech-Poznan'],
  "Ferencváros": ['cl', 'Ferencvaros'],
  "FK-Rīgas-FS": ['cl', 'FK-Rigas-FS'],
  "AÉK-Lárnaka": ['cl', 'AEK-Larnaka'],
  "Dynamo-Kyiv": ['cl', 'Dynamo-Kyiv'],
  "Trabzonspor": ['cl', 'Trabzonspor'],
  "Midtjylland": ['cl', 'Midtjylland'],
  "Lech-Poznan": ['cl', 'Lech-Poznan'],
  "Ferencvaros": ['cl', 'Ferencvaros'],
  "FK-Rigas-FS": ['cl', 'FK-Rigas-FS'],
  "AEK-Larnaka": ['cl', 'AEK-Larnaka'],
  "Atalanta-BC": ['sa', 'Atalanta'],
  "Inter-Milan": ['sa', 'Inter'],
  "Salernitana": ['sa', 'Salernitana'],
  "Juventus-FC": ['sa', 'Juventus'],
  "FC-Augsburg": ['bun', 'Augsburg'],
  "Montpellier": ['lig', 'Montpellier'],
  "Le-Havre-AC": ['lig', 'Le-Havre'],
  "Toulouse-FC": ['lig', 'Toulouse'],
  "Stade-Reims": ['lig', 'Reims'],
  "BAL-Orioles": ['mlb', 'Baltimore-Orioles'],
  "BOS-Red-Sox": ['mlb', 'Boston-Red-Sox'],
  "MIA-Marlins": ['mlb', 'Miami-Marlins'],
  "COL-Rockies": ['mlb', 'Colorado-Rockies'],
  "ARI-D-backs": ['mlb', 'Arizona-Diamondbacks'],
  "TEX-Rangers": ['mlb', 'Texas-Rangers'],
  "PIT-Pirates": ['mlb', 'Pittsburgh-Pirates'],
  "MIL-Brewers": ['mlb', 'Milwaukee-Brewers'],
  "Alba-Berlin": ['eurol', 'Alba-Berlin'],
  "Chicago-Sky": ['wnba', 'Chicago-Sky'],
  "Connecticut": ['wnba', 'Connecticut-Sun'],
  "WAS-Mystics": ['wnba', 'Washington-Mystics'],
  "PHO-Mercury": ['wnba', 'Phoenix-Mercury'],
  "NY-Rangers": ['nhl', 'NY-Rangers'],
  "SEA-Kraken": ['nhl', 'Seattle'],
  "CGY-Flames": ['nhl', 'Calgary'],
  "BUF-Sabres": ['nhl', 'Buffalo'],
  "BOS-Bruins": ['nhl', 'Boston'],
  "PHI-Flyers": ['nhl', 'Philadelphia'],
  "EDM-Oilers": ['nhl', 'Edmonton'],
  "Hurricanes": ['nhl', 'Carolina'],
  "Blackhawks": ['nhl', 'Chicago'],
  "New-Jersey": ['nhl', 'New-Jersey'],
  "Pittsburgh": ['nhl', 'Pittsburgh'],
  "Washington": ['nhl', 'Washington'],
  "Commanders": ['nfl', 'Washington'],
  "Cincinnati": ['nfl', 'Cincinnati'],
  "Miami-Heat": ['nba', 'Miami'],
  "IND-Pacers": ['nba', 'Indiana'],
  "Sacramento": ['nba', 'Sacramento'],
  "Arsenal-FC": ['epl', 'Arsenal'],
  "Burnley-FC": ['epl', 'Burnley'],
  "Chelsea-FC": ['epl', 'Chelsea'],
  "Celta-Vigo": ['laliga', 'Celta-Vigo'],
  "Valladolid": ['laliga', 'Valladolid'],
  "Villarreal": ['laliga', 'Villarreal'],
  "Real-Betis": ['laliga', 'Betis'],
  "Las-Palmas": ['laliga', 'Las-Palmas'],
  "Granada-CF": ['laliga', 'Granada'],
  "Ca-Osasuna": ['laliga', 'Osasuna'],
  "Toronto-FC": ['mls', 'Toronto-FC'],
  "HOU-Dynamo": ['mls', 'Houston-Dynamo'],
  "RB-Leipzig": ['cl', 'RB-Leipzig'],
  "Leverkusen": ['cl', 'Leverkusen'],
  "Hibernians": ['cl', 'Hibernians'],
  "Ludogorets": ['cl', 'Ludogorets'],
  "Bodø/Glimt": ['cl', 'Bodo/Glimt'],
  "La-Fiorita": ['cl', 'La-Fiorita'],
  "Sturm-Graz": ['cl', 'Sturm-Graz'],
  "Fenerbahçe": ['cl', 'Fenerbahce'],
  "Olympiacos": ['cl', 'Olympiacos'],
  "Bodo/Glimt": ['cl', 'Bodo/Glimt'],
  "Fenerbahce": ['cl', 'Fenerbahce'],
  "Fiorentina": ['sa', 'Fiorentina'],
  "SSC-Napoli": ['sa', 'Napoli'],
  "Bologna-FC": ['sa', 'Bologna'],
  "Hertha-BSC": ['bun', 'Hertha-BSC'],
  "Hoffenheim": ['bun', 'Hoffenheim'],
  "FC-Cologne": ['bun', 'Koln'],
  "M'Gladbach": ['bun', "M'Gladbach"],
  "Schalke-04": ['bun', 'Schalke'],
  "Heidenheim": ['bun', 'Heidenheim'],
  "Strasbourg": ['lig', 'Strasbourg'],
  "FC-Lorient": ['lig', 'Lorient'],
  "LA-Dodgers": ['mlb', 'Los-Angeles-Dodgers'],
  "NY-Yankees": ['mlb', 'New-York-Yankees'],
  "HOU-Astros": ['mlb', 'Houston-Astros'],
  "ATL-Braves": ['mlb', 'Atlanta-Braves'],
  "LDLC-ASVEL": ['eurol', 'LDLC-ASVEL'],
  "Asvel-Lyon": ['eurol', 'LDLC-ASVEL'],
  "Olympiakos": ['eurol', 'Olympiacos'],
  "NY-Liberty": ['wnba', 'New-York-Liberty'],
  "SJ-Sharks": ['nhl', 'San-Jose'],
  "NJ-Devils": ['nhl', 'New-Jersey'],
  "DAL-Stars": ['nhl', 'Dallas'],
  "STL-Blues": ['nhl', 'St-Louis'],
  "Predators": ['nhl', 'Nashville'],
  "Lightning": ['nhl', 'Tampa-Bay'],
  "Islanders": ['nhl', 'NY-Islanders'],
  "Red-Wings": ['nhl', 'Detroit'],
  "Canadiens": ['nhl', 'Montreal'],
  "Avalanche": ['nhl', 'Colorado'],
  "Minnesota": ['nhl', 'Minnesota'],
  "Nashville": ['nhl', 'Nashville'],
  "St.-Louis": ['nhl', 'St-Louis'],
  "Tampa-Bay": ['nhl', 'Tampa-Bay'],
  "Vancouver": ['nhl', 'Vancouver'],
  "Cardinals": ['nfl', 'Arizona'],
  "Baltimore": ['nfl', 'Baltimore'],
  "Cleveland": ['nfl', 'Cleveland'],
  "Green-Bay": ['nfl', 'Green-Bay'],
  "L.A.-Rams": ['nfl', 'LA-Rams'],
  "Las-Vegas": ['nfl', 'Las-Vegas'],
  "N.Y.-Jets": ['nfl', 'NY-Jets'],
  "Tennessee": ['nfl', 'Tennessee'],
  "LA-Lakers": ['nba', 'LA-Lakers'],
  "NY-Knicks": ['nba', 'New-York'],
  "Cavaliers": ['nba', 'Cleveland'],
  "PHI-76ers": ['nba', 'Philadelphia'],
  "Utah-Jazz": ['nba', 'Utah'],
  "Mavericks": ['nba', 'Dallas'],
  "MIL-Bucks": ['nba', 'Milwaukee'],
  "Milwaukee": ['nba', 'Milwaukee'],
  "CHI-Bulls": ['nba', 'Chicago'],
  "ATL-Hawks": ['nba', 'Atlanta'],
  "SAC-Kings": ['nba', 'Sacramento'],
  "ORL-Magic": ['nba', 'Orlando'],
  "Charlotte": ['nba', 'Charlotte'],
  "Newcastle": ['epl', 'Newcastle'],
  "Tottenham": ['epl', 'Tottenham'],
  "Brentford": ['epl', 'Brentford'],
  "Leicester": ['epl', 'Leicester'],
  "Liverpool": ['epl', 'Liverpool'],
  "Barcelona": ['laliga', 'Barcelona'],
  "Dynamo-FC": ['mls', 'Houston-Dynamo'],
  "FC-Dallas": ['mls', 'FC-Dallas'],
  "LA-Galaxy": ['mls', 'LA-Galaxy'],
  "DC-United": ['mls', 'DC-United'],
  "Austin-FC": ['mls', 'Austin'],
  "PHI-Union": ['mls', 'Philadelphia'],
  "Paris-S-G": ['cl', 'Paris'],
  "Marseille": ['cl', 'Marseille'],
  "KF-Tirana": ['cl', 'KF-Tirana'],
  "Shakhtyor": ['cl', 'Shakhtyor'],
  "Sampdoria": ['sa', 'Sampdoria'],
  "Cremonese": ['sa', 'Cremonese'],
  "Frosinone": ['sa', 'Frosinone'],
  "Genoa-CFC": ['sa', 'Genoa'],
  "Feyenoord": ['eurl', 'Feyenoord'],
  "AC-Omonia": ['eurl', 'AC-Omonia'],
  "Stuttgart": ['bun', 'Stuttgart'],
  "Wolfsburg": ['bun', 'Wolfsburg'],
  "Darmstadt": ['bun', 'Darmstadt'],
  "FC-Nantes": ['lig', 'Nantes'],
  "Lille-OSC": ['lig', 'Lille'],
  "AS-Monaco": ['lig', 'Monaco'],
  "SF-Giants": ['mlb', 'San-Francisco-Giants'],
  "San-Diego": ['mlb', 'San-Diego-Padres'],
  "LA-Angels": ['mlb', 'Los-Angeles-Angels'],
  "Chi.-Cubs": ['mlb', 'Chicago-Cubs'],
  "Guardians": ['mlb', 'Cleveland-Guardians'],
  "Athletics": ['mlb', 'Oakland-Athletics'],
  "Blue-Jays": ['mlb', 'Toronto-Blue-Jays'],
  "Nationals": ['mlb', 'Washington-Nationals'],
  "KC-Royals": ['mlb', 'Kansas-City-Royals'],
  "SD-Padres": ['mlb', 'San-Diego-Padres'],
  "White-Sox": ['mlb', 'Chicago-White-Sox'],
  "MIN-Twins": ['mlb', 'Minnesota-Twins'],
  "IND-Fever": ['wnba', 'Indiana-Fever'],
  "SEA-Storm": ['wnba', 'Seattle-Storm'],
  "LA-Sparks": ['wnba', 'Los-Angeles-Sparks'],
  "DAL-Wings": ['wnba', 'Dallas-Wings'],
  "MIN-Wild": ['nhl', 'Minnesota'],
  "WPG-Jets": ['nhl', 'Winnipeg'],
  "Panthers": ['nhl', 'Florida'],
  "Penguins": ['nhl', 'Pittsburgh'],
  "Senators": ['nhl', 'Ottawa'],
  "Carolina": ['nhl', 'Carolina'],
  "Colorado": ['nhl', 'Colorado'],
  "Columbus": ['nhl', 'Columbus'],
  "Edmonton": ['nhl', 'Edmonton'],
  "Montreal": ['nhl', 'Montreal'],
  "San-Jose": ['nhl', 'San-Jose'],
  "Winnipeg": ['nhl', 'Winnipeg'],
  "Steelers": ['nfl', 'Pittsburgh'],
  "Patriots": ['nfl', 'New-England'],
  "Dolphins": ['nfl', 'Miami'],
  "UTA-Jazz": ['nba', 'Utah'],
  "PHO-Suns": ['nba', 'Phoenix'],
  "BKN-Nets": ['nba', 'Brooklyn'],
  "SA-Spurs": ['nba', 'San-Antonio'],
  "MIA-Heat": ['nba', 'Miami'],
  "Oklahoma": ['nba', 'Oklahoma'],
  "New-York": ['nba', 'New-York'],
  "Portland": ['nba', 'Portland'],
  "Brooklyn": ['nba', 'Brooklyn'],
  "Brighton": ['epl', 'Brighton'],
  "West-Ham": ['epl', 'West-Ham'],
  "Espanyol": ['laliga', 'Espanyol'],
  "Valencia": ['laliga', 'Valencia'],
  "Mallorca": ['laliga', 'Mallorca'],
  "COL-Crew": ['mls', 'Columbus-Crew'],
  "CHI-Fire": ['mls', 'Chicago-Fire'],
  "Dortmund": ['cl', 'Dortmund'],
  "AC-Milan": ['cl', 'Milan'],
  "Shakhtar": ['cl', 'Shakhtar'],
  "Juventus": ['cl', 'Juventus'],
  "Linfield": ['cl', 'Linfield'],
  "CFR-Cluj": ['cl', 'CFR-Cluj'],
  "Žalgiris": ['cl', 'Zalgiris'],
  "Red-Imps": ['cl', 'Red-Imps'],
  "Red-Star": ['cl', 'Red-Star'],
  "Union-SG": ['cl', 'Union-SG'],
  "Zalgiris": ['cl', 'Zalgiris'],
  "Atalanta": ['sa', 'Atalanta'],
  "AC-Monza": ['sa', 'Monza'],
  "Sassuolo": ['sa', 'Sassuolo'],
  "Cagliari": ['sa', 'Cagliari'],
  "Freiburg": ['eurl', 'Freiburg'],
  "Toulouse": ['eurl', 'Toulouse'],
  "Augsburg": ['bun', 'Augsburg'],
  "Mainz-05": ['bun', 'Mainz'],
  "Le-Havre": ['lig', 'Le-Havre'],
  "OGC-Nice": ['lig', 'Nice'],
  "Phillies": ['mlb', 'Philadelphia-Phillies'],
  "Mariners": ['mlb', 'Seattle-Mariners'],
  "CIN-Reds": ['mlb', 'Cincinnati-Reds'],
  "CHI-Cubs": ['mlb', 'Chicago-Cubs'],
  "MIN-Lynx": ['wnba', 'Minnesota-Lynx'],
  "Coyotes": ['nhl', 'Arizona'],
  "Rangers": ['nhl', 'NY-Rangers'],
  "Canucks": ['nhl', 'Vancouver'],
  "Anaheim": ['nhl', 'Anaheim'],
  "Arizona": ['nhl', 'Arizona'],
  "Buffalo": ['nhl', 'Buffalo'],
  "Calgary": ['nhl', 'Calgary'],
  "Chicago": ['nhl', 'Chicago'],
  "Detroit": ['nhl', 'Detroit'],
  "Florida": ['nhl', 'Florida'],
  "Toronto": ['nhl', 'Toronto'],
  "Seattle": ['nhl', 'Seattle'],
  "Packers": ['nfl', 'Green-Bay'],
  "Jaguars": ['nfl', 'Jacksonville'],
  "Vikings": ['nfl', 'Minnesota'],
  "Raiders": ['nfl', 'Las-Vegas'],
  "Broncos": ['nfl', 'Denver'],
  "Cowboys": ['nfl', 'Dallas'],
  "Atlanta": ['nfl', 'Atlanta'],
  "Houston": ['nfl', 'Houston'],
  "Celtics": ['nba', 'Boston'],
  "Thunder": ['nba', 'Oklahoma'],
  "Nuggets": ['nba', 'Denver'],
  "Indiana": ['nba', 'Indiana'],
  "Memphis": ['nba', 'Memphis'],
  "Orlando": ['nba', 'Orlando'],
  "Phoenix": ['nba', 'Phoenix'],
  "Burnley": ['epl', 'Burnley'],
  "Chelsea": ['epl', 'Chelsea'],
  "Everton": ['epl', 'Everton'],
  "Arsenal": ['epl', 'Arsenal'],
  "Osasuna": ['laliga', 'Osasuna'],
  "Sevilla": ['laliga', 'Sevilla'],
  "Almería": ['laliga', 'Almeria'],
  "Granada": ['laliga', 'Granada'],
  "Almeria": ['laliga', 'Almeria'],
  "Benfica": ['cl', 'Benfica'],
  "Maribor": ['cl', 'Maribor'],
  "Apollon": ['cl', 'Apollon'],
  "Udinese": ['sa', 'Udinese'],
  "Bologna": ['sa', 'Bologna'],
  "AS-Roma": ['sa', 'Roma'],
  "FC-Koln": ['bun', 'Koln'],
  "Schalke": ['bun', 'Schalke'],
  "Ajaccio": ['lig', 'Ajaccio'],
  "Auxerre": ['lig', 'Auxerre'],
  "Lorient": ['lig', 'Lorient'],
  "RC-Lens": ['lig', 'Lens'],
  "FC-Metz": ['lig', 'Metz'],
  "NY-Mets": ['mlb', 'New-York-Mets'],
  "Oakland": ['mlb', 'Oakland-Athletics'],
  "Orioles": ['mlb', 'Baltimore-Orioles'],
  "Red-Sox": ['mlb', 'Boston-Red-Sox'],
  "Rockies": ['mlb', 'Colorado-Rockies'],
  "Dodgers": ['mlb', 'Los-Angeles-Dodgers'],
  "Marlins": ['mlb', 'Miami-Marlins'],
  "Brewers": ['mlb', 'Milwaukee-Brewers'],
  "Yankees": ['mlb', 'New-York-Yankees'],
  "Pirates": ['mlb', 'Pittsburgh-Pirates'],
  "TB-Rays": ['mlb', 'Tampa-Bay-Rays'],
  "Liberty": ['wnba', 'New-York-Liberty'],
  "Mercury": ['wnba', 'Phoenix-Mercury'],
  "Mystics": ['wnba', 'Washington-Mystics'],
  "CON-Sun": ['wnba', 'Connecticut-Sun'],
  "LV-Aces": ['wnba', 'Las-Vegas-Aces'],
  "CHI-Sky": ['wnba', 'Chicago-Sky'],
  "Sharks": ['nhl', 'San-Jose'],
  "Kraken": ['nhl', 'Seattle'],
  "Devils": ['nhl', 'New-Jersey'],
  "Flames": ['nhl', 'Calgary'],
  "Sabres": ['nhl', 'Buffalo'],
  "Bruins": ['nhl', 'Boston'],
  "Flyers": ['nhl', 'Philadelphia'],
  "Oilers": ['nhl', 'Edmonton'],
  "Boston": ['nhl', 'Boston'],
  "Dallas": ['nhl', 'Dallas'],
  "Ottawa": ['nhl', 'Ottawa'],
  "Eagles": ['nfl', 'Philadelphia'],
  "Ravens": ['nfl', 'Baltimore'],
  "Titans": ['nfl', 'Tennessee'],
  "Texans": ['nfl', 'Houston'],
  "Giants": ['nfl', 'NY-Giants'],
  "Chiefs": ['nfl', 'Kansas-City'],
  "Denver": ['nfl', 'Denver'],
  "Pacers": ['nba', 'Indiana'],
  "Knicks": ['nba', 'New-York'],
  "Fulham": ['epl', 'Fulham'],
  "Wolves": ['epl', 'Wolves'],
  "Getafe": ['laliga', 'Getafe'],
  "Girona": ['laliga', 'Girona'],
  "Alaves": ['laliga', 'Alaves'],
  "Austin": ['mls', 'Austin'],
  "Celtic": ['cl', 'Celtic'],
  "Napoli": ['cl', 'Napoli'],
  "Pyunik": ['cl', 'Pyunik'],
  "Monaco": ['cl', 'Monaco'],
  "Zürich": ['cl', 'Zurich'],
  "Zurich": ['cl', 'Zurich'],
  "Torino": ['sa', 'Torino'],
  "Spezia": ['sa', 'Spezia'],
  "Empoli": ['sa', 'Empoli'],
  "Verona": ['sa', 'Hellas-Verona'],
  "Rennes": ['eurl', 'Rennes'],
  "Nantes": ['eurl', 'Nantes'],
  "Bochum": ['bun', 'Bochum'],
  "Angers": ['lig', 'Angers'],
  "Troyes": ['lig', 'Troyes'],
  "Braves": ['mlb', 'Atlanta-Braves'],
  "Tigers": ['mlb', 'Detroit-Tigers'],
  "Astros": ['mlb', 'Houston-Astros'],
  "Royals": ['mlb', 'Kansas-City-Royals'],
  "Angels": ['mlb', 'Los-Angeles-Angels'],
  "Padres": ['mlb', 'San-Diego-Padres'],
  "Sparks": ['wnba', 'Los-Angeles-Sparks'],
  "Stars": ['nhl', 'Dallas'],
  "Blues": ['nhl', 'St-Louis'],
  "Vegas": ['nhl', 'Vegas'],
  "Bears": ['nfl', 'Chicago'],
  "Miami": ['nfl', 'Miami'],
  "Leeds": ['epl', 'Leeds'],
  "Cádiz": ['laliga', 'Cadiz'],
  "Betis": ['laliga', 'Betis'],
  "Elche": ['laliga', 'Elche'],
  "Cadiz": ['laliga', 'Cadiz'],
  "NYCFC": ['mls', 'NYCFC'],
  "Milan": ['cl', 'Milan'],
  "Paris": ['cl', 'Paris'],
  "Porto": ['cl', 'Porto'],
  "Inter": ['cl', 'Inter'],
  "Malmö": ['cl', 'Malmo'],
  "Malmo": ['cl', 'Malmo'],
  "Monza": ['sa', 'Monza'],
  "Lecce": ['sa', 'Lecce'],
  "Lazio": ['sa', 'Lazio'],
  "Genoa": ['sa', 'Genoa'],
  "Braga": ['eurl', 'Braga'],
  "Mainz": ['bun', 'Mainz'],
  "Brest": ['lig', 'Brest'],
  "Lille": ['lig', 'Lille'],
  "Reims": ['lig', 'Reims'],
  "Texas": ['mlb', 'Texas-Rangers'],
  "Twins": ['mlb', 'Minnesota-Twins'],
  "Dream": ['wnba', 'Atlanta-Dream'],
  "Fever": ['wnba', 'Indiana-Fever'],
  "Storm": ['wnba', 'Seattle-Storm'],
  "Wings": ['wnba', 'Dallas-Wings'],
  "Wild": ['nhl', 'Minnesota'],
  "Jets": ['nhl', 'Winnipeg'],
  "Rams": ['nfl', 'LA-Rams'],
  "Utah": ['nba', 'Utah'],
  "Draw": ['epl', 'Draw'],
  "LAFC": ['mls', 'Los-Angeles-FC'],
  "Ajax": ['cl', 'Ajax'],
  "Roma": ['sa', 'Roma'],
  "Köln": ['bun', 'Koln'],
  "Koln": ['bun', 'Koln'],
  "Lens": ['lig', 'Lens'],
  "Lyon": ['lig', 'Lyon'],
  "Nice": ['lig', 'Nice'],
  "Metz": ['lig', 'Metz'],
  "LOSC": ['lig', 'Lille'],
  "Cubs": ['mlb', 'Chicago-Cubs'],
  "Reds": ['mlb', 'Cincinnati-Reds'],
  "Mets": ['mlb', 'New-York-Mets'],
  "Rays": ['mlb', 'Tampa-Bay-Rays'],
  "Lynx": ['wnba', 'Minnesota-Lynx'],
  "Aces": ['wnba', 'Las-Vegas-Aces'],
  "NYI": ['nhl', 'NY-Islanders'],
  "PSG": ['cl', 'Paris'],
  "HJK": ['cl', 'HJK'],
  "TEX": ['mlb', 'Texas-Rangers'],
  "Sky": ['wnba', 'Chicago-Sky'],
  "Sun": ['wnba', 'Connecticut-Sun'],
}