import axios, { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { API_ENDPOINT_URL, API_PROXY_ENDPOINT_URL } from '../config';

const config_internal: AxiosRequestConfig = {
  headers: {
    'Accept': 'application/json',
    'x-api-key': `${process.env.REACT_APP_EDGEBETS_API_KEY}`,
  } as RawAxiosRequestHeaders,
};

export const addNewSearch = async (data: Object) => {
  try {
    const response: AxiosResponse = await axios.post(
      `${API_ENDPOINT_URL}/v1/admin/searches`,
      data,
      {...config_internal, withCredentials: false}
    );
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getApiStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/apistats`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getOddsPendingGames = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/odds`,
      {...config_internal, withCredentials: false}
    );
    //console.log("PENDING GAMES", response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getMlBetStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/stats/ml/agg`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getPsBetStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/stats/ps/agg`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getOuBetStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/stats/ou/agg`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getMlTopBetStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/stats/ml/top`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getPsTopBetStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/stats/ps/top`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getOuTopBetStats = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/admin/stats/ou/top`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getSites = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/sites`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export const getTeams = async () => {
  try {
    const response: AxiosResponse = await axios.get(
      `${API_ENDPOINT_URL}/v1/teams`,
      {...config_internal, withCredentials: false}
    );
    //console.log(response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}

export async function getNLUModelPrediction(utterance: string) {
  // interpret search using Joint Intent and Slot Filling Transformer NLU
  try {
    const response: AxiosResponse = await axios.post(
      `${API_PROXY_ENDPOINT_URL}/proxy/nlu/predict`,
      { utterance: `${utterance}`},
      {...config_internal, withCredentials: false},
    );
    //console.log("NLU MODEL RESPSONSE", response)
    return response as AxiosResponse;
  } catch (error: any) {
    return error.response as AxiosResponse;
  }
}