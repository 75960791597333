export const tmap = {
  'nhl': {
      'Anaheim Ducks':"Anaheim",
      'Arizona Coyotes':"Arizona",
      "Boston Bruins":"Boston",
      "Buffalo Sabres":"Buffalo",
      'Calgary Flames':"Calgary",
      "Carolina Hurricanes":"Carolina",
      "Chicago Blackhawks":"Chicago",
      'Colorado Avalanche':"Colorado",
      'Columbus Blue Jackets':"Columbus",
      'Dallas Stars':"Dallas",
      "Detroit Red Wings":"Detroit",
      'Edmonton Oilers':"Edmonton",
      "Florida Panthers":"Florida",
      'Los Angeles Kings':"Los Angeles",
      'Minnesota Wild':"Minnesota",
      'Montreal Canadiens':"Montreal",
      'Nashville Predators':"Nashville",
      'New York Islanders':"N.Y. Islanders",
      'New York Rangers':"N.Y. Rangers",
      'New Jersey Devils':"New Jersey",
      "Ottawa Senators":"Ottawa",
      'Philadelphia Flyers':"Philadelphia",
      'Pittsburgh Penguins':"Pittsburgh",
      'San Jose Sharks':"San Jose",
      'St. Louis Blues':"St. Louis",
      "Tampa Bay Lightning":"Tampa Bay",
      'Toronto Maple Leafs':"Toronto",
      'Vancouver Canucks':"Vancouver",
      'Vegas Golden Knights':"Vegas",
      "Washington Capitals":"Washington",
      'Winnipeg Jets':"Winnipeg",
      'Seattle Kraken':"Seattle",
  },
  'nfl': {
      "Arizona Cardinals":"Arizona",
      "Atlanta Falcons":"Atlanta",
      "Baltimore Ravens":"Baltimore",
      "Buffalo Bills":"Buffalo",
      "Carolina Panthers":"Carolina",
      "Cincinnati Bengals":"Cincinnati",
      "Chicago Bears":"Chicago",
      "Cleveland Browns":"Cleveland",
      "Dallas Cowboys":"Dallas",
      "Denver Broncos":"Denver",
      "Detroit Lions":"Detroit",
      "Houston Texans":"Houston",
      "Green Bay Packers":"Green Bay",
      "Indianapolis Colts":"Indianapolis",
      "Los Angeles Rams":"L.A. Rams",
      "Jacksonville Jaguars":"Jacksonville",
      "Minnesota Vikings":"Minnesota",
      "Kansas City Chiefs":"Kansas City",
      "New Orleans Saints":"New Orleans",
      "Las Vegas Raiders":"Las Vegas",
      'Oakland Raiders':"Las Vegas",
      "New York Jets":"N.Y. Jets",
      "Los Angeles Chargers":"L.A. Chargers",
      "Philadelphia Eagles":"Philadelphia",
      "Miami Dolphins":"Miami",
      "San Francisco 49ers":"San Francisco",
      "New England Patriots":"New England",
      "Seattle Seahawks":"Seattle",
      "New York Giants":"N.Y. Giants",
      "Tampa Bay Buccaneers":"Tampa Bay",
      "Pittsburgh Steelers":"Pittsburgh",
      "Washington Football Team":"Washington",
      'Washington Redskins':"Washington",
      'Washington Commanders':"Washington",
      "Tennessee Titans":"Tennessee",
  },
  'nba': {
      'LA Clippers':'L.A. Clippers',
      'LA Lakers':'L.A. Lakers',
      'Oklahoma City':'Oklahoma',
      'Indiana Pacers':'Indiana',
      'Charlotte Hornets': 'Charlotte',
      'Chicago Bulls': 'Chicago',
      'Detroit Pistons': 'Detroit',
      'Washington Wizards': 'Washington',
      'Toronto Raptors':'Toronto',
      'Boston Celtics':'Boston',
      'New York Knicks':'New York',
      'Cleveland Cavaliers':'Cleveland',
      'Memphis Grizzlies':'Memphis',
      'Philadelphia 76ers':'Philadelphia',
      'New Orleans Pelicans':'New Orleans',
      'Houston Rockets':'Houston',
      'Minnesota Timberwolves':'Minnesota',
      'Orlando Magic':'Orlando',
      'San Antonio Spurs' :'San Antonio',
      'Oklahoma City Thunder':'Oklahoma',
      'Utah Jazz':'Utah',
      'Sacramento Kings':'Sacramento',
      'Portland Trail Blazers':'Portland',
      'Denver Nuggets':'Denver',
      'Phoenix Suns':'Phoenix',
      'Dallas Mavericks':'Dallas',
      'DAL Mavericks':'Dallas',
      'Mavericks':'Dallas',
      'Atlanta Hawks':'Atlanta',
      'Milwaukee Bucks':'Milwaukee',
      'Miami Heat':'Miami',
      'Golden State Warriors':'Golden State',
      'Brooklyn Nets':'Brooklyn',
  },
  'epl': {
      'Draw':'Draw',
      'Aston Villa':'Aston Villa',
      'Burnley':'Burnley',
      'Newcastle':'Newcastle',
      'Chelsea':'Chelsea',
      'Brighton':'Brighton',
      'Brighton & Hove Albion':'Brighton',
      'Brighton and Hove Albion':'Brighton',
      'Southampton':'Southampton',
      'Crystal Palace':'Crystal Palace',
      'Tottenham':'Tottenham',
      'Tottenham Hotspur':'Tottenham',
      'Bournemouth':'Bournemouth',
      'Everton':'Everton',
      'Fulham':'Fulham',
      'Wolves':'Wolves',
      'Wolverhampton':'Wolves',
      'Brentford':'Brentford',
      'Manchester City':'Manchester City',
      'Leicester':'Leicester',
      'Nottingham Forest': 'Nottingham Forest',
      'Manchester Utd': 'Manchester Utd',
      'Manchester United': 'Manchester Utd',
      'West Ham': 'West Ham',
      'West Ham United':'West Ham',
      'Arsenal': 'Arsenal',
      'Leeds': 'Leeds',
      'Leeds United':'Leeds',
      'Liverpool': 'Liverpool',
      "Nott'ham Forest":'Nottingham Forest',
      "Newcastle Utd":'Newcastle',
      "Newcastle United":'Newcastle',
      "Leicester City":'Leicester',
      'Arsenal FC': 'Arsenal',
      'Wolverhampton Wanderers': 'Wolves',
      'AFC Bournemouth': 'Bournemouth',
      'Burnley FC':'Burnley',
      'Chelsea FC':'Chelsea',
      'Liverpool FC':'Liverpool',
  },
  'laliga': {
      'Draw':'Draw',
      'Osasuna': 'Osasuna',
      'Sevilla': 'Sevilla',
      'Celta Vigo': 'Celta Vigo',
      'Espanyol': 'Espanyol',
      'Valladolid': 'Valladolid',
      'Villarreal': 'Villarreal',
      'Villarreal CF': 'Villarreal',
      'Barcelona': 'Barcelona',
      'Cádiz': 'Cádiz',
      'Valencia': 'Valencia',
      'Almería': 'Almería',
      'Athletic Club': 'Athletic Club',
      'Athletic Bilbao': 'Athletic Club',
      'Getafe': 'Getafe',
      'Betis': 'Betis',
      'Real Betis': 'Betis',
      'Rayo Vallecano': 'Rayo Vallecano',
      'Real Sociedad': 'Real Sociedad',
      'Girona': 'Girona',
      'Real Madrid': 'Real Madrid',
      'Mallorca': 'Mallorca',
      'Atlético Madrid': 'Atlético Madrid',
      'Elche': 'Elche',
      'Las Palmas': 'Las Palmas',
      'Alaves': 'Alaves',
      'Granada': 'Granada',
      'Granada CF': 'Granada',
      'Deportivo Alaves': 'Alaves',
      'Real Mallorca' : 'Mallorca',
      'Celta de Vigo': 'Celta Vigo',
      'UD Almería': 'Almería',
      'FC Barcelona': 'Barcelona',
      'UD Las Palmas': 'Las Palmas',
      'Ca Osasuna': 'Osasuna',
  },
  'mls': {
      'Draw':'Draw',
      'Nashville': 'Nashville',
      'NYCFC': 'NYCFC',
      'FC Cincinnati': 'FC Cincinnati',
      'Houston Dynamo': 'Houston Dynamo',
      'Dynamo FC': 'Houston Dynamo',
      'FC Dallas': 'FC Dallas',
      'Minnesota Utd': 'Minnesota Utd',
      'Atlanta Utd': 'Atlanta Utd',
      'San Jose': 'San Jose',
      'Philadelphia': 'Philadelphia',
      'Columbus Crew': 'Columbus Crew',
      'Orlando City': 'Orlando City',
      'NY Red Bulls': 'NY Red Bulls',
      'Austin': 'Austin',
      'St. Louis': 'St. Louis',
      'Charlotte': 'Charlotte',
      'New England': 'New England',
      'Vancouver': 'Vancouver',
      'Real Salt Lake': 'Real Salt Lake',
      'D.C. United': 'D.C. United',
      'Toronto FC': 'Toronto FC',
      'Inter Miami': 'Inter Miami',
      'CF Montréal': 'CF Montréal',
      'Seattle': 'Seattle',
      'Colorado Rapids': 'Colorado Rapids',
      'Portland Timbers': 'Portland Timbers',
      'Sporting KC': 'Sporting KC',
      'Los Angeles FC': 'Los Angeles FC',
      'Chicago Fire':'Chicago Fire',
      'LA Galaxy':'LA Galaxy',
      'Montreal':'CF Montréal',
      'DC United': 'D.C. United',
      'New York City':'NYCFC',
      'Nashville SC':'Nashville',
      'New York RB': 'NY Red Bulls',
      'Toronto': 'Toronto FC',
      'Atlanta United':'Atlanta Utd',
      'Austin FC': 'Austin',
      'Vancouver Whitecaps': 'Vancouver',
      'Philadelphia Union': 'Philadelphia',
      'Dallas': 'FC Dallas',
      'Minnesota United': 'Minnesota Utd',
      'St. Louis City': 'St. Louis',
      'Seattle Sounders': 'Seattle',
      'San Jose Earthquakes': 'San Jose',
      'Sporting Kansas City': 'Sporting KC',
      'New York Red Bulls': 'NY Red Bulls',
      'Columbus Crew SC': 'Columbus Crew',
      'Orlando City SC': 'Orlando City',
      'Inter Miami CF': 'Inter Miami',
      'New England Revolution': 'New England',
      'New York City FC': 'NYCFC',
      'St. Louis CITY SC': 'St. Louis',
      'Vancouver Whitecaps FC': 'Vancouver',
      'Minnesota United FC': 'Minnesota Utd',
      'Seattle Sounders FC': 'Seattle',
      'Atlanta United FC':'Atlanta Utd',
      'Charlotte FC': 'Charlotte',
      'Nashville Soccer Club': 'Nashville',
      'NE Revolution': 'New England',
      'Saint Louis City SC': 'St. Louis',
      'Los Angeles Galaxy': 'LA Galaxy',
      'LAFC': 'Los Angeles FC',
  },
  'cl': {
      'Draw':'Draw',
      'Dinamo Zagreb':'Dinamo Zagreb',
      'Chelsea':'Chelsea',
      'Dortmund':'Dortmund',
      'Borussia Dortmund': 'Dortmund',
      'FC Copenhagen':'FC Copenhagen',
      'Celtic':'Celtic',
      'Real Madrid':'Real Madrid',
      'Benfica':'Benfica',
      'Maccabi Haifa':'Maccabi Haifa',
      'RB Salzburg':'RB Salzburg',
      'Milan':'Milan',
      'AC Milan':'Milan',
      'Sevilla':'Sevilla',
      'Manchester City':'Manchester City',
      'RB Leipzig':'RB Leipzig',
      'Shakhtar':'Shakhtar',
      'Paris':'Paris',
      'Paris S-G':'Paris',
      'PSG':'Paris',
      'Paris Saint-Germain':'Paris',
      'Paris Saint Germain':'Paris',
      'Juventus':'Juventus',
      'Ajax':'Ajax',
      'Rangers':'Rangers',
      'Eint Frankfurt':'Eint Frankfurt',
      'Sporting CP':'Sporting CP',
      'Sporting Lisboa':'Sporting CP',
      'Tottenham':'Tottenham',
      'Tottenham Hotspur':'Tottenham',
      'Marseille':'Marseille',
      'Napoli':'Napoli',
      'Liverpool':'Liverpool',
      'Atlético Madrid':'Atlético Madrid',
      'Porto':'Porto',
      'Barcelona':'Barcelona',
      'Viktoria Plzeň':'Viktoria Plzeň',
      'Inter':'Inter',
      'Bayern Munich':'Bayern Munich',
      'Club Brugge':'Club Brugge',
      'Leverkusen':'Leverkusen',
      'Malmö':'Malmö',
      'KV':'KV',
      'The New Saints':'The New Saints',
      'Linfield':'Linfield',
      'Shamrock Rov':'Shamrock Rov',
      'Hibernians':'Hibernians',
      'Lech Poznań':'Lech Poznań',
      'Qarabağ Ağdam':'Qarabağ Ağdam',
      'Pyunik':'Pyunik',
      'CFR Cluj':'CFR Cluj',
      'KF Ballkani Therandë':'KF Ballkani Therandë',
      'Žalgiris':'Žalgiris',
      'Shkupi Skopje':'Shkupi Skopje',
      'Red Imps':'Red Imps',
      'Ludogorets':'Ludogorets',
      'Sutjeska Nikšić':'Sutjeska Nikšić',
      'Bodø/Glimt':'Bodø/Glimt',
      'KÍ':'KÍ',
      'HJK':'HJK',
      'Tobol Qostanaı':'Tobol Qostanaı',
      'Ferencváros':'Ferencváros',
      'F91 Dudelange':'F91 Dudelange',
      'KF Tirana':'KF Tirana',
      'Zrinjski Mostar':'Zrinjski Mostar',
      'Sheriff Tiraspol':'Sheriff Tiraspol',
      'Maribor':'Maribor',
      'Shakhtyor':'Shakhtyor',
      'Slovan Bratislava':'Slovan Bratislava',
      'Dinamo Batumi':'Dinamo Batumi',
      'FK Rīgas FS':'FK Rīgas FS',
      'La Fiorita':'La Fiorita',
      'Levadia Tallinn':'Levadia Tallinn',
      "Inter d'Escaldes":"Inter d'Escaldes",
      "AÉK Lárnaka":"AÉK Lárnaka",
      "Dynamo Kyiv":"Dynamo Kyiv",
      "Apollon":"Apollon",
      "PSV Eindhoven":"PSV Eindhoven",
      "Sturm Graz":"Sturm Graz",
      "Red Star":"Red Star",
      "Monaco":"Monaco",
      "Trabzonspor":"Trabzonspor",
      "Union SG": "Union SG",
      "St. Gilloise": "Union SG",
      "Fenerbahçe": "Fenerbahçe",
      "Zürich": "Zürich",
      "Olympiacos": "Olympiacos",
      "Midtjylland": "Midtjylland",
      "Arsenal": "Arsenal",
      'Arsenal FC': 'Arsenal',
      "Bayern Munchen": "Bayern Munchen",
  },
  'sa': {
      'Draw':'Draw',
      'Sampdoria':'Sampdoria',
      'Atalanta':'Atalanta',
      'Atalanta BC':'Atalanta',
      'Milan':'Milan',
      'AC Milan':'Milan',
      'Udinese':'Udinese',
      'Monza':'Monza',
      'AC Monza':'Monza',
      'Torino':'Torino',
      'Lecce':'Lecce',
      'Inter':'Inter',
      'Inter Milan':'Inter',
      'Fiorentina':'Fiorentina',
      'Cremonese':'Cremonese',
      'Lazio':'Lazio',
      'Bologna':'Bologna',
      'Spezia':'Spezia',
      'Empoli':'Empoli',
      'Salernitana':'Salernitana',
      'Roma':'Roma',
      'AS Roma':'Roma',
      'Hellas Verona':'Hellas Verona',
      'Hellas Verona FC':'Hellas Verona',
      'Napoli':'Napoli',
      'Juventus':'Juventus',
      'Sassuolo':'Sassuolo',
      'Cagliari':'Cagliari',
      'Cagliari Calcio':'Cagliari',
      'Frosinone':'Frosinone',
      'Genoa':'Genoa',
      'Frosinone Calcio':'Fiorentina',
      'SSC Napoli': 'Napoli',
      'Genoa CFC': 'Genoa',
      'Verona': 'Hellas Verona',
      'Juventus FC': 'Juventus',
      'Bologna FC': 'Bologna',
  },
  'eurl':{
      'Draw':'Draw',
      'Malmö': 'Malmö',
      'Braga': 'Braga',
      'Union Berlin': 'Union Berlin',
      'Union SG': 'Union SG',
      "St. Gilloise": "Union SG",
      'Zürich': 'Zürich',
      'Arsenal': 'Arsenal',
      'Arsenal FC': 'Arsenal',
      'PSV Eindhoven': 'PSV Eindhoven',
      'Bodø/Glimt': 'Bodø/Glimt',
      'Ludogorets': 'Ludogorets',
      'Roma': 'Roma',
      'AS Roma': 'Roma',
      'Fenerbahçe': 'Fenerbahçe',
      'Dynamo Kyiv': 'Dynamo Kyiv',
      'HJK': 'HJK',
      'Betis': 'Betis',
      'AÉK Lárnaka': 'AÉK Lárnaka',
      'Rennes': 'Rennes',
      'Stade Rennes': 'Rennes',
      'Manchester Utd': 'Manchester Utd',
      'Real Sociedad': 'Real Sociedad',
      'Sturm Graz': 'Sturm Graz',
      'Midtjylland': 'Midtjylland',
      'Freiburg': 'Freiburg',
      'Qarabağ Ağdam': 'Qarabağ Ağdam',
      'Ferencváros': 'Ferencváros',
      'Trabzonspor': 'Trabzonspor',
      'Red Star': 'Red Star',
      'Monaco': 'Monaco',
      'Lazio': 'Lazio',
      'Feyenoord': 'Feyenoord',
      'Nantes': 'Nantes',
      'Olympiacos': 'Olympiacos',
      'AC Omonia': 'AC Omonia',
      'Sheriff Tiraspol': 'Sheriff Tiraspol',
      'Ajax':'Ajax',
      'Barcelona':'Barcelona',
      'RB Salzburg':'RB Salzburg',
      'Shakhtar':'Shakhtar',
      'Sporting CP':'Sporting CP',
      'Sporting Lisboa':'Sporting CP',
      'Juventus':'Juventus',
      'Leverkusen':'Leverkusen',
      'Bayer Leverkusen': 'Leverkusen',
      'Sevilla':'Sevilla',
      'Benfica':'Benfica',
      "AC Milan": "AC Milan",
      'Milan': "AC Milan",
      'Brighton': 'Brighton',
      "West Ham": "West Ham",
      "Liverpool": 'Liverpool',
      "Atalanta": "Atalanta",
      "Marseille": "Marseille",
      'Villarreal': 'Villarreal',
      'Sparta Prague': 'Sparta Prague',
      'Toulouse': 'Toulouse',
  },
  'bun':{
      'Draw':'Draw',
      'Augsburg': 'Augsburg',
      'FC Augsburg': 'Augsburg',
      'Bayern Munich': 'Bayern Munich',
      'Bayern Munchen': 'Bayern Munich',
      'Bochum': 'Bochum',
      'Dortmund': 'Dortmund',
      'Borussia Dortmund': 'Dortmund',
      'Eint Frankfurt': 'Eint Frankfurt',
      'Eintracht Frankfurt': 'Eint Frankfurt',
      'Freiburg': 'Freiburg',
      'Hertha BSC': 'Hertha BSC',
      'Hertha Berlin': 'Hertha BSC',
      'Hoffenheim': 'Hoffenheim',
      'Köln': 'Köln',
      'FC Cologne': 'Köln',
      'FC Koln': 'Köln',
      'Leverkusen': 'Leverkusen',
      'Bayer Leverkusen': 'Leverkusen',
      "M'Gladbach": "M'Gladbach",
      "Borussia Monchengladbach": "M'Gladbach",
      'Mainz': 'Mainz',
      'Mainz 05': 'Mainz',
      'RB Leipzig': 'RB Leipzig',
      'Schalke': 'Schalke',
      'Schalke 04': 'Schalke',
      'Stuttgart': 'Stuttgart',
      'Union Berlin': 'Union Berlin',
      'Werder Bremen': 'Werder Bremen',
      'Wolfsburg': 'Wolfsburg',
      'VfB Stuttgart': 'Stuttgart',
      'Heidenheim': 'Heidenheim',
      'FC Heidenheim': 'Heidenheim',
      'Darmstadt':'Darmstadt',
      'Darmstadt 98': 'Darmstadt',
      'SV Darmstadt 98': 'Darmstadt',
  },
  'lig':{
      'Draw':'Draw',
      'Ajaccio': 'Ajaccio',
      'Angers': 'Angers',
      'Auxerre': 'Auxerre',
      'Brest': 'Brest',
      'Clermont Foot': 'Clermont Foot',
      'Lens': 'Lens',
      'Lille': 'Lille',
      'Lorient': 'Lorient',
      'Lyon': 'Lyon',
      'Marseille': 'Marseille',
      'Monaco': 'Monaco',
      'Montpellier': 'Montpellier',
      'Nantes': 'Nantes',
      'Nice': 'Nice',
      'Paris':'Paris',
      'Paris S-G': 'Paris',
      'PSG':'Paris',
      'Paris Saint-Germain':'Paris',
      'Reims': 'Reims',
      'Rennes': 'Rennes',
      'Stade Rennes': 'Rennes',
      'Strasbourg': 'Strasbourg',
      'Toulouse': 'Toulouse',
      'Troyes': 'Troyes',
      'Metz': 'Metz',
      'Le Havre': 'Le Havre',
      'Le Havre AC': 'Le Havre',
      'OGC Nice': 'Nice',
      'Olympique Lyonnais': 'Lyon',
      'FC Nantes': 'Nantes',
      'Montpellier HSC': 'Montpellier',
      'Olympique Marseille': 'Marseille',
      'Toulouse FC': 'Toulouse',
      'RC Strasbourg': 'Strasbourg',
      'Stade Rennais': 'Rennes',
      'Stade Reims': 'Reims',
      'RC Lens': 'Lens',
      'Stade Brestois 29': 'Brest',
      'FC Lorient': 'Lorient',
      'Lille OSC': 'Lille',
      'AS Monaco': 'Monaco',
      'FC Metz': 'Metz',
      'LOSC': 'Lille',
      'Stade Brestois': 'Brest',
  },
  'mlb': {
      'Arizona Diamondbacks': 'Arizona Diamondbacks',
      "Arizona D'Backs": 'Arizona Diamondbacks',
      'Atlanta Braves': 'Atlanta Braves',
      'Baltimore Orioles': 'Baltimore Orioles',
      'Boston Red Sox': 'Boston Red Sox',
      'Chicago Cubs': 'Chicago Cubs',
      'Chicago White Sox': 'Chicago White Sox',
      'Cincinnati Reds': 'Cincinnati Reds',
      'Cleveland Guardians': 'Cleveland Guardians',
      'Colorado Rockies': 'Colorado Rockies',
      'Detroit Tigers': 'Detroit Tigers',
      'Houston Astros': 'Houston Astros',
      'Kansas City Royals': 'Kansas City Royals',
      'Los Angeles Angels': 'Los Angeles Angels',
      'Los Angeles Dodgers': 'Los Angeles Dodgers',
      'Miami Marlins': 'Miami Marlins',
      'Milwaukee Braves': 'Milwaukee Brewers',
      'Minnesota Twins': 'Minnesota Twins',
      'New York Mets': 'New York Mets',
      'New York Yankees': 'New York Yankees',
      'Oakland Athletics': 'Oakland Athletics',
      'Philadelphia Phillies': 'Philadelphia Phillies',
      'Pittsburgh Pirates': 'Pittsburgh Pirates',
      'San Diego Padres': 'San Diego Padres',
      'San Francisco Giants': 'San Francisco Giants',
      'Seattle Mariners': 'Seattle Mariners',
      'St. Louis Cardinals': 'St. Louis Cardinals',
      'Tampa Bay Rays': 'Tampa Bay Rays',
      'Texas Rangers': 'Texas Rangers',
      'Toronto Blue Jays': 'Toronto Blue Jays',
      'Washington Nationals': 'Washington Nationals',
      'Milwaukee Brewers': 'Milwaukee Brewers',
      'ATL':'Atlanta Braves',
      'DET':'Detroit Tigers',
      'Detroit':'Detroit Tigers',
      'DET Tigers':'Detroit Tigers',
      'TOR':'Toronto Blue Jays',
      'BAL':'Baltimore Orioles',
      'COL':'Colorado Rockies',
      'BOS':'Boston Red Sox',
      'NYY':'New York Yankees',
      'NYM':'New York Mets',
      'MIL':'Milwaukee Brewers',
      'MIN':'Minnesota Twins',
      'SF':'San Francisco Giants',
      'SF Giants':'San Francisco Giants',
      'STL':'St. Louis Cardinals',
      'PIT':'Pittsburgh Pirates',
      'CHC':'Chicago Cubs',
      'LAA':'Los Angeles Angels',
      'CIN':'Cincinnati Reds',
      'KC':'Kansas City Royals',
      'WAS': 'Washington Nationals',
      'HOU': 'Houston Astros',
      'TB': 'Tampa Bay Rays',
      'OAK': 'Oakland Athletics',
      'PHI': 'Philadelphia Phillies',
      'PHI Phillies': 'Philadelphia Phillies',
      'ARI': 'Arizona Diamondbacks',
      'MIA': 'Miami Marlins',
      'SEA': 'Seattle Mariners',
      'CLE': 'Cleveland Guardians',
      'SD': 'San Diego Padres',
      'CHW': 'Chicago White Sox',
      'LAD': 'Los Angeles Dodgers',
      'LA Dodgers': 'Los Angeles Dodgers',
      'TEX':'Texas Rangers',
      'Tampa Bay': 'Tampa Bay Rays',
      'Boston':'Boston Red Sox',
      'Washington':'Washington Nationals',
      'Kansas City':'Kansas City Royals',
      'Miami':'Miami Marlins',
      'Colorado': 'Colorado Rockies',
      'Texas':'Texas Rangers',
      'Chi. White Sox':'Chicago White Sox',
      'Houston':'Houston Astros',
      'San Diego':'San Diego Padres',
      'San Francisco':'San Francisco Giants',
      'NY Mets': 'New York Mets',
      'Toronto': 'Toronto Blue Jays',
      'NY Yankees': 'New York Yankees',
      'Cleveland': 'Cleveland Guardians',
      'Arizona': 'Arizona Diamondbacks',
      'Pittsburgh': 'Pittsburgh Pirates',
      'Atlanta': 'Atlanta Braves',
      'Cincinnati': 'Cincinnati Reds',
      'Minnesota': 'Minnesota Twins',
      'Seattle': 'Seattle Mariners',
      'Baltimore': 'Baltimore Orioles',
      'Philadelphia': 'Philadelphia Phillies',
      'Oakland': 'Oakland Athletics',
      'Milwaukee': 'Milwaukee Brewers',
      'LA Angels':'Los Angeles Angels',
      'St. Louis': 'St. Louis Cardinals',
      'Chi. Cubs': 'Chicago Cubs',
      'National League': 'National League',
      'American League': 'American League',
      "Diamondbacks": "Arizona Diamondbacks",
      "Braves": "Atlanta Braves",
      "Orioles": "Baltimore Orioles",
      "Red Sox": "Boston Red Sox",
      "Cubs": "Chicago Cubs",
      "Reds": "Cincinnati Reds",
      "Guardians": "Cleveland Guardians",
      "Rockies": "Colorado Rockies",
      "Tigers": "Detroit Tigers",
      "Astros": "Houston Astros",
      "Royals": "Kansas City Royals",
      "Angels": "Los Angeles Angels",
      "Dodgers": "Los Angeles Dodgers",
      "Marlins": "Miami Marlins",
      "Brewers": "Milwaukee Brewers",
      "Twins": "Minnesota Twins",
      "Mets": "New York Mets",
      "Yankees": "New York Yankees",
      "Athletics": "Oakland Athletics",
      "Phillies": "Philadelphia Phillies",
      "Pirates": "Pittsburgh Pirates",
      "Padres": "San Diego Padres",
      "Giants": "San Francisco Giants",
      "Mariners": "Seattle Mariners",
      "Cardinals": "St. Louis Cardinals",
      "Rays": "Tampa Bay Rays",
      "Rangers": "Texas Rangers",
      "Blue Jays": "Toronto Blue Jays",
      "Nationals": "Washington Nationals",
      'HOU Astros': "Houston Astros",
      'TB Rays': "Tampa Bay Rays",
      'BAL Orioles': "Baltimore Orioles",
      'TOR Blue Jays':"Toronto Blue Jays",
      'BOS Red Sox': "Boston Red Sox",
      'MIA Marlins': "Miami Marlins",
      'ATL Braves': "Atlanta Braves",
      'CHI White Sox':'Chicago White Sox',
      'COL Rockies': "Colorado Rockies",
      'KC Royals': "Kansas City Royals",
      'SD Padres': "San Diego Padres",
      'CIN Reds': "Cincinnati Reds",
      'White Sox': 'Chicago White Sox',
      'ARI D-backs': "Arizona Diamondbacks",
      'MIN Twins': "Minnesota Twins",
      'WAS Nationals': "Washington Nationals",
      'CLE Guardians': "Cleveland Guardians",
      'TEX Rangers': "Texas Rangers",
      'CHI Cubs': "Chicago Cubs",
      'ARI Diamondbacks': "Arizona Diamondbacks",
      'STL Cardinals': "St. Louis Cardinals",
      'OAK Athletics': "Oakland Athletics",
      'PIT Pirates': 'Pittsburgh Pirates',
      'MIL Brewers': 'Milwaukee Brewers',
  },
  'eurol':{
      'Olympiacos': 'Olympiacos',
      'Real Madrid': 'Real Madrid',
      'Barcelona': 'Barcelona',
      'AS Monaco': 'AS Monaco',
      'Fenerbahçe Beko': 'Fenerbahçe Beko',
      'Maccabi Tel Aviv': 'Maccabi Tel Aviv',
      'Partizan Mozzart Bet': 'Partizan Mozzart Bet',
      'Cazoo Baskonia': 'Cazoo Baskonia',
      'Žalgiris': 'Žalgiris',
      'Anadolu Efes': 'Anadolu Efes',
      'Crvena zvezda mts': 'Crvena zvezda mts',
      'EA7 Emporio Armani Milan': 'EA7 Emporio Armani Milan',
      'Valencia Basket': 'Valencia Basket',
      'Virtus Segafredo Bologna': 'Virtus Segafredo Bologna',
      'Bayern München': 'Bayern München',
      'Bayern Munchen': 'Bayern München',
      'Panathinaikos': 'Panathinaikos',
      'Alba Berlin': 'Alba Berlin',
      'LDLC ASVEL': 'LDLC ASVEL',
      'Asvel Lyon': 'LDLC ASVEL',
      'Fenerbahce Ulker': 'Fenerbahçe Beko',
      'Crvena zvezda':'Crvena zvezda mts',
      'Saski Baskonia':'Cazoo Baskonia',
      'Olympiakos':'Olympiacos',
      'Olimpia Milano':'EA7 Emporio Armani Milan',
      'Virtus Bologna':'Virtus Segafredo Bologna',
  },
  'wnba':{
      'Atlanta Dream': 'Atlanta Dream',
      'Chicago Sky': 'Chicago Sky',
      'Connecticut Sun': 'Connecticut Sun',
      'Indiana Fever': 'Indiana Fever',
      'Los Angeles Sparks': 'Los Angeles Sparks',
      'Minnesota Lynx': 'Minnesota Lynx',
      'New York Liberty': 'New York Liberty',
      'Phoenix Mercury': 'Phoenix Mercury',
      'Las Vegas Aces': 'Las Vegas Aces',
      'Seattle Storm': 'Seattle Storm',
      'Dallas Wings': 'Dallas Wings',
      'Washington Mystics': 'Washington Mystics',
      'New York': 'New York Liberty',
      'Connecticut': 'Connecticut Sun',
      'Seattle': 'Seattle Storm',
      'Minnesota': 'Minnesota Lynx',
      'Dallas': 'Dallas Wings',
      'Phoenix': 'Phoenix Mercury',
      'Los Angeles': 'Los Angeles Sparks',
      'Chicago': 'Chicago Sky',
      'Atlanta': 'Atlanta Dream',
      'Washington': 'Washington Mystics',
      'Las Vegas': 'Las Vegas Aces',
      'Indiana': 'Indiana Fever',
      "Dream": "Atlanta Dream",
      "Sky": "Chicago Sky",
      "Sun": "Connecticut Sun",
      "Fever": "Indiana Fever",
      "Sparks": "Los Angeles Sparks",
      "Lynx": "Minnesota Lynx",
      "Liberty": "New York Liberty",
      "Mercury": "Phoenix Mercury",
      "Aces": "Las Vegas Aces",
      "Storm": "Seattle Storm",
      "Wings": "Dallas Wings",
      "Mystics": "Washington Mystics",
      'NY Liberty': 'New York Liberty',
      'IND Fever': 'Indiana Fever',
      'WAS Mystics': "Washington Mystics",
      'CON Sun': 'Connecticut Sun',
      'PHO Mercury': "Phoenix Mercury",
      'LV Aces': 'Las Vegas Aces',
      'MIN Lynx': 'Minnesota Lynx',
      'SEA Storm': "Seattle Storm",
  }
};

export const leaguestmap = Object.keys(tmap);