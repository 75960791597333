import styled, { keyframes } from "styled-components"

const fadeInOut = keyframes`
  0% {
    opacity: 0.95;
  }
  5% {
    opacity: 1.0;
  }
  80% {
    opacity: 1.0;
  }
  100% {
    opacity: 0;
  }
`;

const hue = keyframes`
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(45deg);
  }
`;

interface HeaderContainerProps {
  color: string;
};

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  position: sticky;
  background: linear-gradient(to bottom, ${({color}) => color} 70%, transparent);
  z-index: 2;
  /* border-bottom: 1px solid black; */
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0 56px 0;
  @media (max-width: 1040px) {
    flex-direction: column;
    gap: 22px;
  }
`

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ButtonAddress = styled.div`
  padding: 10px 6px;
  width: 120px;
  background: ${({color}) => color};
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  filter: brightness(120%);
  &:hover {
    filter: brightness(150%);
  }
  span {
    color: black;
    font-weight: 600;
    font-size: 16px;
  }
`

export const ButtonLarge = styled.div`
  padding: 16px 12px;
  width: 220px;
  background:  ${({color}) => color};
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  filter: brightness(120%);
  &:hover {
    filter: brightness(150%);
  }
  span {
    color: black;
    font-weight: 600;
    font-size: 18px;
  }
`

interface ButtonAltProps {
  borderColor: string;
};

export const ButtonAlt = styled.div<ButtonAltProps>`
  padding: 10px 0;
  width: 100%;
  border: 1px solid #303030;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  filter: brightness(100%);
  &:hover {
    filter: brightness(150%);
    border: 1px solid ${({borderColor}) => borderColor};
  }
  span {
    color: lightgrey;
    font-weight: 600;
    font-size: 14px;
  }
`

export const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ModalBlock = styled.div`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  opacity: 1;
  z-index: 2;
`;

interface ModalContainerProps {
  bgColor: string;
  brColor: string;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  background: ${({bgColor}) => bgColor};
  border: 1px solid ${({brColor}) => brColor};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-height: 82vh;
  width: 480px;
  padding: 16px;
  animation: slide-down 0.3s ease 1;
  z-index: 1;
  overflow-y: auto;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 48px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content:center;
`;

export const ModalTitle = styled.span`
  color: ${({color}) => color};
  padding: 0px 0px 22px 0px;
  pointer-events: all;
  position: relative;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const NoticeWrap = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 22px 0px 0px 0px;
`

export const NoticeContent = styled.div`
  color: ${({color}) => color};
  padding: 0em 1em 1em 1em;
  pointer-events: all;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 18px;
`

export const NoticeContentStrong = styled.span`
  font-weight: 400;
  cursor: pointer;
  color: ${({ color }) => color};
  a {
    color: ${({ color }) => color};
    text-decoration: none;
  }
`

export const DropDownWrapper = styled.div`
  display: inline-block;
  position: absolute;
  background: #101010;
  border: 1px solid #303030;
  border-radius: 10px;
  width: 156px;
  z-index: 2;
  margin-top: 122px;
`;

export const DropDownContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  gap: 6px;
  padding: 8px;
  span {
    color: lightgrey;
    font-weight: 600;
    font-size: 14px;
  }
  &:hover {
    background: #202020;
    filter: brightness(150%);
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 82vh;
  width: 100%;
`

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 82vh;
  @media (max-width: 1040px) {
    flex-direction: column;
  }
`

export const BodySubContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;//calc(100% - 224px);
  align-items: center;
  justify-content: top;
`

export const FooterWrapper = styled.div`
  display: flex;
  //border-top: 1px solid #303030;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  padding: 42px 0px 42px 0px;
  @media screen and (max-width: 1040px) {
    flex-direction: column;
    align-items: center;
  }
`

export const FooterSubMenuWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  //padding-left: 2%;
  gap: 12px;
  padding: 22px;
`

export const FooterSubMenu = styled.div`
  display: flex;
  flex-direction: row;
  //text-align: center;
  justify-content: center;
  align-items: left;
  //@media screen and (max-width: 1040px) {
  //  padding: 0 12px;
  //}
`

export const FooterLeftWrap = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 22px;
  @media screen and (max-width: 1040px) {
    padding: 6px;
  }
`

export const FooterLeftContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  //padding-left: 2%;
  gap: 12px;
  padding: 22px;
  height: 220px;
`

export const FooterCopyRightWrap = styled.div`
  padding: 6px;
  padding-top: 0px;
  display: inline-block;
  @media screen and (max-width: 1040px) {
    padding: 6px 0px;
  }
`

export const FooterCopyRight = styled.span`
  font-size: 0.95rem;
  font-weight: 400;
  color: ${({ color }) => color};
  letter-spacing: 0.025rem;
  vertical-align: middle;
  @media screen and (max-width: 1040px) {
    white-space: nowrap;
    //font-size: 10px;
  }
`

export const FooterSubMenuTitleWrap = styled.div`
  padding: 22px 12px;
`

export const FooterSubMenuTitle = styled.span`
  color: ${({ color }) => color};
  &:hover {
    color: grey;
  }
`

export const FooterSubMenuContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`

export const FooterContentWrap = styled.div`
  padding: 6px 0;
  @media screen and (max-width: 1040px) {
    padding: 2px 0;
  }
`
export const FooterSubMenuContent = styled.span`
  font-size: 0.95rem;
  font-weight: 400;
  letter-spacing: 0rem;
  color: ${({ color }) => color};
  //@media screen and (max-width: 1040px)  {
  //  font-size: 10px;
  //}
  &:hover {
    color: #25adea;
  }
`

export const FooterSubMenuLogoContent = styled.span`
  padding-right: 6px;
  @media screen and (max-width: 1040px) {
    display: none;
  }
`

export const FooterLogoWrapper = styled.span`
  img{
    padding-right: 2px;
    vertical-align: middle;
    width: 112px;
    height: 112px;
    @media screen and (max-width: 1040px) {
      width: 38px;
      height: 38px;
    }
  }
`;

export const FooterLink = styled.div`
  //margin-right: 22px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  &:hover {
    color: lightgrey;
  }
`

export const SnackBarWrapper = styled.div`
  position: fixed;
  width: fit-content;
  bottom: 30px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 42px;
  pointer-events: none;
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 10px;
  min-width: 250px;
  animation: ${fadeInOut} 2s linear infinite;
`;

export const SnackBarTileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 20px;

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const LargeTitle = styled.span`
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  -webkit-animation: ${hue} 2s infinite linear;
  background: ${({color}) => color};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-direction: alternate;
`