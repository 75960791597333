import React from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa"
import styled from "styled-components"
import { Config as config } from "../../lib/config";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";

interface BoxProps {
  bgColor: string;
}

export const JoinUsMainWrapper = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  padding: 100px 0 120px 0;
  background: ${({bgColor}) => bgColor};
  overflow-y: hidden;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const JoinUsWrapper = styled.div`
  max-width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 0px auto;
  background-size: 100%;
  background-position-y: 60px;
  font-size: 30px;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 600px) {
    height: fit-content;
  }
`;

export const JoinUsButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  padding: 5px 25px;
  border-radius: 10px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  font-size: 1rem;
  filter: brightness(1.2);
  transition: all 0.3s ease-in-out;
  @media (max-width: 420px) {
    font-size: 16px;
  }
  &:hover {
    filter: brightness(1.4);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 1);
  }
`;

export const SubText = styled.span`
  font-weight: 400;
  font-size: 1.1rem;
  color: ${({color}) => color};
  text-align: center;
  line-height: 27px;
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`

export const MainText = styled.span`
  font-weight: 800;
  font-size: 2rem;
  color: ${({color}) => color};
  text-align: center;
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
`

const JoinUsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 22px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const JoinUs = () => {
  const {theme} = useGetTheme();
  return (
    <JoinUsMainWrapper bgColor={getThemeData(theme).bgBox1}>
      <JoinUsWrapper>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "22px" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "12px" }}>
            <MainText color={getThemeData(theme).iconColor}>
              {"Join Us"}
            </MainText>
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", maxWidth: "360px"}}>
            <SubText color={getThemeData(theme).textColor}>
              {"Be part of our growing ecosystem and stay updated on new sports and features."}
            </SubText>
          </div>
        </div>
        <JoinUsButtonWrapper>
          <JoinUsButton
            onClick={() => {
              window.open(config.discordUrl, "_blank");
            }}
          >
            <FaDiscord size={26} color={getThemeData(theme).iconColor} />
            <p style={{fontWeight: 600, color: (theme === 'dark' ? getThemeData(theme).colorAccent : 'black')}}>
              {`DISCORD`}
            </p>
          </JoinUsButton>
          <JoinUsButton
            onClick={() => {
              window.open(config.twitterUrl, "_blank");
            }}
          >
            <FaTwitter size={24} color={getThemeData(theme).iconColor} />
            <p style={{fontWeight: 600, color: (theme === 'dark' ? getThemeData(theme).colorAccent : 'black')}}>
              {`TWITTER`}
            </p>
          </JoinUsButton>
        </JoinUsButtonWrapper>
      </JoinUsWrapper>
    </JoinUsMainWrapper>
  );
};

export default JoinUs;