import {
  PLAN_1_PRICE_ID_MONTHLY,
  PLAN_1_PRICE_ID_YEARLY,
  PLAN_2_PRICE_ID_MONTHLY,
  PLAN_2_PRICE_ID_YEARLY,
  PLAN_3_PRICE_ID_MONTHLY,
  PLAN_3_PRICE_ID_YEARLY,
} from "../../lib/config";

export const plansData = [
  {
    type: "planFree",
    name: "Free", //"Starter",
    save: 0,
    price_monthly: 0,
    price_yearly: 0,
    price_id_monthly: 0,
    price_id_yearly: 0,
    note: "",
    isRecommended: false,
    benefits: [
      {temph: "1,000 requests", text: " per month", included: true},
      {temph: "", text: "All sports", included: true},
      {temph: "", text: "All bookies", included: true},
      {temph: "", text: "All betting markets", included: true},
      {temph: "", text: "Basic betting stats", included: true},
      {temph: "", text: "Crunchtime App Search AI", included: true},
      {temph: "", text: "Historical odds data", included: false},
      {temph: "", text: "Advanced betting stats", included: false},
      {temph: "", text: "Locked Crunchtime App Data", included: false},
      {temph: "", text: "CSV Downloads", included: false},
      {temph: "", text: "Private Discord Channel", included: false},
      {temph: "", text: "Custom Odds Alerts", included: false},
      {temph: "", text: "Custom Scripts", included: false},
    ],
  },
  {
    type: "planStarter", //"planPro",
    name: "Starter", //"Professional",
    save: 20,
    price_monthly: 19.99,
    price_yearly: 191.88,
    price_id_monthly: PLAN_1_PRICE_ID_MONTHLY,
    price_id_yearly: PLAN_1_PRICE_ID_YEARLY,
    note: "",
    isRecommended: true,
    benefits: [
      {temph: "50,000 requests", text: " per month", included: true},
      {temph: "", text: "All sports", included: true},
      {temph: "", text: "All bookies", included: true},
      {temph: "", text: "All betting markets", included: true},
      {temph: "", text: "Basic betting stats", included: true},
      {temph: "", text: "Crunchtime App Search AI", included: true},
      {temph: "", text: "Historical odds data", included: true},
      {temph: "", text: "Advanced betting stats", included: true},
      {temph: "", text: "Locked Crunchtime App Data", included: true},
      {temph: "", text: "CSV Downloads", included: false},
      {temph: "", text: "Private Discord Channel", included: false},
      {temph: "", text: "Custom Odds Alerts", included: false},
      {temph: "", text: "Custom Scripts", included: false},
    ],
  },
  /*
  {
    type: "planPremium",
    name: "Premium",
    save: 20,
    price_monthly: 49.99,
    price_yearly: 479.88,
    price_id_monthly: PLAN_2_PRICE_ID_MONTHLY,
    price_id_yearly: PLAN_2_PRICE_ID_YEARLY,
    note: "",
    isRecommended: false,
    benefits: [
      {text: "500,000 requests per month", included: true},
      {text: "All sports", included: true},
      {text: "All bookies", included: true},
      {text: "All betting markets", included: true},
      {text: "Basic betting stats", included: true},
      {text: "Crunchtime App Search AI", included: true},
      {text: "Historical odds data", included: true},
      {text: "Advanced betting stats", included: true},
      {text: "Locked Crunchtime App Data", included: true},
      {text: "CSV Downloads", included: true},
    ],
  },
  */
  {
    type: "planPremium", //"planElite",
    name: "Premium", //"Elite",
    save: 20,
    price_monthly: 99.99,
    price_yearly: 959.88,
    price_id_monthly: PLAN_3_PRICE_ID_MONTHLY,
    price_id_yearly: PLAN_3_PRICE_ID_YEARLY,
    note: "",
    isRecommended: false,
    benefits: [
      {temph: "5M requests", text: " per month", included: true},
      {temph: "", text: "All sports", included: true},
      {temph: "", text: "All bookies", included: true},
      {temph: "", text: "All betting markets", included: true},
      {temph: "", text: "Basic betting stats", included: true},
      {temph: "", text: "Crunchtime App Search AI", included: true},
      {temph: "", text: "Historical odds data", included: true},
      {temph: "", text: "Advanced betting stats", included: true},
      {temph: "", text: "Locked Crunchtime App Data", included: true},
      {temph: "", text: "CSV Downloads", included: true},
      {temph: "", text: "Private Discord Channel", included: true},
      {temph: "", text: "Custom Odds Alerts", included: true},
      {temph: "", text: "Custom Scripts", included: true},
    ],
  },
];