import { create } from "zustand";

export const pressEnterForm = create((set) => ({
  isPressed: false,
  sign: "",
  dummy: false,
  setSign: ({ sign }) => set((state) => ({ sign: sign })),
  setDummy: () => set((state) => ({ dummy: !state.dummy })),
  setTrue: () => set((state) => ({ isPressed: true })),
  setIsPressed: ({ isPressed }) => set((state) => ({ isPressed: isPressed })),
}));

export const generalSearchState = create((set) => ({
  text: "",
  show: false,
  setShow: (show) => set({ show }),
  setText: ({ text }) => set((state) => ({ text: text })),
}));