import { SUPPORTED_FILES } from "./constants";

export function sortArrays(arrays, comparator = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0) {
  let arrayKeys = Object.keys(arrays);
  let sortableArray = Object.values(arrays)[0];
  let indexes = Object.keys(sortableArray);
  let sortedIndexes = indexes.sort((a, b) => comparator(sortableArray[a], sortableArray[b]));

  let sortByIndexes = (array, sortedIndexes) => sortedIndexes.map(sortedIndex => array[sortedIndex]);

  return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));
}

export function normalize(min, max, val) {
  return (val - min) / (max - min);
}

export function matchBetRow(row, item) {
  if (item.c3 === 'Moneyline' && row.bet_type.includes('Moneyline')) {
    return (
      (row.site_id === item.c4) &&
      (row.current > 0 && item.c5 === "Underdog" || row.current < 0 && item.c5 === "Favored") &&
      (row.bet_type === item.c1+" "+`${item.c3} vs ${item.c11}`)
    )
  } else if (item.c3 === "Spread" && row.bet_type.includes('Spread')) {
    return (
      (row.site_id === item.c4) &&
      (row.current > 0 && item.c5 === "Underdog" || row.current < 0 && item.c5 === "Favored") &&
      (row.bet_type === item.c1+" "+`${item.c3} ${item.c11}`)
    )
  } else if (
    (item.c3 === "Over" || item.c3 === "Under") &&
    (row.bet_type.includes('Over') || row.bet_type.includes("Under"))
  ) {
    return (
      (row.site_id === item.c4) &&
      (row.current > 0 && item.c5 === "Underdog" || row.current < 0 && item.c5 === "Favored") &&
      (row.bet_type === item.c1+" "+`${item.c3} ${item.c11}`)
    )
  } else {
    return false;
  }
}

export const download = function (data, file_name) {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8," });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${file_name}.csv`);
  a.click();
};

export const csvmaker = function (data, inc_headers = true) {
  var csvRows = ["sep=,"];
  var csvDelim = ",";
  if (inc_headers) {
    const headers = Object.keys(data);
    csvRows.push(headers.join(csvDelim));
  }
  const values = Object.values(data).join(csvDelim);
  csvRows.push(values);
  if (inc_headers) {
    return csvRows.join("\n");
  }
  return "\n" + values;
};

export async function handleDataDownload(dataRows, dataName){
  const csvdata = dataRows.map((row, index) => {
    var item = row;
    var inchead = true;
    if (index > 0) {
      inchead = false;
    }
    if (dataName.includes('top-bets') || dataName.includes('query-result')) {
      return csvmaker(
        {
          league: item.c2,
          bet_desc: (
            item.c3 === "Moneyline"
              ? `${item.c1} ${row.c3} vs ${row.c11}`
              : item.c3 === "Spread"
                ? `${item.c1} ${row.c3} ${row.c11}`
                : `${item.c1} ${row.c3} ${row.c11}`
          ),
          site_id: item.c4,
          bet_position: item.c5,
          unit_profit_7d: item.c6,
          unit_profit_1m: item.c7,
          unit_profit_3m: item.c8,
          unit_profit_1y: item.c9,
          unit_profit_all_time: item.c10
        },
        inchead
      );
    } else if (dataName.includes('trending-bets')) {
      return csvmaker(
        {
          league: item.league,
          game_desc: item.game_desc.replaceAll(","," -"),
          site_id: item.site_id,
          team_id: item.team_id,
          current: item.current,
          first: item.first,
          lowest: item.lowest,
          highest: item.highest,
          standard_deviation: item.standard_deviation,
          change: item.change,
          change_highest: item.change_highest,
          change_lowest: item.change_lowest,
          bet_type: item.bet_type,
          vig: item.vig
        },
        inchead
      );
    }
  });
  console.log(csvdata);
  download(csvdata, `${dataName}`);
};

export const getCurSearchQuery = (title) => {
  return encodeURIComponent(title);
}

export const getCurURLTwitter = (url) => {
  return encodeURIComponent(url);
}

export const getTeamLogo = (
  league_id,
  team_id,
  logo_width = "18px",
  logo_height = "18px"
) => {
  if (SUPPORTED_FILES.includes(`${league_id.toLowerCase()}/${team_id}.png`)) {
    return (
      <img
        src={require(`../../assets/leagues/${league_id.toLowerCase()}/${team_id}.png`)}
        style={{
          width: logo_width,
          height: logo_height,
          display: "inline-block",
          verticalAlign: "middle",
          paddingBottom: "4px",
        }}
      />
    );
  } else {
    return null;
  }
}

export const getLeagueLogo = (
  league_id,
  logo_width = "18px",
  logo_height = "18px"
) => {
  if (SUPPORTED_FILES.includes(`${league_id.toLowerCase()}/_${league_id.toUpperCase()}.png`)) {
    return (
      <img
        src={require(`../../assets/leagues/${league_id.toLowerCase()}/_${league_id.toUpperCase()}.png`)}
        style={{
          width: logo_width,
          height: logo_height,
          display: "inline-block",
          verticalAlign: "middle",
          paddingBottom: "4px",
        }}
      />
    );
  } else {
    return null;
  }
}

/*
export const getOverUnderLogo = (
  ou_type,
  logo_width = "18px",
  logo_height = "18px"
) => {
  if (ou_type === 'over') {
    return (
      <img
        src={require(`../../assets/Over.png`)}
        style={{
          width: logo_width,
          height: logo_height,
          display: "inline-block",
          verticalAlign: "middle",
          paddingBottom: "4px",
        }}
      />
    );
  } else if (ou_type === 'under') {
    return (
      <img
        src={require(`../../assets/Under.png`)}
        style={{
          width: logo_width,
          height: logo_height,
          display: "inline-block",
          verticalAlign: "middle",
          paddingBottom: "4px",
        }}
      />
    );
  } else {
    return null;
  }
}
*/