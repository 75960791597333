import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';
import { getThemeData } from "./theme";
import {
  ThemeContext,
  HeaderDisplayContext,
  AcceptedCookiesContext,
  OddsContext,
  SearchHistoryContext,
} from './lib/contexts';
import Header from "./components/Header";
import Footer from "./components/Footer";
//import Docs from "./components/Docs";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
//import Account from "./components/Account";
import Dash from "./components/Dash";
import NotFound from "./components/NotFound";
import { createGlobalStyle } from "styled-components";
//import { Config as config} from "./lib/config";
//import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import styled from "styled-components";
import ScrollToTop from './ScrollToTop';
import { AuthProvider } from './lib/auth';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LandingAPI from './components/LandingAPI';

const ElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `

const ProtectedRoute = () => {

  const auth = sessionStorage.getItem("auth");
  var a = window.location.href;
  var b = a.substring(a.indexOf("/account") + 1);
  //console.log(b)

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <>
      {auth !== undefined && auth !== null && JSON.parse(auth) === true
        ? <Elements stripe={stripePromise}>
            <ElementWrapper>
              <Dash content={"account"}/>
            </ElementWrapper>
          </Elements>
        : <Navigate to="/" state={{ from: b }}/>
      }
    </>
  );
};

function App() {

  //const [theme, setTheme] = useState('light');
  const [theme, setTheme] = useState(
    JSON.parse(localStorage.getItem("theme")) || 'dark'
  );
  const [headerDisplay, setHeaderDisplay] = useState(
    JSON.parse(localStorage.getItem("headerDisplay")) || "show"
  );
  const [odds, setOdds] = useState(
    JSON.parse(localStorage.getItem("oddsType"))
      ? parseInt(JSON.parse(localStorage.getItem("oddsType")))
      : 2
  );
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(
    JSON.parse(localStorage.getItem("hasAcceptedCookies")) || "no"
  );
  const [prevSearches, setPrevSearches] = useState(
    JSON.parse(localStorage.getItem("prevSearches")) || ""
  );

  const GlobalStyle = createGlobalStyle`
    *{
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${getThemeData(theme).colorAccent};
        border-radius: 10px;
      }
      @media (max-width: 600px) {
        ::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        ::-webkit-scrollbar-track {
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${getThemeData(theme).bgGlobal};
        }
      }
    }
    body {
      background: ${getThemeData(theme).bgGlobal};
      font-family: Avenir Next, Arial, sans-serif;
      //font-family: 'Montserrat', sans-serif;
    }
  `;

  /* not currently using
  const isMobile = () => {
    if (window.innerWidth <= 420) {
      return true
    } else {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    }
  };
  */

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  /* not currently using
  const isApp = () => {
    const currentDomain = process.env.REACT_APP_URL.replace("https://","").replace("http://","");
    console.log(currentDomain);
    if (window.location.href.toString().includes(`app.${currentDomain}`)) {
      return true;
    } else {
      return false;
    }
  }
  */

  useEffect(() => {
    console.log("THEME", theme)
    localStorage.setItem("theme", JSON.stringify(theme))
  },[theme])

  useEffect(() => {
    console.log("HEADER DISPLAY", headerDisplay)
    localStorage.setItem("headerDisplay", JSON.stringify(headerDisplay))
  },[headerDisplay])

  useEffect(() => {
    console.log("ODDS TYPE", odds)
    localStorage.setItem("oddsType", JSON.stringify(odds))
  },[odds])

  useEffect(() => {
    localStorage.setItem("hasAcceptedCookies", JSON.stringify(hasAcceptedCookies))
  },[hasAcceptedCookies])

  useEffect(() => {
    localStorage.setItem("prevSearches", JSON.stringify(prevSearches))
  },[prevSearches])

  const app = () => (
    <AcceptedCookiesContext.Provider value={{hasAcceptedCookies, setHasAcceptedCookies}}>
      <ThemeContext.Provider value={{theme, setTheme}}>
        <OddsContext.Provider value={{odds, setOdds}}>
          <HeaderDisplayContext.Provider value={{headerDisplay, setHeaderDisplay}}>
            <SearchHistoryContext.Provider value={{prevSearches, setPrevSearches}}>
              <AuthProvider>
                <div>
                  <GlobalStyle/>
                  <Router>
                    <ScrollToTop />
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"home"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path={"/company"}
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Header/>
                              <LandingAPI/>
                              <Footer/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path={'terms-of-service'}
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Header/>
                              <Terms/>
                              <Footer/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path={'privacy'}
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Header/>
                              <Privacy/>
                              <Footer/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path="bets/*"
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"bets"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path="q/*"
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"q"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path="help"
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"help"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path="api-spec"
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"api-spec"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path={"api-docs/*"}
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"api-docs"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path="hedger"
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Dash content={"hedger"}/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      <Route
                        path="account"
                        element={
                          <ProtectedRoute/>
                        }
                      />
                      {/*
                      <Route
                        path="*"
                        exact={true}
                        element={
                          <Elements stripe={stripePromise}>
                            <ElementWrapper>
                              <Header/>
                              <NotFound/>
                              <Footer/>
                            </ElementWrapper>
                          </Elements>
                        }
                      />
                      */}
                    </Routes>
                  </Router>
                </div>
              </AuthProvider>
            </SearchHistoryContext.Provider>
          </HeaderDisplayContext.Provider>
        </OddsContext.Provider>
      </ThemeContext.Provider>
    </AcceptedCookiesContext.Provider>
  );

  /*
  const landing = () => (
    <ThemeContext.Provider value={{theme, setTheme}}>
      <OddsContext.Provider value={{odds, setOdds}}>
      <AuthProvider>
        <div>
          <GlobalStyle/>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route
                path={"/"}
                element={
                  <Elements stripe={stripePromise}>
                    <ElementWrapper>
                      <Header/>
                      <LandingAPI/>
                      <Footer/>
                    </ElementWrapper>
                  </Elements>
                }
              />
              <Route
                path={'terms-of-service'}
                element={
                  <Elements stripe={stripePromise}>
                    <ElementWrapper>
                      <Header/>
                      <Terms/>
                      <Footer/>
                    </ElementWrapper>
                  </Elements>
                }
              />
            </Routes>
          </Router>
        </div>
      </AuthProvider>
      </OddsContext.Provider>
    </ThemeContext.Provider>
  );
  */

  //if (!(isMobile())) {
  //  return app();
  //} else {
  //  return app();
  //}

  //if (isApp()) {
  //  return app();
  //} else {
  //  return landing();
  //}

  return app();

}

export default App;