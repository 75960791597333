import styled from "styled-components";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { useEffect, useState } from "react";

const PrivacyContainer = styled.div`
  width: ${({isMobile}) => isMobile ? "90%" : "75%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 22px;
  padding: 22px 0 82px 0;
`

const Header = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 24px;
`

const SubHeader = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 20px;
`

const SubSubHeader = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 600;
  font-size: 18px;
`

const SubSubSubHeader = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
`

const Content = styled.span`
  color: ${({color}) => color};
  font-family: Avenir Next, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 18px;
`

const Bullet = styled.span`
  display: list-item;
  list-style-type: disc;
  margin-left: 42px;
  margin-top: 12px;
`

const EmailText = styled.span`
  color: ${({color}) => color};
`

const Privacy = () => {
  const {theme} = useGetTheme();

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <PrivacyContainer isMobile={windowDimension.winWidth < 1040}>
      <Header color={getThemeData(theme).textColor}>
        CRUNCHTIMEODDS PRIVACY POLICY
      </Header>
      <SubHeader color={getThemeData(theme).textColor}>
        Last Updated: June 12, 2024
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        CrunchtimeOdds (www.crunchtimeodds.com), as well as any present or future affiliated or related company ("we"), recognizes the importance of your privacy.
        This privacy policy tells you what personal information we collect and how we use it.
        CrunchtimeOdds may change this privacy policy from time to time, in its sole discretion, as discussed in section 8 below.
        By using or visiting any CrunchtimeOdds website, application, product, software, tool, API, and/or service (collectively the "Service"), you understand and agree to the terms of this policy.
        Please read this policy carefully.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        1. What Information Do We Collect?
      </SubHeader>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Information You Provide To Us
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        We may collect personally identifiable information when you specifically and knowingly provide it to us, for example when you contact us and provide personal information such as your e-mail address, name, phone number, or other information.
        Where applicable, personally identifiable information includes "personal data" as defined by applicable law.
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Information We Receive From Your Use Of Our Services
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        We may collect and aggregate certain information derived from your use of our Services.
        This information may include information such as a website that referred you to us, your IP address, browser type and language, hardware types, geographic location, access times and durations, queries submitted to the Service (including text translation of any audio queries).
        We also may collect navigational information, including information about the Service content you access or are served in response to your written or audio request, or pages you view, the links you click, and other actions taken in connection with the Service.
        We also collect information about your activity in our Services, which we use to do things like generate content or improve our models.
        The activity information we collect may include search history and interactions with the Service.
        We use this information to analyze usage patterns as part of making improvements to the Service.
        All information we collect may be used as training data in our own AI datasets.
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Third Parties
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        This Privacy Policy does not apply to the privacy practices of third parties that we do not own or control, including but not limited to any third party voice assistants, websites, services, applications, online resources to which the Service may link to, be accessed from, or otherwise reference (collectively "Third Party Services" or "TPS").
        We encourage you to carefully review the privacy policies of any TPS you access.
        You may have the option to register or access the Service using a TPS.
        By authorizing us to access your TPS account, you authorize us to obtain certain information from your TPS account, which may include your name, email address, associated IP addresses or other available information.
        We only obtain information from your TPS account that you specifically authorize and grant us permission to obtain.
        CrunchtimeOdds does not consider personally identifiable information to include information that has been anonymized so that it does not allow a third party to identify a specific individual.
        We collect and use your personally-identifiable information to: provide the Service; operate and improve our Service; provide customer service; perform research and analysis aimed at improving our products, Service and technology; and display content that is customized to your interests and preferences.
        You may always choose not to provide personally identifiable information, but if you so choose, certain parts of the Service may not be available to you.
        If you have registered an account with us, you will have agreed to provide your personally identifiable information in order to access the Service.
        This consent provides us with the legal basis we require under applicable law to process your data.
        You maintain the right to withdraw such consent at any time.
        If you do not agree to our use of your personal data in line with this Policy, please do not use our Service.
        The Service may be accessed through mobile phones, websites, and audio TSP (such as personal assistant or Artificial Intelligence apps) which have their own terms of use and privacy policies, particularly regarding the handling of audio content submitted by a user.
        We encourage you to review such terms and policies as they may apply to your use of the Service.
        We have no responsibility for the terms and policies of any hardware, software, application, or TSP.
        We do not collect sensitive personal data and we ask that you not send us, and you not disclose, any sensitive personal data (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through the Service or otherwise to us.
        If you send or disclose any sensitive personal data to us when you submit content to the Service, you consent to our processing and use of such sensitive personal data in accordance with this Policy.
        If you do not consent to our processing and use of such sensitive personal data, you must not submit such content to us.
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Cookies and Local Storage
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        We may collect information using "cookies", which are small data files stored on the hard drive of your computer or mobile device by a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience when using the Service.
        We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which are used only by us to recognize your computer or mobile device when it revisits any site or application that is party of the Service; and (2) third party cookies, which are served by service providers on sites or applications and can be used by such service providers to recognize your computer or mobile device when it visits other websites.
      </Content>
      <SubSubHeader color={getThemeData(theme).textColor}>
        Cookies we use
      </SubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        The Service uses the following types of cookies for the purposes set out below:
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Essential Cookies
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        These cookies are essential to provide you with services available through the Service and to enable you to use some of its features. For example, they allow you to log in to secure areas of the sites or applications and help the content of the pages you request load quickly. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Functionality Cookies
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        These cookies allow the Service to remember choices you make when you use a site or application, such as remembering your preferred display theme and language, remembering your login details and remembering the changes you make to other parts of your account, or preferences. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Service.
        These cookies are used to collect information about traffic to the Service and how users use the Service. The information gathered does not identify any individual visitor. The information is aggregated and anonymous. It includes the number of visitors to, the websites that referred them, the pages visited, what time of day they visited, whether they have visited before, and other similar information. We use this information to help operate the Service more efficiently, to gather broad demographic information and to monitor the level of activity on our Service.
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Analytics and Performance Cookies
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how the Service work. You can find out more information about Google Analytics cookies here: https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
        You can find out more about how Google protects your data here: www.google.com/analytics/learn/privacy.html.
        You can prevent the use of Google Analytics relating to your use of the Service by downloading and installing the browser plugin available via this link: http://tools.google.com/dlpage/gaoptout?hl=en-GB
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Social Media Cookies
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        These cookies are used when you share information using a social media sharing button or "like" button on any site or application for the Service or you link your account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+.
        The social network will record that you have done this.
      </Content>
      <SubSubHeader color={getThemeData(theme).textColor}>
        Disabling cookies
      </SubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        You can typically remove or reject cookies via your browser settings.
        In order to do this, follow the instructions provided by your browser (usually located within the "settings," "help" "tools" or "edit" facility).
        Many browsers are set to accept cookies until you change your settings.
        If you do not accept our cookies, you may experience some inconvenience in your use of the Service.
        For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you use the Service.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        2. What Do We Do With The Information That We Collect?
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        Except as disclosed in this privacy policy, CrunchtimeOdds does not sell or share your personal information with any outside parties.
      </Content>
      <Content color={getThemeData(theme).textColor}>
        <SubSubSubHeader color={getThemeData(theme).textColor}>
          CrunchtimeOdds will use your information:
        </SubSubSubHeader>
        <Bullet> to operate, maintain, and improve the Service; </Bullet>
        <Bullet> to manage your account, including to communicate with you regarding your account; </Bullet>
        <Bullet> to operate and administer any promotions you participate in on any site or application; </Bullet>
        <Bullet> to respond to your comments and questions and to provide customer service; </Bullet>
        <Bullet> to send information including technical notices, updates, security alerts, and support and administrative messages; </Bullet>
        <Bullet>
          to, with your consent, send you marketing e-mails about upcoming promotions, and other news, including information about products and services offered by us and our affiliates.
          You may opt-out of receiving such information at any time: such marketing emails tell you how to "opt-out."
          Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails.
          Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you;
        </Bullet>
        <Bullet>
          as we believe necessary or appropriate
          (a) to comply with applicable laws;
          (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities;
          (c) to enforce our Policy; and
          (d) to protect our rights, privacy, safety or property, and/or that of you or others;
        </Bullet>
        <Bullet> to process payments you make via the Service; and </Bullet>
        <Bullet> as training data for our own AI datasets. </Bullet>
      </Content>
      <Content color={getThemeData(theme).textColor}>
        We may share this information with service providers who perform services on our behalf, or other services like processing information requests, displaying stored data you access, to assist us in marketing, to conduct audits, etc.
        Those companies will be permitted to obtain only the personal information they need to provide the service they provide, will be required to maintain the confidentiality of the information, and will be prohibited from using it for any other purpose.
      </Content>
      <Content color={getThemeData(theme).textColor}>
        We may also use information you provide to better serve you, and, if you have given your consent for us to do so, to send you email or text messages concerning offers from our partners and other third parties that we think may be of interest to you.
        If you do not wish to receive marketing emails, you may adjust your "Personal Information Preferences" as described below or follow the "unsubscribe" or "stop" instructions included within each communication.
      </Content>
      <Content color={getThemeData(theme).textColor}>
        We will only retain your personally identifiable information as long as reasonably required to provide you with the Service unless a longer retention period is required or permitted by law (for example, for regulatory purposes).
      </Content>
      <Content color={getThemeData(theme).textColor}>
        You may contact us anytime to opt-out of:
        (i) direct marketing communications;
        (ii) our collection of sensitive personal data;
        (iii) any new processing of your personal data that we may carry out beyond the original purpose; or
        (iv) the transfer of your personal data outside the European Economic Area.
        Please note that your use of some of the Service may be ineffective upon opt-out.
        You may also:
        (A) access the data we hold about you at any time via your account or by contacting us directly;
        (B) update or correct any inaccuracies in your personal data by contacting us;
        (C) move your data to other service provider;
        (D) in certain situations, for example when the data we hold about you is no longer relevant or is incorrect, you can request that we erase your data.
        You may contact us at <EmailText color={getThemeData(theme).colorAccent}>team@crunchtimeodds.com</EmailText> anytime for any other questions you may have about your personally identifiable information and our use of it.
      </Content>
      <SubSubSubHeader color={getThemeData(theme).textColor}>
        Disclosure
      </SubSubSubHeader>
      <Content color={getThemeData(theme).textColor}>
        As a general rule, CrunchtimeOdds will not disclose any of your personally identifiable information except under one of the following circumstances:
        we have your permission, including the permission granted by your acceptance of this Privacy Policy;
        we determine in good faith that it is legally required to be revealed by any relevant statute, regulation, ordinance, rule, administrative or court order, decree, or subpoena;
        it is information that we determine must be disclosed to correct what we believe to be false or misleading information or to address activities that we believe to be manipulative, deceptive or otherwise a violation of law;
        where you are otherwise notified at the time we collect the data;
        where we need to share your information to provide the product or service you have requested;
        when such disclosure is made subject to confidentiality restrictions in connection with a sale, merger, transfer, exchange, or other disposition (whether of assets, stock, or otherwise) of all or a portion of the business conducted by CrunchtimeOdds.
        CrunchtimeOdds may share the non-personally identifiable information that CrunchtimeOdds gathers, in aggregate form only, with advertisers and other partners.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        3. Children's Policy
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        The Terms of Service clearly provide that Users must be (i) 18 or older, or (ii) 13 and older if either (a) an emancipated minor, or (b) he/she possess legal parental or guardian consent.
        CrunchtimeOdds does not knowingly collect personally identifiable information from users under 13.
        In the event that we learn that we have collected any personal information from a user under the age of 13, we will attempt to identify and delete that information from our database.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        4. International Usage
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        The Service is owned by CrunchtimeOdds and may be accessed in the United States and abroad.
        For data protection purposes, CrunchtimeOdds is the controller and, unless otherwise noted, is also the processor of data.
        Information collected may be retained, and may be stored, processed, accessed, and used in jurisdictions whose privacy laws may be different and less protective than those of your home jurisdiction.
        If you are located outside of the United States, please note that the information you provide to us may be transferred to the United States.
        By using the Service, API, application and/or website, you consent to such transfer.
        We will take reasonable steps to ensure that your data is treated securely and in accordance with this Policy.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        5. How Does A User Change Or Update Information?
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        If you have any questions or concerns about this privacy policy or would like the personally identifiable information that you have provided to be removed from our files, please contact CrunchtimeOdds via email at <EmailText color={getThemeData(theme).colorAccent}>team@crunchtimeodds.com</EmailText>.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        6. California Privacy Disclosures
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        <SubSubSubHeader color={getThemeData(theme).textColor}> Privacy Rights of California Residents: </SubSubSubHeader> Under the California Consumer Privacy Act and the California Privacy Rights Act (together, the "CCPA/CPRA"), and their respective amendments and implementing regulations, California residents are afforded certain privacy rights.
        If you are a California resident, subject to certain limitations, you have the following rights concerning your Personal Information:
        <Bullet> Right to Know. You have the right to access the specific pieces of personal information collected about you, the sources from which such information was obtained, the categories of information that have been sold or disclosed for a business purpose, and the commercial purpose for which the information was collected or sold. </Bullet>
        <Bullet> Right to Deletion. You have the right to request that we delete any of the personal information we have collected or maintain about you. </Bullet>
        <Bullet> Right to Rectification and Correction. You have the right to rectify and correct inaccuracies in your personal information. </Bullet>
        <Bullet> Right to Opt-out of Sales of Personal Information. You have a right to opt-out of the sale of your personal information, including cross-context behavioral advertising, and to opt-in again at your election. As described in this policy, we do not sell your personal information. </Bullet>
        <Bullet> Right to Limit Use and Disclosure of Sensitive Personal Information. You have a right to opt-out of secondary uses and disclosures of your sensitive personal information. We do not collect sensitive personal information. </Bullet>
        <Bullet> Right to Opt-out of Automated Decision-making. You have the right to opt-out to certain types of automated decision-making, subject to certain exceptions. </Bullet>
        <Bullet> Right to Non-Discrimination. You have a right to not be discriminated against in the event you exercise your other rights listed above. If you exercise these rights, we will not deny you goods or services, charge you a higher rate, or provide you with lower quality products. </Bullet>
      </Content>
      <Content color={getThemeData(theme).textColor}>
        <SubSubSubHeader color={getThemeData(theme).textColor}> Categories Of Information We Collect, Use, And Disclose For Business Purposes: </SubSubSubHeader>
        As described in this policy, we collect the categories of personal information listed below.
        We receive this information from you, your use of the Services, and as otherwise described in this notice.
        We use and disclose these categories of information for the business purposes described in this policy.
        The categories are:
        <Bullet> Identifiers, like your name, email address, IP address, cookie ID, and other similar identifiers. </Bullet>
        <Bullet> Demographic information, such as your gender or age. </Bullet>
        <Bullet> Commercial information, including records of any products or services you purchased. </Bullet>
        <Bullet> Internet or other electronic network activity information, such as browsing history and interactions with our Services. </Bullet>
        <Bullet> Professional or employment-related information, if you applied for a job with us. </Bullet>
        <Bullet> Other information that you provide. </Bullet>
        <Bullet> Inferences drawn from any of the above. </Bullet>
      </Content>
      <Content color={getThemeData(theme).textColor}>
        <SubSubSubHeader color={getThemeData(theme).textColor}> Exercising Your Rights Under The CCPA/CPRA: </SubSubSubHeader>
        You, or an authorized agent, may exercise your rights by submitting a request to <EmailText color={getThemeData(theme).colorAccent}>team@crunchtimeodds.com</EmailText>.
        The CCPA/CPRA allows for one disclosure request per 12-month period at no cost to you.
        For subsequent requests, we may charge a fee if we determine that the request is excessive, repetitive, or manifestly unfounded, but will not do so without providing you with a cost estimate before processing your request.
      </Content>
      <Content color={getThemeData(theme).textColor}>
        We respond to and process requests promptly and within the timeframes required by the CCPA/CPRA.
        Please note that all requests are subject to proper prior verification before processing, as required by the CCPA/CPRA.
        If you are the authorized agent of an individual making a request, you may have to take additional steps to verify your identity and demonstrate that you can exercise the individua's rights on their behalf.
      </Content>
      <Content color={getThemeData(theme).textColor}>
        <SubSubSubHeader color={getThemeData(theme).textColor}> Sale Of California Resident Personal Information: </SubSubSubHeader>
        We do not sell your personal information, and we do not share your personal information except in the limited circumstances described above.
        We do work with partners who provide us with advertising services as described in the Cookies section in this policy.
        To learn more about how these partners collect data and your options for controlling the use of your information for interest-based advertising, please read that section.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        7. Security and Encryption
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        We follow generally accepted industry standards to help protect your personal information.
        No method of transmission over the internet, mobile technology, or method of electronic storage, is completely secure.
        Therefore, while we endeavor to maintain physical, electronic, and procedural safeguards to protect the confidentiality of the information that we collect online, we cannot guarantee its absolute security.
        Our Service has security measures in place designed to protect against the loss, misuse and alteration of the information under our control.
        We use standard Secure Socket Layer (SSL) encryption that encodes information for such transmissions.
        All Service information is maintained on secure servers.
        Access to stored data is protected by multi-layered security controls including firewalls, role-based access controls and passwords.
        You are responsible to keep your password secure.
        If you have reason to believe that your interaction with us is no longer secure, please immediately notify us of the problem by contacting us at <EmailText color={getThemeData(theme).colorAccent}>team@crunchtimeodds.com</EmailText>.
      </Content>
      <SubHeader color={getThemeData(theme).textColor}>
        8. Changes to This Policy
      </SubHeader>
      <Content color={getThemeData(theme).textColor}>
        We reserve the right to change the terms of this privacy policy at any time.
        When we make changes, we will revise the "last updated" date at the top of the policy.
        If there are material changes to this statement or in how we will use your personal information, we will notify you by prominently posting a notice of such changes here or on our home page, or by sending you an email.
        We encourage you to review this policy whenever you visit one of our websites or applications.
      </Content>
    </PrivacyContainer>
  );
};

export default Privacy;