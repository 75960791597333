import styled from "styled-components"
import { MainTableContainerProps } from "../../lib/interface"

interface OddsOptionContainerProps {
  isMobile: boolean;
}

interface RowContainerProps {
  color: string;
  color2: string;
};

interface BodyContainerProps {
  width: string;
};

interface BlurContainerProps {
  color: string;
  width: string;
  height: string;
};

export const BlurContainer = styled.div<BlurContainerProps>`
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: ${({color}) => color};
  width: ${({width}) => width};
  height: ${({height}) => height};
`


export const BodyContainer = styled.div<BodyContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${({width}) => width};
`

export const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid ${({color}) => color};
  //border-top: 1px solid ${({color}) => color};
  cursor: pointer;
  //&:hover {
  //  background: ${({color2}) => color2};
  //}
`

export const RowContainer2 = styled.div<RowContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid ${({color}) => color};
  //border-top: 1px solid ${({color}) => color};
  //cursor: pointer;
  //&:hover {
  //  background: ${({color2}) => color2};
  //}
`

export const MainTableHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  @media (max-width: 1560px) {
    width: 100%;
  }
`

export const RowsPerPageButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({color}) => color};
  padding: 6px;
  cursor: pointer;
`

export const MainTableFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  padding: 12px 0px;
  @media (max-width: 1560px) {
    width: 100%;
    overflow-x: scroll;
  }
`

interface MainTableTitleProps {
  color: string;
  isSmall: boolean;
}

export const MainTableTitle = styled.span<MainTableTitleProps>`
  font-size: ${({isSmall}) => isSmall ? "24px" : "28px"};
  font-weight: 600;
  color: ${({color}) => color};
  text-align: center;
`

export const MainTableSubtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({color}) => color};
`

export const MainTableContainer = styled.div<MainTableContainerProps>`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  height: fit-content;
  width: 1280px;
  margin: 0 auto;
  background: ${({color}) => color};
  //border-radius: 10px;
  //padding: 12px 0px;
  //border-top: 1px solid ${({brcolor}) => brcolor};
  //border-bottom: 1px solid ${({brcolor}) => brcolor};
  @media (max-width: 1560px) {
    width: 100%;
    overflow-x: scroll;
  }
`

export const OddsOptionContainer = styled.div<OddsOptionContainerProps>`
  display: inline-flex;
  flex-direction: ${({isMobile}) => isMobile ? "row" : "row"};
  align-items: center;
  gap: 6px;
  width: ${({isMobile}) => isMobile ? "100%" : "fit-content"};
`