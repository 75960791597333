import { ArrowRight, DollarSign, Info } from "react-feather";
import { getThemeData } from "../../theme"
import { TextContent3, TooltipContainer } from "./hedgerStyle";
import { Tooltip } from "react-tooltip";
import { OD, RowObj } from "../../lib/interface";
import { convertOdds } from "../../odds";
import { useGetOdds, useGetTheme } from "../../lib/contexts";
import { sortArrays } from "./helpers";
import { BlurContainer } from "./tableStyle";

interface OddsDetailsProps {
  oddsData: OD[],
  indexData: RowObj[],
  detailWidth: string;
  detailHeight: string;
  detailHeight2: string;
  isMobile: boolean;
  canViewAdv: boolean;
}

const OddsDetails = ({
  oddsData,
  indexData,
  detailWidth,
  detailHeight,
  detailHeight2,
  isMobile,
  canViewAdv,
}: OddsDetailsProps) => {
  const {theme} = useGetTheme();
  const {odds} = useGetOdds();
  console.log(indexData)
  return (<>
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      width: detailWidth,
      height: detailHeight,
    }}>
      <div style={{height: "22px"}}/>
      {/* TEMP REMOVAL */}
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between"
      }}>
        <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
          {`Unit Profit (All Time)`}
        </span>
        {canViewAdv
        ? <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <DollarSign size={14} color={getThemeData(theme).themeGreen}/>
          <span style={{fontWeight: 600, color: getThemeData(theme).themeGreen, fontSize: "14px"}}>
            {indexData.length > 0
            ? indexData[0].c10
            : "-"
            }
          </span>
        </div>
        : <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <DollarSign size={14} color={getThemeData(theme).themeGreen}/>
          <BlurContainer color={getThemeData(theme).themeGreen} width={"28px"} height={"14px"}/>
        </div>
        }
      </div>
      <div style={{height: "12px"}}/>
      {/* */}
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          width: "100%",
        }}>
          <div style={{display: "inline-flex", flexDirection: "row", alignItems: "center"}}>
            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
              {"Smart Money"}
            </span>
            <div style={{width: "4px"}}/>
            <Info size={16} color={"grey"} cursor={"pointer"} data-tooltip-id="tip-smart-money"/>
            <TooltipContainer color={getThemeData(theme).bgGlobal}>
              <Tooltip id="tip-smart-money" place="bottom" className='custom-tooltip' opacity={1.0}>
                <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                  {"Bookie odds changes as a result of updated expectations and smart bettors"}
                </TextContent3>
              </Tooltip>
            </TooltipContainer>
          </div>
          <div style={{height: "12px"}}/>
          <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
          {[...oddsData].map((item, ind) => {
            //const allLabs =  [...oddsData].map(item => item.update_times);
            //const labs = Array.from(new Set(allLabs)).flat(1);
            //const labs = Array.from(new Set([...oddsData].map(item => item.update_times).flat(1)))
            //const shifts = Array.from(new Set([...oddsData].map(item => item.data).flat(1)))
            const labs_unsorted = item.update_times;//.sort((a, b) => a.localeCompare(b));
            const shifts_unsorted = item.data;
            const arrs = sortArrays([labs_unsorted, shifts_unsorted]);
            const labs = Array.from([...arrs[0]]);
            const shifts = Array.from([...arrs[1]]);
            //console.log("ODDS DETAILS", labs)
            //console.log("ODDS DETAILS", shifts)
            let cntr = 0;
            if (labs.length > 1) {
              return (
                <div style={{display: "flex", flexDirection: "column", maxHeight: detailHeight2, overflowY: "scroll"}}>
                  {labs.map((lit,lix) => {
                    if (lix > 0 && shifts[lix] !== shifts[lix-1]) {
                      cntr += 1;
                      return (
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: isMobile ? "fit-content" : "98%",
                          justifyContent: isMobile ? "center" : "space-between",
                          gap: isMobile ? "6px" : "0px"
                        }}>
                          <div style={{display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "10px", padding: "4px 8px", border: `1px solid ${getThemeData(theme).borderColor}`}}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).textColor4, fontSize: "14px"}}>
                              {lit.replace('T',' @ ')}
                            </span>
                          </div>
                          <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                              {"Odds Change"}
                            </span>
                          </div>
                          {canViewAdv || cntr === 1
                          ? <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).themeGreen, fontSize: "14px"}}>
                              {odds !== 2 ? convertOdds(shifts[lix-1], odds).toFixed(2) : convertOdds(shifts[lix-1], odds)}
                            </span>
                            <div style={{width: "6px"}}/>
                            <ArrowRight size={16} color={getThemeData(theme).textColor}/>
                            <div style={{width: "6px"}}/>
                            <span style={{fontWeight: 600, color: getThemeData(theme).themeGreen, fontSize: "14px"}}>
                              {odds !== 2 ? convertOdds(shifts[lix], odds).toFixed(2) : convertOdds(shifts[lix], odds)}
                            </span>
                          </div>
                          : <div style={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                            <BlurContainer color={getThemeData(theme).themeGreen} width={"26px"} height={"14px"}/>
                            <div style={{width: "6px"}}/>
                            <ArrowRight size={16} color={getThemeData(theme).textColor}/>
                            <div style={{width: "6px"}}/>
                            <BlurContainer color={getThemeData(theme).themeGreen} width={"26px"} height={"14px"}/>
                          </div>
                          }
                        </div>
                      )
                    }
                  })}
                </div>
              )
            } else {
              return (<>
                <div style={{height: "12px"}}/>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "left"}}>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", borderRadius: "10px", padding: "4px 8px", border: `1px solid ${getThemeData(theme).borderColor}`}}>
                    <span style={{fontWeight: 600, color: getThemeData(theme).textColor4, fontSize: "14px"}}>
                      {"No Events"}
                    </span>
                  </div>
                </div>
              </>)
            }
          })}
        </div>
      </div>
    </div>
  </>)
}

export default OddsDetails;