import { useState, useEffect } from "react";
import { Check, Copy } from "react-feather";
import styled from "styled-components";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import HighlightComp from "../../assets/crunchtimeSearch.webp";
import AppShowcase from "../../assets/trendingBets.webp";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark as darkStyle, oneLight as lightStyle } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Config as config } from "../../lib/config";
import SnackBar from "../SnackBar";
import { logoUrl } from "../Dash/constants";

interface FeatureContainerProps {
  flexDirection: string;
  idx: any;
}

interface FeatureImageProps {
  glow: boolean;
  brighter: boolean;
  theme: string;
  bgColor: string;
}

interface FeatureImageCoreProps {
  bgColor: string;
}

interface FeatureTextProps {
  alignItems: string;
}

interface ButtonLearnMoreProps {
  backgroundColor: string;
}

export const ButtonLearnMore = styled.div<ButtonLearnMoreProps>`
  padding: 7px 17px;
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;
  color: lightgrey;
  text-align: center;
  height: fit-content;
  border: 1px solid #303030;
  background-color: ${({backgroundColor}) => backgroundColor};
  transition: all 0.3s ease-in-out;
`

export const FeaturesWrapper = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  padding: 80px 0px 42px 0px;
  gap: 100px;
  @media (max-width: 1000px) {
    padding: 50px 0px;
  }
`;

export const FeatureContainerWrapper = styled.div`
  width: 100%;
  background: transparent;
  padding: 40px 0px;
`;

export const FeatureContainer = styled(motion.div) <FeatureContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  max-width: 1300px;
  padding: ${(props) => (props.idx === 0 ? "42px 0px 42px 0px" :  "42px 0px 0px 0px")};
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 182px;
    //flex-direction: column-reverse;
    //margin-top: ${(props) => (props.idx === 0 ? "-45px" : "20px")};
  }
  @media (max-width: 600px) {
    width: 96%;
  }
`;

export const FeatureContainerCore = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  max-width: 1300px;
  padding: 0px 0px;
`;

export const FeatureText = styled.div<FeatureTextProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  height: fit-content !important;
  max-width: 500px;
  width: 100%;
  text-align: left;
`;

const FeatureTitleCore = styled.span`
  color: ${({ color }) => color};
  font-size: 26px;
  font-weight: 800;
  @media (max-width: 800px) {
    font-weight: 600;
    font-size: 24px;
  }
`;

const FeatureSubTitleCore = styled.span`
  color: ${({ color }) => color};
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
  }
`;


const FeatureTitle = styled.h3`
  color: ${({ color }) => color};
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 26px;
  font-weight: 800;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
  }
`;

export const FeatureSubTitle = styled.div`
  //margin-top: 2px;
  color: ${({ color }) => color};
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media (max-width: 800px) {
    color: ${({ color }) => color};
    width: 100%;
    //text-align: start;
    text-align: center;
    //font-weight: 300;
    font-size: 18px;
    //margin-top: 20px;
  }
  @media (max-width: 420px) {
    color: ${({ color }) => color};
    width: 100%;
    //text-align: start;
    text-align: center;
    //font-weight: 300;
    font-size: 16px;
    //margin-top: 20px;
  }
`;

const FeatureImage = styled.img<FeatureImageProps>`
    //max-width: 660px;
    //max-height: 500px;
    max-width: 580px;
    width: 100%;
    background: ${({bgColor}) => bgColor};
    //min-height: 300px;
    //min-width: 300px;
    object-fit: fit;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    filter: ${({ glow }) => (glow ? 'brightness(125%)' : 'brightness(100%)')};
    box-shadow: ${({ glow, theme }) => (glow ? (theme === 'dark' ? 'lightgrey 0 0 22px 2px' : 'black 0 0 22px 2px') : 'none')};
  `;

const FeatureImageCore = styled.img<FeatureImageCoreProps>`
    //max-width: 660px;
    //max-height: 500px;
    max-width: 1020px;
    width: 100%;
    height: 100%;
    background: ${({bgColor}) => bgColor};
    //min-height: 300px;
    //min-width: 300px;
    object-fit: fit;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    filter: brightness(125%);
  `;

const Highlights = () => {

  const {theme} = useGetTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [snackBarTitle, setSnackBarTitle] = useState<string | undefined>(undefined);
  const [snackBarType, setSnackBarType] = useState<string | undefined>(undefined);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 492px;
    @media (max-width: 600px) {
      min-height: fit-content;
    }
    .code_hover {
      background: ${getThemeData(theme).bgBox2};
      border-radius: 10px;
      width: 100%;
    }
    .code_hover .icon {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.3s, opacity 0.3s linear;
    }
    .code_hover:hover .icon {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s linear;
    }
  `

  const example2 = `
  // Get live data
  // Apply strategies
  {
    // Query odds with lowest vig
    "league": "mls",
    "starts_at": 1710097200,
    "t1_id": "mls-FC-Cincinnati",
    "t2_id": "mls-DC-United",
    "t1_score": 0,
    "t2_score": 0,
    "ml_site_id": "Betrivers",
    "t1_odds": 2.1,
    "t2_odds": 3.45,
    "draw_odds": 3.6,
    "ou_site_id": "Bet365",
    "line": 2.5,
    "over_odds": 1.901,
    "under_odds": 1.901,
    "ps_site_id": "DraftKings",
    "ps_line": 0.25,
    "t1_ps_odds": 1.833,
    "t2_ps_odds": 2.0
  }
  `

  const example = `
  // Powerful stats derived from
  // odds and game data
  // Backtest and optimize strategies
  {
    'league': 'nba',
    'site_id': 'Fanduel',
    'team_id': 'nba-Milwaukee',
    'opp_id': 'nba-LA-Clippers',
    'is_underdog_odds': 0,
    'is_underdog_team': 1,
    'game_time': 1709600400,
    'odds_update_time': 1709595603,
    'odds': 1.962,
    'odds_no_vig': 2.038,
    'opp_odds': 1.893,
    'opp_odds_no_vig': 1.964,
    'vig': 0.038,
    'is_winner': 1,
    'unit_profit': 0.96
  }
  `

  const Highlight1 = (
    <div style={{
      display: "flex",
      flexDirection: (windowDimension.winWidth > 600 ? "row" : "column"),
      gap: "12px",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}>
      <Container>
        <div className="code_hover">
          <div className="icon" style={{
            float: "right",
            padding: "6px 6px 4px 6px",
            border: "1px solid #303030",
            borderRadius: "10px",
            marginTop: "4px",
            marginRight: "4px",
          }}>
            {/*<span style={{color: getThemeData(theme).textColor}}>
              {match[1]}
            </span>*/}
            {!showCopySuccess
            ? <Copy size={22} color={getThemeData(theme).textColor} cursor={"pointer"} onClick={() => {
              navigator.clipboard.writeText(String(example).replace(/\n$/, ''));
              setShowCopySuccess(true)
              setTimeout(() => {
                setShowCopySuccess(false)
              },1000)
            }}/>
            : <Check size={22} color={theme === 'dark' ? 'lightgreen' : 'green'} cursor={"pointer"}/>
            }

          </div>
            <SyntaxHighlighter
              children={String(example).replace(/\n$/, '')}
              language={'bash'}
              style={theme === 'dark' ? darkStyle : lightStyle}
              customStyle={{
                background: getThemeData(theme).bgBox2,
                fontFamily: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New",
                fontSize: "12px",
              }}
              codeTagProps={{style: {
                fontFamily: "inherit",
                fontSize: "inherit",
                outline: "none !important",
                outlineWidth: "0 !important",
              }}}
              wrapLines={true}
              wrapLongLines={true}
              showLineNumbers={false}
              showInlineLineNumbers={false}
              lineNumberStyle={{color: getThemeData(theme).textColor}}
            />
          </div>
      </Container>
      <Container>
        <div className="code_hover">
          <div className="icon" style={{
            float: "right",
            padding: "6px 6px 4px 6px",
            border: "1px solid #303030",
            borderRadius: "10px",
            marginTop: "4px",
            marginRight: "4px",
          }}>
            {/*<span style={{color: getThemeData(theme).textColor}}>
              {match[1]}
            </span>*/}
            {!showCopySuccess
            ? <Copy size={22} color={getThemeData(theme).textColor} cursor={"pointer"} onClick={() => {
              navigator.clipboard.writeText(String(example2).replace(/\n$/, ''));
              setShowCopySuccess(true)
              setTimeout(() => {
                setShowCopySuccess(false)
              },1000)
            }}/>
            : <Check size={22} color={theme === 'dark' ? 'lightgreen' : 'green'} cursor={"pointer"}/>
            }
          </div>
          <SyntaxHighlighter
            children={String(example2).replace(/\n$/, '')}
            language={'bash'}
            style={theme === 'dark' ? darkStyle : lightStyle}
            customStyle={{
              background: getThemeData(theme).bgBox2,
              fontFamily: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New",
              fontSize: "12px",
            }}
            codeTagProps={{style: {
              fontFamily: "inherit",
              fontSize: "inherit",
              outline: "none !important",
              outlineWidth: "0 !important",
            }}}
            wrapLines={true}
            wrapLongLines={true}
            showLineNumbers={false}
            showInlineLineNumbers={false}
            lineNumberStyle={{color: getThemeData(theme).textColor}}
          />
        </div>
      </Container>
    </div>
  );

  const FeaturesData = [
    {
      title: "One platform to search for aggregated betting data with natural language in real-time.",
      titleCode: "Search",
      //image: Highlight1,
      image: <FeatureImage
        dir={"left"}
        src={HighlightComp}
        loading="lazy"
        decoding="async"
        alt="feature-image"
        brighter={true}
        glow={true}
        theme={theme}
        bgColor={"transparent"}
      />,
      head: (
        "Leverage team statistics, game outcomes, and odds together to create, backtest, and deploy strategies. "+
        "Powerful indexed data such as conditional bet profitability and impactful real-time data such as odds vig and volatility are reliable and accessible. "+
        "Find the best odds for a bet, capitalize on line discrepancies, determine optimal moments to hedge parlays, and more. "+
        "Train, test, and validate reinforcement and deep learning models on processed statistics for game-changing results."
      ),
      redirect: "/", //config.accountRegister,
      redirect2: config.docs,
      list: [],
      //renderImage: false,
      renderImage: true,
    },
    {
      title: "Powerful insights from aggregated odds and game data for informed decisions",
      titleCode: "Learn More",
      image: Highlight1,
      /*
      image: <FeatureImage
        dir={"left"}
        src={HighlightComp}
        loading="lazy"
        decoding="async"
        alt="feature-image"
        brighter={true}
        glow={true}
        theme={theme}
      />,
      */
      redirect: "/", //config.docs,
      redirect2: config.docs,
      head: (
        "Our powerful, pragmatic and blazing fast API aggregates consistent and accurate data from dozens of bookies and sports leagues to provide real-time odds in a reliable and accessible manner. "+
        "Access novel sports betting statistics and indicators curated from a history of odds and game data, all in one convenient place. "+
        "Written in Rust, the API features robust request handlers with consistent responses and powerful request routing to deliver a seamless experience."
      ),
      list: [
        //"landingFeatureAlphaP1",
        //"landingFeatureAlphaP2",
        //"landingFeatureAlphaP3",
      ],
      renderImage: false,
      //renderImage: true,
      //path: "/alpha",
    },
    /*
    {
      title: "Protocol to Decentralize the Sportsbook Edge",
      titleCode: "The Protocol",
      image:[Highlight3, Highlight3],
      //buttonText: "Automate your NFT activities",
      redirect: '/',
      redirect2: '/',
      head: (
        "We are a community-owned protocol encapsulating the sportsbook edge into a yield-generating financial instrument, where a community of token stakers can contribute bookie liquidity used as collateral for bet positions and benefit from the house vig over time. " +
        "We are dedicated to transforming betting and fantasy into decentralized, accessible, social and data-driven activities."
      ),
      list: [
        //"landingFeatureAlphaP1",
        //"landingFeatureAlphaP2",
        //"landingFeatureAlphaP3",
      ],
      //path: "/alpha",
    },
    */

  ];

  return (
    <FeaturesWrapper id="highlights">
      <FeatureContainerCore
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ type: "spring" }}
        variants={{
          visible: { opacity: 2, y: "0%" },
          hidden: { opacity: 0, y: "3%" },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "98%",
            maxWidth: "500px",
          }}
        >
          <FeatureTitleCore color={getThemeData(theme).textColor}>
            {"An impactful blend of sports betting statistics and real-time odds"}
          </FeatureTitleCore>
        </div>
        <div style={{height: "22px"}}/>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "98%",
          maxWidth: "542px",
        }}>
          <FeatureSubTitleCore color={getThemeData(theme).textColor4}>
            <img src={
              theme === 'dark'
              ? logoUrl
              : logoUrl
              }
              alt={'logo'}
              style={{
                width: windowDimension.winWidth > 800 ? "22px" : "18px",
                height: windowDimension.winWidth > 800 ? "22px" : "18px",
                display: "inline-block",
                verticalAlign: "middle",
                paddingBottom: "4px",
              }}
            />
            {" CRUNCHTIMEODDS is an artificial intelligence platform that unifies all the essential data and tools for data-driven betting, enabling you to discover patterns that give you an edge."}
          </FeatureSubTitleCore>
        </div>
        <div style={{height: "22px"}}/>
        <div
          onClick={() => {
            navigate("/");
          }}
          style={{
            cursor: "pointer",
            padding: "8px 18px",
            width: "fit-content",
            background: getThemeData(theme).bgButton1,
            color: "white",
            borderRadius: "10px",
            fontSize: "1rem",
            fontWeight: "600",
          }}
        >
          {"Try The Platform"}
        </div>
        <div style={{height: "42px"}}/>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "98%",
          }}
        >
          <FeatureImageCore
            src={AppShowcase}
            loading="lazy"
            decoding="async"
            alt="feature-image-core"
            bgColor={"transparent"}
          />
        </div>
      </FeatureContainerCore>
      {FeaturesData.map((feature, index) => {
        return (
          <FeatureContainer
            idx={index}
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ type: "spring" }}
            variants={{
              visible: { opacity: 2, y: "0%" },
              hidden: { opacity: 0, y: "3%" },
            }}
            flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
          >
            <FeatureText alignItems={index % 2 === 0 ? "flex-start" : "flex-end"}>
              {/*
              <FeatureTitle color={getThemeData(theme).textColor}>
                {feature.title}
              </FeatureTitle>
              */}
              <FeatureTitle color={getThemeData(theme).textColor}>
                {feature.title}
              </FeatureTitle>
              <FeatureSubTitle color={getThemeData(theme).textColor4}>
                <div style={{display: "flex", flexDirection: "column",}}>
                  <span>{feature.head}</span>
                  <ul>
                    {feature.list.map((item, index) => {
                      return <li style={{marginBottom:"12px"}} key={index}>
                        {item}
                      </li>;
                    })}
                  </ul>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: (windowDimension.winWidth > 1000 ? "left" : "center"),
                  gap: "12px",
                  width: "100%",
                }}>
                  <div
                    onClick={() => {
                      //window.open(`${feature.redirect}`, "_self");
                      navigate(feature.redirect);
                    }}
                    style={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      width: "fit-content",
                      background: getThemeData(theme).bgButton1,
                      color: "white",
                      borderRadius: "10px",
                      fontSize: "1rem",
                      fontWeight: "600",
                    }}
                  >
                    {feature.titleCode}
                  </div>
                  {index !== 0 &&
                    <ButtonLearnMore backgroundColor={"transparent"} onClick={() => {
                      try {
                        navigator.clipboard.writeText("curl -X GET https://crunchtimeodds.com/api/health")
                        setSnackBarTitle("Success")
                        setSnackBarType("success")
                        setTimeout(() => {
                          setSnackBarType(undefined);
                          setSnackBarTitle(undefined);
                        }, config.snackTimeout);
                      } catch {
                        console.log('copy err')
                      }
                    }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer", gap: "4px" }}>
                      <span style={{ fontSize: "1rem", fontWeight: "600", fontFamily: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New",color: getThemeData(theme).textColor }}>
                        {"cURL"}
                      </span>
                      <Copy size={18} color={getThemeData(theme).textColor} />
                      {/*<ExternalLink size={18} color={'lightgrey'} />*/}
                    </div>
                  </ButtonLearnMore>
                  }
                  {index === 0 &&
                  //<Link to={feature.redirect2} style={{textDecoration: "none"}} target="_self">
                    <ButtonLearnMore backgroundColor={"transparent"} onClick={() => {navigate(feature.redirect2)}}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                        <span style={{ fontSize: "1rem", fontWeight: "600", color: getThemeData(theme).textColor }}>
                          Docs
                        </span>
                        {/*<ExternalLink size={18} color={'lightgrey'} />*/}
                      </div>
                    </ButtonLearnMore>
                  }
                </div>
              </FeatureSubTitle>
            </FeatureText>
            {feature.renderImage
            ? <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "340px",
                width:  (windowDimension.winWidth > 680 ? "660px" : "98%"),
              }}
            >
              {feature.image}
            </div>
            : <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: (windowDimension.winWidth > 680 ? "680px" : "98%"),
              }}
            >
              {feature.image}
            </div>
            }
          </FeatureContainer>
        );
      })}
      {snackBarTitle !== undefined && <SnackBar title={snackBarTitle} type={snackBarType} />}
    </FeaturesWrapper>
  );
};

export default Highlights;