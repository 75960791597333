type AppConfig = {
	appName: string;
	environment: 'test' | 'prod';
	appUrl: string;
  docs: string;
  terms: string;
  mediumUrl: string;
  discordUrl: string;
  twitterUrl: string;
  telegramUrl: string;
  email: string;
  snackTimeout: number;
  swagger: string;
  githubUrl: string;
  githubExamplesUrl: string;
  recaptchaSiteKey: string;
  accountRegister: string;
  accountLogin: string;
  dashApp: string;
  privacy: string;
};

export const API_ENDPOINT_URL =`${process.env.REACT_APP_API_URL}`;
export const API_PROXY_ENDPOINT_URL = `${process.env.REACT_APP_PROXY_API_URL}`;

export const PLAN_1_PRICE_ID_MONTHLY = `${process.env.REACT_APP_PRO_MONTHLY_PRICE_ID}`;
export const PLAN_1_PRICE_ID_YEARLY = `${process.env.REACT_APP_PRO_YEARLY_PRICE_ID}`;
export const PLAN_1_PRODUCT_ID_MONTHLY = `${process.env.REACT_APP_PRO_MONTHLY_PRODUCT_ID}`;

export const PLAN_2_PRICE_ID_MONTHLY = `${process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID}`;
export const PLAN_2_PRICE_ID_YEARLY = `${process.env.REACT_APP_PREMIUM_YEARLY_PRICE_ID}`;
export const PLAN_2_PRODUCT_ID_MONTHLY = `${process.env.REACT_APP_PREMIUM_MONTHLY_PRODUCT_ID}`;

export const PLAN_3_PRICE_ID_MONTHLY = `${process.env.REACT_APP_ELITE_MONTHLY_PRICE_ID}`;
export const PLAN_3_PRICE_ID_YEARLY = `${process.env.REACT_APP_ELITE_YEARLY_PRICE_ID}`;
export const PLAN_3_PRODUCT_ID_MONTHLY = `${process.env.REACT_APP_ELITE_MONTHLY_PRODUCT_ID}`;

const environment = process.env.NODE_ENV === 'production' ? 'prod' : 'test';
const appName = `${process.env.REACT_APP_NAME}`;
const appUrl = `${process.env.REACT_APP_URL}`;
const twitterUrl = `${process.env.REACT_APP_TWITTER_URL}`;
const telegramUrl = `${process.env.REACT_APP_TELEGRAM_URL}`;
const discordUrl = `${process.env.REACT_APP_DISCORD_URL}`;
const mediumUrl = `${process.env.REACT_APP_MEDIUM_URL}`;
const email = `${process.env.REACT_APP_EMAIL}`;
const githubUrl = `${process.env.REACT_APP_GITHUB}`;
const snackTimeout = 1.8 * 1000;
const terms = `/terms-of-service`;
const privacy = `/privacy`;
const docs = `/api-docs`;
const swagger = `${API_ENDPOINT_URL}/swagger`;
const githubExamplesUrl = `${githubUrl}/examples`;
const recaptchaSiteKey = `${process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}`;
const accountRegister = `/account?register=true`;
const accountLogin = `/account?login=true`;
const dashApp = `${process.env.REACT_APP_DASH_URL}`;

export const Config: AppConfig = {
  appName,
	environment,
	appUrl,
  docs,
  terms,
  mediumUrl,
  discordUrl,
  twitterUrl,
  telegramUrl,
  email,
  snackTimeout,
  swagger,
  githubUrl,
  githubExamplesUrl,
  recaptchaSiteKey,
  accountRegister,
  accountLogin,
  dashApp,
  privacy,
};