import { useState, useEffect, useRef } from "react";
import { Sun, Moon, LogOut, Menu, X, Codesandbox, ExternalLink } from "react-feather";
//import Toggle from 'react-toggle';
import { Link, useLocation, useNavigate } from "react-router-dom";
//import "react-toggle/style.css";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import Modal from "../Modal";
//import Button from "../Button";
//import LogoWhite from "../../assets/C_Logo_White.webp";
//import LogoBlack from "../../assets/C_Logo_Black.webp";
import LogoWhite from "../../assets/C_Logo_White.webp";
import LogoBlack from "../../assets/C_Logo_Black.webp";
import { FaUser, FaBolt, FaCalculator, FaSearch, FaHome, FaSignal, FaFire, FaMoneyBill, FaSignInAlt } from "react-icons/fa";
import SnackBar from "../SnackBar";
import { Config as config } from "../../lib/config";
import Login from "../Login";
import { useAuth } from "../../lib/auth";
import { userData } from "../../lib/api/user";
import styled from "styled-components";
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarLeftExpandFilled } from "react-icons/tb";
import { IoMdHelpCircle } from "react-icons/io";
import { SiGitbook } from "react-icons/si";
import { motion } from "framer-motion";
import { logoUrl } from "./constants";

interface HeaderDashProps {
  //showModal: boolean;
  //setShowModal: any;
  showSubMenu: boolean;
  setShowSubMenu: any;
  lgSubMenuW: string;
  smSubMenuW: string;
  isMobile: boolean;
  setIsQuery: any;
  isMacLike: boolean;
  isIOS: boolean;
}

interface HeaderContainerProps {
  color: string;
  color2: string;
  width: string;
  isMobile: boolean;
  hidden: string;
};

interface HeaderWrapperProps {
  isMobile: boolean;
}

interface SectionProps {
  color: string;
  color2: string;
  color3: string;
  color4: string;
  isActive: boolean;
  isShown: boolean;
  theme: string;
};

interface MobileWrapperProps {
  color: string;
  isMobile: boolean;
};

const MobileWrapper = styled.div<MobileWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${({isMobile}) => isMobile ? "100%" : "100%"};
  height: fit-content;
  top: 0;
  left: 0;
  position: fixed; //sticky;
  background: linear-gradient(to bottom, ${({color}) => color} 90%, transparent);
  z-index: 999;
  padding: 22px 0px 22px 0px;
`

const HeaderContainer = styled(motion.div)<HeaderContainerProps>`
  display: ${({isMobile, hidden}) => isMobile ? hidden : "flex"};
  flex-direction: column;
  align-items: center;
  justify-content: ${({isMobile}) => isMobile ? "top" : "center"};
  width: ${({width}) => width};
  top: 0;
  left: 0;
  height: ${({isMobile}) => isMobile ? "100vh" : "100vh"};
  position: ${({isMobile}) => isMobile ? "fixed" : "sticky"};
  background: ${({isMobile, color}) => isMobile
    ? `${color}`
    : `linear-gradient(to bottom, ${color} 70%, transparent)`
  };
  z-index: 2;
  border-right: 1px solid ${({color2}) => color2};
`

const HeaderWrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: ${({isMobile}) => isMobile ? "top" : "space-between"};
  align-items: center;
  padding: 22px 0px;
`

const HeaderSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
`

const SectionWrapper = styled.div`
  width: calc(100% - 24px);
  margin-left: 12px;
`

const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({isShown}) => isShown ? "left" : "center"};
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  width: calc(100% - 24px);
  border-radius: 10px;
  filter: ${({isActive}) => isActive ? "brightness(1.25)" : "none"};
  background: ${({isActive, color}) => isActive ? color : "transparent"};
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: ${({theme}) => theme === 'dark' ? "brightness(1.5)" : "none"};
    background: ${({isActive, color, theme}) => theme === 'dark' ? (isActive ? color : "transparent") : "#E8E8E8"};
  }
`

const Section2 = styled.div<SectionProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({isShown}) => isShown ? "center" : "center"};
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  width: calc(100% - 24px);
  border-radius: 10px;
  filter: ${({isActive}) => isActive ? "brightness(1.25)" : "none"};
  background: ${({isActive, color}) => isActive ? color : "transparent"};
  &:hover {
    filter: ${({theme}) => theme === 'dark' ? "brightness(1.5)" : "none"};
    background: ${({isActive, color, theme}) => theme === 'dark' ? (isActive ? color : "transparent") : "#E8E8E8"};
  }
`

const Section3 = styled.div<SectionProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({isShown}) => isShown ? "center" : "center"};
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 10px;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: 1px solid ${({color4}) => color4};
  filter: ${({isActive}) => isActive ? "brightness(1.25)" : "none"};
  background: ${({isActive, color}) => isActive ? color : "transparent"};
  &:hover {
    filter: ${({theme}) => theme === 'dark' ? "brightness(1.5)" : "none"};
    background: ${({isActive, color, theme}) => theme === 'dark' ? (isActive ? color : "transparent") : "#E8E8E8"};
  }
`

const HeaderDash = ({showSubMenu, setShowSubMenu, lgSubMenuW, smSubMenuW, isMobile, setIsQuery, isMacLike, isIOS}: HeaderDashProps) => {
  const {theme, setTheme} = useGetTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [snackBarTitle, setSnackBarTitle] = useState<string | undefined>(undefined);
  const [snackBarType, setSnackBarType] = useState<string | undefined>(undefined);
  const [auth, setAuth] = useState<string | undefined | null>(sessionStorage.getItem("auth"));
  const [id, setId] = useState<string | null | undefined>(sessionStorage.getItem("id"));
  const [registerMode, setRegisterMode] = useState<boolean>(false);
  const { from } = location.state || { from: { pathname: "/" } };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "-100%" },
  };
  const changeNavBg = () => {
    if (window.scrollY > 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNavBg);
  const mobileNavBarRef = useRef<HTMLInputElement>(null);
  const handleClickOutside = (event:any) => {
    if (
      mobileNavBarRef.current &&
      !mobileNavBarRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const ToggleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${theme === 'dark' ? "#303030" : "lightgrey"};
    padding: 8px;
    cursor: pointer;
    //gap: 6px;
    /*.react-toggle-thumb {
      width: 18px;
      height: 18px;
    }
    .react-toggle-track {
      width: 46px;
      height: 20px;
    }
    */
  `

  /*const replaceHistory = useCallback(() => {
    location = { ...location, state: undefined };
  }, [location]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => replaceHistory);
    return () => {
      window.removeEventListener("beforeunload", replaceHistory);
    };
  }, []);*/

  const hideModal = () => {
    //location.state.from = undefined;
    //console.log("LOCATION RESET")
    setShowModal(false);
    navigate("/")
    //setRegisterMode(false);
  }

  const { logout } = useAuth();

  function handleMode() {
    var newTheme = (theme === 'dark' ? 'light' : 'dark');
    setTheme(newTheme)
  }

  async function checkUserLogin() {
    if (id !== undefined && id !== null) {
      const response = await userData(JSON.parse(id));
      console.log("CHECK", response)
      if (response.status !== 200) {
        setAuth(JSON.stringify(false));
        setId(undefined);
        sessionStorage.setItem("auth", JSON.stringify(false))
        sessionStorage.removeItem("id");
      } else {
        console.log(response.data)
      }
    }
  }

  useEffect(() => {
    checkUserLogin()
  },[auth])

  function promptLogin() {
    console.log('from where', from)
    if (from !== undefined && from !== null) {
      if (from === 'account?login=true') {
        setRegisterMode(false);
        setShowModal(true)
      } else if ( from === 'account?register=true') {
        setRegisterMode(true)
        setShowModal(true)
      }
    }
  }

  useEffect(() => {
    promptLogin();
  },[from])

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  const ModalBody = <>
    <Login
      setSnackBarTitle={setSnackBarTitle}
      setSnackBarType={setSnackBarType}
      setShowModal={setShowModal}
      setAuth={setAuth}
      setId={setId}
      registerMode={registerMode}
      setRegisterMode={setRegisterMode}
    />
  </>;

  return (
    <>
    {isMobile &&
      <MobileWrapper
        isMobile={isMobile}
        color={theme === 'dark'
          ? getThemeData(theme).bgGlobal
          : "#fdfbfb"
          //: "#ebedee"
          //: "white"
        }
      >
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            cursor: "pointer"
          }}>
            <img src={
              theme === 'dark'
              ? logoUrl
              : logoUrl
              }
              width={"26px"}
              height={"26px"}
              alt={'logo'}
              onClick={() => { navigate("/") }}
            />
            {location.pathname === "/" ? (
              (auth !== undefined && auth !== null && JSON.parse(auth) === true)
              ? <>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  padding: "6px 12px",
                  background: getThemeData(theme).bgButton1,
                  borderRadius: "10px",
                  cursor: "pointer",
                }} onClick={() => {
                  navigate("/account")
                  if (isMobile) setOpen(false)
                }}>
                  <span style={{
                    color: "white",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}>
                    {"Account"}
                  </span>
                </div>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  padding: "6px 12px",
                  background: "transparent",
                  borderRadius: "10px",
                  cursor: "pointer",
                }} onClick={() => {
                  if (auth !== undefined && auth !== null && JSON.parse(auth) === true) {
                    logout()
                    checkUserLogin()
                    navigate("/")
                  }
                }}>
                  <span style={{
                    color: getThemeData(theme).colorAccent,
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}>
                    {"Logout"}
                  </span>
                </div>
              </>
              : <>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  padding: "6px 12px",
                  background: "transparent",
                  borderRadius: "10px",
                  cursor: "pointer",
                }} onClick={() => {
                  setRegisterMode(false);
                  setShowModal(true)
                }}>
                  <span style={{
                    color: getThemeData(theme).colorAccent,
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}>
                    {"Sign in"}
                  </span>
                </div>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  padding: "6px 12px",
                  background: getThemeData(theme).bgButton1,
                  borderRadius: "10px",
                  cursor: "pointer",
                }} onClick={() => {
                  setRegisterMode(true);
                  setShowModal(true)
                }}>
                  <span style={{
                    color: "white",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}>
                    {"Sign up"}
                  </span>
                </div>
              </>
            ) : (
              <div style={{
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                cursor: "pointer",
                width: "fit-content", //"100%",
                padding: "4px 12px",
                borderRadius: "10px",
                border: `1px solid ${getThemeData(theme).borderColor}`
              }} onClick={() => {
                setIsQuery(true);
              }}>
                <FaSearch size={"1rem"} color={getThemeData(theme).textColor4}/>
                <div style={{width: "8px"}}/>
                <span style={{fontWeight: 600, fontSize: "1.1rem", color: getThemeData(theme).textColor4}}>
                  {"Search with"}
                </span>
                <div style={{width: "6px"}}/>
                <img src={
                  theme === 'dark'
                  ? logoUrl
                  : logoUrl
                  }
                  width={"20px"}
                  height={"20px"}
                  alt={'logo'}
                />
                <div style={{width: "4px"}}/>
                <span style={{fontWeight: 600, fontSize: "1.1rem", color: getThemeData(theme).colorAccent}}>
                  {"AI"}
                </span>
              </div>
            )}
            {/*<span style={{
              fontSize: "1.2rem",
              color: (theme === 'dark' ? "white" : "black"),
              letterSpacing: "0.01rem",
              fontWeight: 600
            }}>
              {config.appName.substring(0,config.appName.length).toUpperCase()}
            </span>*/}
          </div>
          {!open
          ? <Menu
            color={getThemeData(theme).textColor}
            size={"24px"}
            cursor={"pointer"}
            onClick={() => {
              setOpen(true);
            }}
          />
          : <X
            color={getThemeData(theme).textColor}
            size={"24px"}
            cursor={"pointer"}
            onClick={() => {
              setOpen(false);
            }}
          />
          }
        </div>
      </MobileWrapper>
    }
    <HeaderContainer
      color={theme === 'dark'
        ? getThemeData(theme).bgGlobal
        : "#fdfbfb"
        //: "#ebedee"
        //: "white"
      }
      color2={getThemeData(theme).borderColor}
      width={isMobile ? "100%" : (showSubMenu ? lgSubMenuW : smSubMenuW)}
      ref={isMobile ? mobileNavBarRef : null}
      variants={variants}
      isMobile={isMobile}
      animate={!isMobile ? "open" : (open ? "open" : "closed")}
      hidden={!open ? "none" : "flex"}
    >
      <HeaderWrapper isMobile={isMobile}>
        <HeaderSectionWrapper>
          {!isMobile && <SectionWrapper>
          <Link to="/" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: showSubMenu ? "left" : "center",
              width: "100%",
              alignItems: "center",
              cursor: "pointer",
            }}>
              {showSubMenu && <div style={{width: "6px"}}/>}
              <img src={
                theme === 'dark'
                ? logoUrl
                : logoUrl
                }
                width={"26px"}
                height={"26px"}
                alt={'logo'}
              />
              {showSubMenu && <div style={{width: "12px"}}/>}
              {showSubMenu && <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                <span style={{
                  fontSize: "0.65rem",
                  color: getThemeData(theme).colorAccent,
                  //letterSpacing: "0.01rem",
                  fontWeight: 600,
                }}>
                  {"BETA"}
                </span>
              </div>}
            </div>
          </Link>
          </SectionWrapper>}
          <div style={{width: "100%", height: "12px"}}/>
          {isMobile && <div style={{height: "42px"}}/>}
          {!isMobile && (
            <SectionWrapper>
              <Section
                color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
                color2={getThemeData(theme).colorAccent}
                color3={getThemeData(theme).bgBox2}
                color4={getThemeData(theme).borderColor}
                theme={theme}
                isActive={false}
                isShown={showSubMenu}
                onClick={() => {setIsQuery(true)}}
              >
                <FaSearch
                  size={showSubMenu ? "1rem": "1.1rem"}
                  color={(theme === 'dark' ? "#c8c8c8" : "black")}
                />
                {showSubMenu && <span style={{
                  fontSize: "1rem",
                  color: (theme === 'dark' ? "#c8c8c8" : "black"),
                  letterSpacing: "0.01rem",
                  fontWeight: 600,
                }}>
                  {"Search"}
                </span>}
                {showSubMenu && <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                  <span style={{
                    fontSize: "0.65rem",
                    color: getThemeData(theme).colorAccent,
                    //letterSpacing: "0.01rem",
                    fontWeight: 600,
                  }}>
                    {isMacLike || isIOS ? "⌘ K" : "Ctrl-K"}
                  </span>
                </div>}
              </Section>
            </SectionWrapper>
          )}
          <div style={{width: "100%", height: "8px"}}/>
          {isMobile && location.pathname === "/" && <div style={{width: "100%", height: "8px"}}/>}
          {isMobile && location.pathname !== "/" && <div style={{width: "100%", height: "6px"}}/>}
          {isMobile && location.pathname !== "/" && ( <>
            <SectionWrapper>
              <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer"
              }}>
                {(auth !== undefined && auth !== null && JSON.parse(auth) === true)
                ? <>
                  <div style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    padding: "6px 12px",
                    background: getThemeData(theme).bgButton1,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }} onClick={() => {
                    navigate("/account")
                    if (isMobile) setOpen(false)
                  }}>
                    <span style={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}>
                      {"Account"}
                    </span>
                  </div>
                  <div style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    padding: "6px 12px",
                    background: "transparent",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }} onClick={() => {
                    if (auth !== undefined && auth !== null && JSON.parse(auth) === true) {
                      logout()
                      checkUserLogin()
                      navigate("/")
                    }
                  }}>
                    <span style={{
                      color: getThemeData(theme).colorAccent,
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}>
                      {"Logout"}
                    </span>
                  </div>
                </>
                : <>
                  <div style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    padding: "6px 12px",
                    background: "transparent",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }} onClick={() => {
                    setRegisterMode(false);
                    setShowModal(true)
                  }}>
                    <span style={{
                      color: getThemeData(theme).colorAccent,
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}>
                      {"Sign in"}
                    </span>
                  </div>
                  <div style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    padding: "6px 12px",
                    background: getThemeData(theme).bgButton1,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }} onClick={() => {
                    setRegisterMode(true);
                    setShowModal(true)
                  }}>
                    <span style={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}>
                      {"Sign up"}
                    </span>
                  </div>
                </>
                }
              </div>
            </SectionWrapper>
            <div style={{width: "100%", height: "22px"}}/>
            <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
            <div style={{width: "100%", height: "8px"}}/>
          </>
          )}
          <SectionWrapper>
          <Link to="/" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/" || location.pathname.includes("/q")}
              isShown={showSubMenu}
            >
              <FaHome
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname === "/" || location.pathname.includes("/q")
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/" || location.pathname.includes("/q")
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Home"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to="/bets/trending" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/bets/trending"}
              isShown={showSubMenu}
            >
              <FaFire
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname === "/bets/trending"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/bets/trending"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Trending Bets"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to="/bets/top" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/bets/top"}
              isShown={showSubMenu}
            >
              <FaMoneyBill
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname === "/bets/top"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/bets/top"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Top Bets"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to={{}} style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/signals"}
              isShown={showSubMenu}
            >
              <FaSignal
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname === "/signals"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/signals"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Signals"}
              </span>}
              {showSubMenu && <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                <span style={{
                  fontSize: "0.65rem",
                  color: getThemeData(theme).colorAccent,
                  //letterSpacing: "0.01rem",
                  fontWeight: 600,
                }}>
                  {"COMING SOON"}
                </span>
              </div>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to="/api-docs" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname.includes("/api-docs")}
              isShown={showSubMenu}
            >
              <SiGitbook
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname.includes("/api-docs")
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname.includes("/api-docs")
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"API Docs"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to="/api-spec" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/api-spec"}
              isShown={showSubMenu}
            >
              <FaBolt
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname === "/api-spec"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/api-spec"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"API Spec"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to={`${config.githubExamplesUrl}`} target="_blank" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={false}
              isShown={showSubMenu}
            >
              <Codesandbox
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(theme === 'dark' ? "#c8c8c8" : "black")}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (theme === 'dark' ? "#c8c8c8" : "black"),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Code Examples"}
              </span>}
              {showSubMenu && <ExternalLink size={16} color={(theme === 'dark' ? "#c8c8c8" : "black")}/>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to="/hedger" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/hedger"}
              isShown={showSubMenu}
            >
              <FaCalculator
                size={showSubMenu ? "1rem": "1.1rem"}
                color={(location.pathname === "/hedger"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/hedger"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Bet Hedger"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
          <Link to="/company" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={location.pathname === "/company"}
              isShown={showSubMenu}
            >
              <img src={
                theme === 'dark'
                ? LogoWhite //? logoUrl
                : LogoBlack //: logoUrl
                }
                width={showSubMenu ? "16px" : "17.6px"}
                height={showSubMenu ? "16px" : "17.6px"}
                alt={'logo'}
                style={{filter: theme === 'dark' ? "brightness(70%)" : "brightness(100%)"}}
                //color={(location.pathname === "/company"
                //  ? (theme === 'dark'
                //    ? "white"
                //    : "black")
                //  : (theme === 'dark' ? "#c8c8c8" : "black"))}
              />
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (location.pathname === "/company"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"About"}
              </span>}
            </Section>
          </Link>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
        </HeaderSectionWrapper>
        <HeaderSectionWrapper>
          {!isMobile && <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>}
          <div style={{width: "100%", height: "8px"}}/>
          <SectionWrapper>
            <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={false}
              isShown={showSubMenu}
              onClick={() => handleMode()}
            >
              <ToggleContainer>
                {theme === 'dark'
                ? <Moon size={"1rem"} color={"white"}/>
                : <Sun size={"1rem"} color={"black"}/>
                }
              </ToggleContainer>
              {showSubMenu && <span style={{
                fontSize: "1rem",
                color: (theme === 'dark' ? "#c8c8c8" : "black"),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Theme"}
              </span>}
            </Section>
          </SectionWrapper>
          <div style={{width: "100%", height: "8px"}}/>
          <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
          <div style={{width: "100%", height: "8px"}}/>
          {!isMobile && (
            (auth !== undefined && auth !== null && JSON.parse(auth) === true)
            ? <>
              <div style={{width: "100%", height: "8px"}}/>
              <Link to="/account" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
                <SectionWrapper>
                  <Section2
                      color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
                      color2={getThemeData(theme).colorAccent}
                      color3={getThemeData(theme).bgBox2}
                      color4={getThemeData(theme).borderColor}
                      theme={theme}
                      isActive={true}
                      isShown={showSubMenu}
                    >
                    <FaUser
                      size={showSubMenu ? "1rem": "1.1rem"}
                      color={(location.pathname === "/account"
                        ? (theme === 'dark'
                          ? "white"
                          : "black")
                        : (theme === 'dark' ? "lightgrey" : "black"))}
                    />
                    {showSubMenu && <span style={{
                      fontSize: "1rem",
                      color: (location.pathname === "/account"
                        ? (theme === 'dark'
                          ? "white"
                          : "black")
                        : (theme === 'dark' ? "lightgrey" : "black")),
                      letterSpacing: "0.01rem",
                      fontWeight: 600,
                    }}>
                      {"Account"}
                    </span>}
                  </Section2>
                </SectionWrapper>
              </Link>
              <div style={{width: "100%", height: "8px"}}/>
              <SectionWrapper>
              <Section3
                  color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
                  color2={getThemeData(theme).colorAccent}
                  color3={getThemeData(theme).bgBox2}
                  color4={getThemeData(theme).borderColor}
                  theme={theme}
                  isActive={false}
                  isShown={showSubMenu}
                  onClick={() => {
                    if (auth !== undefined && auth !== null && JSON.parse(auth) === true) {
                      logout()
                      checkUserLogin()
                      navigate("/")
                    }
                  }}
                >
                  <LogOut
                    size={showSubMenu ? "1rem": "1.1rem"}
                    color={(theme === 'dark' ? "#c8c8c8" : "black")}
                  />
                  {showSubMenu && <span style={{
                    fontSize: "1rem",
                    color: (theme === 'dark' ? "#c8c8c8" : "black"),
                    letterSpacing: "0.01rem",
                    fontWeight: 600,
                  }}>
                    {"Logout"}
                  </span>}
              </Section3>
              </SectionWrapper>
            </>
            : <>
              <div style={{width: "100%", height: "8px"}}/>
              <SectionWrapper>
                <Section2
                  color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
                  color2={getThemeData(theme).colorAccent}
                  color3={getThemeData(theme).bgBox2}
                  color4={getThemeData(theme).borderColor}
                  theme={theme}
                  isActive={true}
                  isShown={showSubMenu}
                  onClick={() => {
                    setRegisterMode(false);
                    setShowModal(true)
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "6px"}}>
                    <FaSignInAlt
                      size={showSubMenu ? "1rem": "1.1rem"}
                      color={(theme === 'dark' ? "white" : "black")}
                    />
                    {showSubMenu && <span style={{
                      fontSize: "1rem",
                      color: (theme === 'dark' ? "white" : "black"),
                      letterSpacing: "0.01rem",
                      fontWeight: 600,
                    }}>
                      {"Sign in"}
                    </span>}
                  </div>
                </Section2>
              </SectionWrapper>
              <div style={{width: "100%", height: "8px"}}/>
              <SectionWrapper>
                <Section3
                  color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
                  color2={getThemeData(theme).colorAccent}
                  color3={getThemeData(theme).bgBox2}
                  color4={getThemeData(theme).borderColor}
                  theme={theme}
                  isActive={false}
                  isShown={showSubMenu}
                  onClick={() => {
                    setRegisterMode(true);
                    setShowModal(true)
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "6px"}}>
                    <FaUser
                      size={showSubMenu ? "1rem": "1.1rem"}
                      color={(theme === 'dark' ? "white" : "black")}
                    />
                    {showSubMenu && <span style={{
                      fontSize: "1rem",
                      color: (theme === 'dark' ? "white" : "black"),
                      letterSpacing: "0.01rem",
                      fontWeight: 600,
                    }}>
                      {"Sign up"}
                    </span>}
                  </div>
                </Section3>
              </SectionWrapper>
            </>
          )}
          <div style={{width: "100%", height: "8px"}}/>
          <Link to="/help" style={{textDecoration: "none"}} onClick={() => {if (isMobile) setOpen(false)}}>
            <SectionWrapper>
            <Section
                color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
                color2={getThemeData(theme).colorAccent}
                color3={getThemeData(theme).bgBox2}
                color4={getThemeData(theme).borderColor}
                theme={theme}
                isActive={location.pathname === "/help"}
                isShown={showSubMenu}
              >
                <IoMdHelpCircle
                  size={showSubMenu ? "1rem": "1.1rem"}
                  color={(location.pathname === "/help"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black"))}
                />
                {showSubMenu && <span style={{
                  fontSize: "1rem",
                  color: (location.pathname === "/help"
                  ? (theme === 'dark'
                    ? "white"
                    : "black")
                  : (theme === 'dark' ? "#c8c8c8" : "black")),
                  letterSpacing: "0.01rem",
                  fontWeight: 600,
                }}>
                  {"Help"}
                </span>}
            </Section>
            </SectionWrapper>
          </Link>
          <div style={{width: "100%", height: "8px"}}/>
          {!isMobile && <SectionWrapper>
          <Section
              color={theme === 'dark' ? getThemeData(theme).bgBox1 : "#A9AAAB"}
              color2={getThemeData(theme).colorAccent}
              color3={getThemeData(theme).bgBox2}
              color4={getThemeData(theme).borderColor}
              theme={theme}
              isActive={false}
              isShown={showSubMenu}
              onClick={() => {setShowSubMenu(!showSubMenu)}}
            >
              {showSubMenu
              ? <TbLayoutSidebarLeftCollapseFilled
                size={"1rem"}
                color={(theme === 'dark' ? "#c8c8c8" : "black")}
              />
              : <TbLayoutSidebarLeftExpandFilled
                size={"1.1rem"}
                color={(theme === 'dark' ? "#c8c8c8" : "black")}
              />
              }
              {showSubMenu
              && <span style={{
                fontSize: "1rem",
                color: (theme === 'dark' ? "#c8c8c8" : "black"),
                letterSpacing: "0.01rem",
                fontWeight: 600,
              }}>
                {"Collapse Menu"}
              </span>
              }
          </Section>
          </SectionWrapper>
          }
        </HeaderSectionWrapper>
      </HeaderWrapper>
    </HeaderContainer>
    {showModal &&
      <Modal
        title={registerMode ? "Register" : "Login"}
        active={showModal}
        hideModal={hideModal}
        body={ModalBody}
        action={"logging in"}
      />
    }
    {snackBarTitle !== undefined && <SnackBar title={snackBarTitle} type={snackBarType} />}
    </>
  );
};

export default HeaderDash;