import React, { useEffect, useState } from "react";
import { CheckCircle, XCircle } from "react-feather";
import { plansData } from "./content";
import {
  BenefitContainer,
  BenefitText,
  BenefitWrapper,
  MonthFontNumber,
  MonthFontText,
  MostPopTag,
  PlanButton,
  PlanContainer,
  PlanHeadText,
  PriceDiv,
  PriceRow,
  RightIcon,
  TopContainer,
} from "./style";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { Config as config } from "../../lib/config";
import { checkout, userData } from "../../lib/api/user";

const PlanComponent = ({plan, setSnackBarType, setSnackBarTitle, isYearly,}) => {

  const price_monthly = plan.price_monthly;
  const price_id_monthly = plan.price_id_monthly;
  const price_yearly = plan.price_yearly;
  const price_id_yearly = plan.price_id_yearly;
  const benefits = plan.benefits;
  const type = plan.type;
  const note = plan.note;
  const rec = plan.isRecommended;
  const save = plan.save;
  const name = plan.name;

  const {theme} = useGetTheme();
  const [localPrice, setLocalPrice] = useState({ monthly: price_monthly, yearly: price_yearly});
  //const symbol = localStorage.getItem("currencySymbol");
  //const currency = localStorage.getItem("currency");
  const currency = "usd";
  const symbol = "$";
  const id = sessionStorage.getItem("id");

  const handlePlanSelect = async () => {
    let user = undefined;
    let isAuth = false;
    if (id !== undefined && id !== null) {
      const response = await userData(JSON.parse(id));
      console.log("CHECK", response)
      if (response.status !== 200) {
        sessionStorage.setItem("auth", JSON.stringify(false))
        sessionStorage.removeItem("id");
        window.location.href = "/account?register=true";
        /*setSnackBarType("customWarning");
        setSnackBarTitle("Login or Register to get started")
        setTimeout(() => {
          setSnackBarType(undefined);
          setSnackBarTitle(undefined);
        }, config.snackTimeout);*/
      } else {
        isAuth = true;
        console.log(response.data)
        user = response.data;
      }
    }
    console.log("USER", user)
    console.log("IS AUTH", isAuth)
    console.log("USER ID", id)
    if (!isAuth) {
      sessionStorage.setItem("auth", JSON.stringify(false))
      sessionStorage.removeItem("id");
      window.location.href = "/account?register=true";
      /*setSnackBarType("customWarning");
      setSnackBarTitle("Login or Register to get started")
      setTimeout(() => {
        setSnackBarType(undefined);
        setSnackBarTitle(undefined);
      }, config.snackTimeout);*/
    } else {
      var price_id = price_id_monthly;
      if (isYearly) {
        price_id = price_id_yearly;
      }
      if (price_id === 0) {
        // free plan
        console.log("FREE")
        if (user !== undefined && user.customer_id) {
          window.location.href = "/account";
        } else {
          window.location.href = "/account?register=true";
        }
      } else {
        console.log(user)
        setSnackBarType("creating")
        if (user !== undefined && user.customer_id) {
          let cur = currency;
          //if (currency !== undefined && currency !== null && currency.toLowerCase() === "eur") {
          //  cur = "eur"
          //}
          const subscription = {
            customer_id: user.customer_id,
            price_id:  price_id,
            currency: cur,
          }
          console.log("subscription ", subscription);
          var resp = await checkout(subscription);
          console.log(resp);
          if (resp.status === 200 && resp.data.checkout_session) {
            setSnackBarType("success");
            window.open(resp.data.checkout_session, "_self");
          } else {
            setSnackBarType("error");
          }
        } else {
          //setSnackBarType("customWarning");
          //setSnackBarTitle("Login or Register to get started")
          window.location.href = "/account?register=true";
        }
        setTimeout(() => {
          setSnackBarType(undefined);
          setSnackBarTitle(undefined);
        }, config.snackTimeout);
      }
    }
  };

  /*
  useEffect(() => {

  },[isYearly])
  */

  return (
    <PlanContainer
      style={{
        background: getThemeData(theme).bgBox1,
      }}
    >
      {rec && (
        <MostPopTag backgroundColor={getThemeData('light').colorAccent}>
          {"Recommended"}
        </MostPopTag>
      )}
      <TopContainer>
        <PlanHeadText color={getThemeData(theme).textColor}>
          {name}
        </PlanHeadText>
        {type !== "planFree" ? (
          <PriceDiv justifyContent={"space-between"}>
            {!isYearly && (
              <PriceRow>
              <MonthFontNumber color={getThemeData(theme).textColor}>
                {symbol + localPrice.monthly.toFixed(2)}
              </MonthFontNumber>
              <MonthFontText color={getThemeData(theme).textColor}>{"Monthly"}</MonthFontText>
            </PriceRow>
            )}
            {isYearly && (
              <PriceRow>
              <MonthFontNumber color={getThemeData(theme).textColor}>
                {symbol + localPrice.yearly.toFixed(2)}
              </MonthFontNumber>
              <MonthFontText color={getThemeData(theme).textColor}>{"Yearly"}</MonthFontText>
            </PriceRow>
            )}
          </PriceDiv>
        ) : (
          <PriceDiv justifyContent={"space-between"}>
            <MonthFontNumber color={getThemeData(theme).textColor}>
              {symbol + "0"}
            </MonthFontNumber>
          </PriceDiv>
        )}
      </TopContainer>
      <BenefitContainer>
        {benefits.map((benefit, index) => {
          return (
            <BenefitWrapper key={index}>
              <RightIcon>
                {benefit.included
                ? <CheckCircle
                  color={theme === "dark" ? "lightgreen" : "green"}
                  size={14}
                />
                : <XCircle
                  color={"red"}
                  size={14}
                />}
              </RightIcon>
              <BenefitText color={getThemeData(theme).textColor} key={index}>
                {benefit.included
                ? <> {benefit.temph !== ""
                  ? <span>
                    <span style={{fontWeight: 600}}>
                      {benefit.temph}
                    </span>
                    {benefit.text}
                  </span>
                  : <span>
                    {benefit.text}
                  </span>
                } </>
                : <span style={{textDecoration: "line-through"}}>
                  {benefit.text}
                </span>
                }
              </BenefitText>
            </BenefitWrapper>
          );
        })}
      </BenefitContainer>
      {note !== undefined && note !== null && note !== "" && (
        <BenefitText
          style={{
            position: "absolute",
            bottom: "80px",
            textAlign: "center",
            padding: "0 10px",
          }}
          color={getThemeData(theme).textColor}
        >
          {note}
        </BenefitText>
      )}
      <PlanButton
        onClick={() => {
          handlePlanSelect()
        }}
        color={"white"}
        bgColor={getThemeData(theme).bgButton1}
      >
        <p>
          {"Get"} {name}
        </p>
      </PlanButton>
    </PlanContainer>
  );
};

export default PlanComponent;