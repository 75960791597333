import { plansData } from "./content";
import PlanComponent from "./component";
import {
  HeadingTextAlt,
  PlanBody,
  PlanWrapper,
  GradientWrapper,
} from "./style";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { useState } from "react";
import SnackBar from "../SnackBar";
import styled from "styled-components"

const Plans = () => {
  const {theme} = useGetTheme();
  const [snackBarType, setSnackBarType] = useState(undefined);
  const [snackBarTitle, setSnackBarTitle] = useState(undefined);
  const [isYearly, setIsYearly] = useState(false);

  const TabWrapper = styled.div`
    color: white;
    display: flex;
    flex-direction: row;
    border: 1px solid ${getThemeData(theme).textColor};
    border-radius: 50px;
    margin: 40px 0;
    font-size: 14px;
    position: relative;
  `;

  const YearlyTab = styled.div`
    background: ${(props) => (props.isActive ? getThemeData(theme === 'dark' ? 'light' : 'dark').bgGlobal : "transparent")};
    color: ${(props) => (props.isActive ? getThemeData(theme === 'dark' ? 'light' : 'dark').textColor : getThemeData(theme).textColor)};
    padding: 10px;
    font-weight: 600;
    border-radius: 50px;
    width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const MonthlyTab = styled.div`
    background: ${(props) => (props.isActive ? getThemeData(theme === 'dark' ? 'light' : 'dark').bgGlobal : "transparent")};
    color: ${(props) => (props.isActive ? getThemeData(theme === 'dark' ? 'light' : 'dark').textColor : getThemeData(theme).textColor)};
    font-weight: 600;
    padding: 20px 10px;
    border-radius: 50px;
    width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <PlanBody>
      <HeadingTextAlt color={getThemeData(theme).textColor}>
        {"Plans"}
      </HeadingTextAlt>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <TabWrapper>
          <MonthlyTab
            isActive={!isYearly}
            onClick={() => {
              setIsYearly(false);
            }}
          >
            <span
              style={{
                fontSize: "16px",
              }}
            >
              {"Monthly"}
            </span>
          </MonthlyTab>
          <YearlyTab
            isActive={isYearly}
            onClick={() => {
              setIsYearly(true);
            }}
          >
            <span
              style={{
                fontSize: "16px",
              }}
            >
              {"Yearly"}
            </span>
          </YearlyTab>
        </TabWrapper>
        <p
          style={{
            color: theme === 'dark' ? 'lightgreen' : "#1fc600",
            fontSize: "1.1rem",
            marginTop: "-32px",
            marginBottom: "32px",
            fontWeight: "600",
          }}
        >
          <span
            style={{
              fontWeight: "800",
            }}
          >
            {"SAVE 20%"}
          </span>
          {" on yearly plans"}
        </p>
      </div>
      <div style={{height: "42px"}}/>
      <PlanWrapper
        /*
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, type: "spring" }}
        variants={{
          visible: { opacity: 2, x: 0 },
          hidden: { opacity: 0, x: "-10%" },
        }}
        */
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ type: "spring" }}
        variants={{
          visible: { opacity: 2, y: "0%" },
          hidden: { opacity: 0, y: "3%" },
        }}
      >
        {plansData.map((plan, index) => {
          return (
            <GradientWrapper>
              <PlanComponent plan={plan} setSnackBarType={setSnackBarType} setSnackBarTitle={setSnackBarTitle} isYearly={isYearly}/>
            </GradientWrapper>
          );
        })}
      </PlanWrapper>
      {snackBarTitle !== undefined && <SnackBar title={snackBarTitle} type={snackBarType} />}
    </PlanBody>
  );
};

export default Plans;