import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import {
  StatsCol,
  StatsComponentStyle,
  StatsIconContainer,
} from "./style";
import { getThemeData } from "../../theme";
import styled from "styled-components";

const StatsComponent = ({ count, title, IconName, theme, addPlus }) => {
  const [played, setPlayed] = React.useState(false);

  const StatsHeading = styled.span`
    color: ${getThemeData(theme).textColor};
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  `;

  const StatsNumber = styled(CountUp)`
    color: ${getThemeData(theme).textColor};
  `;

  const StatsNumberH1 = styled.span`
    color: ${getThemeData(theme).textColor};
    font-size: 48px;
    font-weight: 600;
  `;

  return (
    <StatsComponentStyle>
      <StatsCol>
        {/*<StatsIconContainer color={getThemeData(theme).bgBox1}>
          <div className="gradientCirle"></div>
          <IconName
            className="statsIcon"
            style={{
              transform: "rotate(-45deg)",
            }}
            color={"white"}
          />
        </StatsIconContainer>*/}
        {/*addPlus
        ? <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "4px"}}>
          <StatsNumber redraw={true} end={count}>
            {({ countUpRef, start }) => (
              <VisibilitySensor
                onChange={start}
                delayedCall
              >
                <StatsNumberH1 ref={countUpRef} />
              </VisibilitySensor>
            )}
          </StatsNumber>
          <span style={{color: getThemeData(theme).textColor, fontSize: "42px", fontWeight: 600}}>{"+"}</span>
        </div>
        : <StatsNumber redraw={true} end={count}>
            {({ countUpRef, start }) => (
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !played) {
                    start();
                    setPlayed(true);
                  }
                }}
                delayedCall
              >
                <StatsNumberH1 ref={countUpRef} />
              </VisibilitySensor>
            )}
          </StatsNumber>
        */}
        <StatsNumber redraw={true} end={count}>
          {({ countUpRef, start }) => (
            <VisibilitySensor
              onChange={start}
              delayedCall
            >
              <StatsNumberH1 ref={countUpRef} />
            </VisibilitySensor>
          )}
        </StatsNumber>
        <StatsHeading>{title}</StatsHeading>
      </StatsCol>
    </StatsComponentStyle>
  );
};

export default StatsComponent;