import { createContext, useContext} from 'react';

export const ThemeContext = createContext({
  //theme: "light" as string,
  theme: "dark" as string,
  setTheme: (theme: string) => {},
});

export const HeaderDisplayContext = createContext({
  headerDisplay: "show" as string,
  setHeaderDisplay: (headerDisplay: string) => {},
});

export const AcceptedCookiesContext = createContext({
  hasAcceptedCookies: "no" as string,
  setHasAcceptedCookies: (hasAcceptedCookies: string) => {},
})

export const OddsContext = createContext({
  odds: 2 as number, //1 as number,
  setOdds: (odds: number) => {},
});

export const SearchHistoryContext = createContext({
  prevSearches: "" as string,
  setPrevSearches: (prevSearches: string) => {},
})

export const useGetTheme = () => useContext(ThemeContext);

export const useGetHeaderDisplay = () => useContext(HeaderDisplayContext);

export const useGetAcceptedCookies = () => useContext(AcceptedCookiesContext);

export const useGetOdds = () => useContext(OddsContext);

export const useGetSearchHistory = () => useContext(SearchHistoryContext);