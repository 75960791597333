import { getThemeData } from "../../theme";
import { useGetTheme, useGetOdds } from "../../lib/contexts";
import { BlurContainer, BodyContainer, MainTableContainer, MainTableHeader, MainTableSubtitle, MainTableTitle, RowContainer2 } from "./tableStyle";
import { ChevronDown, ChevronUp, DollarSign, ExternalLink, Info } from "react-feather";
import TableFooter from "./tableFooter";
import { RowObj, QuestionData } from "../../lib/interface";
import { getSiteURLs, getColorForPercentage, getLeagueEmoji, logoUrl, twitterHandle } from "./constants";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import styled from "styled-components";
import LoadingData from "../LoadingData";
import { useEffect, useState } from "react";
import { FaLock, FaSearch, FaUnlock } from "react-icons/fa";
import { getUserScope } from "./protectedAction";
import { Config as config } from "../../lib/config";
import { DownloadButton } from "./dataDownload";
import Explore from "./explore";
import { TextContent3, TooltipContainer } from "./hedgerStyle";
import { Tooltip } from "react-tooltip";
import TableFilter from "./tableFilter";
import BannerText from "./bannerText";
import { BsCopy, BsTwitterX } from "react-icons/bs";
import { getCurURLTwitter } from "./helpers";
import PageTags from "./pageTags";
import { getTeamLogo } from "./helpers";

interface BetsQProps {
  isMobile: boolean;
  showSubMenu: boolean;
  betRows: RowObj[],
  betRowsStore: RowObj[],
  sort7D: boolean,
  sort1M: boolean,
  sort3M: boolean,
  sort1Y: boolean,
  sortAllTime: boolean,
  handleSort: any,
  page: number,
  setPage: any,
  rowsPerPage: number,
  setRowsPerPage: any,
  loadingBetRows: boolean,
  showCustomizeModal: boolean,
  setShowCustomizeModal: any,
  addBetType: any,
  removeBetType: any,
  betTypes: string[],
  lastColSorted: number,
  restrictRowsAfterN: number,
  questionParams: QuestionData,
  setSnackBarType: any,
  setSnackBarTitle: any,
  loadingNLUPrediction: boolean,
  setIsQuery: any,
  showAvg: boolean,
  setShowAvg: any,
  setBetTypes: any,
};

const UnlockButton = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  padding: 4px 0px;
  width: 98%;
  gap: 4px;
  background: ${({color}) => color};
  &:hover {
    filter: brightness(110%);
  }
`

interface RowContentULProps {
  color: string,
  underline: boolean
}

const RowContentUL = styled.span<RowContentULProps>`
  font-weight: 500;
  color: ${({color}) => color};
  font-size: 14px;
  text-decoration: ${({underline}) => (underline ? "underline" : "none")};
  cursor: pointer;
`

const RowContent = styled.span`
  font-weight: 500;
  color: ${({color}) => color};
  font-size: 14px;
`

const RowContent2 = styled.span`
  font-weight: 600;
  color: ${({color}) => color};
  font-size: 14px;
`

const BetsQ = ({
  isMobile,
  showSubMenu,
  betRows,
  betRowsStore,
  sort7D,
  sort1M,
  sort3M,
  sort1Y,
  sortAllTime,
  handleSort,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadingBetRows,
  showCustomizeModal,
  setShowCustomizeModal,
  addBetType,
  removeBetType,
  betTypes,
  lastColSorted,
  restrictRowsAfterN,
  questionParams,
  setSnackBarType,
  setSnackBarTitle,
  loadingNLUPrediction,
  setIsQuery,
  showAvg,
  setShowAvg,
  setBetTypes,
}: BetsQProps) => {

  const [showLock, setShowLock] = useState<boolean[]>([true, ...Array(betRows.length).fill(false)]);
  const {odds, setOdds} = useGetOdds();
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  const userScope = getUserScope();
  const [sitesFound, setSitesFound] = useState<string[]>([]);
  const [teamsFound, setTeamsFound] = useState<string[]>([]);
  //const [teamIdsFound, setTeamIdsFound] = useState<string[]>([]);

  const formatQuestion = () => {
    var title = "";
    var sep = ", ";
    var sts = questionParams.site_ids.join(" ");
    if (sts !== "" && title !== "") title += sep;
    if (!sitesFound.includes(sts)) setSitesFound([...sitesFound, sts])
    title += sts;
    var lgs = questionParams.leagues.join(" ").toUpperCase();
    if (lgs !== "" && title !== "") title += sep;
    title += lgs;
    var tms = questionParams.team_ids.map(tid => tid?.split('-').slice(1).map(tsub => tsub[0].toUpperCase()+tsub.slice(1)).join(" ")).join(" ");
    if (tms !== "" && title !== "") title += sep;
    if (!teamsFound.includes(tms.toLowerCase())) setTeamsFound([...teamsFound, tms.toLowerCase()])
    /*
    var tmids = questionParams.team_ids.map(tid => tid?.toString());
    for (let tmidx = 0; tmidx < tmids.length; tmidx++) {
      if (tmids[tmidx] !== undefined && tmids[tmidx] !== null && tmids[tmidx] !== "") {
        if (!teamIdsFound.includes(tmids[tmidx]!)) setTeamIdsFound([...teamIdsFound, tmids[tmidx]!]);
      }
    }
    */
    console.log("FOUND", questionParams)
    title += tms;
    var bts = questionParams.bet_types.join(" ");
    if (bts !== "" && title !== "") title += sep;
    title += bts;
    var bls = questionParams.bet_lines.join(" ");
    if (bts !== "" && bls !== "") {
      title += ` ${bls}`;
    }
    title = title.replaceAll("moneyline", "Moneyline");
    title = title.replaceAll("over", "Over");
    title = title.replaceAll("under", "Under");
    title = title.replaceAll("spread", "Spread");
    title = title.replaceAll("point", "Point");
    return `${title}`
  }

  const formatIntent = () => {
    var intent = questionParams.intent?.match(/[A-Z][a-z]+/g)?.join(" ");
    if (intent === undefined) {
      intent = "Unknown";
    } else {
      if (intent.includes("Site Team")) {
        intent = intent.replaceAll("Site Team","Site and Team");
      }
      if (intent.includes("Site League")) {
        intent = intent.replaceAll("Site League","Site and League");
      }
      if (intent.includes("Ml")) {
        intent = intent.replaceAll("Ml","Moneylines");
      }
      if (intent.includes("Ps")) {
        intent = intent.replaceAll("Ps", "Point Spreads");
      }
      if (intent.includes("Ou")) {
        intent = intent.replaceAll("Ou", "Over Unders");
      } else if (intent.includes("Over")) {
        intent = intent.replaceAll("Over", "Overs");
      } else if (intent.includes("Under")) {
        intent = intent.replaceAll("Under", "Unders");
      }
    }
    return intent;
  }

  useEffect(() => {
    console.log("FOUND TOP BETS DATA", betRows.length)
  },[betRows])

  useEffect(() => {
    try {
      setPage(1);
      handleSort(5,1);
      if (!betTypes.includes('ml') ||
          !betTypes.includes('ou') ||
          !betTypes.includes('ps')
      ) {
        setBetTypes(['ml','ou','ps']);
      }
      window.scrollTo(0, 0);
      setTeamsFound([]);
      setSitesFound([]);
    } catch (e) {
      console.error(e)
    }
  }, [questionParams]);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <BodyContainer width={
      isMobile
      ? '90%'
      : (showSubMenu
        ? 'calc(100% - 12px)'//`calc(100% - ${lgSubMenuW})`
        : 'calc(100% - 12px)'//`calc(100% - ${smSubMenuW})`
      )}>
      <PageTags
        pageUrl={`${window.location.href}`}
        pageTitle={`Betting Data for ${formatQuestion()}`}
        pageDescription={`Generated by CrunchtimeOdds AI with interpreted intent ${formatIntent()}`}
        pageImageUrl={logoUrl}
        pageTwitter={twitterHandle}
      />
      {false //loadingBetRows
      ? <LoadingData active={loadingBetRows}/>
      : <>
        {isMobile
          ? <div style={{height: "82px"}}/>
          : <div style={{height: "42px"}}/>
        }
        <div style={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "100%" : "99%",
          height: "fit-content",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <MainTableHeader>
            {!isMobile && (
              <>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "fit-content", //"100%",
                  padding: "4px 12px",
                  borderRadius: "10px",
                  border: `1px solid ${getThemeData(theme).borderColor}`
                }} onClick={() => {
                  setIsQuery(true);
                }}>
                  <FaSearch size={"1rem"} color={getThemeData(theme).textColor4}/>
                  <div style={{width: "8px"}}/>
                  <span style={{fontWeight: 600, fontSize: "1.1rem", color: getThemeData(theme).textColor4}}>
                    {"Search with"}
                  </span>
                  <div style={{width: "6px"}}/>
                  <img src={
                    theme === 'dark'
                    ? logoUrl
                    : logoUrl
                    }
                    width={"20px"}
                    height={"20px"}
                    alt={'logo'}
                  />
                  <div style={{width: "4px"}}/>
                  <span style={{fontWeight: 600, fontSize: "1.1rem", color: getThemeData(theme).colorAccent}}>
                    {"AI"}
                  </span>
                </div>
                <div style={{height: "8px"}}/>
              </>
            )}
            {isMobile && <div style={{height: "22px"}}/>}
            <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: isMobile ? "center" : "space-between",
              alignItems: "center",
              width: "100%",
            }}>
              <div style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <MainTableTitle color={getThemeData(theme).textColor} isSmall={isMobile}>
                  {`${formatQuestion()}`}
                </MainTableTitle>
                {!isMobile && (
                  <>
                    <div style={{display: "inline-block", width: "12px"}}/>
                    <BsTwitterX
                      size={"18px"}
                      color={getThemeData(theme).colorAccent}
                      cursor={"pointer"}
                      onClick={(e) => {
                        window.open(`https://x.com/intent/post?url=${getCurURLTwitter(`${window.location.href}`)}`, "_blank"); //&text=${getCurSearchQuery(formatQuestion())}`;
                        e.preventDefault();
                      }}
                    />
                    <div style={{display: "inline-block", width: "12px"}}/>
                    <BsCopy
                      size={"18px"}
                      color={getThemeData(theme).colorAccent}
                      cursor={"pointer"}
                      onClick={() => {
                        navigator.clipboard.writeText(`${window.location.href}`);
                      }}
                    />
                  </>
                )}
              </div>
              {!loadingNLUPrediction && !loadingBetRows && !isMobile && betRows.length > 0 && (
                <DownloadButton
                  isSmallWidth={windowDimension.winWidth < 556}
                  dataRows={betRows}
                  dataName={"crunchtimeodds-query-result"}
                  canDownloadCSV={(userScope !== undefined && userScope >= 3)}
                  setSnackBarTitle={setSnackBarTitle}
                  setSnackBarType={setSnackBarType}
                />
              )}
            </div>
            <div style={{height: "8px"}}/>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: isMobile ? "center" : "space-between",
              alignItems: "top",
              width: "100%",
              position: "relative",
            }}>
              <div style={{display: "inline-flex", flexDirection: "row", textAlign: isMobile ? "center" : "left", maxWidth: isMobile ? "100%" : "calc(100% - 112px)"}}>
                <MainTableSubtitle color={getThemeData(theme).textColor4}>
                  <img src={
                    theme === 'dark'
                    ? logoUrl
                    : logoUrl
                    }
                    alt={'logo'}
                    style={{
                      width: windowDimension.winWidth > 800 ? "22px" : "18px",
                      height: windowDimension.winWidth > 800 ? "22px" : "18px",
                      display: "inline-block",
                      verticalAlign: "middle",
                      paddingBottom: "4px",
                    }}
                  />
                  <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent}}>
                    {" AI "}
                  </span>
                  <span style={{fontWeight: 600, color: getThemeData(theme).textColor4,}}>
                    {"INTERPRETED INTENT: "}
                  </span>
                  <span style={{fontWeight: 700, color: getThemeData(theme).textColor,}}>
                    {formatIntent()}
                  </span>
                </MainTableSubtitle>
              </div>
              {!loadingNLUPrediction && !loadingBetRows && !isMobile && betRows.length > 0 && (
                <TableFilter
                  showCustomizeModal={showCustomizeModal}
                  setShowCustomizeModal={setShowCustomizeModal}
                  betTypes={betTypes}
                  addBetType={addBetType}
                  removeBetType={removeBetType}
                  showAvg={showAvg}
                  setShowAvg={setShowAvg}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  displayBetTypeFilter={false}
                />
              )}
            </div>
            {isMobile && (
              <div style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "12px",
              }}>
                <BsTwitterX
                  size={"18px"}
                  color={getThemeData(theme).colorAccent}
                  cursor={"pointer"}
                  onClick={(e) => {
                    window.open(`https://x.com/intent/post?url=${getCurURLTwitter(`${window.location.href}`)}`, "_blank"); //&text=${getCurSearchQuery(formatQuestion())}`;
                    e.preventDefault();
                  }}
                />
                <div style={{display: "inline-block", width: "12px"}}/>
                <BsCopy
                  size={"18px"}
                  color={getThemeData(theme).colorAccent}
                  cursor={"pointer"}
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.href}`);
                  }}
                />
              </div>
            )}
            <div style={{height: "22px"}}/>
          </MainTableHeader>
          {!loadingNLUPrediction && !loadingBetRows
          ? <>
            {betRows.length === 0
            ? <MainTableHeader>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                  padding: "0px 0px 42px 0px",
                }}>
                  <div style={{display: "inline-flex", flexDirection: "row", alignItems: "center", maxWidth: isMobile ? "calc(100% - 124px)" : "calc(100% - 220px)", gap: "4px"}}>
                    <span style={{fontWeight: 600, fontSize: "24px", color: getThemeData(theme).textColor,}}>
                      {"No Results Found"}
                    </span>
                    <span style={{fontWeight: 600, fontSize: "24px", textDecoration: "underline", cursor: "pointer", color: getThemeData(theme).colorAccent,}} onClick={() => {
                      navigate("/");
                    }}>
                      {"Try A Different Search"}
                    </span>
                  </div>
                </div>
              </MainTableHeader>
            : <MainTableHeader>
              <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                position: "relative",
                padding: "0px 0px 42px 0px",
              }}>
                <BannerText
                  betRows={betRows}
                  isMobile={isMobile}
                />
              </div>
            </MainTableHeader>
            }
          </>
          : <MainTableHeader>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              position: "relative",
              padding: "0px 0px 42px 0px",
            }}>
              <div style={{display: "inline-flex", flexDirection: "row", alignItems: "center", maxWidth: isMobile ? "calc(100% - 124px)" : "calc(100% - 220px)", gap: "4px"}}>
                <Spinner text={"Loading..."} color={getThemeData(theme).textColor}/>
              </div>
            </div>
          </MainTableHeader>
          }
          {!loadingNLUPrediction && !loadingBetRows && betRows.length > 0 && isMobile && (
            <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", padding: "0px 0px 12px 0px"}}>
              <DownloadButton
                isSmallWidth={windowDimension.winWidth < 556}
                dataRows={betRows}
                dataName={"crunchtimeodds-top-bets-by-unit-profit"}
                canDownloadCSV={(userScope !== undefined && userScope >= 3)}
                setSnackBarTitle={setSnackBarTitle}
                setSnackBarType={setSnackBarType}
              />
              <TableFilter
                showCustomizeModal={showCustomizeModal}
                setShowCustomizeModal={setShowCustomizeModal}
                betTypes={betTypes}
                addBetType={addBetType}
                removeBetType={removeBetType}
                showAvg={showAvg}
                setShowAvg={setShowAvg}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                displayBetTypeFilter={false}
              />
            </div>
          )}
          {betRows.length === 0
          ? null
          : <MainTableContainer
            color={'transparent'}
            brcolor={getThemeData(theme).borderColor}
            //acolor={getThemeData(theme).colorAccent}
          >
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "12px 0px",
              borderTop: `1px solid ${getThemeData(theme).borderColor}`,
              borderBottom: `1px solid ${getThemeData(theme).borderColor}`,
              minWidth: "1040px",
              //overflowX: "scroll",
            }}>
              <div style={{width: "40px"}}/>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "60px"}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"League"}
                </span>
              </div>
              <div style={{width: "6px"}}/>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "288px", background: getThemeData(theme).bgGlobal, position: "sticky", left: 0}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Bet"}
                </span>
              </div>
              <div style={{width: "6px"}}/>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "140px"}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Site"}
                </span>
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "100px", alignItems: "center"}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Position"}
                </span>
                <div style={{width: "4px"}}/>
                <Info size={16} color={"grey"} cursor={"pointer"} data-tooltip-id="tip-position"/>
                <TooltipContainer color={getThemeData(theme).bgBox1}>
                  <Tooltip id="tip-position" place="bottom" className='custom-tooltip' opacity={1.0}>
                    <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"An underdog bet returns more than the amount wagered, while a favored bet returns the same or less."}
                    </TextContent3>
                  </Tooltip>
                </TooltipContainer>
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort7D) {
                    handleSort(1,1)
                  } else {
                    handleSort(1,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"7d"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort7D
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort1M) {
                    handleSort(2,1)
                  } else {
                    handleSort(2,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"1m"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort1M
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort3M) {
                    handleSort(3,1)
                  } else {
                    handleSort(3,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"3m"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort3M
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort1Y) {
                    handleSort(4,1)
                  } else {
                    handleSort(4,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"1y"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort1Y
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", cursor: "pointer"}}
                onClick={() => {
                  if (!sortAllTime) {
                    handleSort(5,1)
                  } else {
                    handleSort(5,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"All Time"}
                </span>
                <div style={{width: "6px"}}/>
                {!sortAllTime
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
            </div>
            {loadingBetRows
            ? <Spinner text={"Loading..."} color={getThemeData(theme).textColor}/>
            : <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              minWidth: "1040px",
              //overflowX: "scroll",
            }}>
              {betRows.slice((page-1)*rowsPerPage, page*rowsPerPage).map((row, rind) => {
                if ((userScope === 0 || userScope === 1) &&
                    ((page === 1 && (rind+1) > restrictRowsAfterN) ||
                     (page > 1 && ((rind+1)+((page-1)*rowsPerPage)) > restrictRowsAfterN)
                )) {
                  return (
                    <RowContainer2
                      color={getThemeData(theme).borderColor}
                      color2={getThemeData(theme).bgBox1}
                    >
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "40px", alignItems: "center",}}>
                        <RowContent color={getThemeData(theme).textColor}>
                          {page === 1 ? (rind+1).toLocaleString() : ((rind+1)+((page-1)*rowsPerPage)).toLocaleString()}
                        </RowContent>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "354px", alignItems: "center", gap: "4px"}}>
                        <UnlockButton color={getThemeData(theme).bgBlurGlobal}
                        onMouseEnter={() => {
                          let cnew = [...showLock];
                          cnew[rind] = false;
                          setShowLock([...cnew]);
                        }}
                        onMouseLeave={() => {
                          let cnew = [...showLock];
                          cnew[rind] = true;
                          setShowLock([...cnew]);
                        }}
                        onClick={() => {
                          if (userScope === 0) {
                            navigate("/account?login=true");
                          } else {
                            setSnackBarTitle("Upgrade your subscription")
                            setSnackBarType("customWarning")
                            setTimeout(() => {
                              setSnackBarType(undefined);
                              setSnackBarTitle(undefined);
                            }, config.snackTimeout);
                          }
                        }}>
                          <div style={{width: "4px"}}/>
                          {showLock[rind] && <FaLock color={getThemeData(theme).textColor} size={"14px"} />}
                          {!showLock[rind] && <FaUnlock color={getThemeData(theme).textColor} size={"14px"} />}
                          <RowContent color={getThemeData(theme).textColor}>
                            {"Unlock More Data"}
                          </RowContent>
                        </UnlockButton>
                      </div>
                      <div style={{width: "6px"}}/>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "140px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "100px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        <BlurContainer color={getThemeData(theme).bgBlurGlobal} width={"98%"} height={"22px"}/>
                      </div>
                    </RowContainer2>
                  );
                } else {
                  return (
                    <RowContainer2
                      color={getThemeData(theme).borderColor}
                      color2={getThemeData(theme).bgBox1}
                    >
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "40px", alignItems: "center",}}>
                        <RowContent color={getThemeData(theme).textColor}>
                          {page === 1 ? (rind+1).toLocaleString() : ((rind+1)+((page-1)*rowsPerPage)).toLocaleString()}
                        </RowContent>
                      </div>
                      {questionParams.leagues.length === 0 && questionParams.team_ids.length === 0
                      ? <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "60px", alignItems: "center", gap: "4px",}} onClick={() => {
                        navigate(`/q?league=${row.c2.toLowerCase()}&intent=GetLeagueOdds`)
                      }}>
                        {getLeagueEmoji(row.c2)}
                        <RowContentUL color={getThemeData(theme).textColor} underline={true}>
                          {row.c2}
                        </RowContentUL>
                      </div>
                      : <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "60px", alignItems: "center", gap: "4px",}}>
                        {getLeagueEmoji(row.c2)}
                        <RowContent color={getThemeData(theme).textColor}>
                          {row.c2}
                        </RowContent>
                      </div>
                      }
                      <div style={{width: "6px"}}/>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "288px", alignItems: "center", gap: "2px", background: getThemeData(theme).bgGlobal, position: "sticky", left: 0}}>
                        <RowContentUL
                          color={getThemeData(theme).textColor}
                          underline={false}
                          onClick={() => {
                            var tnf = row.c2.toLowerCase()+"-"+row.c1.split(" ").map(tc => tc.toLowerCase()).join("-");
                            if (questionParams.bet_types.includes(row.c3.toLowerCase()) &&
                                questionParams.team_ids.includes(tnf)
                            ) {
                              navigate(`/q?team_id=${tnf}&league=${row.c2.toLowerCase()}&intent=GetTeamOdds`)
                            } else {
                              if (row.c3 === "Moneyline") {
                                navigate(`/q?team_id=${tnf}&bet_type=moneyline&intent=GetSiteTeamMl`)
                              } else if (row.c3 === "Spread") {
                                navigate(`/q?team_id=${tnf}&bet_type=spread&intent=GetSiteTeamPs`)
                              } else if (row.c3 === "Over") {
                                navigate(`/q?team_id=${tnf}&bet_type=over&intent=GetSiteTeamOver`)
                              } else if (row.c3 === "Under") {
                                navigate(`/q?team_id=${tnf}&bet_type=under&intent=GetSiteTeamUnder`)
                              }
                            }
                          }}
                        >
                          {row.c3 !== "Moneyline" && (
                            <>
                              {getTeamLogo(row.c2, row.c1.split(" ").map(tc => tc).join("-"))}
                              {" "}
                              <RowContentUL color={getThemeData(theme).textColor} underline={true}>
                                {row.c1}
                              </RowContentUL>
                            </>
                          )}
                          {row.c3 === "Moneyline" && (
                            <>
                              <div style={{display: "inline-block", width: "100%"}}>
                                {getTeamLogo(row.c2, row.c1.split(" ").map(tc => tc).join("-"))}
                                {" "}
                                <RowContentUL color={getThemeData(theme).textColor} underline={true}>
                                  {row.c1}
                                </RowContentUL>
                                <RowContentUL color={getThemeData(theme).textColor} underline={
                                  (questionParams.bet_types.includes(row.c3.toLowerCase()) &&
                                   questionParams.team_ids.includes(row.c2.toLowerCase()+"-"+row.c1.split(" ").map(tc => tc.toLowerCase()).join("-")))
                                    ? false
                                    : true
                                }>
                                  {` ${row.c3}`}
                                </RowContentUL>
                              </div>
                              <div style={{display: "inline-block"}}>
                                <RowContentUL color={getThemeData(theme).textColor} underline={false}>
                                  {" vs "}
                                  {getTeamLogo(row.c2, row.c11.split(" ").map(tc => tc).join("-"))}
                                  {" "}
                                </RowContentUL>
                                <RowContentUL color={getThemeData(theme).textColor} underline={false}>
                                  {row.c11}
                                </RowContentUL>
                              </div>
                            </>
                          )}
                          {row.c3 === "Spread" && (
                            <>
                              <RowContentUL color={getThemeData(theme).textColor} underline={
                                (questionParams.bet_types.includes(row.c3.toLowerCase()) &&
                                questionParams.team_ids.includes(row.c2.toLowerCase()+"-"+row.c1.split(" ").map(tc => tc.toLowerCase()).join("-")))
                                  ? false
                                  : true
                              }>
                                {` ${row.c3}`}
                              </RowContentUL>
                              <RowContentUL color={getThemeData(theme).textColor} underline={false}>
                                {` ${row.c11}`}
                              </RowContentUL>
                            </>
                          )}
                          {(row.c3 === "Over" || row.c3 === "Under") && (
                            <>
                              <RowContentUL color={getThemeData(theme).textColor} underline={
                                (questionParams.bet_types.includes(row.c3.toLowerCase()) &&
                                questionParams.team_ids.includes(row.c2.toLowerCase()+"-"+row.c1.split(" ").map(tc => tc.toLowerCase()).join("-")))
                                  ? false
                                  : true
                              }>
                                {` ${row.c3}`}
                              </RowContentUL>
                              <RowContentUL color={getThemeData(theme).textColor} underline={false}>
                                {` ${row.c11}`}
                              </RowContentUL>
                            </>
                          )}
                        </RowContentUL>
                      </div>
                      <div style={{width: "6px"}}/>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "140px", alignItems: "center", gap: "2px",}}>
                        {getSiteURLs(row.c4) !== ""
                        ? <Link to={getSiteURLs(row.c4)} target="_blank" style={{textDecoration: "none"}}>
                          <div style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            justifyContent: "left",
                            alignItems: "center",
                            gap: "4px",
                            cursor: (questionParams.site_ids.length === 0 ? "pointer" : "default")
                          }}>
                            <span style={{
                              fontWeight: 600,
                              fontSize: "14px",
                              color: getThemeData(theme).colorAccent,
                            }}>
                              {row.c4 !== 'OvertimeMarkets'
                              ? row.c4
                              : 'Overtime Markets'}
                            </span>
                            <ExternalLink size={14} color={getThemeData(theme).colorAccent} cursor={"pointer"}/>
                          </div>
                        </Link>
                        : (questionParams.site_ids.length === 0
                            ? <RowContentUL
                                color={getThemeData(theme).textColor}
                                underline={true}
                                onClick={() => {
                                  if (row.c3 === "Moneyline") {
                                    navigate(`/q?site_id=${row.c4}&bet_type=moneyline&intent=GetSiteMl`)
                                  } else if (row.c3 === "Spread") {
                                    navigate(`/q?site_id=${row.c4}&bet_type=spread&intent=GetSitePs`)
                                  } else if (row.c3 === "Over") {
                                    navigate(`/q?site_id=${row.c4}&bet_type=over&intent=GetSiteOver`)
                                  } else if (row.c3 === "Under") {
                                    navigate(`/q?site_id=${row.c4}&bet_type=under&intent=GetSiteUnder`)
                                  }
                                }}
                              >
                                {row.c4 !== 'OvertimeMarkets'
                                ? row.c4
                                : 'Overtime Markets'}
                              </RowContentUL>
                            : <RowContentUL
                                color={getThemeData(theme).textColor}
                                underline={false}
                              >
                                {row.c4 !== 'OvertimeMarkets'
                                ? row.c4
                                : 'Overtime Markets'}
                              </RowContentUL>
                          )
                        }
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "100px", alignItems: "center", gap: "2px",}}>
                        <span style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          color: (row.c5 === 'Underdog' ? getThemeData(theme).underdogPosition : getThemeData(theme).favoredPosition),
                        }}>
                          {row.c5}
                        </span>
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        {lastColSorted === 1 ? <> {sort7D
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {parseFloat(row.c6) >= 0 ? "+" : "-"}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {parseFloat(row.c6) >= 0 ? "+" : "-"}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {parseFloat(row.c6) >= 0 ? "+" : "-"}
                        </RowContent2>}
                        <DollarSign size={14} color={
                          lastColSorted === 1
                          ? ( sort7D
                            ? (
                              page === 1
                                ? getColorForPercentage((rind+1)/betRows.length)
                                : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                            )
                            : (
                              page === 1
                                ? getColorForPercentage(1-((rind+1)/betRows.length))
                                : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                            )
                          )
                          : getThemeData(theme).textColor
                        }/>
                        {lastColSorted === 1 ? <> {sort7D
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {row.c6.replace("-","")}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {row.c6.replace("-","")}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {row.c6.replace("-","")}
                        </RowContent2>}
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        {lastColSorted === 2 ? <> {sort1M
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {parseFloat(row.c7) >= 0 ? "+" : "-"}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {parseFloat(row.c7) >= 0 ? "+" : "-"}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {parseFloat(row.c7) >= 0 ? "+" : "-"}
                        </RowContent2>}
                        <DollarSign size={14} color={
                          lastColSorted === 2
                          ? ( sort1M
                            ? (
                              page === 1
                                ? getColorForPercentage((rind+1)/betRows.length)
                                : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                            )
                            : (
                              page === 1
                                ? getColorForPercentage(1-((rind+1)/betRows.length))
                                : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                            )
                          )
                          : getThemeData(theme).textColor
                        }/>
                        {lastColSorted === 2 ? <> {sort1M
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {row.c7.replace("-","")}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {row.c7.replace("-","")}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {row.c7.replace("-","")}
                        </RowContent2>}
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        {lastColSorted === 3 ? <> {sort3M
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {parseFloat(row.c8) >= 0 ? "+" : "-"}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {parseFloat(row.c8) >= 0 ? "+" : "-"}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {parseFloat(row.c8) >= 0 ? "+" : "-"}
                        </RowContent2>}
                        <DollarSign size={14} color={
                          lastColSorted === 3
                          ? ( sort3M
                            ? (
                              page === 1
                                ? getColorForPercentage((rind+1)/betRows.length)
                                : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                            )
                            : (
                              page === 1
                                ? getColorForPercentage(1-((rind+1)/betRows.length))
                                : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                            )
                          )
                          : getThemeData(theme).textColor
                        }/>
                        {lastColSorted === 3 ? <> {sort3M
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {row.c8.replace("-","")}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {row.c8.replace("-","")}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {row.c8.replace("-","")}
                        </RowContent2>}
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        {lastColSorted === 4 ? <> {
                        sort1Y
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {parseFloat(row.c9) >= 0 ? "+" : "-"}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {parseFloat(row.c9) >= 0 ? "+" : "-"}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {parseFloat(row.c9) >= 0 ? "+" : "-"}
                        </RowContent2>}
                        <DollarSign size={14} color={
                          lastColSorted === 4
                          ? ( sort1Y
                            ? (
                              page === 1
                                ? getColorForPercentage((rind+1)/betRows.length)
                                : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                            )
                            : (
                              page === 1
                                ? getColorForPercentage(1-((rind+1)/betRows.length))
                                : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                            )
                          )
                          : getThemeData(theme).textColor
                        }/>
                        {lastColSorted === 4 ? <> {
                        sort1Y
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {row.c9.replace("-","")}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {row.c9.replace("-","")}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {row.c9.replace("-","")}
                        </RowContent2>}
                      </div>
                      <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "80px", alignItems: "center", gap: "2px",}}>
                        {lastColSorted === 5 ? <> {
                        sortAllTime
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {parseFloat(row.c10) >= 0 ? "+" : "-"}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {parseFloat(row.c10) >= 0 ? "+" : "-"}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {parseFloat(row.c10) >= 0 ? "+" : "-"}
                        </RowContent2>}
                        <DollarSign size={14} color={
                          lastColSorted === 5
                          ? ( sortAllTime
                            ? (
                              page === 1
                                ? getColorForPercentage((rind+1)/betRows.length)
                                : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                            )
                            : (
                              page === 1
                                ? getColorForPercentage(1-((rind+1)/betRows.length))
                                : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                            )
                          )
                          : getThemeData(theme).textColor
                        }/>
                        {lastColSorted === 5 ? <> {
                        sortAllTime
                        ? <RowContent2 color={
                          page === 1
                            ? getColorForPercentage((rind+1)/betRows.length)
                            : getColorForPercentage(((rind+1)+((page-1)*rowsPerPage))/betRows.length)
                        }>
                          {row.c10.replace("-","")}
                        </RowContent2>
                        : <RowContent2 color={
                          page === 1
                            ? getColorForPercentage(1-((rind+1)/betRows.length))
                            : getColorForPercentage(1-(((rind+1)+((page-1)*rowsPerPage))/betRows.length))
                        }>
                          {row.c10.replace("-","")}
                        </RowContent2>
                        } </>
                        : <RowContent2 color={getThemeData(theme).textColor}>
                          {row.c10.replace("-","")}
                        </RowContent2>}
                      </div>
                    </RowContainer2>
                  );
                }
              })}
            </div>
            }
          </MainTableContainer>
          }
          {betRows.length === 0
          ? null
          : <TableFooter
            isMobile={isMobile}
            pageN={page}
            rowsPerPageN={rowsPerPage}
            dataN={betRows.length}
            setPageN={setPage}
            setRowsPerPageN={setRowsPerPage}
            showIncNotice={true}
          />
          }
        </div>
        <div style={{height: "42px"}}/>
        <Explore
          site_ids={
            questionParams.site_ids.join(" ") !== ""
            ? [questionParams.site_ids.join(" "), questionParams.site_ids.join(" "), questionParams.site_ids.join(" ")]
            : ["BetOnline", "Fanduel", "Betrivers"]
          }
          inc_trending={true}
          inc_top={false}
        />
        <div style={{height: "42px"}}/>
        <div style={{height: "42px"}}/>
      </>
      }
    </BodyContainer>
  );
};

export default BetsQ;