import { convertOdds } from "../../odds";
import { getThemeData } from "../../theme";
import { useGetTheme, useGetOdds } from "../../lib/contexts";
import { BodyContainer, MainTableContainer, MainTableHeader, MainTableSubtitle, MainTableTitle, RowContainer } from "./tableStyle";
import { TextContent3, TooltipContainer, ItemContentMobile2, OddsButton } from "./hedgerStyle";
import { ChevronDown, ChevronUp, ExternalLink, Info, Lock } from "react-feather";
import { Tooltip } from "react-tooltip";
import TableFooter from "./tableFooter";
import { OD, RowObj } from "../../lib/interface";
import { useEffect, useState } from "react";
import { getSiteURLs, getColorForPercentage, getLeagueEmoji, logoUrl, twitterHandle } from "./constants";
import { getCurURLTwitter, getTeamLogo, matchBetRow, normalize } from "./helpers";
import { Link, useNavigate } from "react-router-dom";
import LoadingData from "../LoadingData";
import Spinner from "../Spinner";
import OddsChart from "./oddsChart";
import OddsDetails from "./oddsDetails";
import styled from "styled-components";
import { getUserScope } from "./protectedAction";
import { Config as config } from "../../lib/config";
import { DownloadButton } from "./dataDownload";
import Explore from "./explore";
import { FaSearch } from "react-icons/fa";
import TableFilter from "./tableFilter";
import { BsCopy, BsTwitterX } from "react-icons/bs";
import PageTags from "./pageTags";

interface BetsTrendingProps {
  isMobile: boolean;
  showSubMenu: boolean;
  oddsData: OD[],
  oddsDataStore: OD[],
  betRowsStore: RowObj[],
  sort7D2: boolean,
  sort1M2: boolean,
  sort3M2: boolean,
  sort1Y2: boolean,
  sortAllTime2: boolean,
  sortVig: boolean,
  handleSort2: any,
  page2: number,
  setPage2: any,
  rowsPerPage2: number,
  setRowsPerPage2: any,
  loadingOddsData: boolean,
  restrictRowsAfterN: number,
  setSnackBarType: any,
  setSnackBarTitle: any,
  showCustomizeModal: boolean,
  setShowCustomizeModal: any,
  addBetType: any,
  removeBetType: any,
  betTypes: string[],
  setIsQuery: any,
};

interface HiddenStatsContainerProps {
  color: string;
  isMobile: boolean;
}

interface RowContainer2Props {
  isMobile: boolean;
}

interface CustomizeButtonProps {
  padExtra: boolean;
  color: string;
  smallWidth: boolean;
}

interface OddsOptionContainerProps {
  isMobile: boolean;
}

export const OddsOptionContainer = styled.div<OddsOptionContainerProps>`
  display: inline-flex;
  flex-direction: ${({isMobile}) => isMobile ? "row" : "row"};
  justify-content: ${({isMobile}) => isMobile ? "center" : "left"};
  align-items: center;
  gap: 6px;
  width: ${({isMobile}) => isMobile ? "100%" : "fit-content"};
`

const RowContainer2 = styled.div<RowContainer2Props>`
  display: flex;
  flex-direction: ${({isMobile}) => isMobile ? "row" : "row"};
  justify-content: ${({isMobile}) => isMobile ? "space-between" : "center"};
  align-items: center;
  width: ${({isMobile}) => isMobile ? "100%" : "100%"};
`

const HiddenStatsContainer = styled.div<HiddenStatsContainerProps>`
  width: ${({isMobile}) => isMobile ? "fit-content" : "260px"};
  height: 180px;
  background: ${({color}) => color};
  border-radius: 10px;
  box-shadow: 0 0 6px #9ecaed;
  cursor: pointer;
  &:hover {
    filter: brightness(150%);
    box-shadow: 0 0 12px #9ecaed;
  }
`

const BetsTrending = ({
  isMobile,
  showSubMenu,
  oddsData,
  oddsDataStore,
  betRowsStore,
  sort7D2,
  sort1M2,
  sort3M2,
  sort1Y2,
  sortAllTime2,
  sortVig,
  handleSort2,
  page2,
  rowsPerPage2,
  setPage2,
  setRowsPerPage2,
  loadingOddsData,
  restrictRowsAfterN,
  setSnackBarType,
  setSnackBarTitle,
  showCustomizeModal,
  setShowCustomizeModal,
  addBetType,
  removeBetType,
  betTypes,
  setIsQuery,
}: BetsTrendingProps) => {

  const [showOddsChart, setShowOddsChart] = useState<boolean[]>([true, ...Array(oddsData.length).fill(false)]);
  const {odds, setOdds} = useGetOdds();
  const {theme} = useGetTheme();
  const navigate = useNavigate();
  const userScope = getUserScope();
  const canViewAdv = (userScope !== 0 && userScope !== 1);

  useEffect(() => {
    console.log("FOUND PENDING BETS DATA", oddsData.length)
  },[oddsData])

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <BodyContainer width={
      isMobile
      ? '90%'
      : (showSubMenu
        ? 'calc(100% - 12px)'//`calc(100% - ${lgSubMenuW})`
        : 'calc(100% - 12px)'//`calc(100% - ${smSubMenuW})`
      )}>
      <PageTags
        pageUrl={`${window.location.href}`}
        pageTitle={"🔥 Top Movers"}
        pageDescription={"Trending Bets with the largest odds movement across bookies."}
        pageImageUrl={logoUrl}
        pageTwitter={twitterHandle}
      />
      {false //loadingOddsData
      ? <LoadingData active={loadingOddsData}/>
      : <>
        {isMobile
          ? <div style={{height: "82px"}}/>
          : <div style={{height: "42px"}}/>
        }
        <div style={{
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "100%" : "99%",
          height: "fit-content",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <MainTableHeader>
          {!isMobile && (
              <>
                <div style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "fit-content", //"100%",
                  padding: "4px 12px",
                  borderRadius: "10px",
                  border: `1px solid ${getThemeData(theme).borderColor}`
                }} onClick={() => {
                  setIsQuery(true);
                }}>
                  <FaSearch size={"1rem"} color={getThemeData(theme).textColor4}/>
                  <div style={{width: "8px"}}/>
                  <span style={{fontWeight: 600, fontSize: "1.1rem", color: getThemeData(theme).textColor4}}>
                    {"Search with"}
                  </span>
                  <div style={{width: "6px"}}/>
                  <img src={
                    theme === 'dark'
                    ? logoUrl
                    : logoUrl
                    }
                    width={"20px"}
                    height={"20px"}
                    alt={'logo'}
                  />
                  <div style={{width: "4px"}}/>
                  <span style={{fontWeight: 600, fontSize: "1.1rem", color: getThemeData(theme).colorAccent}}>
                    {"AI"}
                  </span>
                </div>
                <div style={{height: "8px"}}/>
              </>
            )}
            {isMobile && <div style={{height: "22px"}}/>}
            <div style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: isMobile ? "center" : "space-between",
              alignItems: "center",
              width: "100%",
            }}>
              <div style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <MainTableTitle color={getThemeData(theme).textColor} isSmall={isMobile}>
                  {"🔥 Top Movers"}
                </MainTableTitle>
                <div style={{display: "inline-block", width: "12px"}}/>
                <BsTwitterX
                  size={"18px"}
                  color={getThemeData(theme).colorAccent}
                  cursor={"pointer"}
                  onClick={(e) => {
                    window.open(`https://x.com/intent/post?url=${getCurURLTwitter(`${window.location.href}`)}`, "_blank"); //&text=${getCurSearchQuery(formatQuestion())}`;
                    e.preventDefault();
                  }}
                />
                <div style={{display: "inline-block", width: "12px"}}/>
                <BsCopy
                  size={"18px"}
                  color={getThemeData(theme).colorAccent}
                  cursor={"pointer"}
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.href}`);
                  }}
                />
              </div>
              {!loadingOddsData && !isMobile && (
                <DownloadButton
                  isSmallWidth={windowDimension.winWidth < 556}
                  dataRows={oddsData}
                  dataName={"crunchtimeodds-trending-bets"}
                  canDownloadCSV={(userScope !== undefined && userScope >= 3)}
                  setSnackBarTitle={setSnackBarTitle}
                  setSnackBarType={setSnackBarType}
                />
              )}
            </div>
            <div style={{height: "8px"}}/>
            <div style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: isMobile ? "center" : "space-between",
              alignItems: isMobile ? "center" : "top",
              width: "100%",
              position: "relative",
              gap: isMobile ? "12px" : "0px",
              padding: isMobile ? "0px 0px 12px 0px" : "0px 0px 12px 0px",
            }}>
              <div style={{display: "inline-flex", flexDirection: "row", textAlign: isMobile ? "center" : "left", maxWidth: isMobile ? "100%" : "calc(100% - 112px)"}}>
                <MainTableSubtitle color={getThemeData(theme).textColor4}>
                  {"Bets with the largest odds movement across bookies. "}
                  {!canViewAdv &&
                    <span style={{fontWeight: 600, color: getThemeData(theme).textColor, textDecoration: "underline", cursor: "pointer",}} onClick={() => {
                      if (userScope === 0) {
                        navigate("/account?login=true");
                      } else {
                        setSnackBarTitle("Upgrade your subscription")
                        setSnackBarType("customWarning")
                        setTimeout(() => {
                          setSnackBarType(undefined);
                          setSnackBarTitle(undefined);
                        }, config.snackTimeout);
                      }
                    }}>
                      {"Unlock Blurred Data"}
                    </span>
                  }
                </MainTableSubtitle>
              </div>
              <OddsOptionContainer isMobile={isMobile}>
                <OddsButton
                  color={odds === 1 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
                  onClick={() => {setOdds(1)}}
                >
                  <ItemContentMobile2 color={getThemeData(theme).textColor}>
                    {"Decimal Odds"}
                  </ItemContentMobile2>
                </OddsButton>
                <OddsButton
                  color={odds === 2 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
                  onClick={() => {setOdds(2)}}
                >
                  <ItemContentMobile2 color={getThemeData(theme).textColor}>
                    {"American Odds"}
                  </ItemContentMobile2>
                </OddsButton>
                <OddsButton
                  color={odds === 3 ? getThemeData(theme).iconColor : (getThemeData(theme).borderColor)}
                  onClick={() => {setOdds(3)}}
                >
                  <ItemContentMobile2 color={getThemeData(theme).textColor}>
                    {"Implied Probability"}
                  </ItemContentMobile2>
                </OddsButton>
                {!loadingOddsData && !isMobile && oddsData.length > 0 && (
                  <TableFilter
                    showCustomizeModal={showCustomizeModal}
                    setShowCustomizeModal={setShowCustomizeModal}
                    betTypes={betTypes}
                    addBetType={addBetType}
                    removeBetType={removeBetType}
                    showAvg={null}
                    setShowAvg={null}
                    rowsPerPage={rowsPerPage2}
                    setRowsPerPage={setRowsPerPage2}
                    displayBetTypeFilter={true}
                  />
                )}
              </OddsOptionContainer>
              {!loadingOddsData && isMobile && oddsData.length > 0 && (
                <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center", padding: "12px 0px 12px 0px"}}>
                  <DownloadButton
                    isSmallWidth={windowDimension.winWidth < 556}
                    dataRows={oddsData}
                    dataName={"crunchtimeodds-trending-bets"}
                    canDownloadCSV={(userScope !== undefined && userScope >= 3)}
                    setSnackBarTitle={setSnackBarTitle}
                    setSnackBarType={setSnackBarType}
                  />
                  <TableFilter
                    showCustomizeModal={showCustomizeModal}
                    setShowCustomizeModal={setShowCustomizeModal}
                    betTypes={betTypes}
                    addBetType={addBetType}
                    removeBetType={removeBetType}
                    showAvg={null}
                    setShowAvg={null}
                    rowsPerPage={rowsPerPage2}
                    setRowsPerPage={setRowsPerPage2}
                    displayBetTypeFilter={true}
                  />
                </div>
              )}
            </div>
          </MainTableHeader>
          <MainTableContainer
            color={'transparent'}
            brcolor={getThemeData(theme).borderColor}
            //acolor={getThemeData(theme).colorAccent}
          >
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "12px 0px",
              borderTop: `1px solid ${getThemeData(theme).borderColor}`,
              borderBottom: `1px solid ${getThemeData(theme).borderColor}`,
              minWidth: "1068px",
              //overflowX: "scroll",
            }}>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "60px"}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"League"}
                </span>
              </div>
              <div style={{width: "6px"}}/>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "172px", background: getThemeData(theme).bgGlobal, position: "sticky", left: 0}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Game"}
                </span>
              </div>
              <div style={{width: "6px"}}/>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "146px"}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Bet"}
                </span>
              </div>
              <div style={{width: "6px"}}/>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "110px"}}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Site"}
                </span>
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "110px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort7D2) {
                    handleSort2(1,1)
                  } else {
                    handleSort2(1,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Volatility"}
                </span>
                <div style={{width: "4px"}}/>
                <Info size={16} color={"grey"} cursor={"pointer"} data-tooltip-id="tip-volatility"/>
                <TooltipContainer color={getThemeData(theme).bgBox1}>
                  <Tooltip id="tip-volatility" place="bottom" className='custom-tooltip' opacity={1.0}>
                    <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"Standard deviation of odds"}
                    </TextContent3>
                  </Tooltip>
                </TooltipContainer>
                <div style={{width: "4px"}}/>
                {!sort7D2
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "70px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort1M2) {
                    handleSort2(2,1)
                  } else {
                    handleSort2(2,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {/*"Decimal Odds"*/}
                  {"Odds"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort1M2
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "70px", cursor: "pointer"}}
                onClick={() => {
                  if (!sortVig) {
                    handleSort2(6,1)
                  } else {
                    handleSort2(6,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Vig"}
                </span>
                <div style={{width: "4px"}}/>
                <Info size={16} color={"grey"} cursor={"pointer"} data-tooltip-id="tip-vig"/>
                <TooltipContainer color={getThemeData(theme).bgBox1}>
                  <Tooltip id="tip-vig" place="bottom" className='custom-tooltip' opacity={1.0}>
                    <TextContent3 isMobile={isMobile} color={getThemeData(theme).textColor}>
                      {"Fee charged by a bookie for a given bet"}
                    </TextContent3>
                  </Tooltip>
                </TooltipContainer>
                <div style={{width: "4px"}}/>
                {!sortVig
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "94px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort3M2) {
                    handleSort2(3,1)
                  } else {
                    handleSort2(3,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Since Start"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort3M2
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "94px", cursor: "pointer"}}
                onClick={() => {
                  if (!sort1Y2) {
                    handleSort2(4,1)
                  } else {
                    handleSort2(4,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Since High"}
                </span>
                <div style={{width: "6px"}}/>
                {!sort1Y2
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "94px", cursor: "pointer"}}
                onClick={() => {
                  if (!sortAllTime2) {
                    handleSort2(5,1)
                  } else {
                    handleSort2(5,0)
                  }
              }}>
                <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                  {"Since Low"}
                </span>
                <div style={{width: "6px"}}/>
                {!sortAllTime2
                ? <ChevronDown size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                : <ChevronUp size={16} cursor={"pointer"} color={getThemeData(theme).textColor}/>
                }
              </div>
              <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "30px"}}/>
            </div>
            {loadingOddsData
            ? <Spinner text={"Loading..."} color={getThemeData(theme).textColor}/>
            : <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              minWidth: "1068px",
              //overflowX: "scroll",
            }}>
              {oddsData.slice((page2-1)*rowsPerPage2, page2*rowsPerPage2).map((row, rind) => {
                return (
                  <>
                  <RowContainer
                    color={getThemeData(theme).borderColor}
                    color2={getThemeData(theme).bgBox1}
                    onClick={() => {
                      let cnew = [...showOddsChart];
                      cnew[rind] = !cnew[rind];
                      setShowOddsChart([...cnew]);
                    }}
                  >
                    <div style={{
                      display: "inline-flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: "4px",
                      width: "60px",
                      cursor: "pointer",
                    }} onClick={() => {
                      navigate(`/q?league=${row.league.toLowerCase()}&intent=GetLeagueOdds`)
                    }}>
                      {getLeagueEmoji(row.league)}
                      <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px", textDecoration: "underline"}}>
                        {row.league}
                      </span>
                    </div>
                    <div style={{width: "6px"}}/>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "172px", background: getThemeData(theme).bgGlobal, position: "sticky", left: 0}}>
                      <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                        <div style={{display: "inline-block", width: "100%"}}>
                          <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                            {getTeamLogo(
                              row.game_desc.league,
                              row.game_desc.team1.split(" ").map(tc => tc).join("-")
                            )}
                            {" "}
                            {row.game_desc.team1}
                          </span>
                        </div>
                        <div style={{display: "inline-block", width: "100%"}}>
                          <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                            {"vs "}
                            {getTeamLogo(
                              row.game_desc.league,
                              row.game_desc.team2.split(" ").map(tc => tc).join("-")
                            )}
                            {" "}
                            {row.game_desc.team2}
                          </span>
                        </div>
                        <div style={{display: "inline-block"}}>
                          <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                            {row.game_desc.gameTime}
                          </span>
                        </div>
                      </span>
                    </div>
                    <div style={{width: "6px"}}/>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "146px"}} onClick={() => {
                      navigate(row.bet_nav_url)
                    }}>
                      <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px",}}>
                        {row.bet_type.includes(row.game_desc.team1) && (
                          <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px", textDecoration: "underline"}}>
                              {getTeamLogo(
                                row.game_desc.league,
                                row.game_desc.team1.split(" ").map(tc => tc).join("-")
                              )}
                              {" "}
                          </span>
                        )}
                        {!row.bet_type.includes("Spread") && (
                          <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px", textDecoration: "underline"}}>
                            {row.bet_type.includes(" Moneyline vs ")
                            ? row.bet_type.split(" vs ")[0]
                            : row.bet_type
                            }
                          </span>
                        )}
                        {row.bet_type.includes("Spread") && (
                          <>
                            <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px", textDecoration: "underline"}}>
                              {`${row.bet_type.split("Spread")[0]} Spread`}
                            </span>
                            <span style={{fontWeight: 500, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                              {` ${row.bet_type.split("Spread")[1]}`}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                    <div style={{width: "6px"}}/>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "110px"}}>
                      {getSiteURLs(row.site_id) !== ""
                      ? <Link to={getSiteURLs(row.site_id)} target="_blank" style={{textDecoration: "none"}}>
                        <div style={{
                          display: "inline-flex",
                          flexDirection: "row",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "4px",
                          cursor: "pointer",
                        }}>
                          <span style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: getThemeData(theme).colorAccent,
                            //wordBreak: "break-all",
                          }}>
                            {row.site_id !== 'OvertimeMarkets'
                            ? row.site_id
                            : 'Overtime Markets'}
                          </span>
                          <ExternalLink size={14} color={getThemeData(theme).colorAccent} cursor={"pointer"}/>
                        </div>
                      </Link>
                      : <span style={{
                          fontWeight: 500,
                          color: getThemeData(theme).textColor,
                          fontSize: "14px",
                          textDecoration: "underline",
                          //wordBreak: "break-all",
                        }} onClick={(e) => {
                          e.stopPropagation()
                          if (row.bet_type.includes("Moneyline")) {
                            navigate(`/q?site_id=${row.site_id}&bet_type=moneyline&intent=GetSiteMl`)
                          } else if (row.bet_type.includes("Spread")) {
                            navigate(`/q?site_id=${row.site_id}&bet_type=spread&intent=GetSitePs`)
                          } else if (row.bet_type.includes("Over")) {
                            navigate(`/q?site_id=${row.site_id}&bet_type=over&intent=GetSiteOver`)
                          } else if (row.bet_type.includes("Under")) {
                            navigate(`/q?site_id=${row.site_id}&bet_type=under&intent=GetSiteUnder`)
                          }
                        }}
                      >
                        {row.site_id !== 'OvertimeMarkets'
                          ? row.site_id
                          : 'Overtime Markets'}
                      </span>
                      }
                    </div>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "110px"}}>
                        <span style={{
                          fontWeight: 600,
                          color: (
                          Math.min(...oddsDataStore.map(item => item.standard_deviation)) !== Math.max(...oddsDataStore.map(item => item.standard_deviation))
                            ? getColorForPercentage(
                            1.0 - normalize(
                              Math.min(...oddsDataStore.map(item => item.standard_deviation)),
                              Math.max(...oddsDataStore.map(item => item.standard_deviation)),
                              row.standard_deviation
                            )
                          )
                          : getThemeData(theme).textColor
                          ),
                          fontSize: "14px",
                        }}>
                        {row.standard_deviation.toFixed(4).toString()}
                      </span>
                    </div>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "70px"}}>
                      <span style={{
                        fontWeight: 600,
                        color: getThemeData(theme).textColor,
                        fontSize: "14px"
                      }}>
                        {odds === 1 && convertOdds(row.current, odds).toFixed(2).toString()}
                        {odds === 2 && (parseInt(convertOdds(row.current, odds).toString()) > 0 ? "+"+convertOdds(row.current, odds) : convertOdds(row.current, odds))}
                        {odds === 3 && convertOdds(row.current, odds).toFixed(2).toString()}
                      </span>
                    </div>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "70px"}}>
                      <span style={{
                        fontWeight: 600,
                        color: (
                          Math.min(...oddsDataStore.map(item => item.vig)) !== Math.max(...oddsDataStore.map(item => item.vig))
                          ? getColorForPercentage(
                          normalize(
                            Math.min(...oddsDataStore.map(item => item.vig)),
                            Math.max(...oddsDataStore.map(item => item.vig)),
                            row.vig
                          ))
                          : getThemeData(theme).textColor
                        ),
                        fontSize: "14px",
                      }}>
                        {`${row.vig.toFixed(2).toString()}%`}
                      </span>
                    </div>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "94px"}}>
                      <span style={{
                        fontWeight: 600,
                        color: (
                          Math.min(...oddsDataStore.map(item => item.change)) !== Math.max(...oddsDataStore.map(item => item.change))
                          ? getColorForPercentage(
                          1.0 - normalize(
                            Math.min(...oddsDataStore.map(item => item.change)),
                            Math.max(...oddsDataStore.map(item => item.change)),
                            row.change
                          ))
                          : getThemeData(theme).textColor
                        ),
                        fontSize: "14px",
                      }}>
                        {row.change >= 0
                        ? `+${row.change.toFixed(2).toString()}%`
                        : `${row.change.toFixed(2).toString()}%`
                        }
                      </span>
                    </div>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "94px"}}>
                      <span style={{
                        fontWeight: 600,
                        color: (
                          Math.min(...oddsDataStore.map(item => item.change_highest)) !== Math.max(...oddsDataStore.map(item => item.change_highest))
                          ? getColorForPercentage(
                          1.0 - normalize(
                            Math.min(...oddsDataStore.map(item => item.change_highest)),
                            Math.max(...oddsDataStore.map(item => item.change_highest)),
                            row.change_highest
                          ))
                          : getThemeData(theme).textColor
                        ),
                        fontSize: "14px",
                      }}>
                        {row.change_highest >= 0
                        ? `+${row.change_highest.toFixed(2).toString()}%`
                        : `${row.change_highest.toFixed(2).toString()}%`
                        }
                      </span>
                    </div>
                    <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "left", width: "94px"}}>
                      <span style={{
                        fontWeight: 600,
                        color: (
                          Math.min(...oddsDataStore.map(item => item.change_lowest)) !== Math.max(...oddsDataStore.map(item => item.change_lowest))
                          ? getColorForPercentage(
                          1.0 - normalize(
                            Math.min(...oddsDataStore.map(item => item.change_lowest)),
                            Math.max(...oddsDataStore.map(item => item.change_lowest)),
                            row.change_lowest
                          ))
                          : getThemeData(theme).textColor
                        ),
                        fontSize: "14px",
                      }}>
                        {row.change_lowest >= 0
                        ? `+${row.change_lowest.toFixed(2).toString()}%`
                        : `${row.change_lowest.toFixed(2).toString()}%`
                        }
                      </span>
                    </div>
                    <div style={{width: "30px"}}>
                      {!showOddsChart[rind] &&<ChevronDown color={getThemeData(theme).colorAccent} size={20}/>}
                      {showOddsChart[rind] &&<ChevronUp color={getThemeData(theme).colorAccent} size={20}/>}
                    </div>
                  </RowContainer>
                  {showOddsChart[rind] &&
                  <RowContainer
                    color={getThemeData(theme).borderColor}
                    color2={getThemeData(theme).bgBox1}
                  >
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      width: isMobile ? "94%" : "fit-content",
                    }}>
                    {/*<div style={{
                      display: "flex",
                      flexDirection: "column",
                    }}>*/}
                      <div style={{
                        display: "flex",
                        flexDirection: "row", //isMobile ? "column" : "row",
                        justifyContent: "left",
                        alignItems: "center",
                        width: "100%", //isMobile ? "fit-content" : "100%",
                      }}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center" }}>
                          {/*<span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                            {"Game"}
                          </span>*/}
                          <div style={{width: "6px"}}/>
                          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent, fontSize: "14px"}}>
                              {getTeamLogo(
                                row.game_desc.league,
                                row.game_desc.team1.split(" ").map(tc => tc).join("-")
                              )}
                              {" "}
                              {row.game_desc.team1}
                              {" vs "}
                              {getTeamLogo(
                                row.game_desc.league,
                                row.game_desc.team2.split(" ").map(tc => tc).join("-")
                              )}
                              {" "}
                              {row.game_desc.team2}
                              {" "}
                              {row.game_desc.gameTime}
                            </span>
                          </div>
                          {/*<span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "14px"}}>
                            {"Bet"}
                          </span>*/}
                          <div style={{width: "6px"}}/>
                          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent, fontSize: "14px"}}>
                              {`${row.site_id}`}
                            </span>
                          </div>
                          {/*<div style={{width: "6px"}}/>
                          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent, fontSize: "14px"}}>
                              {`${row.team_id}`}
                            </span>
                          </div>*/}
                          <div style={{width: "6px"}}/>
                          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent, fontSize: "14px"}}>
                              {/* row.bet_type */}
                              {/* `${oddsData[0].bet_type.replace(oddsData[0].team_id,'')}` */}
                              {/*row.bet_type.includes("Over") && (
                                <>
                                  {getOverUnderLogo("over")}
                                  {" "}
                                </>
                              )*/}
                              {/*row.bet_type.includes("Under") && (
                                <>
                                  {getOverUnderLogo("under")}
                                  {" "}
                                </>
                              )*/}
                              {row.bet_type.includes(row.game_desc.team1) && (
                                <>
                                  {getTeamLogo(
                                    row.game_desc.league,
                                    row.game_desc.team1.split(" ").map(tc => tc).join("-")
                                  )}
                                  {" "}
                                </>
                              )}
                              {row.bet_type.includes(" Moneyline vs ")
                              ? row.bet_type.split(" vs ")[0]
                              : row.bet_type}
                            </span>
                          </div>
                          {/*<div style={{width: "6px"}}/>
                          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).colorAccent}`, padding: "2px 8px"}}>
                            <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent, fontSize: "14px"}}>
                              {`Vig = ${row.vig.toFixed(2)}%`}
                            </span>
                          </div>*/}
                          {/*isMobile && <div style={{width: "6px"}}/>*/}
                          {/*isMobile && <div style={{display: "flex", flexDirection: "row", gap: "2px",justifyContent: "center", borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 4px"}}>
                            <Lock size={"16px"} color={getThemeData(theme).colorAccent}/>
                            <span style={{fontWeight: 600, color: getThemeData(theme).colorAccent, fontSize: "14px"}}>
                              {"Advanced stats and indicators"}
                            </span>
                          </div>*/}
                        </div>
                      </div>
                      <RowContainer2 isMobile={isMobile}>
                        <OddsDetails
                          canViewAdv={canViewAdv}
                          isMobile={isMobile}
                          detailWidth={isMobile ? "100%" : "520px"}
                          detailHeight="220px"
                          detailHeight2="140px"
                          oddsData={[...oddsDataStore].filter(item => (
                            item.game_id === row.game_id &&
                            item.team_id === row.team_id &&
                            item.league === row.league &&
                            item.org_bet_type === row.org_bet_type &&
                            //item.bet_type === row.bet_type &&
                            item.site_id === row.site_id
                          ))}
                          indexData={[...betRowsStore.filter(item => {
                            return matchBetRow(row, item);
                          })]}
                        />
                        {isMobile ? <div style={{width: "12px"}}/> : <div style={{width: "42px"}}/>}
                        {/*<div style={{width: "22px"}}/>*/}
                        <OddsChart
                          canViewAdv={canViewAdv}
                          isMobile={isMobile}
                          chartHeight="220px"
                          chartWidth={isMobile ? "100%" : "520px"}
                          oddsData={[...oddsDataStore].filter(item => (
                            item.game_id === row.game_id &&
                            item.team_id === row.team_id &&
                            item.league === row.league &&
                            item.org_bet_type === row.org_bet_type &&
                            //item.bet_type === row.bet_type &&
                            item.site_id === row.site_id
                          ))}
                        />
                        {//!isMobile &&
                        false &&
                        <HiddenStatsContainer isMobile={isMobile} color={"transparent"} onClick={() => {
                          navigate("/account?login=true")
                          //setShowModal(true)
                        }}>
                          <div style={{height: "32px"}}/>
                          <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", width: "75%", justifyContent: "center", textAlign: "center"}}>
                              <span style={{fontWeight: 600, color: getThemeData(theme).textColor, fontSize: "16px"}}>
                                {"Unlock advanced stats and indicators"}
                              </span>
                            </div>
                          </div>
                          <div style={{height: "22px"}}/>
                          <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                            <Lock size={"48px"} color={getThemeData(theme).colorAccent}/>
                          </div>
                        </HiddenStatsContainer>}
                      </RowContainer2>
                    </div>
                  </RowContainer>
                  }
                </>
                )
              })}
            </div>
          }
          </MainTableContainer>
          <TableFooter
            isMobile={isMobile}
            pageN={page2}
            rowsPerPageN={rowsPerPage2}
            dataN={oddsData.length}
            setPageN={setPage2}
            setRowsPerPageN={setRowsPerPage2}
            showIncNotice={false}
          />
        </div>
        <div style={{height: "42px"}}/>
        <Explore
          site_ids={["BetOnline", "Fanduel", "Betrivers"]}
          inc_trending={false}
          inc_top={true}
        />
        <div style={{height: "42px"}}/>
        <div style={{height: "42px"}}/>
      </>
      }
    </BodyContainer>
  );
}

export default BetsTrending;