import { MdEmail } from "react-icons/md";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { SiGitbook } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
import { Config as config} from "../../lib/config";

interface HelpProps {
  isMobile: boolean;
};

const Help = ({ isMobile }: HelpProps) => {
  const { theme } = useGetTheme();
  const navigate = useNavigate();
  return (
    <>
    {isMobile ? <div style={{height: "82px"}}/> : <div style={{height: "42px"}}/> }
    <div style={{
      display: "flex",
      flexDirection: "column",
      padding: "12px",
      width: isMobile ? "90%": "fit-content",
    }}>
      <div style={{
        width: isMobile ? "90%" : "100%",
        padding: "22px",
        borderRadius: "10px",
        //background: getThemeData(theme).bgBox1,
        border: `1px solid ${getThemeData(theme).borderColor}`,
        display: "flex",
        flexDirection: "column",
        maxWidth: isMobile ? "100%" : "420px",
      }}>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <span style={{fontWeight: 600, fontSize: "1rem", color: getThemeData(theme).textColor}}>
            {"Support & Feedback"}
          </span>
        </div>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <span style={{fontWeight: 600, fontSize: "0.95rem", color: getThemeData(theme).textColor4}}>
            {"Your feedback is extremely important to us. If you encounter issues or have suggestions to improve Crunchtime, please don't hesitate to reach out."}
          </span>
        </div>
        <div>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <Link to={config.discordUrl} target="_blank" style={{width: "100%", textDecoration: "none"}}>
            <div style={{
              width: "100%",
              display: 'flex',
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              border: `1px solid ${getThemeData(theme).borderColor}`,
              cursor: "pointer",
              background: getThemeData(theme).bgButton1,
              padding: "8px 0px",
              gap: "6px"
            }}>
              <FaDiscord size={16} color={'white'}/>
              <span style={{fontWeight: 600, fontSize: "1rem", color:'white'}}>
                {"Join Crunchtime Discord"}
              </span>
            </div>
          </Link>
        </div>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <div style={{
            width: "100%",
            display: 'flex',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            border: `1px solid ${getThemeData(theme).borderColor}`,
            cursor: "pointer",
            background: getThemeData(theme).bgButton1,
            padding: "8px 0px",
            gap: "6px"
          }} onClick={(e) => {
            window.location.href = `mailto:${config.email}`;
            e.preventDefault();
          }}>
            <MdEmail size={16} color={'white'}/>
            <span style={{fontWeight: 600, fontSize: "1rem", color:'white'}}>
              {"Email Crunchtime Support"}
            </span>
          </div>
        </div>
        </div>
      </div>
      <div style={{height: "42px"}}/>
      <div style={{
        width: isMobile ? "90%" : "100%",
        padding: "22px",
        borderRadius: "10px",
        //background: getThemeData(theme).bgBox1,
        border: `1px solid ${getThemeData(theme).borderColor}`,
        display: "flex",
        flexDirection: "column",
        maxWidth: isMobile ? "100%" : "420px",
      }}>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <span style={{fontWeight: 600, fontSize: "1rem", color: getThemeData(theme).textColor}}>
            {"Explore Resources"}
          </span>
        </div>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <span style={{fontWeight: 600, fontSize: "0.95rem", color: getThemeData(theme).textColor4}}>
            {"To find out more about how to use Crunchtime:"}
          </span>
        </div>
        <div>
        <div style={{height: "22px"}}/>
        <div style={{width: "100%", display: "inline-flex", alignItems: "left",}}>
          <div style={{
            width: "100%",
            display: 'flex',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            border: `1px solid ${getThemeData(theme).borderColor}`,
            cursor: "pointer",
            background: getThemeData(theme).bgButton1,
            padding: "8px 0px",
            gap: "6px"
          }} onClick={() => {navigate("/api-docs")}}>
            <SiGitbook size={16} color={'white'}/>
            <span style={{fontWeight: 600, fontSize: "1rem", color:'white'}}>
              {"Crunchtime Documentation"}
            </span>
          </div>
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Help;