import { useState, useEffect, } from "react";
import { Sun, Moon, ChevronDown } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import "react-toggle/style.css";
import { HeaderContainer, HeaderWrapper } from "../../styles";
//import type { Theme } from "../../lib/types";
//import { HeaderProps } from "../../lib/interface";
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import Modal from "../Modal";
//import Button from "../Button";
import LogoWhite from "../../assets/C_Logo_White.webp";
import LogoBlack from "../../assets/C_Logo_Black.webp";
//import LogoBlue from "../../assets/C_Logo_Blue.webp";
//import Logo from "../../assets/Logo.webp";
import { FaBolt } from "react-icons/fa";
import SnackBar from "../SnackBar";
import { Config as config } from "../../lib/config";
import Login from "../Login";
//import { useAuth } from "../../lib/auth";
import { userData } from "../../lib/api/user";
import styled from "styled-components";
//import Search from "../Search";
import { MdAnalytics } from "react-icons/md";
//import { loadReCaptcha } from '../../lib/recaptcha';

interface GapOnlyProps {
  gap: string;
};

interface ParentSectionProps {
  color: string;
  color2: string;
  color3: string;
  color4: string;
}

interface ChildOptionProps {
  color: string;
  isActive: boolean;
}

const HeaderSectionWrapper = styled.div<GapOnlyProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({gap}) => gap};
`

const HeaderSectionWrapper2 = styled.div<GapOnlyProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({gap}) => gap};
  //@media screen and (max-width: 600px) {
  //  flex-direction: column-reverse;
  //}
`

const Section = styled.div<ParentSectionProps>`
  .parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: ${({color}) => color};
    position: relative;
    &:hover {
      color: ${({color2}) => color2};
    }
  }
  .arrw {
    &:hover {
      color: ${({color2}) => color2};
    }
  }
  .child {
    display: none;
    position: absolute;
    transition: all 0.3s ease-in-out;
    top: 24px;
    left: 0px;
    padding: 12px;
    background: ${({color3}) => color3};
    border-radius: 10px;
    border: 1px solid ${({color4}) => color4};
    width: 160px;
  }
  .parent:hover .child {
    display: block;
  }
`

const ChildOption = styled.div<ChildOptionProps>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  width: calc(100% - 24px);
  border-radius: 10px;
  filter: ${({isActive}) => isActive ? "brightness(1.25)" : "none"};
  background: ${({isActive, color}) => isActive ? color : "transparent"};
  &:hover {
    filter: brightness(1.5);
    background: ${({color}) => color};
  }
`

const Header = () => {
  const {theme, setTheme} = useGetTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);
  //const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [snackBarTitle, setSnackBarTitle] = useState<string | undefined>(undefined);
  const [snackBarType, setSnackBarType] = useState<string | undefined>(undefined);
  const [auth, setAuth] = useState<string | undefined | null>(sessionStorage.getItem("auth"));
  const [id, setId] = useState<string | null | undefined>(sessionStorage.getItem("id"));
  const [registerMode, setRegisterMode] = useState<boolean>(false);
  const { from } = location.state || { from: { pathname: "/" } };

  const ToggleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${theme === 'dark' ? "#303030" : "lightgrey"};
    padding: 8px;
    cursor: pointer;
    //gap: 6px;
    /*.react-toggle-thumb {
      width: 18px;
      height: 18px;
    }
    .react-toggle-track {
      width: 46px;
      height: 20px;
    }
    */
  `

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const hideModal = () => {
    setShowModal(false);
    //setRegisterMode(false);
  }

  //const { logout } = useAuth();

  function handleMode() {
    var newTheme = (theme === 'dark' ? 'light' : 'dark');
    setTheme(newTheme)
  }

  async function checkUserLogin() {
    if (id !== undefined && id !== null) {
      const response = await userData(JSON.parse(id));
      console.log("CHECK", response)
      if (response.status !== 200) {
        setAuth(JSON.stringify(false));
        setId(undefined);
        sessionStorage.setItem("auth", JSON.stringify(false))
        sessionStorage.removeItem("id");
      } else {
        console.log(response.data)
      }
    }
  }

  useEffect(() => {
    checkUserLogin()
  },[auth])

  function promptLogin() {
    console.log('from', from)
    if (from !== undefined && from !== null) {
      if (from === 'account?login=true') {
        setRegisterMode(false);
        setShowModal(true)
      } else if ( from === 'account?register=true') {
        setRegisterMode(true)
        setShowModal(true)
      }
    }
  }

  useEffect(() => {
    promptLogin();
  },[from])

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  const ModalBody = <>
    <Login
      setSnackBarTitle={setSnackBarTitle}
      setSnackBarType={setSnackBarType}
      setShowModal={setShowModal}
      setAuth={setAuth}
      setId={setId}
      registerMode={registerMode}
      setRegisterMode={setRegisterMode}
    />
  </>;

  return (
    <HeaderContainer color={theme === 'dark' ? getThemeData(theme).bgGlobal : "white"}>
      <HeaderWrapper>
        <HeaderSectionWrapper gap={"44px"}>
          <Link to="/" style={{textDecoration: "none"}}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              cursor: "pointer"
            }}>
              <img src={theme === 'dark' ? LogoWhite : LogoBlack} width={38} height={38} alt={'logo'}/>
              <> {windowDimension.winWidth > 600
              ? <span style={{
                fontSize: "1.4rem",
                color: (theme === 'dark'
                  ? "white"
                  : "black"),
                letterSpacing: "0.025rem",
                fontWeight: 600
              }}>
                {config.appName.substring(0,config.appName.length).toUpperCase()}
              </span>
              : null} </>
            </div>
          </Link>
          <Section
            color={getThemeData(theme).textColor}
            color2={getThemeData(theme).colorAccent}
            color3={getThemeData(theme).bgBox2}
            color4={getThemeData(theme).borderColor}
          >
            <div className="parent">
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <span style={{
                  fontSize: "1.1rem",
                  letterSpacing: "0.01rem",
                  fontWeight: 600
                }}>
                  {"Products"}
                </span>
                <ChevronDown className="arrw" size={"18px"}/>
              </div>
              <div className="child">
                <ChildOption
                  color={getThemeData(theme).bgBox1}
                  isActive={location.pathname === "/products/api" && !location.hash.includes("#plans")}
                  onClick={() => {
                    navigate("/")
                  }}
                >
                  <MdAnalytics
                    size={18}
                    color={(location.pathname === "/products/api" && !location.hash.includes("#plans")
                      ? (theme === 'dark'
                        ? "white"
                        : "black")
                      : (theme === 'dark' ? "lightgrey" : "black"))}
                  />
                  <span style={{
                    fontSize: "1.1rem",
                    color: (location.pathname === "/products/api" && !location.hash.includes("#plans")
                      ? (theme === 'dark'
                        ? "white"
                        : "black")
                      : (theme === 'dark' ? "lightgrey" : "black")),
                    letterSpacing: "0.01rem",
                    fontWeight: 600
                  }}>
                    {"Platform"}
                  </span>
                </ChildOption>
                <ChildOption
                  color={getThemeData(theme).bgBox1}
                  isActive={location.pathname === "/products/api" && !location.hash.includes("#plans")}
                  onClick={() => {
                    navigate(config.docs)
                  }}
                >
                  <FaBolt
                    size={18}
                    color={(location.pathname === "/products/api" && !location.hash.includes("#plans")
                      ? (theme === 'dark'
                        ? "white"
                        : "black")
                      : (theme === 'dark' ? "lightgrey" : "black"))}
                  />
                  <span style={{
                    fontSize: "1.1rem",
                    color: (location.pathname === "/products/api" && !location.hash.includes("#plans")
                      ? (theme === 'dark'
                        ? "white"
                        : "black")
                      : (theme === 'dark' ? "lightgrey" : "black")),
                    letterSpacing: "0.01rem",
                    fontWeight: 600
                  }}>
                    {"Odds API"}
                  </span>
                </ChildOption>
              </div>
            </div>
          </Section>
          <Section
            color={getThemeData(theme).textColor}
            color2={getThemeData(theme).colorAccent}
            color3={getThemeData(theme).bgBox2}
            color4={getThemeData(theme).borderColor}
          >
            <div className="parent">
              {location.pathname === '/company'
                ? <span style={{
                  fontSize: "1.1rem",
                  letterSpacing: "0.01rem",
                  fontWeight: 600
                }} onClick={() => {
                  navigate('/company#plans');
                  const href = window.location.href.substring(
                    window.location.href.lastIndexOf('#') + 1
                  );
                  console.log(href)
                  const element = document.getElementById(href);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth',});
                  }
                }}>
                    {"Plans"}
                  </span>
                : <span style={{
                    fontSize: "1.1rem",
                    letterSpacing: "0.01rem",
                    fontWeight: 600
                  }} onClick={() => {
                    navigate("/company#plans")
                  }}>
                    {"Plans"}
                  </span>
                }
            </div>
          </Section>
        </HeaderSectionWrapper>
        <HeaderSectionWrapper2 gap={"22px"}>
          <ToggleContainer onClick={() => handleMode()}>
            {theme === 'dark'
            ? <Moon size={20} color={"white"}/>
            : <Sun size={20} color={"black"}/>
            }
          </ToggleContainer>
          <> {
          <Link to={"/"} target="_self" style={{textDecoration: "none"}}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            background: getThemeData(theme).bgButton1,
            padding: "10px 6px",
            cursor: "pointer",
            textAlign: "center",
            height: "fit-content",
            width: "120px"
          }}>
            <span style={{color: "white", fontWeight: 600, fontSize: "1rem"}}>
              {"Launch App"}
            </span>
          </div>
          </Link>
          //: null
          }
          </>
        </HeaderSectionWrapper2>
        {showModal &&
          <Modal
            title={registerMode ? "Register" : "Login"}
            active={showModal}
            hideModal={hideModal}
            body={ModalBody}
            action={"logging in"}
          />
        }
        {snackBarTitle !== undefined && <SnackBar title={snackBarTitle} type={snackBarType} />}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;