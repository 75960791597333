import { SearchPage } from "../Search";
import { BodyContainer } from "./tableStyle";
import BannerBlack from "../../assets/bannerBlack.webp";
import BannerWhite from "../../assets/bannerWhite.webp";
import { useGetTheme } from "../../lib/contexts";

interface HomeProps {
  isMobile: boolean;
  showSubMenu: boolean;
  isMacLike: boolean;
  isIOS: boolean;
  setShowSR: any;
  showSR: boolean;
  setLoadingNLUPrediction: any;
  //setSearchAiInterpretation: any;
};

const Home = ({
  isMobile,
  showSubMenu,
  isMacLike,
  isIOS,
  setShowSR,
  showSR,
  setLoadingNLUPrediction,
  //setSearchAiInterpretation,
}: HomeProps) => {
  const { theme } = useGetTheme();
  return (
    <BodyContainer width={
      isMobile
      ? '90%'
      : (showSubMenu
        ? 'calc(100% - 12px)'//`calc(100% - ${lgSubMenuW})`
        : 'calc(100% - 12px)'//`calc(100% - ${smSubMenuW})`
      )}>
      {<>
        {isMobile
          ? <div style={{height: "82px"}}/>
          : <div style={{height: "12px"}}/>
        }
        <div style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "fit-content",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "100%" : "820px",
          }}>
            {theme === 'dark'
            ? <img src={BannerWhite} width={"100%"}/>
            : <img src={BannerBlack} width={"100%"}/>
            }
            <SearchPage
              isMacLike={isMacLike}
              isIOS={isIOS}
              isMobile={isMobile}
              setShowSR={setShowSR}
              showSR={showSR}
              setLoadingNLUPrediction={setLoadingNLUPrediction}
              //setSearchAiInterpretation={setSearchAiInterpretation}
            />
          </div>
        </div>
        <div style={{height: "42px"}}/>
        <div style={{height: "42px"}}/>
      </>
      }
    </BodyContainer>
  );
};

export default Home;