export interface PendingBet {
  bet_amount: number;
  bet_odds: number;
  cur_opp_odds: number;
  assume_win: boolean;
};

export const getHedgeAmounts = (
  current_total: number,
  guarantee: number,
  pending_bets: PendingBet[],
) => {
  var loss_from_other_bets = 0;
  var wins_from_other_bets = 0;
  for (let i = 0; i < pending_bets.length; i++) {
    if (pending_bets[i].assume_win) {
      wins_from_other_bets += (pending_bets[i].bet_amount * pending_bets[i].bet_odds);
    } else {
      loss_from_other_bets += (pending_bets[i].bet_amount);
    }
  }
  current_total += wins_from_other_bets;
  var hedgeBets = [];
  for (let i = 0; i < pending_bets.length; i++) {
    if (pending_bets[i].assume_win) {
      continue
    }
    var t = current_total - loss_from_other_bets
    if (t < guarantee) {
      var h = (((pending_bets[i].bet_amount * pending_bets[i].bet_odds) - t + guarantee) / pending_bets[i].cur_opp_odds)
      if (h > 0) {
        var dhb = {
          bet_index: i+1,
          bet_odds: pending_bets[i].bet_odds,
          hedge_amount: h,
        };
        hedgeBets.push(dhb);
      }
    }
  }
  var data = {
    current_total: current_total,
    hedge_bets: hedgeBets,
  }
  return data;
}