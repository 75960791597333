import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExternalLink } from "react-feather";
import styled from "styled-components"
import { getThemeData } from "../../theme";
import { useGetTheme } from "../../lib/contexts";
import { FaSearch } from "react-icons/fa";
import { generalSearchState, pressEnterForm } from "./stores";
import { addNewSearch, getNLUModelPrediction, getSites, getTeams } from "../../lib/api/data";
import {
  minTextLengthForSearchResults,
  maxResults,
  searchItems,
  defaultQuestionsSection1,
  defaultQuestionsSection2,
  defaultSearchItems,
  MAX_SEARCH_HISTORY_STORE,
} from "./constants";
import { SUPPORTED_FILES, getLeagueEmoji, logoUrl } from "../Dash/constants";
import { teamToLeagueAndIdMap } from "./teamToLeagueAndIdMap";
import { tmap, leaguestmap } from "../Dash/teamDisplayMap";
import { useGetSearchHistory } from "../../lib/contexts";

const InnerHeaderNavWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({color}) => color};
  width: ${({width}) => width};
  cursor: default;
  //border-radius: 10px;
  //padding: 0px 0px 0px 0px;
`;

const InnerHeaderNavWrap2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({color}) => color};
  width: ${({width}) => width};
  cursor: default;
  //border-radius: 10px;
  //padding: 0px 0px 0px 0px;
`;

const Input = styled.input`
  text-align: left;
  width: ${({width}) => width};
  background: transparent;
  border: none;
  color: ${({color}) => color};
  white-space: nowrap;
  transition: all 0.2s ease-out;
  font-size: 0.95rem;
  font-weight: 400;
  &:focus {
    background-color: transparent;
    outline: none;
  }
  &::placeholder {
    color: ${({colorP}) => colorP};
  }
`;

const Input2 = styled.input`
  text-align: left;
  width: ${({width}) => width};
  background: transparent;
  border: none;
  color: ${({color}) => color};
  white-space: nowrap;
  transition: all 0.2s ease-out;
  font-size: 0.95rem;
  font-weight: 400;
  &:focus {
    background-color: transparent;
    outline: none;
  }
  &::placeholder {
    color: ${({colorP}) => colorP};
  }
`;

const GeneralSearchWrapper = styled.div`
  width: ${({width}) => width};
  height: ${({height}) => height};
  overflow-y: scroll;
  background: transparent;
  //color: white;
  //z-index: 3;
  //position: absolute;
  //margin-top: 400px;
  //border-radius: 10px;
  //-ms-overflow-style: none;
  //scrollbar-width: none;
  //::-webkit-scrollbar {
  //  display: none;
  //}
  //::-moz-scrollbar {
  //  display: none;
  //}
`;

const GeneralSearchWrapper2 = styled.div`
  width: ${({width}) => width};
  max-height: ${({height}) => height};
  overflow-y: scroll;
  background: ${({bgBox}) => bgBox};
  border-left: 1px solid ${({borderC}) => borderC};
  border-right: 1px solid ${({borderC}) => borderC};
  border-bottom: 1px solid ${({borderC}) => borderC};
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  z-index: 2;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  justify-content: center;
  align-items: center;
  gap: ${({isMobile}) => isMobile ? "6px" : "6px"};
  padding: 16px 0px 16px 0px;
  width: 100%;
  border: 1px solid ${({color}) => color};
  border-radius: ${({hasResults}) => hasResults ? "10px 10px 0px 0px" : "10px"};
  &:focus-within {
    border: 1px solid ${({color2}) => color2};
    //border-top: 1px solid ${({color2}) => color2};
    //border-left: 1px solid ${({color2}) => color2};
    //border-right: 1px solid ${({color2}) => color2};
    //border-bottom: ${({hasResults, color2}) => hasResults ? "0px" : `1px solid ${color2}`};
    outline: none;
  }
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: transparent;
  border-radius: 0px;
  &:hover {
    background: ${({theme}) => theme === 'dark' ? "#303136" : "#EEEEEE"};
    color: ${({theme}) => theme === 'dark' ? "lightgrey" : "black"};
    .arrow {
      display: block;
    }
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({width}) => width};
  padding: ${({firstItem, lastItem}) => firstItem ? "16px 0px 8px 0px" : lastItem ? "8px 0px 16px 0px" : "8px 0px"};
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 6px;
    .title {
      color: ${({theme}) => getThemeData(theme).textColor};
      font-size: 0.95rem;
      font-weight: 600;
    }
    .subtitle {
      color: ${({theme}) => getThemeData(theme).textColor4};
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
  .arrow {
    display: none;
  }
`;

function getSearchResultIcon(searchResultText) {
  const {prevSearches} = useGetSearchHistory();
  if (searchResultText === undefined || searchResultText === null || searchResultText.replaceAll(" ","") === "") {
    return (
      <>
        <img
          src={logoUrl}
          style={{
            width: "18px",
            height: "18px",
            display: "inline-block",
            verticalAlign: "middle",
            paddingBottom: "4px",
          }}
        />
        {" "}
      </>
    );
  } else if (prevSearches !== undefined && prevSearches !== null && prevSearches.toString().toLowerCase().includes(searchResultText.toLowerCase())) {
    return (
      <>
        <img
          src={require(`../../assets/userHistoryIcon.png`)}
          style={{
            width: "18px",
            height: "18px",
            display: "inline-block",
            verticalAlign: "middle",
            paddingBottom: "4px",
          }}
        />
        {" "}
      </>
    )
  } else {
    let team_id = "";
    let league_id = "";
    const srtl = searchResultText.toLowerCase().replaceAll(" ","-");
    const matches = Object.entries(teamToLeagueAndIdMap).find(([k]) => srtl.includes(k.toLowerCase()));
    if (matches !== undefined && matches.length > 0) {
      const varr = teamToLeagueAndIdMap[matches[0]];
      league_id = varr[0];
      team_id = varr[1];
      console.log("MATCHED TEAM", matches[0], varr, "WITH", srtl, `${league_id.toLowerCase()}/${team_id}.png`)
    }
    if (team_id !== "" && league_id !== "" && SUPPORTED_FILES.includes(`${league_id.toLowerCase()}/${team_id}.png`)) {
        return (
          <>
            <img
              src={require(`../../assets/leagues/${league_id.toLowerCase()}/${team_id}.png`)}
              style={{
                width: "18px",
                height: "18px",
                display: "inline-block",
                verticalAlign: "middle",
                paddingBottom: "4px",
              }}
            />
            {" "}
          </>
        );
    } else {
      const leagues = Object.keys(tmap).filter(val => searchResultText.toLowerCase().split(" ").includes(val));
      if (leagues !== undefined && leagues.length > 0) {
        return (
          <>
            {getLeagueEmoji(leagues[0])}
            {" "}
          </>
        );
      } else {
        return (
          <>
            <img
              src={logoUrl}
              style={{
                width: "18px",
                height: "18px",
                display: "inline-block",
                verticalAlign: "middle",
                paddingBottom: "4px",
              }}
            />
            {" "}
          </>
        );
      }
    }
  }
}

function parseNLUJointIntentSlotPrediction(
  searchAiInterpretation,
  apiTeams,
  apiLeagues,
  apiSites,
) {
  const intent = searchAiInterpretation.data.intent.toString();
  const slots = searchAiInterpretation.data.slots;
  var parsedEndpoint = "";
  var parsedTeams = [];
  var parsedLeague = "";
  if (intent === "GetTrendingOdds") {
    parsedEndpoint = "/bets/trending";
  } else {
    Object.keys(slots).map((val, _ind) => {
      var val_str = val.toString().replaceAll("_","-");
      if (val_str === 'number') {
        if (parsedEndpoint !== "") parsedEndpoint += "&"
        parsedEndpoint += `bet_line=${slots[val]}`;
      } else if (apiTeams.includes(val_str)) {
        parsedTeams.push(val_str);
      } else if (apiSites.includes(val_str)) {
        if (parsedEndpoint !== "") parsedEndpoint += "&"
        parsedEndpoint += `site_id=${val_str}`;
      } else if (apiLeagues.includes(val_str)) {
        if (parsedEndpoint !== "") parsedEndpoint += "&"
        parsedEndpoint += `league=${val_str}`;
        parsedLeague = val_str
      }
    });
    for (let i = 0; i < parsedTeams.length; i++) {
      var lg = parsedTeams[i].split('-')[0];
      if (parsedLeague !== "" && parsedLeague === lg) {
        if (!(parsedEndpoint.includes(`team_id=${parsedTeams[i]}`))) {
          if (parsedEndpoint !== "") parsedEndpoint += "&"
          parsedEndpoint += `team_id=${parsedTeams[i]}`;
        }
      } else if (parsedLeague === "" && (!(parsedEndpoint.includes(`team_id=${parsedTeams[i]}`)))) {
        if (parsedEndpoint !== "") parsedEndpoint += "&"
        parsedEndpoint += `team_id=${parsedTeams[i]}`;
      }
    }
    if (parsedLeague === "" && parsedTeams.length > 0) {
      parsedLeague = ""
      for (let tidx = 0; tidx < parsedTeams.length; tidx++) {
        parsedLeague += parsedTeams[tidx].split("-")[0];
      }
      if (parsedEndpoint !== "") parsedEndpoint += "&"
      parsedEndpoint += `league=${parsedLeague}`;
    }
    if (intent.includes('Ou')) {
      if (parsedEndpoint !== "") parsedEndpoint += "&"
      parsedEndpoint += `bet_type=overunder`;
    } else if (intent.includes('Ml')) {
      if (parsedEndpoint !== "") parsedEndpoint += "&"
      parsedEndpoint += `bet_type=moneyline`;
    } else if (intent.includes('Ps')) {
      if (parsedEndpoint !== "") parsedEndpoint += "&"
      parsedEndpoint += `bet_type=spread`;
    } else if (intent.includes('Over')) {
      if (parsedEndpoint !== "") parsedEndpoint += "&"
      parsedEndpoint += `bet_type=over`;
    } else if (intent.includes('Under')) {
      if (parsedEndpoint !== "") parsedEndpoint += "&"
      parsedEndpoint += `bet_type=under`;
    }
    if (parsedEndpoint !== "") parsedEndpoint += "&"
    parsedEndpoint += `intent=${intent}`
    parsedEndpoint = `/q?${parsedEndpoint}`;
  }
  //console.log("PARSED ENDPOINT", parsedEndpoint);
  return parsedEndpoint
}

export const SearchPage = ({
  isMacLike,
  isIOS,
  setShowSR,
  isMobile,
  showSR,
  setLoadingNLUPrediction,
}) => {
  const {theme} = useGetTheme();
  const {prevSearches, setPrevSearches} = useGetSearchHistory();
  const navigate = useNavigate();
  const setIsPressed = pressEnterForm((state) => state.setIsPressed);
  const setShow = generalSearchState((state) => state.setShow);
  const [highLighted, sethighLighted] = useState(0);
  const [activeCursor, setActiveCursor] = useState(0);
  const [text, setText] = useState("");
  const [focusedInp, setFocusedInp] = useState(false);
  //const [searchResults, setSearchResults] = useState([...defaultSearchItems]);
  const [searchResults, setSearchResults] = useState(
    (prevSearches !== undefined && prevSearches !== null && prevSearches.length > 0)
      ? [...prevSearches.split(",").map((v,i) => {
          return {
            result: v.split(' ').map(w => {
              if (leaguestmap.includes(w.toLowerCase())) {
                return w.toUpperCase();
              } else {
                return w[0].toUpperCase() + w.slice(1);
              }
            }).join(' '),
            subtitle: "",
            path: undefined,
            external: false,
          }
        }), ...defaultSearchItems].slice(0,maxResults)
      : [...defaultSearchItems]
  );
  const [searchAiInterpretation, setSearchAiInterpretation] = useState("");
  const [apiTeams, setApiTeams] = useState([]);
  const [apiSites, setApiSites] = useState([]);
  const [apiLeagues, setApiLeagues] = useState([]);

  const width1Small = isMobile ? "98%" : "98%";
  const width2Small = isMobile ? "90%" : "90%";

  const height1 = "520px";
  const height2 = "400px";
  const width1 = isMobile ? "98%" : "98%";

  const emailInput = useRef(null);
  const wrapperRef = useRef(null);
  const mainRef = useRef(null);

  const handleClickOutside = (event) => {
    if (mainRef.current && !mainRef.current.contains(event.target)) {
      setShow(false);
      setText("");
      setShowSR(false);
    }
  };

  function handleSearchAiInterpretation() {
    if (
      searchAiInterpretation !== undefined &&
      searchAiInterpretation !== null &&
      searchAiInterpretation !== ""
    ) {
      sethighLighted(0);
      setIsPressed(false);
      setShow(false);
      setShowSR(false);
      setText("");
      const parsedEndpoint = parseNLUJointIntentSlotPrediction(
        searchAiInterpretation,
        apiTeams,
        apiLeagues,
        apiSites,
      );
      navigate(parsedEndpoint);
    }
  }

  String.prototype.fuzzy = function (s) {
    var hay = this.toLowerCase(), i = 0, n = -1, l;
    s = s.toLowerCase();
    for (; l = s[i++] ;) if (!~(n = hay.indexOf(l, n + 1))) return false;
    return true;
  };

  function fuzzyMatch(s1, s2) {
    return (s1.toString()).fuzzy(s2.toString(), 0.9);
  }

  function updateSearchResults() {
    try {
      if (text !== undefined && text !== null && text !== "" && text.length > minTextLengthForSearchResults) {
        const search_res = [...searchItems].filter((val, ind) => {
          return fuzzyMatch(val.result, text)
        });
        setSearchResults([...search_res.slice(0,maxResults)]);
        //fuse.search(text);
      } else if (text === "" && focusedInp) {
        //setSearchResults([...defaultSearchItems]);
        setSearchResults(
          (prevSearches !== undefined && prevSearches !== null && prevSearches.length > 0)
          ? [...prevSearches.split(",").map((v,i) => {
              return {
                result: v.split(' ').map(w => {
                  if (leaguestmap.includes(w.toLowerCase())) {
                    return w.toUpperCase();
                  } else {
                    return w[0].toUpperCase() + w.slice(1);
                  }
                }).join(' '),
                subtitle: "",
                path: undefined,
                external: false,
              }
            }), ...defaultSearchItems].slice(0,maxResults)
          : [...defaultSearchItems]
        )
      } else {
        setSearchResults([...defaultSearchItems]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  function updateSearchHistory(searchText) {
    var searchHist = prevSearches;
    if (searchHist === undefined || searchHist === null || searchHist.length === 0) {
      searchHist = searchText;
    } else {
      if (!searchHist.includes(searchText)) {
        if (searchHist.includes(",") && searchHist.split(",").length >= MAX_SEARCH_HISTORY_STORE) {
          searchHist = searchHist.split(",").slice(0, MAX_SEARCH_HISTORY_STORE - 1).join(",");
        }
        searchHist = (searchText + "," + searchHist);
      }
    }
    setPrevSearches(searchHist);
  }

  async function interpretText(t) {
    if (t !== undefined && t !== null && t !== "") {
      setLoadingNLUPrediction(true);
      updateSearchHistory(t);
      const interpretation = await getNLUModelPrediction(t.toLowerCase());
      setSearchAiInterpretation(interpretation);
      setLoadingNLUPrediction(false);
      var newSearchObj = {search_text: t};
      var userId = sessionStorage.getItem("id");
      if (userId !== undefined && userId !== null) {
        newSearchObj.user_id = JSON.parse(userId);
      }
      const newSearchResponse = await addNewSearch(newSearchObj);
      console.log("NEW SEARCH", newSearchResponse)
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await interpretText(text)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
      setShowSR(true);
    }
  }, []);

  const loadApiTeams = async () => {
    const resp = await getTeams();
    const data = resp.data;
    const teamids = [...data].map((val, _ind) => val['team_id'].toString());
    const leagues = [...new Set(teamids.map((val, _ind) => val.split('-')[0]))];
    setApiTeams(teamids);
    setApiLeagues(leagues);
  }

  const loadApiSites = async () => {
    const resp = await getSites();
    const data = resp.data;
    const siteids = [...data].map((val, _ind) => val['site_id'].toString());
    setApiSites(siteids);
  }

  useEffect(() => {
    loadApiTeams();
  }, []);

  useEffect(() => {
    loadApiSites();
  }, []);

  useEffect(() => {
    handleSearchAiInterpretation();
  },[searchAiInterpretation])

  useEffect(() => {
    updateSearchResults()
  },[text, focusedInp])

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px 0px 12px 0px",
      width: "100%",
      maxWidth: width1,
      borderRadius: "10px",
      background: getThemeData(theme).bgGlobal,
    }} ref={mainRef}>
      <InnerHeaderNavWrap2
        color={"transparent"}
        width={width1Small}
      >
        <div style={{
          width: "100%",
          position: "relative",
        }}>
          <SearchInputWrapper
            color={getThemeData(theme).borderColor}
            color2={getThemeData(theme).colorAccent}
            isMobile={isMobile}
            hasResults={focusedInp && searchResults.length > 0}
          >
            {isMobile && <div style={{width: "6px"}}/>}
            <FaSearch
              size={isMobile ? 14 : 16}
              color={getThemeData(theme).iconColor}
            />
            <Input2
              width={width2Small}
              type="text"
              name="Search"
              value={text}
              onChange={(event) => {
                event.preventDefault();
                setText(event.target.value);
              }}
              placeholder={
                windowDimension.winWidth > 475
                  ? "Search leagues, teams, sites, odds or anything on Crunchtime"
                  : "Search on Crunchtime"
              }
              color={getThemeData(theme).textColor}
              colorP={getThemeData(theme).textColor4}
              ref={emailInput}
              onFocus={() => {
                setFocusedInp(true)
              }}
              onBlur={() => {
                setTimeout(() => {
                  setFocusedInp(false)
                },[200])
              }}
              onKeyDown={handleKeyDown}
            />
          </SearchInputWrapper>
          {focusedInp && searchResults.length > 0 && (
            <GeneralSearchWrapper2
              width={"100%"}
              height={height2}
              bgBox={getThemeData(theme).bgGlobal}
              borderC={getThemeData(theme).colorAccent}
              ref={wrapperRef}
              id="search-result"
            >
              {
                searchResults.map((search, index) => {
                  return (
                    <SearchContainer
                      id={`SearchResult${index}`}
                      theme={theme}
                      active={activeCursor === index}
                      onlyOne={index === 0 && searchResults.length === 1}
                      onClick={() => {
                        if (search.external) {
                          window.open(search.path, "_blank");
                        } else if (search.path !== undefined) {
                          if (search.result === "Best Bets of All Time") {
                            console.log("setting init sort 5")
                            navigate(search.path, {state:{init_sort: 5}});
                          } else if (search.result === "Best Bets in the last 7 days") {
                            console.log("setting init sort 1")
                            navigate(search.path, {state:{init_sort: 1}});
                          } else if (search.result === "Best Bets in the last 1 month") {
                            console.log("setting init sort 2")
                            navigate(search.path, {state:{init_sort: 2}});
                          } else if (search.result === "Best Bets in the last 3 months") {
                            console.log("setting init sort 3")
                            navigate(search.path, {state:{init_sort: 3}});
                          } else if (search.result === "Best Bets in the last 1 year") {
                            console.log("setting init sort 4")
                            navigate(search.path, {state:{init_sort: 4}});
                          } else {
                            navigate(search.path);
                          }
                        } else {
                          interpretText(search.result);
                        }
                      }}
                    >
                      <SearchWrapper
                        width={width2Small}
                        theme={theme}
                        firstItem={index === 0}
                        lastItem={index === searchResults.length - 1}
                      >
                        <div className="text-wrapper">
                          <div style={{display: "flex", flexDirection: "row", alignItems: "center",}}>
                            <span className="title">
                              {getSearchResultIcon(search.result)}
                              {search.result}
                            </span>
                            {search.external && (
                              <>
                                <div style={{width: "4px"}}/>
                                <ExternalLink className="title" size={16}/>
                              </>
                            )}
                          </div>
                          {/*<span className="subtitle">{search.item.subtitle}</span>*/}
                        </div>
                        {/*
                        <ArrowRight
                          size={18}
                          className="arrow"
                        />*/}
                      </SearchWrapper>
                    </SearchContainer>
                  );
                })
              }
            </GeneralSearchWrapper2>
          )}
        </div>
        <div style={{
          //height: height2,
          width: "100%",
          padding: searchResults.length === 0 ? "6px 0px 6px 0px" : "27px 0px 6px 0px",
          position: "relative",
          height: "auto",
        }}>
          {searchResults.length === 0
          ? <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
            <div style={{width: "100%", height: "4px"}}/>
            <div style={{
              width: "100%",
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <span style={{
                color: getThemeData(theme).textColor4,
                fontWeight: 500,
                fontSize: "12px",
                letterSpacing: "0.01rem",
              }}>
                {"Press Enter to Search"}
              </span>
            </div>
          </div>
          : null}
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
            <div style={{width: "100%", height: "22px"}}/>
            {prevSearches !== undefined && prevSearches !== null && prevSearches.toString().length > 0 && (
              <>
              <div style={{
                width: "100%",
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
              }}>
                <span style={{
                  color: getThemeData(theme).textColor4,
                  fontWeight: 600,
                  fontSize: "14px",
                  letterSpacing: "0.025rem",
                }}>
                  {"RECENT SEARCHES"}
                </span>
              </div>
              <div style={{width: "100%", height: "12px"}}/>
              <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
              {[...prevSearches.split(",")].map((v,i) => {
                return {
                  result: v.split(' ').map(w => {
                    if (leaguestmap.includes(w.toLowerCase())) {
                      return w.toUpperCase();
                    } else {
                      return w[0].toUpperCase() + w.slice(1);
                    }
                  }).join(' '),
                  subtitle: "",
                  path: undefined,
                  external: false,
                }
              }).map((dfq, dfi) => {
                return (
                  <>
                  <div style={{width: "100%", height: "12px"}}/>
                  <div style={{
                    width: "100%",
                    display: "inline-flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    cursor: "pointer",
                  }} onClick={() => {
                    if (dfq.external) {
                      window.open(dfq.path, "_blank");
                    } else if (dfq.path !== undefined) {
                      if (dfq.result === "Best Bets of All Time") {
                        console.log("setting init sort 5")
                        navigate(dfq.path, {state:{init_sort: 5}});
                      } else if (dfq.result === "Best Bets in the last 7 days") {
                        console.log("setting init sort 1")
                        navigate(dfq.path, {state:{init_sort: 1}});
                      } else if (dfq.result === "Best Bets in the last 1 month") {
                        console.log("setting init sort 2")
                        navigate(dfq.path, {state:{init_sort: 2}});
                      } else if (dfq.result === "Best Bets in the last 3 months") {
                        console.log("setting init sort 3")
                        navigate(dfq.path, {state:{init_sort: 3}});
                      } else if (dfq.result === "Best Bets in the last 1 year") {
                        console.log("setting init sort 4")
                        navigate(dfq.path, {state:{init_sort: 4}});
                      } else {
                        navigate(dfq.path);
                      }
                    } else {
                      interpretText(dfq.result);
                    }
                  }}>
                    <span style={{
                      color: getThemeData(theme).colorAccent,
                      fontWeight: 600,
                      fontSize: isMobile ? "0.95rem" : "1rem",
                      letterSpacing: isMobile ? "0.01rem" : "0.025rem",
                    }}>
                      {getSearchResultIcon(dfq.result)}
                      {dfq.result}
                    </span>
                  </div>
                  </>
                )
              })}
              <div style={{width: "100%", height: "42px"}}/>
              </>
            )}
            <div style={{
              width: "100%",
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <span style={{
                color: getThemeData(theme).textColor4,
                fontWeight: 600,
                fontSize: "14px",
                letterSpacing: "0.025rem",
              }}>
                {"ACTIVE BETS"}
              </span>
            </div>
            <div style={{width: "100%", height: "12px"}}/>
            <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
            {defaultQuestionsSection1.map((dfq, dfi) => {
              return (
                <>
                <div style={{width: "100%", height: "12px"}}/>
                <div style={{
                  width: "100%",
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  cursor: "pointer",
                }} onClick={() => {
                  if (dfq.result === "Best Bets of All Time") {
                    console.log("setting init sort 5")
                    navigate(dfq.path, {state:{init_sort: 5}});
                  } else if (dfq.result === "Best Bets in the last 7 days") {
                    console.log("setting init sort 1")
                    navigate(dfq.path, {state:{init_sort: 1}});
                  } else if (dfq.result === "Best Bets in the last 1 month") {
                    console.log("setting init sort 2")
                    navigate(dfq.path, {state:{init_sort: 2}});
                  } else if (dfq.result === "Best Bets in the last 3 months") {
                    console.log("setting init sort 3")
                    navigate(dfq.path, {state:{init_sort: 3}});
                  } else if (dfq.result === "Best Bets in the last 1 year") {
                    console.log("setting init sort 4")
                    navigate(dfq.path, {state:{init_sort: 4}});
                  } else {
                    navigate(dfq.path);
                  }
                }}>
                  <span style={{
                    color: getThemeData(theme).colorAccent,
                    fontWeight: 600,
                    fontSize: isMobile ? "0.95rem" : "1rem",
                    letterSpacing: isMobile ? "0.01rem" : "0.025rem",
                  }}>
                    {getSearchResultIcon(dfq.result)}
                    {dfq.result}
                  </span>
                </div>
                </>
              )
            })}
            <div style={{width: "100%", height: "42px"}}/>
            <div style={{
              width: "100%",
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <span style={{
                color: getThemeData(theme).textColor4,
                fontWeight: 600,
                fontSize: "14px",
                letterSpacing: "0.025rem",
              }}>
                {"BETTING STATS AND SITE RECAPS"}
              </span>
            </div>
            <div style={{width: "100%", height: "12px"}}/>
            <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
            {defaultQuestionsSection2.map((dfq, dfi) => {
              return (
                <>
                <div style={{width: "100%", height: "12px"}}/>
                <div style={{
                  width: "100%",
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  cursor: "pointer",
                }} onClick={() => {
                  if (dfq.result === "Best Bets of All Time") {
                    console.log("setting init sort 5")
                    navigate(dfq.path, {state:{init_sort: 5}});
                  } else if (dfq.result === "Best Bets in the last 7 days") {
                    console.log("setting init sort 1")
                    navigate(dfq.path, {state:{init_sort: 1}});
                  } else if (dfq.result === "Best Bets in the last 1 month") {
                    console.log("setting init sort 2")
                    navigate(dfq.path, {state:{init_sort: 2}});
                  } else if (dfq.result === "Best Bets in the last 3 months") {
                    console.log("setting init sort 3")
                    navigate(dfq.path, {state:{init_sort: 3}});
                  } else if (dfq.result === "Best Bets in the last 1 year") {
                    console.log("setting init sort 4")
                    navigate(dfq.path, {state:{init_sort: 4}});
                  } else {
                    navigate(dfq.path);
                  }
                }}>
                  <span style={{
                    color: getThemeData(theme).colorAccent,
                    fontWeight: 600,
                    fontSize: isMobile ? "0.95rem" : "1rem",
                    letterSpacing: isMobile ? "0.01rem" : "0.025rem",
                  }}>
                    {getSearchResultIcon(dfq.result)}
                    {dfq.result}
                  </span>
                </div>
                </>
              )
            })}
          </div>
        </div>
      </InnerHeaderNavWrap2>
      <div style={{width: "100%", height: "12px"}}/>
    </div>
  );
};

const Search = ({
  isMacLike,
  isIOS,
  setShowSR,
  isMobile,
  showSR,
  setIsQuery,
  isQuery,
  setLoadingNLUPrediction,
  //setSearchAiInterpretation
}) => {
  const {theme} = useGetTheme();
  const {prevSearches, setPrevSearches} = useGetSearchHistory();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("LOCATION", location)
  //const text = generalSearchState((state) => state.text);
  //const show = generalSearchState((state) => state.show);
  //const isPressed = pressEnterForm((state) => state.isPressed);
  const setIsPressed = pressEnterForm((state) => state.setIsPressed);
  //const sign = pressEnterForm((state) => state.sign);
  //const dummy = pressEnterForm((state) => state.dummy);
  //const setText = generalSearchState((state) => state.setText);
  const setShow = generalSearchState((state) => state.setShow);
  const [highLighted, sethighLighted] = useState(0);
  const [activeCursor, setActiveCursor] = useState(0);
  const [text, setText] = useState("");
  const [focusedInp, setFocusedInp] = useState(false);
  const [searchResults, setSearchResults] = useState(
    location.pathname !== '/bets/top'
      ? (prevSearches !== undefined && prevSearches !== null && prevSearches.length > 0)
        ? [...prevSearches.split(",").map((v,i) => {
            return {
              result: v.split(' ').map(w => {
                if (leaguestmap.includes(w.toLowerCase())) {
                  return w.toUpperCase();
                } else {
                  return w[0].toUpperCase() + w.slice(1);
                }
              }).join(' '),
              subtitle: "",
              path: undefined,
              external: false,
            }
          }), ...defaultSearchItems].slice(0,maxResults)
        : [...defaultSearchItems]
      : (prevSearches !== undefined && prevSearches !== null && prevSearches.length > 0)
        ? [...prevSearches.split(",").map((v,i) => {
            return {
              result: v.split(' ').map(w => {
                if (leaguestmap.includes(w.toLowerCase())) {
                  return w.toUpperCase();
                } else {
                  return w[0].toUpperCase() + w.slice(1);
                }
              }).join(' '),
              subtitle: "",
              path: undefined,
              external: false,
            }
          }), ...defaultSearchItems.filter(it => {
            return (
              it.result !== "Best Bets in the last 7 days" &&
              it.result !== "Best Bets in the last 1 month" &&
              it.result !== "Best Bets in the last 3 months" &&
              it.result !== "Best Bets in the last 1 year" &&
              it.result !== "Best Bets of All Time"
            );
          })].slice(0,maxResults)
        : [...defaultSearchItems].filter(it => {
            return (
              it.result !== "Best Bets in the last 7 days" &&
              it.result !== "Best Bets in the last 1 month" &&
              it.result !== "Best Bets in the last 3 months" &&
              it.result !== "Best Bets in the last 1 year" &&
              it.result !== "Best Bets of All Time"
            );
          })
  );
  const [searchAiInterpretation, setSearchAiInterpretation] = useState("");
  const [apiTeams, setApiTeams] = useState([]);
  const [apiSites, setApiSites] = useState([]);
  const [apiLeagues, setApiLeagues] = useState([]);

  const width1Small = isMobile ? "98%" : "480px"; //"300px";
  const width2Small = isMobile ? "90%" : "428px"; //"248px";

  const height1 = "520px";
  const height2 = "400px";
  const width1 = isMobile ? "98%" : "500px";

  const emailInput = useRef(null);
  const wrapperRef = useRef(null);
  const mainRef = useRef(null);

  //const fuse = new Fuse(searchItems, {
  //  keys: ["result"],
  //});

  //const searchResults = fuse.search(text);

  const handleClickOutside = (event) => {
    if (mainRef.current && !mainRef.current.contains(event.target)) {
      setShow(false);
      setText("");
      setShowSR(false);
      setIsQuery(false);
    }
  };

  function handleSearchAiInterpretation() {
    if (
      searchAiInterpretation !== undefined &&
      searchAiInterpretation !== null &&
      searchAiInterpretation !== ""
    ) {
      sethighLighted(0);
      setIsPressed(false);
      setShow(false);
      setShowSR(false);
      setText("");
      const parsedEndpoint = parseNLUJointIntentSlotPrediction(
        searchAiInterpretation,
        apiTeams,
        apiLeagues,
        apiSites,
      );
      setIsQuery(false);
      navigate(parsedEndpoint);
    }
  }

  String.prototype.fuzzy = function (s) {
    var hay = this.toLowerCase(), i = 0, n = -1, l;
    s = s.toLowerCase();
    for (; l = s[i++] ;) if (!~(n = hay.indexOf(l, n + 1))) return false;
    return true;
  };

  function fuzzyMatch(s1, s2) {
    return (s1.toString()).fuzzy(s2.toString(), 0.9);
  }

  function updateSearchResults() {
    try {
      if (text !== undefined && text !== null && text !== "" && text.length > minTextLengthForSearchResults) {
        const search_res = [...searchItems].filter((val, ind) => {
          return fuzzyMatch(val.result, text)
        });
        setSearchResults(location.pathname !== '/bets/top'
          ? [...search_res.slice(0,maxResults)]
          : [...search_res].filter(it => {
            return (
              it.result !== "Best Bets in the last 7 days" &&
              it.result !== "Best Bets in the last 1 month" &&
              it.result !== "Best Bets in the last 3 months" &&
              it.result !== "Best Bets in the last 1 year" &&
              it.result !== "Best Bets of All Time"
            );
          }).slice(0,maxResults)
        );
        //fuse.search(text);
      } else if (text === "" && focusedInp) {
        setSearchResults(
          location.pathname !== '/bets/top'
            ? (prevSearches !== undefined && prevSearches !== null && prevSearches.length > 0)
              ? [...prevSearches.split(",").map((v,i) => {
                  return {
                    result: v.split(' ').map(w => {
                      if (leaguestmap.includes(w.toLowerCase())) {
                        return w.toUpperCase();
                      } else {
                        return w[0].toUpperCase() + w.slice(1);
                      }
                    }).join(' '),
                    subtitle: "",
                    path: undefined,
                    external: false,
                  }
                }), ...defaultSearchItems].slice(0,maxResults)
              : [...defaultSearchItems]
            : (prevSearches !== undefined && prevSearches !== null && prevSearches.length > 0)
              ? [...prevSearches.split(",").map((v,i) => {
                  return {
                    result: v.split(' ').map(w => {
                      if (leaguestmap.includes(w.toLowerCase())) {
                        return w.toUpperCase();
                      } else {
                        return w[0].toUpperCase() + w.slice(1);
                      }
                    }).join(' '),
                    subtitle: "",
                    path: undefined,
                    external: false,
                  }
                }), ...defaultSearchItems.filter(it => {
                  return (
                    it.result !== "Best Bets in the last 7 days" &&
                    it.result !== "Best Bets in the last 1 month" &&
                    it.result !== "Best Bets in the last 3 months" &&
                    it.result !== "Best Bets in the last 1 year" &&
                    it.result !== "Best Bets of All Time"
                  );
                })].slice(0,maxResults)
              : [...defaultSearchItems].filter(it => {
                  return (
                    it.result !== "Best Bets in the last 7 days" &&
                    it.result !== "Best Bets in the last 1 month" &&
                    it.result !== "Best Bets in the last 3 months" &&
                    it.result !== "Best Bets in the last 1 year" &&
                    it.result !== "Best Bets of All Time"
                  );
                })
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  function updateSearchHistory(searchText) {
    var searchHist = prevSearches;
    if (searchHist === undefined || searchHist === null || searchHist.length === 0) {
      searchHist = searchText;
    } else {
      if (!searchHist.includes(searchText)) {
        if (searchHist.includes(",") && searchHist.split(",").length >= MAX_SEARCH_HISTORY_STORE) {
          searchHist = searchHist.split(",").slice(0, MAX_SEARCH_HISTORY_STORE - 1).join(",");
        }
        searchHist = (searchText + "," + searchHist);
      }
    }
    setPrevSearches(searchHist);
  }

  async function interpretText(t) {
    if (t !== undefined && t !== null && t !== "") {
      setLoadingNLUPrediction(true);
      updateSearchHistory(t);
      const interpretation = await getNLUModelPrediction(t.toLowerCase());
      setSearchAiInterpretation(interpretation);
      setLoadingNLUPrediction(false);
      var newSearchObj = {search_text: t};
      var userId = sessionStorage.getItem("id");
      if (userId !== undefined && userId !== null) {
        newSearchObj.user_id = JSON.parse(userId);
      }
      const newSearchResponse = await addNewSearch(newSearchObj);
      console.log("NEW SEARCH", newSearchResponse)
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      //console.log("ENTERED", text)
      await interpretText(text)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (emailInput.current) {
      //console.log("SETTING INPUT FOCUS");
      emailInput.current.focus();
      setShowSR(true);
    }
  }, []);

  const loadApiTeams = async () => {
    const resp = await getTeams();
    const data = resp.data;
    const teamids = [...data].map((val, _ind) => val['team_id'].toString());
    const leagues = [...new Set(teamids.map((val, _ind) => val.split('-')[0]))];
    setApiTeams(teamids);
    setApiLeagues(leagues);
  }

  const loadApiSites = async () => {
    const resp = await getSites();
    const data = resp.data;
    const siteids = [...data].map((val, _ind) => val['site_id'].toString());
    setApiSites(siteids);
  }

  useEffect(() => {
    loadApiTeams();
  }, []);

  useEffect(() => {
    loadApiSites();
  }, []);

  useEffect(() => {
    handleSearchAiInterpretation();
  },[searchAiInterpretation])

  useEffect(() => {
    updateSearchResults()
  },[text, focusedInp])

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  /*
  useEffect(() => {
    if (isPressed && searchResults.length > 0) {
      //navigate(searchResults[highLighted].item.path);
      (searchResults[highLighted].external
        ? window.open(searchResults[highLighted].path, "_blank")
        : searchResults[highLighted].path !== undefined
          ? navigate(searchResults[highLighted].path)
          : interpretText(searchResults[highLighted].result) //setSearchAiInterpretation(searchResults[highLighted].result)
      );
      sethighLighted(0);
      setIsPressed(false);
      setShow(false);
      ////setShowSR(false);
      setText({
        text: "",
      });
    }
  }, [isPressed]);
  */

  /*
  useEffect(() => {
    if (text === "") {
      sethighLighted(0);
      setShow(false);
      //setShowSR(false);
    } else {
      setShow(true);
    }
  }, [text]);
  */

  /*
  useEffect(() => {
    if (sign === "ArrowDown") {
      if (highLighted < searchResults.length - 1) {
        if (highLighted > 2) {
          document.getElementById("search-result").scrollTop += 90;
          sethighLighted(highLighted + 1);
        } else {
          sethighLighted(highLighted + 1);
        }
      }
    }
    if (sign === "ArrowUp") {
      if (highLighted > 0) {
        if (highLighted > 2) {
          document.getElementById("search-result").scrollTop -= 90;
          sethighLighted(highLighted - 1);
        } else {
          sethighLighted(highLighted - 1);
        }
      }
    }
  }, [sign, dummy]);
  */

  /*
  const arrowDownHandler = (e) => {
    if (e.key === 'ArrowDown' && activeCursor > 0) {
      console.log("CURSOR UP")
      const newActiveCursor = activeCursor - 1;
      document.getElementById(`SearchResult${newActiveCursor}`).focus();
      setActiveCursor(newActiveCursor);
    } else if (e.key === 'ArrowUp' && activeCursor < searchResults.length - 1) {
      console.log("CURSOR DOWN")
      const newActiveCursor = activeCursor + 1;
      document.getElementById(`SearchResult${newActiveCursor}`).focus();
      setActiveCursor(newActiveCursor);
    }
  };

  useEffect(() => {
    window.addEventListener("arrowdown", arrowDownHandler);
    return () => {
      window.removeEventListener("arrowdown", arrowDownHandler);
    };
  });
  */

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      padding: "12px 0px 12px 0px",
      height: height1,
      width: "100%",
      maxWidth: width1,
      borderRadius: "10px",
      border: `1px solid ${getThemeData(theme).borderColor}`,
      background: getThemeData(theme).bgGlobal,
    }} ref={mainRef}>
      <InnerHeaderNavWrap
        color={"transparent"}
        width={width1Small}
      >
        <div style={{
          display: "flex",
          flexDirection: "row",
          background: "transparent",
          justifyContent: "center",
          alignItems: "center",
          gap: "6px",
          padding: "12px 0px 12px 12px",
          width: "100%",
        }}>
          {isMobile && <div style={{width: "6px"}}/>}
          <FaSearch size={16} color={getThemeData(theme).iconColor}/>
          <Input
            width={width2Small}
            type="text"
            name="Search"
            value={text}
            onChange={(event) => {
              event.preventDefault();
              setText(event.target.value);
              //setText({text: event.target.value});
            }}
            placeholder={
              windowDimension.winWidth > 475
                ? "Search leagues, teams, sites, odds or anything on Crunchtime"
                : "Search on Crunchtime"
            }
            color={getThemeData(theme).textColor}
            colorP={getThemeData(theme).textColor4}
            ref={emailInput}
            onFocus={() => {
              setFocusedInp(true)
            }}
            onBlur={() => {
              setTimeout(() => {
                setFocusedInp(false)
              },[200])
            }}
            onKeyDown={handleKeyDown}
            //id="inp"
          />
        </div>
        <div style={{
          height: height2,
          width: "100%",
          padding: "6px 0px"
        }}>
          <GeneralSearchWrapper
            width={width1Small}
            height={height2}
            ref={wrapperRef}
            id="search-result"
          >
            {(focusedInp || isQuery) && searchResults.map((search, index) => {
                return (
                  <SearchContainer
                    id={`SearchResult${index}`}
                    theme={theme}
                    active={activeCursor === index}
                    onlyOne={index === 0 && searchResults.length === 1}
                    onClick={() => {
                      //console.log("HIT")
                      if (search.external) {
                        setIsQuery(false);
                        window.open(search.path, "_blank");
                      } else if (search.path !== undefined) {
                        setIsQuery(false);
                        if (search.result === "Best Bets of All Time") {
                          console.log("setting init sort 5")
                          navigate(search.path, {state:{init_sort: 5}});
                        } else if (search.result === "Best Bets in the last 7 days") {
                          console.log("setting init sort 1")
                          navigate(search.path, {state:{init_sort: 1}});
                        } else if (search.result === "Best Bets in the last 1 month") {
                          console.log("setting init sort 2")
                          navigate(search.path, {state:{init_sort: 2}});
                        } else if (search.result === "Best Bets in the last 3 months") {
                          console.log("setting init sort 3")
                          navigate(search.path, {state:{init_sort: 3}});
                        } else if (search.result === "Best Bets in the last 1 year") {
                          console.log("setting init sort 4")
                          navigate(search.path, {state:{init_sort: 4}});
                        } else {
                          navigate(search.path);
                        }
                      } else {
                        interpretText(search.result);
                        //setSearchAiInterpretation(search.result)
                      }
                      //sethighLighted(0);
                      //setIsPressed(false);
                      //setShow(false);
                      //setShowSR(false);
                      //setText({
                      //  text: "",
                      //});
                    }}
                  >
                    <SearchWrapper
                      width={width2Small}
                      theme={theme}
                      firstItem={index === 0}
                      lastItem={index === searchResults.length - 1}
                    >
                      <div className="text-wrapper">
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center",}}>
                          <span className="title">
                            {getSearchResultIcon(search.result)}
                            {search.result}
                          </span>
                          {search.external && (
                            <>
                              <div style={{width: "4px"}}/>
                              <ExternalLink className="title" size={16}/>
                            </>
                          )}
                        </div>
                        {/*<span className="subtitle">{search.item.subtitle}</span>*/}
                      </div>
                      {/*
                      <ArrowRight
                        size={18}
                        className="arrow"
                      />*/}
                    </SearchWrapper>
                  </SearchContainer>
                );
              })
            }
          </GeneralSearchWrapper>
        </div>
      </InnerHeaderNavWrap>
      <div style={{width: "100%", height: "12px"}}/>
      <div style={{width: "100%", height: "1px", background: getThemeData(theme).borderColor}}/>
      {!isMobile && (
        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", padding: "12px 0px 12px 12px"}}>
          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
            <span style={{
              fontSize: "0.85rem",
              color: getThemeData(theme).colorAccent,
              //letterSpacing: "0.01rem",
              fontWeight: 600,
            }}>
              {isMacLike || isIOS ? "⌘ K" : "Ctrl-K"}
            </span>
          </div>
          <div style={{width: "6px"}}/>
          <div>
            <span style={{
              fontSize: "0.85rem",
              color: getThemeData(theme).colorAccent,
              //letterSpacing: "0.01rem",
              fontWeight: 600,
            }}>
              {"Open Search"}
            </span>
          </div>
          <div style={{width: "6px"}}/>
          <div style={{borderRadius: "10px",border: `1px solid ${getThemeData(theme).borderColor}`, padding: "2px 8px"}}>
            <span style={{
              fontSize: "0.85rem",
              color: getThemeData(theme).colorAccent,
              //letterSpacing: "0.01rem",
              fontWeight: 600,
            }}>
              {"ESC"}
            </span>
          </div>
          <div style={{width: "6px"}}/>
          <div>
            <span style={{
              fontSize: "0.85rem",
              color: getThemeData(theme).colorAccent,
              //letterSpacing: "0.01rem",
              fontWeight: 600,
            }}>
              {"Close"}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;