import { MdWarning } from "react-icons/md";
import styled from "styled-components";

const ActionButton = styled.button`
  width: fit-content;
  background: linear-gradient(to right, #25adea 0%, #8a53cd 100%);
  color: white;
  padding: 8px 16px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  &:hover {
    filter: brightness(0.8);
  }
`;

export const MainWrapper = styled.div`
  height: calc(100%);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.s ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3dbc;
  z-index: 200;
`;

export const AlterModalWrap = styled.div`
  height: 300px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AlterModalTop = styled.div`
  height: 230px;
  width: 100%;
  background-color: #18191d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const AlterModalBottom = styled.div`
  height: 60px;
  width: 100%;
  background-color: #18191d;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 12px 0px;
`;

export const DescriptionCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 12px 0px;
  gap: 12px;
`
export const Description = styled.span`
  font-size: 16px;
  color: #d8d8d8;
  font-weight: 600;
`;

export const Description2 = styled.span`
  font-size: 16px;
  color: #d8d8d8;
`;

export const WarnCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 6px;
  span {
    color: yellow;
    font-weight: 600;
    font-size: 16px;
  }
`

const ConfirmRegenModal = ({
  showActive,
  setShowActive,
  actionName,
  handleAction,
}) => {

  const exitDetailHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      setShowActive();
    }
  };

  return (
    <MainWrapper
      onClick={(e) => {
        exitDetailHandler(e);
      }}
      className="shadow"
      s={showActive}
    >
      <AlterModalWrap>
        <AlterModalTop>
          <TextContainer>
            <div style={{padding: "22px"}}>
              <Title>{"Regenerate your API Key"}</Title>
              <WarnCont>
                <MdWarning size={16} color={"yellow"}/>
                <span>{"This action is irreversible"}</span>
              </WarnCont>
              <DescriptionCont>
                <Description>
                  {"A new private API key will be sent to your registered email."}
                </Description>
                <Description2>
                  {"Update any applications with this key to ensure uninterrupted service."}
                </Description2>
              </DescriptionCont>
              <ActionButton
                style={{
                  background: "linear-gradient(to right, #25adea 0%, #a151ff 100%)",
                  color: "black",
                  fontWeight: 600,
                }}
                onClick={() => {
                  handleAction()
                }}
              >
                {actionName}
              </ActionButton>
            </div>
          </TextContainer>
        </AlterModalTop>
      </AlterModalWrap>
    </MainWrapper>
  );
};

export default ConfirmRegenModal;